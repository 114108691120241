import React from 'react';
import BaseApi from './BaseApi';

export default class ImageManagementApi extends BaseApi {

    /**
     * /api/v1/image
     */
    static _fetchImage(params, success, error) {
        super._fetch('/api/v1/image', 'POST', params, success, error);
    }
}