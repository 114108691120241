import React, {useState, Fragment, Component} from 'react';
import {range, _dateToString} from '../../helpers/utils';
import Calendar, {CalendarTileProperties} from 'react-calendar';
import Moment from 'moment';
import './CongregateCreate.css';
import FontAwesome from "react-fontawesome";
import CommonSegmentButton from '../../components/CommonSegmentButton';
import CommonIconTitle from '../../components/CommonIconTitle';

import StoresApi from '../../helpers/api/StoresApi';
import StocksApi from '../../helpers/api/StocksApi';
import CoursesApi from '../../helpers/api/CoursesApi';
import CongregationsApi from '../../helpers/api/CongregationsApi';
import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';
import BenefitApi from '../../helpers/api/BenefitApi';
import SeatsApi from '../../helpers/api/SeatsApi';

import {SEAT_TYPES} from '../../Construct';

import reduction_ratio from 'assets/reduction_ratio.png';
import btn_add_image from 'assets/btn_add_image.png';
import local from 'assets/local.png';
import icon_titi from 'assets/icon_titi.png';
import icon_user from 'assets/icon_user.png';
import icon_calendar from 'assets/icon_calendar.png';
import icon_shape from 'assets/icon_shape.png';
import icon_location from 'assets/icon_location.png';
import icon_directions from 'assets/icon_directions.png';
import icon_bus from 'assets/icon_bus.png';
import icon_food from 'assets/icon_food.png';
import icon_price from 'assets/icon_price.png';
import icon_exclamation from 'assets/icon_exclamation.png';
import icon_tel from 'assets/icon_tel.png';
import icon_exit from 'assets/icon_exit.png';
import icon_seat from 'assets/icon_seat.png';
import icon_smoking from 'assets/icon_smoking.png';
import icon_web from 'assets/icon_web.png';


// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import no_image from 'assets/no_image.png';

import Modal from '../../components/Modal';
import CommonImageSwiper from '../../components/CommonImageSwiper';
import CommonCourseCell from '../../components/CommonCourseCell';
import CommonUserCell from '../../components/CommonUserCell';
import Header from '../../components/Header';

enum VIEW_TYPE {
    DETAIL,     // 0
    TITLE,   // 1
    CONFIRM,   // 2
}

enum CONGREGATE_TYPE {
    RESERVE,     // 0
    USE,   // 1
    BENEFIT,   // 2
}

const NUMBER_FILTER_NONE = 99;
const NUMBER_FILTER = [
    '〜4人', '4-8人', '8-12人', '12人以上',
];

// デフォルトのリクエスト時間
const REQ_TIME = '19:00';

interface CongregateCreateProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    // currentUser: {
    //     communities?: {
    //         id: string;
    //         name: string;
    //         communities_users: {}[];
    //         community_image?: {
    //             image: {
    //                 content: {
    //                     url: string;
    //                 }
    //             }
    //         };
    //     }[] | null;
    // };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
        communities?: {
            id: string;
            name: string;
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
            communities_users: {
                id: number;
                user_id: string;
                nickname?: string;
                avatar_url?: string;
            }[];
        }[] | null;
    };
}

interface CongregateCreateState {

    view: VIEW_TYPE;
    type: CONGREGATE_TYPE;
    request_mode: boolean;

    name?: string;
    comment?: string;
    request?: string;
    number_filter: number;
    request_date?: string;
    request_seat_id?: number;

    store_id: number;
    render: boolean;
    show_desc: boolean;
    swiper: any | null;
    image_index: number;
    course_index: number;
    date: any | null;

    phone_number: string;

    store?: {
        address: string;
        area_list: string[];
        description: string;
        id: number;
        name: string;
        phone_number: string;
        place: string;
        reduction_ratio: string;
        stock_count: number;
        url: string;
        courses: {
            id: number;
            name: string;
            benefits?: string;
            description: string;
            overview: string;
            price: number;
            list_price: number;
            time: number;
            tag_list: string[];
        }[];
        images: {
            content: {
                url: string;
            }
        }[];
    };
    stocks?: {
        [key: string]: {
            id: number;
            max_number_of_sales_tables: number;
            number_of_reserved_tables: number;
            number_of_sales_tables: number;
            decision_number_of_people: number;
            number_of_people: number;
            seat_id: number;
            start_time: string;
            end_time: string;
            type_of_seat: number;
            smoking_is_enabled: boolean;
        }[];
    };
    courses?: {
        id: number;
        name: string;
        benefits?: string;
        description: string;
        overview: string;
        price: number;
        list_price: number;
        time: number;
        images: {
            content: {
                url: string;
            }
        }[];
        tag_list: string[];
    }[];
    course?: {
        id: number;
        name: string;
        benefits?: string;
        description: string;
        overview: string;
        price: number;
        list_price: number;
        time: number;
        images: {
            content: {
                url: string;
            }
        }[];
        tag_list: string[];
    };
    select_date?: {
        id: number;
        max_number_of_sales_tables: number;
        number_of_reserved_tables: number;
        number_of_sales_tables: number;
        decision_number_of_people: number;
        number_of_people: number;
        seat_id: number;
        start_time: string;
        end_time: string;
        type_of_seat: number;
    };
    date_list?: {
        id: number;
        max_number_of_sales_tables: number;
        number_of_reserved_tables: number;
        number_of_sales_tables: number;
        decision_number_of_people: number;
        number_of_people: number;
        seat_id: number;
        start_time: string;
        end_time: string;
        type_of_seat: number;
        smoking_is_enabled: boolean;
    }[];
    show_user_list: boolean;
    community?: {
        id: string;
        name: string;
        communities_users: {
            id: number;
            user_id: string;
            nickname?: string;
            avatar_url?: string;
        }[];
    };
    communities_users: {
        id: number;
        user_id: string;
        nickname: string;
        user: {
            avatar_url: string;
        }
    }[];
    selected_user_list: {
        id: number;
        user_id: string;
        nickname: string;
        user: {
            avatar_url: string;
        }
    }[]; // 選択済みユーザー
    number_of_people: number;
    benefits: {
        id: number;
        number_of_people: number;
        price: number;
        visit_status: number;
        discount: number;
        description: string;
        favor_flag: boolean;
        communities: {
            id: number;
        }[]
    }[];
    benefit?: {
        id: number;
        number_of_people: number;
        price: number;
        visit_status: number;
        discount: number;
        description: string;
        favor_flag: boolean;
        communities: {
            id: number;
        }[];
    };
    seats: {
        id: number;
        type_of_seat: number;
        number_of_people: number;
        number_of_seats: number;
        decision_number_of_people: number;
        description: string;
        remarks: string;
        smoking_is_enabled: boolean;
    }[];
}

class CongregateCreate extends React.Component<CongregateCreateProps, CongregateCreateState> {

    constructor(props: any) {
        super(props);
        this.state = {
            view: VIEW_TYPE.DETAIL,
            type: CONGREGATE_TYPE.RESERVE,
            request_mode: false,
            request_date: REQ_TIME,
            store_id: props.match.params.id,
            render: true,
            show_desc: false,
            swiper: null,
            image_index: 0,
            course_index: 0,
            date: null,
            show_user_list: false,
            communities_users: [],
            selected_user_list: [],
            number_filter: NUMBER_FILTER_NONE,
            number_of_people: 0,
            benefits: [],
            seats: [],
            phone_number: '',
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchStoresShow();
        this._fetchStocks();
        this._fetchCourses();
        this._fetchBenefits();
        this._fetchSeats();

        if (this.props.currentUser.communities && this.props.currentUser.communities.length == 0) {

            setTimeout(() => {
                this.props.showDialog(
                    "確認",
                    `イベントを作成するためにはコミュニティに所属する必要があります。\nコミュニティを作成しますか？`,
                    (e: any) => {
                        this.props.hideDialog();
                        this.props.history.push(`/community/create/edit`);
                    },
                    (e: any) => {
                        this.props.hideDialog();
                    },
                );
            }, 1000);
        }
    }

    _changeTab = (view: VIEW_TYPE) => {

        if (view != VIEW_TYPE.DETAIL) {
            if (this.state.type == CONGREGATE_TYPE.RESERVE && !this.state.course) {
                this.props.showDialog(
                    "確認",
                    `コースを選択してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (this.state.type == CONGREGATE_TYPE.RESERVE && !this.state.select_date && !this.state.request_mode) {
                this.props.showDialog(
                    "確認",
                    `開催日時を選択してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (!this.state.number_of_people || Number(this.state.number_of_people) < 2) {
                this.props.showDialog(
                    "確認",
                    `人数は2名以上としてください`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (!this.state.community) {
                this.props.showDialog(
                    "確認",
                    `コミュニティを選択してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }
        }

        this.setState({
            view,
        }, () => {
            window.scrollTo(0, 0);
        });
    }

    _onChange = (date: Date | Date[]) => {

        let d: Date = Array.isArray(date) ? date[0] : date;
        if (this.state.request_mode) {
            this.setState({
                date: d,
                date_list: [],
                select_date: undefined,
            });
        }

        let target = Moment(d).format('YYYYMMDD');
        if (!this.state.stocks || !this.state.stocks[target]) {
            return;
        }

        let date_list = this._targetDateList(target);

        this.setState({
            date: d,
            date_list: date_list,
            select_date: undefined,
        });
    }

    _targetDateList = (target_date: string) => {

        if (!this.state.stocks) {
            return [];
        }

        let date_list = this.state.stocks[target_date];

        var min = this.state.number_of_people;
        var max = 0;

        // // '〜4人', '4-8人', '8-12人', '12人以上',
        // switch (this.state.number_filter) {
        //     case 0:
        //         min = 1;
        //         max = 4;
        //         break;
        //     case 1:
        //         min = 4;
        //         max = 8;
        //         break;
        //     case 2:
        //         min = 8;
        //         max = 12;
        //         break;
        //     case 3:
        //         min = 12;
        //         max = 0;
        //         break;
        // }

        var list = [];
        for (const date of date_list) {
            if (min != 0 && min > date.number_of_people) {
                continue;
            }
            if (max != 0 && max < date.number_of_people) {
                continue;
            }
            list.push(date);
        }
        return list;
    }

    _tileDisabled = (props: CalendarTileProperties) => {

        if (!this.state.course) {
            return true;
        }

        // for (let schedule of this.state.production.schedules) {
        //     if (schedule.format == target) {
        //         return false;
        //     }
        // }

        if (Moment(props.date).isBefore(Date())) {
            return true;
        }

        return false;

        // let target = Moment(props.date).format('YYYYMMDD');
        // if (!this.state.stocks || !this.state.stocks[target]) {
        //     return true;
        // }
        //
        // // 座席数チェック 廃止処理は残しておく
        // let date_list = this._targetDateList(target);
        // if (date_list.length == 0) {
        //     return true;
        // }
        // return false;
    }

    _fetchStoresShow = () => {

        this.props.showSpinner(true);

        StoresApi._fetchStoresShow(
            this.state.store_id,
            {},
            (responseJson: any) => {
                // console.log('_fetchStoresShow', responseJson);
                this.props.showSpinner(false);

                // let images = [];
                // for (let i of responseJson.images) {
                //     images.push({image_data: i.content.url});
                // }
                //
                this.setState({
                    store: responseJson,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _fetchStocks = () => {
        this.props.showSpinner(true);

        let moment = Moment(new Date());

        StocksApi._fetchStocks(
            this.state.store_id,
            {
                start_day: moment.format('YYYY-MM-DD'),
                end_day: moment.startOf('month').add(3, 'M').endOf('month').format('YYYY-MM-DD'),
                // start_day: '2019-10-01',
                // end_day: '2019-10-31',
                per: 9999,
            },
            (responseJson: any) => {
                // console.log('_fetchStocks', responseJson);
                this.props.showSpinner(false);
                //
                // 日毎、店舗毎にデータを分けておく
                var stocks: any = {};
                var request_mode = true;
                if (responseJson.stocks) {
                    for (let s of responseJson.stocks) {

                        // 在庫なしはスキップ
                        if ((s.number_of_sales_tables - s.number_of_reserved_tables) == 0) {
                            continue;
                        }

                        const target_date = Moment(s.start_time).format('YYYYMMDD');

                        if (!stocks[target_date]) {
                            stocks[target_date] = [];
                        }

                        stocks[target_date].push(s);
                        request_mode = false;
                    }
                }

                this.setState({
                    stocks: stocks,
                    request_mode,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _fetchSeats = () => {

        this.props.showSpinner(true);

        SeatsApi._fetchSeats(
            this.state.store_id,
            {},
            (responseJson: any) => {
                console.log('_fetchSeats', responseJson);
                this.props.showSpinner(false);

                this.setState({
                    seats: responseJson.seats,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _fetchCourses = () => {

        this.props.showSpinner(true);

        CoursesApi._fetchCourses(
            this.state.store_id,
            {},
            (responseJson: any) => {
                // console.log('_fetchCourses', responseJson);
                this.props.showSpinner(false);

                this.setState({
                    courses: responseJson.courses,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _fetchBenefits = () => {

        this.props.showSpinner(true);

        BenefitApi._fetchBenefits(
            this.state.store_id,
            {},
            (responseJson: any) => {
                console.log('_fetchBenefits', responseJson);
                this.props.showSpinner(false);

                this.setState({
                    benefits: responseJson.benefits,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _fetchCommunitiesUsers = () => {

        if (!this.state.community) {
            console.log('No community.');
            return;
        }

        this.props.showSpinner(true);

        CommunitiesUserApi._fetchCommunitiesUsers(
            this.state.community.name,
            {},
            (responseJson: any) => {
                console.log('_fetchCommunitiesUsers', responseJson);
                this.props.showSpinner(false);

                // let images = [];
                // for (let i of responseJson.images) {
                //     images.push({image_data: i.content.url});
                // }
                //
                this.setState({
                    communities_users: responseJson.communities_users,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _handleCommunityCreate = (e: any) => {

        e.preventDefault();

        this.props.showDialog(
            "確認",
            `コミュニティ作成画面に移動しますか？`,
            (e: any) => {
                this.props.hideDialog();
                this.props.history.push(`/community/create/edit`);
            },
            (e: any) => {
                this.props.hideDialog();
            },
        );
    }

    _handlePressSubmit = (e: any) => {

        e.preventDefault();

        if (this.state.view == VIEW_TYPE.DETAIL) {
            this._changeTab(VIEW_TYPE.TITLE);
            return;
        }

        if (this.state.view == VIEW_TYPE.TITLE) {
            this._changeTab(VIEW_TYPE.CONFIRM);
            return;
        }

        if (this.state.view == VIEW_TYPE.CONFIRM) {
            // 必要データ

            if (this.state.type == CONGREGATE_TYPE.RESERVE && this.state.phone_number.length == 0) {
                this.props.showDialog(
                    "確認",
                    `ご連絡先電話番号を入力してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (this.state.type == CONGREGATE_TYPE.RESERVE && !this.state.course) {
                this.props.showDialog(
                    "確認",
                    `コースを選択してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (this.state.type == CONGREGATE_TYPE.RESERVE && !this.state.select_date && !this.state.request_mode) {
                this.props.showDialog(
                    "確認",
                    `開催日時を選択してください。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (this.state.number_of_people && Number(this.state.number_of_people) < 2) {
                this.props.showDialog(
                    "確認",
                    `人数は2名以上としてください`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            if (this.state.request_mode) {
                this._postRequest();
            } else {
                this._postData();
            }

            // if (this.state.community) {

            // } else {
            //     // コミュニティなければ新規作成する
            //     CommunityManagementApi._fetchCommunitiesCreate({
            //             name: this.state.name,
            //             description: '',
            //         },
            //         (responseJson: any) => {
            //
            //             // console.log('_fetchCommunitiesCreate', responseJson);
            //
            //             this.setState({
            //                 community: responseJson,
            //             }, () => {
            //
            //                 if (this.props.currentUser.communities && this.state.community) {
            //                     let currentUser = this.props.currentUser;
            //                     var communities = this.props.currentUser.communities;
            //                     communities.push(this.state.community);
            //                     currentUser.communities = communities;
            //                     this.props.setCurrentUser(currentUser);
            //                 }
            //
            //                 // プロフィールも作成する
            //                 CommunitiesUserApi._fetchCommunitiesUsersEdit(
            //                     responseJson.name,
            //                     {
            //                         nickname: this.props.currentUser.profile ? this.props.currentUser.profile.last_name : '',
            //                         introduction: '',
            //                         introduction_tag_list: [],
            //                     },
            //                     (responseJson: any) => {
            //                         // console.log('_fetchCommunitiesUsersEdit', responseJson);
            //                         this._postData();
            //                     },
            //                     (error: any) => {
            //                         alert(error);
            //                         this.props.showSpinner(false);
            //                     },
            //                 );
            //             });
            //         },
            //         (error: any) => {
            //             // console.log(error);
            //             alert(error);
            //         },
            //     );
            // }
        }
    }

    _handleSelectNumberOfPeople = (number_of_people: number) => {
        console.log('_handleSelectNumberOfPeople', number_of_people);

        this.setState({
            number_of_people,
            benefit: undefined,
        }, () => {
            // 初期特典選択
            if (this.state.store && this.state.benefits) {
                for (const b of this.state.benefits) {
                    // 予約・来店
                    if (this.state.type == CONGREGATE_TYPE.USE && b.visit_status) {
                        continue;
                    } else if (this.state.type == CONGREGATE_TYPE.RESERVE && !b.visit_status) {
                        continue;
                    }
                    // 人数
                    if (this.state.number_of_people > 0 && b.number_of_people > this.state.number_of_people) {
                        continue;
                    }
                    this.setState({
                        benefit: b,
                    });
                    break;
                }
            }

            // 人数の在庫があるか確認
            var e = false;
            for (let s of this.state.seats) {
                if (number_of_people <= s.number_of_people) {
                    e = true;
                }
            }
            if (!e) {
                this.props.showDialog(
                    "確認",
                    `選択した人数で利用できる座席がありません。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
            }
        });
    }

    _handlePressUse = (e: any) => {

        e.preventDefault();

        if (this.state.number_of_people && Number(this.state.number_of_people) < 2) {
            this.props.showDialog(
                "確認",
                `人数は2名以上としてください`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showDialog(
            "確認",
            `来店特典を受けるにはに人数と特典を選択して店員さんと一緒に確認、利用ボタンを押してください。`,
            (e: any) => {
                this.props.hideDialog();
                this._postDataVisitors();
            },
            (e: any) => {
                this.props.hideDialog();
            },
        );
        return;
    }

    _postData = () => {

        if (!this.state.course) {
            return;
        }

        if (!this.state.select_date) {
            return;
        }

        if (!this.state.community) {
            return;
        }

        // 参加者リスト
        var congregation_roles_attributes = [];
        // 幹事
        var communities_user_id = 0;
        for (let cu of this.state.communities_users) {
            if (this.props.currentUser.user_id == cu.user_id) {
                communities_user_id = cu.id;
                break;
            }
        }
        congregation_roles_attributes.push({
            communities_user_id: communities_user_id,
            role: 0, // 幹事
        });

        // 招待
        for (let cu of this.state.selected_user_list) {
            congregation_roles_attributes.push({
                communities_user_id: cu.id,
                role: 2, // 招待
            });
        }

        this.props.showSpinner(true);
        CongregationsApi._fetchCongregationsCreate(
            {
                title: this.state.name || this.state.course.name,
                comment: this.state.comment,
                request: this.state.request,
                community_id: this.state.community.id,
                reserved_time: Moment(this.state.select_date.start_time).format('YYYY-MM-DD HH:mm'),
                stock_id: this.state.select_date.id,
                course_id: this.state.course.id,
                congregation_roles_attributes: congregation_roles_attributes,
                scope: 1, // 限定
                number_of_people: this.state.number_of_people,
                benefit_id: this.state.benefit ? this.state.benefit.id : '',
                phone_number: this.state.phone_number,
            },
            (responseJson: any) => {
                // console.log('_fetchCongregationsCreate', responseJson);
                this.props.showSpinner(false);

                this.props.showDialog(
                    "確認",
                    "イベントを作成しました。",
                    (e: any) => {
                        this.props.hideDialog();
                        this.props.history.push('/congregate/detail/' + responseJson.id);
                    },
                    null,
                );
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _postDataVisitors = () => {

        console.log('_postDataVisitors', this.state);

        if (!this.state.community) {
            console.log('no community');
            return;
        }

        this.props.showSpinner(true);
        StoresApi._fetchStoresVisitors(
            this.state.store_id,
            {
                number_of_people: this.state.number_of_people,
                benefit_id: this.state.benefit ? this.state.benefit.id : '',
                // visited_at: Moment().format('YYYY-MM-DD HH:mm'),
                community_id: this.state.community.id,
            },
            (responseJson: any) => {
                // console.log('_fetchCongregationsCreate', responseJson);
                this.props.showSpinner(false);
                this.props.showDialog(
                    "確認",
                    `特典情報を送信しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    _postRequest = () => {

        if (!this.state.course) {
            return;
        }

        // if (!this.state.select_date) {
        //     return;
        // }

        if (!this.state.community) {
            return;
        }

        // 参加者リスト
        var congregation_roles_attributes = [];
        // 幹事
        var communities_user_id = 0;
        for (let cu of this.state.communities_users) {
            if (this.props.currentUser.user_id == cu.user_id) {
                communities_user_id = cu.id;
                break;
            }
        }
        congregation_roles_attributes.push({
            communities_user_id: communities_user_id,
            role: 0, // 幹事
        });

        // 招待
        for (let cu of this.state.selected_user_list) {
            congregation_roles_attributes.push({
                communities_user_id: cu.id,
                role: 2, // 招待
            });
        }

        this.props.showSpinner(true);

        let reserved_time = Moment(this.state.date).format('YYYY-MM-DD ') + this.state.request_date;

        StoresApi._fetchStoresRsvRequests(
            this.state.store_id,
            {
                title: this.state.name || this.state.course.name,
                comment: this.state.comment,
                request: this.state.request,
                community_id: this.state.community.id,
                reserved_time: reserved_time,
                course_id: this.state.course.id,
                congregation_roles_attributes: congregation_roles_attributes,
                scope: 1, // 限定
                number_of_people: this.state.number_of_people,
                benefit_id: this.state.benefit ? this.state.benefit.id : '',
                phone_number: this.state.phone_number,
                seat_id: this.state.request_seat_id,
            },
            (responseJson: any) => {
                // console.log('_fetchCongregationsCreate', responseJson);
                this.props.showSpinner(false);

                this.props.showDialog(
                    "確認",
                    "リクエストを作成しました。",
                    (e: any) => {
                        this.props.hideDialog();
                        this.props.history.push('/congregate/detail/' + responseJson.id);
                    },
                    null,
                );
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    getTerms = (b: any) => {
        var terms = '';
        if (b.favor_flag) {
            terms += '贔屓コミュニティのみ';
        }
        if (b.price > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.price + '円以上';
        }
        if (b.number_of_people > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.number_of_people + '人以上';
        }
        if (terms.length > 0) terms += '、';
        terms += b.visit_status == 0 ? '来店で特典付与' : '予約で特典付与';
        return '条件：' + terms;
    }

    render() {

        // console.log('CongregateCreate props', this.props);
        // console.log('CongregateCreate state', this.state);

        // 画像
        var images = [];
        if (this.state.store) {
            for (var image of this.state.store.images) {
                images.push(image.content.url);
            }
        }

        // 時間
        var dates = [];
        if (this.state.store && this.state.date_list) {
            for (const date of this.state.date_list) {

                // テイクアウトのコースのみテイクアウトの在庫表示
                if (date.type_of_seat == 7 && this.state.course && this.state.course.tag_list.indexOf('テイクアウト') == -1) {
                    continue;
                }


                var c = 'box cursor row';
                if (this.state.select_date && this.state.select_date.id == date.id) {
                    c = 'box cursor row active';
                }

                var type_of_seat = ''; // FIXME SEAT_TYPES[date.type_of_seat] 型指定が通らない
                switch (date.type_of_seat) {
                    case 0:
                        type_of_seat = SEAT_TYPES[0];
                        break;
                    case 1:
                        type_of_seat = SEAT_TYPES[1];
                        break;
                    case 2:
                        type_of_seat = SEAT_TYPES[2];
                        break;
                    case 3:
                        type_of_seat = SEAT_TYPES[3];
                        break;
                    case 4:
                        type_of_seat = SEAT_TYPES[4];
                        break;
                    case 5:
                        type_of_seat = SEAT_TYPES[5];
                        break;
                    case 6:
                        type_of_seat = SEAT_TYPES[6];
                        break;
                    case 7:
                        type_of_seat = SEAT_TYPES[7];
                        break;
                }

                // console.log('date.type_of_seat', type_of_seat);

                // コース人数と一致しない在庫はスキップ ※廃止

                // if (!this.state.course || this.state.course.max_number != date.number_of_people) {
                //     continue;
                // }

                var title = '';
                var sub_title = '';
                if (date.type_of_seat == 7) {
                    title = ``;
                    sub_title = `${Moment(date.start_time).format('HH:mm 受け渡し')} - ${type_of_seat}`
                } else {
                    title = `${date.number_of_people}名席`;
                    sub_title = `${Moment(date.start_time).format('HH:mm 開始')} - ${type_of_seat}(${date.smoking_is_enabled ? '喫煙可' : '禁煙'})`;
                }

                dates.push(
                    <CommonCourseCell
                        active={(this.state.select_date && this.state.select_date.id == date.id)}
                        onClick={e => this.setState({select_date: date, request_mode: false})}
                        title={title}
                        sub_title={sub_title}
                    />
                );
            }
        }
        dates.push(
            <CommonCourseCell
                active={this.state.request_mode}
                onClick={e => this.setState({select_date: undefined, request_mode: true})}
                title={'予約をリクエストする'}
            />
        );

        // コース
        var courses = [];
        if (this.state.store && this.state.courses) {
            for (const course of this.state.courses) {

                // const course_index = i;

                var course_image: string = no_image;
                if (course.images.length > 0) {
                    const i = course.images[0];
                    course_image = i.content.url;
                }

                var takeout = course.tag_list.indexOf('テイクアウト') != -1;

                courses.push(
                    <CommonCourseCell
                        active={(this.state.course && this.state.course.id == course.id)}
                        label={takeout ? 'テイクアウト可能' : ''}
                        image={course_image}
                        onClick={(e: any) => this.setState({course: course, show_desc: false, select_date: undefined})}
                        title={course.name}
                        sub_title={course.benefits ? course.benefits.split('\n')[0] : ''}
                        price={course.price}
                        price_origin={course.list_price > course.price ? course.list_price : 0 }
                        time={course.time}
                    />
                );
            }
        }
        if (courses.length == 0) {
            courses.push(
                <div className="col-12 detail">
                    <p className="desc">開催日程を選択してください</p>
                </div>
            );
        }

        // 特典
        var benefits = [];
        if (this.state.store && this.state.benefits) {
            for (const b of this.state.benefits) {

                // 予約・来店
                if (this.state.type == CONGREGATE_TYPE.USE && b.visit_status) {
                    continue;
                } else if (this.state.type == CONGREGATE_TYPE.RESERVE && !b.visit_status) {
                    continue;
                }

                // 人数
                if (this.state.number_of_people > 0 && b.number_of_people > this.state.number_of_people) {
                    continue;
                }

                benefits.push(
                    <CommonCourseCell
                        active={(this.state.benefit && this.state.benefit.id == b.id)}
                        onClick={(e: any) => this.setState({
                            benefit: b,
                            community: undefined,
                        })}
                        title={b.description}
                        label={b.favor_flag ? '贔屓特典' : '通常特典'}
                        terms={this.getTerms(b)}
                    />
                );
            }
        }
        if (benefits.length == 0) {
            benefits.push(
                <div className="col-12 detail">
                    <p className="desc">選択できる特典がありません</p>
                </div>
            );
        }

        // 特典一覧
        var benefit_list = [];
        if (this.state.store && this.state.benefits) {
            for (const b of this.state.benefits) {
                benefit_list.push(
                    <CommonCourseCell
                        active={false}
                        title={b.description}
                        label={b.favor_flag ? '贔屓特典' : '通常特典'}
                        terms={this.getTerms(b)}
                    />
                );
            }
        }
        if (benefit_list.length == 0) {
            benefit_list.push(
                <div className="col-12 detail">
                    <p className="desc">選択できる特典がありません</p>
                </div>
            );
        }

        // コミュニティ一覧
        var communities = [];

        // 未選択時はコミュニテイ新規作成
        if (this.props.currentUser.communities) {
            for (let c of this.props.currentUser.communities) {

                // const course_index = i;

                var community_image: string = no_image;
                // if (c.community_image) {
                //     community_image = c.community_image;
                // }

                // 贔屓特典の場合は絞り込みを行う
                if (this.state.benefit && this.state.benefit.favor_flag == true) {
                    var is_exist = false;
                    for (let bc of this.state.benefit.communities) {
                        if (bc.id == Number(c.id)) {
                            is_exist = true;
                            break;
                        }
                    }
                    if (!is_exist) {
                        continue;
                    }
                }

                communities.push(
                    <CommonCourseCell
                        active={(this.state.community && this.state.community.id == c.id)}
                        image_small={community_image}
                        onClick={(e: any) => {
                            if (this.state.community && c.id == this.state.community.id) {
                                return;
                            }
                            this.setState({
                                community: c,
                                selected_user_list: [],
                            }, () => {
                                this._fetchCommunitiesUsers();
                            });
                        }}
                        title={c.name}
                    />
                );
            }
        }

        if (communities.length == 0) {
            communities.push(
                <CommonCourseCell
                    onClick={this._handleCommunityCreate}
                    title={'新規にコミュニティを作成する'}
                />
            );
        }

        // if (communities.length == 0) {
        //     communities.push(
        //         <div className="col-12 detail">
        //             <p className="desc">選択できるコミュニティがありません</p>
        //         </div>
        //     );
        // }

        // コミュニティのユーザーをセット(モーダル表示)
        let user_list = [];
        if (this.state.community) {

            for (let cu of this.state.communities_users) {
                // console.log('communities_users', cu);

                // 自分自身は選択させない
                if (this.props.currentUser.user_id == cu.user_id) {
                    continue;
                }

                var img = no_image;
                if (cu.user.avatar_url) {
                    img = cu.user.avatar_url;
                }

                user_list.push(
                    <CommonUserCell
                        active={this.state.selected_user_list.indexOf(cu) >= 0}
                        image={img}
                        onClick={e => {

                            if (this.state.selected_user_list.indexOf(cu) == -1) {
                                // 存在しないので追加
                                this.setState({
                                    selected_user_list: this.state.selected_user_list.concat([cu]),
                                });
                            } else {
                                // 削除
                                var selected_user_list = this.state.selected_user_list;
                                selected_user_list.splice(selected_user_list.indexOf(cu), 1);
                                this.setState({
                                    selected_user_list,
                                });
                            }
                        }}
                        title={cu.nickname || ''}
                    />
                );
            }

        }

        // 選択済みのユーザーをセット
        let selected_user_list = [];
        var selected_user_name_list = '';
        for (let cu of this.state.selected_user_list) {

            var img = no_image;
            if (cu.user.avatar_url) {
                img = cu.user.avatar_url;
            }

            selected_user_list.push(
                <div className="user-box">
                    <img src={img} className="selected-user-image"/>
                    <p>{cu.nickname || ''}</p>
                </div>
            );
            selected_user_name_list = selected_user_name_list + cu.nickname + ',';
        }
        if (this.state.community && this.state.communities_users.length <= 1) {
            selected_user_list.push(
                <p className="desc">招待できるユーザーが存在しません</p>
            );
        } else if (selected_user_list.length == 0) {
            selected_user_list.push(
                <p className="desc">招待する人を選択してください</p>
            );
        }

        // コース画像
        var course_image: string = no_image;
        if (this.state.course && this.state.course.images.length > 0) {
            const i = this.state.course.images[0];
            course_image = i.content.url;
        }

        // 注意文言
        var msg = '';
        if (this.state.benefit) {
            msg = `${this.state.benefit.number_of_people}人集まった場合のみ特典が適用されます。`;
        }

        // 人数ソート TODO
        var number = [];
        for (let i in NUMBER_FILTER) {

            var c = 'number cursor';
            if (this.state.number_filter == Number(i)) {
                c = 'number cursor active';
            }

            number.push(
                <input
                    type="button"
                    className={c}
                    value={NUMBER_FILTER[i]}
                    onClick={(e: any) => {
                        var number_filter = Number(i);
                        if (number_filter == this.state.number_filter) {
                            number_filter = NUMBER_FILTER_NONE;
                        }

                        this.setState({
                            number_filter: number_filter,
                            show_desc: false,
                            date_list: [],
                            date: undefined,
                            select_date: undefined,
                        });
                    }}
                />
            );
        }

        const params = {
            iOSEdgeSwipeDetection: true,
            shouldSwiperUpdate: true,
            on: {
                slideChange: () => {
                    if (this.state.swiper) {
                        this.setState({
                            image_index: this.state.swiper.activeIndex,
                        });
                    }
                },
            },
        }

        var number_of_people = [];
        for (var i = 2; i < 100; i++) {
            number_of_people.push(
                <option value={i}>{i}名</option>
            )
        }

        // 時刻入力
        var request_date = [];
        var m = Moment().hour(0).minute(0);
        for (var i = 0; i < 96; i++) {
            var k = m.format('HH:mm');
            var v = m.format('HH時mm分');
            request_date.push(
                <option value={k}>{v}開始</option>
            )
            m.add(15, 'm');
        }

        // メタ 座席
        var sum = 0;
        var max = 0;
        var min = 100;
        for (let s of this.state.seats) {
            sum += s.number_of_seats;
            max = (max < s.number_of_people) ? s.number_of_people : max;
            min = (min > s.number_of_people) ? s.number_of_people : min;
        }
        var desc_seat = `座席数${sum}席 / 最小${min}名 - 最大${max}名`;

        // メタ 個室
        var desc_private = '個室なし';
        for (let s of this.state.seats) {
            if (s.type_of_seat == 4) {
                desc_private = '個室あり';
                break;
            }
        }

        // メタ 分煙
        var smoking = false;
        var no_smoking = false;
        for (let s of this.state.seats) {
            if (s.smoking_is_enabled) {
                smoking = true;
            } else {
                no_smoking = true;
            }
        }
        var desc_smoking = '';
        if (smoking && !no_smoking) {
            desc_smoking = '喫煙席のみ';
        } else if (!smoking && no_smoking) {
            desc_smoking = '禁煙席のみ';
        } else {
            desc_smoking = '分煙';
        }

        // 座席
        var seats = [];
        for (let seat of this.state.seats) {

            if (seat.number_of_people < this.state.number_of_people) {
                continue;
            }

            // テイクアウト除外
            if (seat.type_of_seat == 7) {
                continue;
            }

            var type_of_seat = ''; // FIXME SEAT_TYPES[date.type_of_seat] 型指定が通らない
            switch (seat.type_of_seat) {
                case 0:
                    type_of_seat = SEAT_TYPES[0];
                    break;
                case 1:
                    type_of_seat = SEAT_TYPES[1];
                    break;
                case 2:
                    type_of_seat = SEAT_TYPES[2];
                    break;
                case 3:
                    type_of_seat = SEAT_TYPES[3];
                    break;
                case 4:
                    type_of_seat = SEAT_TYPES[4];
                    break;
                case 5:
                    type_of_seat = SEAT_TYPES[5];
                    break;
                case 6:
                    type_of_seat = SEAT_TYPES[6];
                    break;
                case 7:
                    type_of_seat = SEAT_TYPES[7];
                    break;
            }

            seats.push(
                <CommonCourseCell
                    active={this.state.request_seat_id == seat.id}
                    onClick={e => {
                        this.setState({
                            request_seat_id: seat.id
                        });
                    }}
                    title={type_of_seat}
                    sub_title={ seat.number_of_people + '名'}
                />
            );
        }

        return (
            <div className="CongregateCreate row">

                {this.state.store && this.state.view == VIEW_TYPE.DETAIL &&
                <Fragment>

                    <CommonImageSwiper
                        name={this.state.store.name}
                        desc={this.state.store.place}
                        images={images}
                        leftButton={<FontAwesome name='angle-left'/>}
                        leftButtonAction={() => {
                            this.props.history.goBack();
                        }}
                        onClickHelp={() => {
                            var win = window.open('/help/congregate', '_blank');
                        }}
                    />

                    {/*<div className="col-12 detail">*/}
                    {/*<div className="row help">*/}
                    {/*<div className="col-8">*/}
                    {/*<p>イベントの使い方 / 流れを確認</p>*/}
                    {/*</div>*/}
                    {/*<div className="col-4">*/}
                    {/*<input*/}
                    {/*type="button"*/}
                    {/*className={`w100 cursor`}*/}
                    {/*value={'確認する'}*/}
                    {/*onClick={() => {*/}
                    {/*var win = window.open('/help/congregate', '_blank');*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    <div className="col-12 detail border-bottom">
                        {/*<p className="desc">*/}
                        {/*<img src={reduction_ratio} alt="" className="icon"/>10 人が参加しました*/}
                        {/*</p>*/}
                        <p className="text">{this.state.store.description}</p>
                        {/*<div>*/}
                        {/*<span className="tag">飲食</span>*/}
                        {/*<span className="tag">お出かけ</span>*/}
                        {/*</div>*/}
                    </div>

                    {this.state.store.address &&
                    <div className="col-12 detail">
                        <CommonIconTitle
                            src={icon_directions}
                            text={this.state.store.address}
                            map={this.state.store.address}
                        />
                        <CommonIconTitle
                            src={icon_bus}
                            text={this.state.store.place}
                        />
                        <CommonIconTitle
                            src={icon_tel}
                            tel={this.state.store.phone_number}
                        />
                        <CommonIconTitle
                            src={icon_seat}
                            text={desc_seat}
                        />
                        <CommonIconTitle
                            src={icon_exit}
                            text={desc_private}
                        />
                        <CommonIconTitle
                            src={icon_smoking}
                            text={desc_smoking}
                        />
                        {this.state.store.url &&
                        <CommonIconTitle
                            src={icon_web}
                            text={this.state.store.url}
                            onClick={() => {
                                if (this.state.store && this.state.store.url) {
                                    window.location.href = this.state.store.url;
                                }
                            }}
                        />
                        }
                    </div>
                    }

                    <CommonSegmentButton lists={['予約する', '来店特典', '特典一覧']} index={this.state.type} onChange={(i) => {
                        this.setState({
                            type: i,
                        });
                    }}/>

                    {this.state.type != CONGREGATE_TYPE.BENEFIT &&
                    <div className="col-12 meta-box">
                        <p className="title">人数を選択</p>
                        <select name="number_of_people" className="signup-input number_of_people custom-select" value={this.state.number_of_people} onChange={(e) => this._handleSelectNumberOfPeople(Number(e.target.value))}>
                            <option value="0">人数を選択する</option>
                            {number_of_people}
                        </select>
                    </div>
                    }

                    {/*{this.state.store && this.state.store.stock_count > 0 &&*/}
                    {/*<div className="col-12 meta-box">*/}
                    {/*<p className="title">人数を選択</p>*/}
                    {/*{number}*/}
                    {/*</div>*/}
                    {/*}*/}

                    {this.state.type == CONGREGATE_TYPE.RESERVE &&
                    <div className="col-12 meta-box">
                        <p className="title">コースを選択</p>
                    </div>
                    }


                    {this.state.type == CONGREGATE_TYPE.RESERVE && courses}

                    {this.state.course && this.state.type == CONGREGATE_TYPE.RESERVE &&
                    <div className="col-12 detail">
                        <p className="title">詳細内容</p>
                        <p className={`desc ${this.state.show_desc ? '' : 'short'}`}>
                            {this.state.course.overview}
                        </p>
                        <p className="desc">
                            { !this.state.show_desc ? '' : this.state.course.description}
                        </p>
                        { !this.state.show_desc &&
                        <p className="next cursor" onClick={e => this.setState({show_desc: true})}>
                            <a>続きを読む</a>
                        </p>
                        }
                    </div>
                    }

                    { this.state.store && this.state.course && this.state.type == CONGREGATE_TYPE.RESERVE &&
                    <Fragment>

                        <div className="col-12 date meta-box">
                            <p className="title">開催日を選択</p>
                            <Calendar
                                onChange={this._onChange}
                                value={this.state.date}
                                tileDisabled={this._tileDisabled}
                            />
                        </div>

                        <Fragment>

                            <div className="col-12 meta-box">
                                <p className="title">予約枠を選択</p>
                            </div>
                            {dates}

                            {this.state.request_mode &&
                            <div className="col-12 meta-box">
                                <p className="title">時刻を入力</p>
                                <select name="time" className="signup-input time custom-select" value={this.state.request_date} onChange={(e) => this.setState({request_date: e.target.value})}>
                                    <option value="0">時刻を選択する</option>
                                    {request_date}
                                </select>
                            </div>
                            }

                            {this.state.request_mode &&
                            <div className="col-12 meta-box">
                                <p className="title">座席を選択する</p>
                            </div>
                            }

                            {this.state.request_mode && seats}

                        </Fragment>

                        {/*TODO*/}

                    </Fragment>
                    }

                    {this.state.type != CONGREGATE_TYPE.BENEFIT && this.state.store &&
                    <Fragment>


                        <div className="col-12 meta-box">
                            <p className="title">特典選択</p>
                        </div>

                        {benefits}

                    </Fragment>
                    }

                    {this.state.type != CONGREGATE_TYPE.BENEFIT && msg &&
                    <div className="col-12 detail">
                        <p className="title">注意事項</p>
                        <p className="text">
                            {msg}
                        </p>
                    </div>
                    }

                    {this.state.type != CONGREGATE_TYPE.BENEFIT && this.state.store &&
                    <Fragment>

                        <div className="col-12 meta-box">
                            <p className="title">還元コミュニティを選択</p>
                        </div>

                        {communities}

                        {this.state.type == CONGREGATE_TYPE.RESERVE && this.state.community &&
                        <div className="col-12 meta-box">
                            <p className="title" onClick={e => {
                                if (this.state.community && this.state.communities_users.length == 1) {
                                    this.props.showDialog(
                                        "確認",
                                        `招待できるユーザーが存在しません。`,
                                        (e: any) => {
                                            this.props.hideDialog();
                                        },
                                        null,
                                    );
                                    return;
                                }
                                this.setState({show_user_list: true});
                            }}>招待する人を選択 ＋</p>
                        </div>
                        }

                        {this.state.type == CONGREGATE_TYPE.RESERVE && this.state.community &&
                        <div className="col-12 selected-user-image-list" onClick={e => {
                            if (this.state.community && this.state.communities_users.length == 1) {
                                this.props.showDialog(
                                    "確認",
                                    `招待できるユーザーが存在しません。`,
                                    (e: any) => {
                                        this.props.hideDialog();
                                    },
                                    null,
                                );
                                return;
                            }
                            this.setState({show_user_list: true});
                        }}>
                            {selected_user_list}
                        </div>
                        }

                    </Fragment>
                    }

                    {this.state.type == CONGREGATE_TYPE.BENEFIT && benefit_list}

                </Fragment>
                }

                {this.state.view == VIEW_TYPE.TITLE &&
                <Fragment>
                    <Header
                        leftButtonTitle="戻る"
                        leftButtonAction={() => {
                            this._changeTab(VIEW_TYPE.DETAIL);
                        }}
                        title={'タイトルを決める'}
                    />
                    <div className="col-12 meta-box">
                        <p className="title">イベントのタイトルを決めましょう </p>
                        <p className="text">タイトルを指定しない場合、コース名がイベントのタイトルになります。</p>
                        <input name="name" type="text" className="signup-input" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} placeholder="招待された人にタイトルが表示されます"></input>
                    </div>
                </Fragment>
                }

                {this.state.view == VIEW_TYPE.CONFIRM &&
                <Fragment>
                    <Header
                        title={this.state.name || 'イベント作成'}
                        leftButtonTitle="戻る"
                        leftButtonAction={() => {
                            this._changeTab(VIEW_TYPE.TITLE);
                        }}
                    />
                    <div className="col-12 detail-box">
                        <div className="row">
                            <div className="col-4 course-image">
                                <img src={course_image} alt=""/>
                                {this.state.benefit &&
                                <p>{this.state.benefit.number_of_people || 0}人以上で特典成立</p>
                                }
                            </div>
                            <div className="col-8">
                                <p className="title">{ this.state.course ? this.state.course.name : ''}</p>
                                <p className="desc">{this.state.select_date ? Moment(this.state.select_date.start_time).format('MM月DD日 - HH:mm開始') : ''}</p>
                                <p className="desc"><img src={reduction_ratio} alt="" className="icon"/>{`${ this.state.store ? parseInt(this.state.store.reduction_ratio) : ''}`}%還元</p>
                                <p className="desc">{ this.state.course ? this.state.course.benefits : ''}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 form-box">
                        <div className="row">
                            {this.state.community &&
                            <div className="col-12 title">
                                内容
                            </div>
                            }
                            {this.state.community &&
                            <div className='input-wrapper'>
                                <label className='form-input-label'>還元コミュニティ</label>
                                <input className='form-input-text-with-label' name="" value={this.state.community ? this.state.community.name : ''} type="text"/>
                            </div>
                            }
                            {this.state.selected_user_list.length > 0 &&
                            <div className='input-wrapper'>
                                <label className='form-input-label'>招待する人</label>
                                <input className='form-input-text-with-label' placeholder={''} name="" value={selected_user_name_list} type="text"/>
                            </div>
                            }

                            {this.state.benefit &&
                            <CommonCourseCell
                                active={true}
                                title={this.state.benefit.description}
                                label={this.state.benefit.favor_flag ? '贔屓特典' : '通常特典'}
                                terms={this.getTerms(this.state.benefit)}
                            />
                            }

                            <div className="col-12 title">
                                ご連絡先電話番号(ハイフン不要)
                            </div>

                            <div className='input-wrapper'>
                                <input name="name" type="text" className="signup-input" value={this.state.phone_number} onChange={(e) => this.setState({phone_number: e.target.value})} placeholder=""></input>
                            </div>

                            <div className="col-12 title">
                                イベント参加者に届くコメント
                            </div>
                            <div className='input-wrapper'>
                                <textarea className='form-input-text large' name="" placeholder="例）一緒に飲みたいです" value={this.state.comment} onChange={(e) => this.setState({comment: e.target.value})}></textarea>
                            </div>
                            <div className="col-12 title">
                                店舗への要望
                            </div>
                            <div className='input-wrapper'>
                                <textarea className='form-input-text large' name="" placeholder="" value={this.state.request} onChange={(e) => this.setState({request: e.target.value})}></textarea>
                            </div>
                        </div>
                    </div>
                </Fragment>
                }

                {this.state.type != CONGREGATE_TYPE.BENEFIT && this.state.store &&
                <div className="button">
                    {this.state.type == CONGREGATE_TYPE.RESERVE &&
                    <div className="row">
                        <div className="col-7">
                            {this.state.course &&
                            <p className="desc">{this.state.course.benefits}</p>
                            }
                            {this.state.course &&
                            <p className="price"> ¥{Number(this.state.course.price).toLocaleString()} / 人</p>
                            }
                        </div>
                        <div className="col-5">
                            <input
                                type="button"
                                className={`w100 cursor ${(this.state.course && this.state.select_date) ? '' : 'disable'}`}
                                value={this.state.view == VIEW_TYPE.CONFIRM ? 'イベント作成' : '次へ'}
                                onClick={this._handlePressSubmit}
                            />
                        </div>
                    </div>
                    }
                    {this.state.type == CONGREGATE_TYPE.USE &&
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="button"
                                className={`w100 cursor`}
                                value={'来店特典を利用'}
                                onClick={this._handlePressUse}
                            />
                        </div>
                    </div>
                    }
                </div>
                }

                <Modal show={this.state.show_user_list}
                       onClose={e => this.setState({show_user_list: false})}
                >
                    {user_list}
                </Modal>

            </div>
        );
    }
}
//
// interface GMapProps {
//     google: any;
// }
// interface GMapState {
//
// }
//
// class GoogleMap extends Component <GMapProps, GMapState> {
//
//     render() {
//
//         const style = {
//             width: '50vw',
//             height: '75vh',
//             // 'marginLeft': 'auto',
//             // 'marginRight': 'auto'
//         }
//
//         return (
//             <Map
//                 google={ this.props.google }
//                 zoom={ 14 }
//                 style={ style }
//                 center={{lat: 35.681236, lng: 139.767125}}
//                 initialCenter={{lat: 35.681236, lng: 139.767125}}
//             >
//                 <Marker
//                     title={ 'Changing Colors Garage' }
//                     position={{lat: 39.648209, lng: -75.711185}}
//                     name={ 'Changing Colors Garage' }
//                 />
//             </Map>
//         );
//     }
// }
//
// const GMap = GoogleApiWrapper({
//     apiKey: "AIzaSyCOh5JKCn5L_e7cJSyHKpnoC2h_DUgz3Hk",
// })(GoogleMap);

const PageControl = ({numberOfPages, currentPage}: { numberOfPages: number, currentPage: number }) => {
    return (<div className="main-image-page-control">
        {range(0, numberOfPages - 1).map(i => {
            const _className = i == currentPage ? 'active page-control-item' : 'page-control-item'
            return (<div className={_className}/>)
        })}
    </div>)
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CongregateCreate);