import React from 'react';
import BaseApi from './BaseApi';

export default class CommunitiesUserApi extends BaseApi {

    /**
     * /communities/:community_name/communities_users
     */
    static _fetchCommunitiesUsers(name, params, success, error) {
        super._fetch(`/api/v1/communities/${name}/communities_users`, 'GET', params, success, error);
    }

    /**
     * /communities/:community_name/communities_users/:id
     */
    static _fetchCommunitiesUsersShow(name, user_id, params, success, error) {
        super._fetch(`/api/v1/communities/${name}/communities_users/${user_id}`, 'GET', params, success, error);
    }

    /**
     * /communities/:community_name/communities_users
     */
    static _fetchCommunitiesUsersCreate(name, params, success, error) {
        super._fetch(`/api/v1/communities/${name}/communities_users`, 'POST', params, success, error);
    }

    /**
     * /communities/:community_name/communities_users
     */
    static _fetchCommunitiesUsersEdit(name, params, success, error) {
        super._fetch(`/api/v1/communities/${name}/communities_user`, 'PUT', params, success, error);
    }

    /**
     * /communities/:community_name/communities_users
     */
    static _fetchCommunitiesUsersDelete(name, params, success, error) {
        super._fetch(`/api/v1/communities/${name}/communities_user`, 'DELETE', params, success, error);
    }
}
