import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {Elements, injectStripe, CardElement} from 'react-stripe-elements';
import {HIRAGANA_LIST} from '../../Construct.js';

import BalanceApi from '../../helpers/api/BalanceApi';
import BankApi from '../../helpers/api/BankApi';
import BankAccountsApi from '../../helpers/api/BankAccountsApi';
import PayoutApi from '../../helpers/api/PayoutApi';

import './Balance.css';
import Header from '../../components/Header';
import FontAwesome from "react-fontawesome";

import no_image from 'assets/no_image.png';

interface BalanceProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface BalanceStates {
    amount: number;
    isBankShow: boolean;
    // initial_bank_name: string;
    // initial_branche_name: string;
    available?: {
        amount: number;
    };
    token?: string;
    desc?: string;

    registStripe: boolean;
}

class Balance extends React.Component<BalanceProps, BalanceStates> {

    private form: any;

    constructor(props: any) {
        super(props);
        this.state = {
            amount: 0,
            isBankShow: false,
            registStripe: false,
        };

        for (var i = 0; i < 50; i++) {
            var str1 = String.fromCharCode(12354 + i);
        }
        // var stripe = window.Stripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.fetchBalance();
    }

    fetchBalance = () => {

        this.props.showSpinner(true);

        BalanceApi._fetchBalance(
            {},
            (responseJson: any) => {
                // console.log('responseJson fetchBalance', responseJson);
                this.props.showSpinner(false);

                if (responseJson.available.length > 0) {
                    this.setState({
                        available: responseJson.available[0],
                    });
                }
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    onBankShow = (e: any) => {
        this.setState({
            isBankShow: !this.state.isBankShow,
        });
    }

    createBankAccount = (token: string, desc: string) => {
        this.setState({
            isBankShow: false,
            token,
            desc,
        });
    }

    handleFormSubmit(event: any) {
        if (this.state.amount == 0) {
            this.props.showDialog(
                "確認",
                `金額を指定してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        let amount = this.state.available ? this.state.available.amount : 0;
        if (this.state.amount > amount) {
            this.props.showDialog(
                "確認",
                `売上残高が不足しています。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }
        if (!this.state.token) {
            this.props.showDialog(
                "確認",
                `銀行口座を登録してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        PayoutApi._fetchPayout(
            {
                amount: amount,
            },
            (responseJson: any) => {
                // console.log('responseJson fetchPayout', responseJson);
                this.props.showSpinner(false);
                this.props.showDialog(
                    "確認",
                    `申請しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        window.location.reload(true);
                    },
                    null,
                );
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );

    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        const {available, desc} = this.state;

        let amount = available ? available.amount : 0;

        var bank_detail = desc || '口座を登録してください';

        return (
            <div className="Balance row">
                <Header
                    title={'売上出金'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>

                <div className="col-12 balance">
                    <h1>出金申請</h1>
                    <input name="amount" type="number" className="signup-input" value={this.state.amount} onChange={(e) => this.setState({amount: parseInt(e.target.value)})} placeholder="" required></input>
                    <p>売上残高：{amount}円</p>
                </div>

                <div className="col-6 cursor" onClick={this.onBankShow}>
                    <h2>口座情報</h2>
                </div>
                <div className="col-6 add cursor" onClick={this.onBankShow}>
                    <p>
                        <FontAwesome name={this.state.isBankShow ? 'chevron-down' : 'chevron-right'}/>
                    </p>
                </div>
                { !this.state.isBankShow &&
                <div className="col-12 balance cursor" onClick={this.onBankShow}>
                    <div className="bank">{bank_detail}</div>
                    <p>※次の水曜日までに振り込まれます。</p>
                </div>
                }

                <div className="col-12">
                    { this.state.isBankShow &&
                    <div className="address-inputs">
                        <Elements>
                            <InjectStripeForm {...this.state} {...this.props} createBankAccount={this.createBankAccount}/>
                        </Elements>
                    </div>
                    }
                </div>
                { !this.state.isBankShow &&
                <div className="col-12 balance">
                    <button type="button" className="btn btn-secondary" onClick={this.handleFormSubmit.bind(this)}>
                        売り上げから出金申請
                    </button>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Balance);


/**
 * react-stripe-elementsを利用するためフォームを分けて実装する
 */
interface StripeFormProps {
    history: any;
    showSpinner: (show: boolean) => void;
    stripe?: any;
    createBankAccount: (token: string, desc: string) => void;
}

interface StripeFormStates {

    initial_bank_name: string;
    initial_branche_name: string;

    banks: {
        id: number;
        code: string;
        name: string;
        kana: string;
        hira: string;
        roma: string;
    }[];

    branches: {
        id: number;
        code: string;
        name: string;
        kana: string;
        hira: string;
        roma: string;
    }[];

    bank_code?: string;
    bank_name?: string;
    branche_code?: string;
    branche_name?: string;
    type?: string;
    bank_number?: string;
    name?: string;

    desc?: string;
}

class StripeForm extends React.Component <StripeFormProps, StripeFormStates> {

    private form: any;

    constructor(props: any) {
        super(props);
        this.state = {
            initial_bank_name: '',
            initial_branche_name: '',
            banks: [],
            branches: [],
        };
    }

    componentDidMount() {
    }

    fetchBanks = (initial: string) => {

        this.props.showSpinner(true);

        BankApi._fetchBanks(
            {
                bank_name: initial
            },
            (responseJson: any) => {
                // console.log('responseJson fetchBanks', responseJson);
                this.props.showSpinner(false);

                if (responseJson.banks.length > 0) {
                    this.setState({
                        banks: responseJson.banks,
                    });
                }
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    fetchBanksBranches = (code: string, initial: string) => {

        this.props.showSpinner(true);

        BankApi._fetchBanksBranches(
            code,
            {
                branch_name: initial
            },
            (responseJson: any) => {
                // console.log('responseJson fetchBanksBranches', responseJson);
                this.props.showSpinner(false);

                if (responseJson.branches.length > 0) {
                    this.setState({
                        branches: responseJson.branches,
                    });
                }

            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }


    handleChangeInitialBankName(event: any) {
        this.setState({
            initial_bank_name: event.target.value,
            banks: [],

            bank_code: undefined,
            bank_name: undefined,
            branches: [],
            initial_branche_name: '',
            branche_code: undefined,
            branche_name: undefined,
        });
        this.fetchBanks(event.target.value);
    }

    handleChangeBankCode(event: any) {
        this.setState({
            bank_code: event.target.value,
            bank_name: event.target.options[event.target.selectedIndex].text,

            branches: [],
            initial_branche_name: '',
            branche_code: undefined,
            branche_name: undefined,
        });
    }

    handleChangeInitialBrancheName(event: any) {
        this.setState({
            initial_branche_name: event.target.value,

            branches: [],
            branche_code: undefined,
            branche_name: undefined,
        });
        if (!this.state.bank_code) {
            return;
        }
        this.fetchBanksBranches(this.state.bank_code, event.target.value);
    }

    handleChangeBrancheCode(event: any) {
        this.setState({
            branche_code: event.target.value,
            branche_name: event.target.options[event.target.selectedIndex].text,
        });
    }

    handleFormBankSubmit(event: any) {
        // console.log('handleFormBankSubmit');

        if (!this.state.bank_code || !this.state.branche_code || !this.state.bank_number || !this.state.name) {
            return;
        }

        const params = {
            country: 'JP',
            currency: 'JPY',
            routing_number: this.state.bank_code + this.state.branche_code,
            account_number: this.state.bank_number,
            account_holder_name: this.state.name,
            account_holder_type: this.state.type,
        }

        // console.log('stripe createToken', params);

        this.props.showSpinner(true);
        this.props.stripe.createToken(
            'bank_account',
            params,
        ).then((result: any) => {

            BankAccountsApi._fetchBankAccounts(
                {
                    stripe_bank_account_token_id: result.token.id,
                },
                (responseJson: any) => {
                    // console.log('responseJson fetchBankAccounts', responseJson);
                    this.props.showSpinner(false);
                    let desc = `${this.state.bank_name}銀行 ${this.state.branche_name}支店 ${this.state.bank_number} ${this.state.name}`;
                    this.props.createBankAccount(result.token.id, desc);
                },
                (error: any) => {
                    alert(error);
                    this.props.showSpinner(false);
                },
            );
        }).catch((e: any) => {
            this.props.showSpinner(false);
            alert(e);
        });
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        return (
            <form
                onSubmit={this.handleFormSubmit.bind(this)}
                ref={el => this.form = el && el.elements}
            >

                {/*bank_name?: string;*/}
                {/*branche_name?: string;*/}
                {/*?: Type;*/}
                {/*?: number;*/}
                {/*?: string;*/}
                <label htmlFor="initial_bank_name">銀行名(頭文字)</label>
                <select name="initial_bank_name" className="signup-input" value={this.state.initial_bank_name} onChange={this.handleChangeInitialBankName.bind(this)}>
                    <option value="">銀行名の頭文字を選択してください</option>
                    { HIRAGANA_LIST.map(d => <option value={d}>{d}</option>)}
                </select>

                <label htmlFor="bank_code">銀行名</label>
                <select name="bank_code" className="signup-input" value={this.state.bank_code} onChange={this.handleChangeBankCode.bind(this)} disabled={this.state.banks.length == 0}>
                    <option value="">銀行を選択してください</option>
                    { this.state.banks.map(d => <option value={d.code}>{d.name}</option>)}
                </select>

                <label htmlFor="initial_branche_name">支店名(頭文字)</label>
                <select name="initial_branche_name" className="signup-input" value={this.state.initial_branche_name} onChange={this.handleChangeInitialBrancheName.bind(this)} disabled={!this.state.bank_code}>
                    <option value="">支店名の頭文字を選択してください</option>
                    { HIRAGANA_LIST.map(d => <option value={d}>{d}</option>)}
                </select>

                <label htmlFor="branche_code">支店名</label>
                <select name="branche_code" className="signup-input" value={this.state.branche_code} onChange={this.handleChangeBrancheCode.bind(this)} disabled={this.state.branches.length == 0}>
                    <option value="">支店を選択してください</option>
                    { this.state.branches.map(d => <option value={d.code}>{d.name}</option>)}
                </select>

                { this.state.branche_code &&
                <div>
                    <label htmlFor="type">個人/法人</label>
                    <select name="type" className="signup-input" value={this.state.type} onChange={(e) => this.setState({type: e.target.value})}>
                        <option value="individual">普通</option>
                        <option value="company">法人</option>
                    </select>

                    <label htmlFor="bank_number">口座番号</label>
                    <input name="bank_number" type="text" className="signup-input" placeholder="" value={this.state.bank_number} onChange={(e) => this.setState({bank_number: e.target.value})}></input>

                    <label htmlFor="name">口座名義</label>
                    <input name="name" type="text" className="signup-input" placeholder="" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}></input>
                </div>
                }
                <button type="button" className="btn btn-secondary" onClick={this.handleFormBankSubmit.bind(this)}>
                    口座を登録する
                </button>
            </form>
        );
    }
}

const InjectStripeForm = injectStripe(StripeForm);