import React from 'react';

import './Header.css';
import menu_icon from 'assets/menu_icon.png';

interface HeaderProps {
    title: string;
    leftButtonTitle?: any | null;
    leftButtonAction?: any | null;
    rightButtonTitle?: string | null;
    rightButtonAction?: any | null;
}

interface HeaderStates {

}

class Header extends React.Component<HeaderProps, HeaderStates> {

    public static defaultProps: HeaderProps = {
        title: '',
    };

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <header>
                <div className="row Header">
                    <div className="col-3 left-button cursor" onClick={this.props.leftButtonAction}>
                        { (this.props.leftButtonTitle && this.props.leftButtonAction) &&
                        <div className="left-button cursor" onClick={this.props.leftButtonAction}>
                            <p>{this.props.leftButtonTitle}</p>
                        </div>
                        }
                        { !this.props.leftButtonTitle && this.props.leftButtonAction &&
                        <img src={menu_icon} className="community-image"/>
                        }
                    </div>
                    <div className="col-6 title">
                        {this.props.title || ''}
                    </div>
                    { (this.props.rightButtonTitle && this.props.rightButtonAction) &&
                    <div className="col-3 right-button cursor" onClick={this.props.rightButtonAction}>
                        <p>{this.props.rightButtonTitle}</p>
                    </div>
                    }
                    { !(this.props.rightButtonTitle && this.props.rightButtonAction) &&
                    <div className="col-3 right-button">
                    </div>
                    }
                </div>
            </header>
        );
    }
}

export default Header;
