import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import firebase, {auth, providerFacebook, emailAuthProvider} from "../../firebase";
import Moment from 'moment';
import {PREF_LIST} from '../../Construct.js';

import Header from '../../components/Header';
import './Password.css';

interface PasswordProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showSpinner: (show: boolean) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface PasswordStates {
    email_org: string;
    email: string;
    password: string;
    password_conf: string;
    password_old: string;
}

/**
 * プロフィール登録・更新
 */
class Password extends React.Component<PasswordProps, PasswordStates> {

    private form: any;

    constructor(props: any) {
        super(props);

        this.state = {
            email_org: '',
            email: '',
            password: '',
            password_conf: '',
            password_old: '',
        };
    }

    componentDidMount() {

        var user = auth.currentUser;

        if (!user) {
            this.props.history.push(`/mypage`);
            return;
        }

        const email = user ? `${user.email}` : '';

        this.setState({
            email_org: email,
            email
        });

    }

    handleFormSubmit(e: any) {
        e.preventDefault();

        // メールアドレスが変更されていなければスキップ
        if (this.state.password !== this.state.password_conf) {
            this.props.showDialog(
                "確認",
                `パスワードが一致しません。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showSpinner(true);
        this._updateEmail();
    }

    _updateEmail = () => {
        var user = auth.currentUser;
        if (!user || !user.email) {
            return;
        }

        // メールアドレスが変更されていなければスキップ
        if (this.state.email_org === this.state.email) {
            this._updatePassword();
            return;
        }

        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            this.state.password_old,
        )

        user.reauthenticateWithCredential(credential).then(() => {
            // 変更実施
            if (!user) {
                return;
            }
            return user.updateEmail(this.state.email);
        }).then(() => {
            this._updatePassword();
        }).catch((error: any) => {
            this.props.showSpinner(false);
            alert(error);
        });
    }

    _updatePassword = () => {
        var user = auth.currentUser;
        if (!user || !user.email) {
            return;
        }

        // メールアドレスが変更されていなければスキップ
        if (!this.state.password && !this.state.password_conf) {
            this._editEnd();
            return;
        }

        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            this.state.password_old,
        )

        // 再認証
        user.reauthenticateWithCredential(credential).then(() => {
            // 変更実施
            if (!user) {
                return;
            }
            return user.updatePassword(this.state.password);
        }).then(() => {
            this._editEnd();
        }).catch((error: any) => {
            this.props.showSpinner(false);
            alert(error);
        });
    }

    _editEnd = () => {
        this.props.showSpinner(false);
        this.props.history.push(`/mypage`);
    }

    render() {

        return (
            <div className="Password row">
                <Header
                    title={'メールアドレス・パスワード変更'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>

                <div className="col-12 detail">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.form = el && el.elements}
                    >
                        <label htmlFor="email">メールアドレス</label>
                        <input name="email" type="text" className="signup-input" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder="" required></input>

                        <label htmlFor="password_old">パスワード</label>
                        <input name="password_old" type="password" className="signup-input" value={this.state.password_old} onChange={(e) => this.setState({password_old: e.target.value})} placeholder=""></input>


                        <label htmlFor="password">新しいパスワード <small>※パスワードを変更する場合のみ入力してください</small></label>
                        <input name="password" type="password" className="signup-input" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} placeholder=""></input>

                        <label htmlFor="password_conf">新しいパスワード(確認用)</label>
                        <input name="password_conf" type="password" className="signup-input" value={this.state.password_conf} onChange={(e) => this.setState({password_conf: e.target.value})} placeholder=""></input>

                        <button type="submit" className="btn btn-secondary">登録</button>

                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            // console.log('setCurrentUser', data)
            // console.log('setCurrentUser', data)
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Password);
