import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../../reducers';
import FontAwesome from "react-fontawesome";
import CommonUserCell from '../../../components/CommonUserCell';
import Header from '../../../components/Header';
import CommonTitle from '../../../components/CommonTitle';
import StorageHelper, {CURRENT_USER, DEFAULT_HREF} from '../../../helpers/StorageHelper';
import EventDetailProperties from '../components/EventDetailProperties';
import CommonComments from '../components/CommonComments';

import './EventDetail.css';

import FireStoreHelper from '../../../helpers/FireStoreHelper';
import { Event } from '../../../helpers/schemas/FireStore';
import CongregateSwiper from '../../../components/CongregateSwiper';
import CommonImage from '../../../components/CommonImage';

interface EventDetailProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface EventDetailStates {
    eventId: string;
    event?: Event;
}

class EventDetail extends React.Component<EventDetailProps, EventDetailStates> {

    constructor(props: any) {
        super(props);
        const eventId = props.match.params.id;
        console.log(props.location)
        let currentUser = StorageHelper.getData(CURRENT_USER);
        this.state = {
            eventId: eventId,
        };
        this._fetchEvent();
    }

    _fetchEvent = async(): Promise<void> => {
        const event = await FireStoreHelper.getEvent(this.state.eventId);
        console.log('fetched Event', this.state.eventId, event);
        this.setState({event: event});
    }

    async componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchEvent();
    }

    onClickFooterButton = () => {
        console.log('onClickFooterButton')
        // TODO: 店舗詳細ページを開く
    }

    render() {
        console.log('event', this.state.eventId, this.state.event)

        return (
            <div className="EventDetail row">

                <Header
                    title={'イベント詳細'}
                    leftButtonTitle={(this.props.history.length > 0) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push('/');
                        }
                    }}
                />

                {this.state.event &&
                <Fragment>
                    <div className="main-image">
                        {this.state.event.is_held &&
                        <div className="main-image-label" >
                            このイベントは開催済みです
                        </div>}
                        <CommonImage src={this.state.event.image_url}/>
                    </div>

                    <div className="title-box">
                        <p className="date">
                            {this.state.event.date_detail}
                        </p>

                        <p className="title">
                            {this.state.event.name}
                        </p>
                        <p className="spot small-text">
                            {this.state.event.access}
                        </p>
                    </div>

                    <EventDetailProperties
                        event={this.state.event}
                    />

                    <div className="col-12 border"></div>

                    {(this.state.event.pre_event_announce_content && this.state.event.pre_event_announcer) &&
                    <Fragment>
                        <CommonTitle
                            title="事前のイベント告知"
                            logo
                        />

                        <CommonUserCell
                            image={this.state.event.pre_event_announcer.user_image_url}
                            title={this.state.event.pre_event_announcer.user_name}
                            sub_title={this.state.event.pre_event_announcer.position}
                        />

                        <div className="col-12 event-announce-detail">
                            <p className="event-announce-desc">
                                {this.state.event.pre_event_announce_content}
                            </p>
                        </div>
                    </Fragment>
                    }

                    <div className="col-12 border"></div>
                    {(this.state.event.event_pictures.length > 0) &&
                    <Fragment>
                        <CommonTitle
                            title={`イベントの様子（${this.state.event.event_pictures.length}枚）`}
                            logo
                        />
                        <div className="event-picture-swiper-wrapper">
                            <CongregateSwiper
                                content={this.state.event.event_pictures.map(pic =>
                                <div key={pic.image_url} className="event-picture">
                                    <img className="image" src={pic.image_url}/>
                                    <div className="title">{pic.title}</div>
                                    <div className="comment">{pic.comment}</div>
                                </div>
                                )}
                                key={'event-picture'}
                                next={()=>{}}
                            />
                        </div>
                    </Fragment>
                    }

                    {(this.state.event.participant_comments.length > 0) &&
                    <Fragment>
                        <CommonTitle
                            title={`参加者の感想（${this.state.event.participant_comments.length}名）`}
                            logo
                        />

                        <CommonComments
                            comments={this.state.event.participant_comments}
                        />
                    </Fragment>
                    }

                    <CommonTitle
                        title="イベントのMVP"
                        logo
                    />
                    <MVPUser
                        image={this.state.event.event_mvp.user_image_url}
                        name={this.state.event.event_mvp.user_name}
                    />
                    {(this.state.event.mvp_voter_comments.length > 0) &&
                    <CommonComments
                        comments={this.state.event.mvp_voter_comments}
                        tag="投稿者コメント"
                    />
                    }

                    <CommonTitle
                    title="美味しかった一位"
                    logo
                    />
                    <div className="most-delicious">
                        {(this.state.event.most_delicious)}
                    </div>
                    {(this.state.event.most_delicious_voter_comments.length > 0) &&
                    <CommonComments
                        comments={this.state.event.most_delicious_voter_comments}
                        tag="投稿者コメント"
                    />
                    }

                    {this.state.event.store_reply_info && 
                    this.state.event.store_reply_comment && 
                    <Fragment>
                        <CommonTitle
                            title="お店からの返信コメント"
                            logo
                        />
                        <StoreReply
                            image={this.state.event.store_reply_info.store_image} 
                            name={this.state.event.store_reply_info.store_name} 
                            date={this.state.event.store_reply_info.date} 
                            reply={this.state.event.store_reply_comment}
                        />
                    </Fragment>
                    }

                    <CommonTitle
                        title="コミュニティにおける禁止行為"
                        logo
                    />
                    <div className="col-12 detail">
                        <p className="small-text">
                            もし同席したユーザーから迷惑を受けたり、禁止行為を見かけた場合は、TiTi 運営管理事務所までご報告ください。
                        </p>
                    </div>
                    <FooterButton onClick={this.onClickFooterButton}/>
                </Fragment>
                }

            </div>
        );
    }
}

const FooterButton = ({onClick}: {onClick: ()=>void}) => (<div className="col-12 footer">
    <div className="open-store-button" onClick={onClick}>
        この店舗でまたイベントを開く
    </div>
</div>)

const MVPUser = ({image, name}: {image: string, name: string}) => (
<div className="event-mvp-user-wrapper">
    <div className="event-mvp-user">
        <img className='image' src={image} />
        <div className='name'>
            <div className='content'>{name}</div>
            <div className='suffix'>さん</div>
        </div>
    </div>
</div>)

const StoreReply = ({image, name, date, reply}: {image: string, name: string, date:string, reply: string}) => (
<div className="col-12 store-reply">
    <div className="store-info">
        <img className="image" src={image}/>
        <div className="right-area">
            <div className="content">
                <div className="name">{name}</div>
                <div className="date">{date}</div>
            </div>
        </div>
    </div>
    <div className="store-comment small-text">
        {reply}
    </div>
</div>
)

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EventDetail);
