import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Moment from 'moment';

import StripeAccountApi from '../../helpers/api/StripeAccountApi';

import './Stripe.css';
import Header from '../../components/Header';
import btn_add_image from 'assets/btn_add_image.png';

interface StripeProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
    };
}

interface StripeStates {
    user_image_data?: string | null;
}

class Stripe extends React.Component<StripeProps, StripeStates> {

    private form: any;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.fetchStripeAccountShow();
    }

    handleFormSubmit(e: any) {
        e.preventDefault();

        if (!this.state.user_image_data) {
            this.props.showDialog(
                "確認",
                `本人確認をアップロードしてください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.fetchStripeAccountEdit();
    }

    handleChangeFile = (e: any) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        reader.onloadend = () => {
            this.setState({
                user_image_data: `${reader.result}`,
            });
        }
        reader.readAsDataURL(file);
    }

    fetchStripeAccountShow = () => {

        this.props.showSpinner(true);

        StripeAccountApi._fetchStripeAccountShow(
            {},
            (responseJson: any) => {
                this.props.showSpinner(false);
                // console.log('_fetchStripeAccountShow success', responseJson);
                if (responseJson.details_submitted) {
                    this.props.history.push(`/home`);
                } else {
                    this.props.showDialog(
                        "確認",
                        `事業者登録情報は現在審査中です。`,
                        (e: any) => {
                            this.props.hideDialog();
                        },
                        null,
                    );
                }
            },
            (error: any) => {
                // console.log('_fetchStripeAccountShow error', error);
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    fetchStripeAccountEdit = () => {

        this.props.showSpinner(true);

        StripeAccountApi._fetchStripeAccountEdit(
            {
                postal_code: this.form.postal_code.value,
                address_kanji_state: this.form.address_kanji_state.value,
                address_kanji_city: this.form.address_kanji_city.value,
                address_kanji_town: this.form.address_kanji_town.value,
                address_kanji_line1: this.form.address_kanji_line1.value,
                address_kanji_line2: this.form.address_kanji_line2.value,
                address_kana_state: this.form.address_kana_state.value,
                address_kana_city: this.form.address_kana_city.value,
                address_kana_town: this.form.address_kana_town.value,
                address_kana_line1: this.form.address_kana_line1.value,
                address_kana_line2: this.form.address_kana_line2.value,
                dob_day: Moment(this.props.currentUser!.profile!.birth).format('DD'),
                dob_month: Moment(this.props.currentUser!.profile!.birth).format('MM'),
                dob_year: Moment(this.props.currentUser!.profile!.birth).format('YYYY'),
                first_name_kanji: this.form.first_name_kanji.value,
                last_name_kanji: this.form.last_name_kanji.value,
                first_name_kana: this.form.first_name_kana.value,
                last_name_kana: this.form.last_name_kana.value,
                gender: this.props.currentUser!.profile!.gender,
                phone_number: this.form.phone_number.value,
                business_type: 'individual',
                verification_document: this.state.user_image_data,
            },
            (responseJson: any) => {
                // console.log('StripeAccountApi._fetchStripeAccountEdit:', responseJson);
                this.props.showSpinner(false);
                window.location.reload();
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    render() {

        let user_image = this.state.user_image_data || btn_add_image;

        return (
            <div className="Stripe row">
                <Header
                    title={'事業者登録'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                />
                <div className="col-12">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.form = el && el.elements}
                    >
                        <div className="row"></div>
                        <label htmlFor="postal_code">郵便番号</label>
                        <input name="postal_code" type="number" className="signup-input" placeholder="XXXXXXX" required maxLength={15}></input>
                        <label htmlFor="address_kanji_state">都道府県</label>
                        <input name="address_kanji_state" type="text" className="signup-input" placeholder="〇〇県" required maxLength={50}></input>
                        <label htmlFor="address_kanji_city">市区町村</label>
                        <input name="address_kanji_city" type="text" className="signup-input" placeholder="〇〇市" required maxLength={50}></input>
                        <label htmlFor="address_kanji_town">町名</label>
                        <input name="address_kanji_town" type="text" className="signup-input" placeholder="〇〇町" required maxLength={50}></input>
                        <label htmlFor="address_kanji_line1">番地</label>
                        <input name="address_kanji_line1" type="text" className="signup-input" placeholder="〇〇〇〇" required maxLength={50}></input>
                        <label htmlFor="address_kanji_line2">部屋番号(任意)</label>
                        <input name="address_kanji_line2" type="text" className="signup-input" placeholder="" maxLength={50}></input>

                        <label htmlFor="address_kana_state">都道府県（カナ）</label>
                        <input name="address_kana_state" type="text" className="signup-input" placeholder="〇〇ケン" required maxLength={50}></input>
                        <label htmlFor="address_kana_city">市区町村（カナ）</label>
                        <input name="address_kana_city" type="text" className="signup-input" placeholder="〇〇シ" required maxLength={50}></input>
                        <label htmlFor="address_kana_town">町名（カナ）</label>
                        <input name="address_kana_town" type="text" className="signup-input" placeholder="〇〇チョウ" required maxLength={50}></input>
                        <label htmlFor="address_kana_line1">番地（カナ）</label>
                        <input name="address_kana_line1" type="text" className="signup-input" placeholder="〇〇" required maxLength={50}></input>
                        <label htmlFor="address_kana_line2">部屋番号（カナ）(任意)</label>
                        <input name="address_kana_line2" type="text" className="signup-input" placeholder="" maxLength={50}></input>

                        <label htmlFor="last_name_kanji">姓（漢字）</label>
                        <input name="last_name_kanji" type="text" className="signup-input" placeholder="〇〇" required maxLength={50}></input>
                        <label htmlFor="first_name_kanji">名（漢字）</label>
                        <input name="first_name_kanji" type="text" className="signup-input" placeholder="〇〇" required maxLength={50}></input>

                        <label htmlFor="last_name_kana">姓（カナ）</label>
                        <input name="last_name_kana" type="text" className="signup-input" placeholder="〇〇" required maxLength={50}></input>
                        <label htmlFor="first_name_kana">名（カナ）</label>
                        <input name="first_name_kana" type="text" className="signup-input" placeholder="〇〇" required maxLength={50}></input>


                        <label htmlFor="phone_number">電話番号（ハイフン不要）</label>
                        <input name="phone_number" type="tel" className="signup-input" placeholder="" required maxLength={50}></input>

                        <label htmlFor="verification_document">本人確認(免許証などの本人確認資料のアップロード)</label>
                        <label className="user-image cursor">
                            <img src={user_image} className="logo"/>
                            <input type="file" name="upfile" id="upfile" accept="image/*" onChange={this.handleChangeFile.bind(this)}/>
                        </label>


                        <button type="submit" className="btn btn-secondary">登録</button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stripe);
