import React from 'react';
import BaseApi from './BaseApi';

export default class CoursesApi extends BaseApi {

    /**
     * GET /api/v1/stores/:store_id/courses
     */
    static _fetchCourses(store_id, params, success, error) {
        // window.setTimeout(() => {
        //     success({
        //         courses: [
        //             {
        //                 id: 1,
        //                 name: '《歓迎会・宴会に》タンや赤身などコスパの高い満喫コース6000円（税込）',
        //                 overview: 'バラカルビ／一本カルビ／やわらかハラミ/ハラミ／ハラミステーキ/ロース／厚切りロースステーキ/骨付カルビ',
        //                 time: 60,
        //                 price: 6000,
        //                 benefits: '飲み放題サービス',
        //             },
        //             {
        //                 id: 2,
        //                 name: '《歓迎会・宴会に》タンや赤身などコスパの高い満喫コース8000円（税込）',
        //                 overview: 'バラカルビ／一本カルビ／やわらかハラミ/ハラミ／ハラミステーキ/ロース／厚切りロースステーキ/骨付カルビ',
        //                 time: 60,
        //                 price: 6000,
        //                 benefits: '飲み放題サービス',
        //             },
        //             {
        //                 id: 3,
        //                 name: '《歓迎会・宴会に》タンや赤身などコスパの高い満喫コース10000円（税込）',
        //                 overview: 'バラカルビ／一本カルビ／やわらかハラミ/ハラミ／ハラミステーキ/ロース／厚切りロースステーキ/骨付カルビ',
        //                 time: 60,
        //                 price: 6000,
        //                 benefits: '飲み放題サービス',
        //             },
        //         ]
        //     });
        // }, 500);
        super._fetch('/api/v1/stores/' + store_id + '/courses', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/stores/:store_id/courses/:id
     */
    static _fetchCoursesShow(store_id, id, params, success, error) {
        // window.setTimeout(() => {
        //     success({
        //         id: 3,
        //         name: '《歓迎会・宴会に》タンや赤身などコスパの高い満喫コース10000円（税込）',
        //         overview: 'バラカルビ／一本カルビ／やわらかハラミ/ハラミ／ハラミステーキ/ロース／厚切りロースステーキ/骨付カルビ',
        //         description: '4名様限定で非常にお得なコースです。',
        //         time: 60,
        //         price: 6000,
        //         benefits: '飲み放題サービス',
        //         min_number: 4,
        //         max_number: 4,
        //     });
        // }, 500);
        super._fetch('/api/v1/stores/' + store_id + '/courses/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/stores/:store_id/courses
     */
    static _fetchCoursesCreate(store_id, params, success, error) {
        // window.setTimeout(() => {
        //     success(true);
        // }, 500);
        super._fetch('/api/v1/stores/' + store_id + '/courses', 'POST', params, success, error);
    }

    /**
     * PATCH /api/v1/stores/:store_id/courses/:id
     */
    static _fetchCoursesEdit(store_id, id, params, success, error) {
        // window.setTimeout(() => {
        //     success(true);
        // }, 500);
        super._fetch('/api/v1/stores/' + store_id + '/courses/' + id, 'PATCH', params, success, error);
    }

    /**
     * DELETE /api/v1/stores/:store_id/courses/:id
     */
    static _fetchCoursesDelete(store_id, id, params, success, error) {
        // window.setTimeout(() => {
        //     success(true);
        // }, 500);
        super._fetch('/api/v1/stores/' + store_id + '/courses/' + id, 'DELETE', params, success, error);
    }
}
