import Moment from 'moment';

export const range =
    (start: number, end: number) => Array.from({ length: (end - start + 1) }, (v, k) => k + start);

export const _dateToString = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
}

export const convertDateToIso = (d: Date) => {
    const shift = d.getTime() + 9 * 60 * 60 * 1000;
    const time = new Date(shift).toISOString().split('.')[0];
    return time;
}

export const _dateMsg = (date: string) => {

    const now = Moment();
    const target = Moment(date);

    if (now.diff(target, 'minutes') < 1) {
        // 秒
        return now.diff(target, 'seconds') + '秒';
    } else if (now.diff(target, 'hours') < 1) {
        // 分
        return now.diff(target, 'minutes') + '分';
    } else if (now.diff(target, 'days') < 1) {
        // 時間
        return now.diff(target, 'hours') + '時間';
    } else if (now.diff(target, 'days') < 7) {
        // 日
        return now.diff(target, 'days') + '日';
    } else {
        // 日付
        return target.format('YYYY-MM-DD h:mm');
    }
}