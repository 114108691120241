import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { actionCreator, RootState } from '../../reducers';
import FontAwesome from 'react-fontawesome'

import CommonImage from '../../components/CommonImage'

import type_goods_image from '../../assets/productCreation/type_goods.png';
// import type_goods_disable_image from '../../assets/productCreation/type_goods_disable.png';
import type_time_image from '../../assets/productCreation/type_time.png';

import Header from '../../components/Header';

import StripeAccountApi from '../..//helpers/api/StripeAccountApi';

import './CreateSelectType.css';
import { string } from 'prop-types';

interface CreateSelectTypeProps {
    history: any;
    showSpinner: (show: boolean) => void;
}

interface CreateSelectTypeStates {
    communityName: string,
}

class CreateSelectType extends React.Component<CreateSelectTypeProps, CreateSelectTypeStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            communityName: props.match.params.name,
        };
    }

    _onPressProductTypeItem = (type_of_product: string) => () => {
        console.log("_onPressProductItem", type_of_product);

        const goCreate = () => { this.props.history.push(`/community/${this.state.communityName}/product/create/${type_of_product}`); }
        const goStripe = () => { this.props.history.push(`/stripe`); }

        this.props.showSpinner(true);
        StripeAccountApi._fetchStripeAccountShow(
            {},
            (res: any) => {
                this.props.showSpinner(false);
                if (res.details_submitted) {
                    goCreate();
                } else {
                    goStripe();
                }
            },
            (error: any) => {
                this.props.showSpinner(false);
                goStripe();
            },
        )
    }

    render() {
        return (
            <div className="CreateSelectType row">
                <Header
                    title={`出品する`}>
                </Header>
                <div className='contents'>
                    <div className='section'>出品物の形式を選んでください</div>
                    <TypeCell src={type_time_image} text={'時間を出品する'} subtext={'体験 / スキル / スペースを貸すなど'}
                        onPress={this._onPressProductTypeItem('time')} />
                    <TypeCell src={type_goods_image} text={'商品（モノ）を出品する'} subtext={'本 / ハンドメイド / インテリア / 電子機器など'}
                        onPress={this._onPressProductTypeItem('goods')}/>
                </div>
            </div>
        );
    }
}

const TypeCell = ({ src, text, subtext, disable, onPress }: { src: string, text: string, subtext: string, disable?: boolean, onPress?: () => void }) => (
    <div className={`type-cell ${disable ? 'cell-disable' : ''}`} onClick={onPress}>
        <CommonImage className='cell-image' src={src} />
        <div className='cell-right'>
            <div className='cell-right-text'>{text}</div>
            <div className='cell-right-subtext'>{subtext}</div>
        </div>
        <FontAwesome name='angle-right' className='cell-arrow' />
    </div>
)

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateSelectType);
