import React from 'react';
import BaseApi from './BaseApi';

import Moment from 'moment';

export default class CongregationsApi extends BaseApi {

    /**
     * GET /api/v1/congregations
     */
    static _fetchCongregations(params, success, error) {
        // success({
        //     status: 200,
        //     meta: {
        //         count: 66,
        //     },
        //     congregations: [
        //         {
        //             id: 123,
        //             title: 'title',
        //             reserved_time: Moment(new Date()).add(1, 'days').format('YYYY-MM-DD 17:00'),
        //             status: 1,
        //             note: 'note',
        //             organizer: {
        //                 email: 'bjobjo0808@yahoo.co.jp',
        //                 nickname: 'nickname',
        //             },
        //             store: {
        //                 reduction_ratio: 0.1,
        //             },
        //             course: {
        //                 id: 5,
        //                 name: 'name',
        //                 price: 6200,
        //             },
        //             seat: {
        //                 id: 5,
        //                 number_of_people: 4,
        //             },
        //         }
        //     ],
        // });

        super._fetch('/api/v1/congregations', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/congregations/:id
     */
    static _fetchCongregationsShow(id, params, success, error) {
        // success({
        //     id: 123,
        //     title: 'title',
        //     reserved_time: Moment(new Date()).format('YYYY-MM-DD 17:00'),
        //     status: 1,
        //     note: 'note',
        //     organizer: {
        //         email: 'bjobjo0808@yahoo.co.jp',
        //         nickname: 'nickname',
        //     },
        //     store: {
        //         reduction_ratio: 0.1,
        //     },
        //     course: {
        //         id: 5,
        //         name: 'name',
        //         price: 6200,
        //     },
        //     seat: {
        //         id: 5,
        //         number_of_people: 4,
        //     },
        // });
        super._fetch('/api/v1/congregations/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/congregations
     */
    static _fetchCongregationsCreate(params, success, error) {
        super._fetch('/api/v1/congregations', 'POST', params, success, error);
    }

    /**
     * PATCH /api/v1/congregations/:id
     */
    static _fetchCongregationsEdit(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id, 'PATCH', params, success, error);
    }

    /**
     * DELETE /api/v1/congregations/:id
     */
    static _fetchCongregationsDelete(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id, 'DELETE', params, success, error);
    }

    /**
     * POST /api/v1/congregations/:id/cancel
     */
    static _fetchCongregationsCancel(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/cancel', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/congregations/:id/join
     */
    static _fetchCongregationsJoin(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/join', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/congregations/:id/leave
     */
    static _fetchCongregationsLeave(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/leave', 'POST', params, success, error);
    }
    
    /**
     * POST /api/v1/congregations/:id/join_temp
     */
    static _fetchCongregationsJoinTemp(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/join_temp', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/congregations/:id/leave_temp
     */
    static _fetchCongregationsLeaveTemp(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/leave_temp', 'POST', params, success, error);
    }

    /**
     * GET /api/v1/congregations/:id/detail
     * 未ログインユーザー向け
     */
    static _fetchCongregationsNoLogin(id, params, success, error) {
        super._fetch('/api/v1/congregations/' + id + '/detail', 'GET', params, success, error);
    }

    //
    // /**
    //  * POST /api/v1/congregations/:id/join
    //  */
    // static _fetchCongregationsDelete(id, params, success, error) {
    //     super._fetch('/api/v1/congregations/' + id + '/join', 'POST', params, success, error);
    // }
}
