import React, {useState, Fragment, Component} from 'react';
import {range, _dateToString} from '../../helpers/utils';
import Calendar, {CalendarTileProperties} from 'react-calendar';
import Moment from 'moment';
import './StoreDetail.css';
import FontAwesome from "react-fontawesome";
import CommonSegmentButton from '../../components/CommonSegmentButton';
import CommonIconTitle from '../../components/CommonIconTitle';

import StoresApi from '../../helpers/api/StoresApi';
import StocksApi from '../../helpers/api/StocksApi';
import CoursesApi from '../../helpers/api/CoursesApi';
import CongregationsApi from '../../helpers/api/CongregationsApi';
import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';
import BenefitApi from '../../helpers/api/BenefitApi';
import SeatsApi from '../../helpers/api/SeatsApi';

import {SEAT_TYPES} from '../../Construct';

import reduction_ratio from 'assets/reduction_ratio.png';
import btn_add_image from 'assets/btn_add_image.png';
import local from 'assets/local.png';
import icon_titi from 'assets/icon_titi.png';
import icon_user from 'assets/icon_user.png';
import icon_calendar from 'assets/icon_calendar.png';
import icon_shape from 'assets/icon_shape.png';
import icon_location from 'assets/icon_location.png';
import icon_directions from 'assets/icon_directions.png';
import icon_bus from 'assets/icon_bus.png';
import icon_food from 'assets/icon_food.png';
import icon_price from 'assets/icon_price.png';
import icon_exclamation from 'assets/icon_exclamation.png';
import icon_tel from 'assets/icon_tel.png';
import icon_exit from 'assets/icon_exit.png';
import icon_seat from 'assets/icon_seat.png';
import icon_smoking from 'assets/icon_smoking.png';
import icon_web from 'assets/icon_web.png';


// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import no_image from 'assets/no_image.png';

import Modal from '../../components/Modal';
import CommonImageSwiper from '../../components/CommonImageSwiper';
import CommonCourseCell from '../../components/CommonCourseCell';
import CommonUserCell from '../../components/CommonUserCell';
import CommonTitle from '../../components/CommonTitle';
import Header from '../../components/Header';

interface StoreDetailProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    // currentUser: {
    //     communities?: {
    //         id: string;
    //         name: string;
    //         communities_users: {}[];
    //         community_image?: {
    //             image: {
    //                 content: {
    //                     url: string;
    //                 }
    //             }
    //         };
    //     }[] | null;
    // };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
        communities?: {
            id: string;
            name: string;
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
            communities_users: {
                id: number;
                user_id: string;
                nickname?: string;
                avatar_url?: string;
            }[];
        }[] | null;
    };
}

interface StoreDetailState {

    store_id: number;
    render: boolean;
    show_desc: boolean;
    swiper: any | null;
    image_index: number;
    course_index: number;

    store?: {
        address: string;
        area_list: string[];
        description: string;
        id: number;
        name: string;
        phone_number: string;
        place: string;
        reduction_ratio: string;
        stock_count: number;
        url: string;
        courses: {
            id: number;
            name: string;
            benefits?: string;
            description: string;
            overview: string;
            price: number;
            list_price: number;
            time: number;
            tag_list: string[];
        }[];
        images: {
            content: {
                url: string;
            }
        }[];
    };
    courses?: {
        id: number;
        name: string;
        benefits?: string;
        description: string;
        overview: string;
        price: number;
        list_price: number;
        time: number;
        images: {
            content: {
                url: string;
            }
        }[];
        tag_list: string[];
    }[];
    course?: {
        id: number;
        name: string;
        benefits?: string;
        description: string;
        overview: string;
        price: number;
        list_price: number;
        time: number;
        images: {
            content: {
                url: string;
            }
        }[];
        tag_list: string[];
    };

    show_user_list: boolean;

    benefits: {
        id: number;
        number_of_people: number;
        price: number;
        visit_status: number;
        discount: number;
        description: string;
        favor_flag: boolean;
        communities: {
            id: number;
        }[]
    }[];
    benefit?: {
        id: number;
        number_of_people: number;
        price: number;
        visit_status: number;
        discount: number;
        description: string;
        favor_flag: boolean;
        communities: {
            id: number;
        }[];
    };
}

class StoreDetail extends React.Component<StoreDetailProps, StoreDetailState> {

    constructor(props: any) {
        super(props);
        this.state = {
            store_id: props.match.params.id,
            render: true,
            show_desc: false,
            swiper: null,
            image_index: 0,
            course_index: 0,
            show_user_list: false,
            benefits: [],
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchStoresShow();
    }

    _fetchStoresShow = () => {

        this.props.showSpinner(true);

        StoresApi._fetchStoresShow(
            this.state.store_id,
            {},
            (responseJson: any) => {
                // console.log('_fetchStoresShow', responseJson);
                this.props.showSpinner(false);
                this.setState({
                    store: responseJson,
                    courses: responseJson.courses,
                    course: responseJson.courses[0],
                    benefits: responseJson.benefits,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    // _fetchSeats = () => {
    //
    //     this.props.showSpinner(true);
    //
    //     SeatsApi._fetchSeats(
    //         this.state.store_id,
    //         {},
    //         (responseJson: any) => {
    //             // console.log('_fetchSeats', responseJson);
    //             this.props.showSpinner(false);
    //
    //             this.setState({
    //                 seats: responseJson.seats,
    //             });
    //         },
    //         (error: any) => {
    //             this.props.showSpinner(false);
    //             alert(error);
    //         },
    //     );
    // }

    _handlePressUse = (e: any) => {
        e.preventDefault();
        this.props.history.push('/signin/');
    }

    getTerms = (b: any) => {
        var terms = '';
        if (b.favor_flag) {
            terms += '贔屓コミュニティのみ';
        }
        if (b.price > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.price + '円以上';
        }
        if (b.number_of_people > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.number_of_people + '人以上';
        }
        if (terms.length > 0) terms += '、';
        terms += b.visit_status == 0 ? '来店で特典付与' : '予約で特典付与';
        return '条件：' + terms;
    }

    render() {

        // console.log('StoreDetail props', this.props);
        // console.log('StoreDetail state', this.state);

        // 画像
        var images = [];
        if (this.state.store) {
            for (var image of this.state.store.images) {
                images.push(image.content.url);
            }
        }

        // コース
        var courses = [];
        if (this.state.store && this.state.courses) {
            for (const course of this.state.courses) {

                // const course_index = i;

                var course_image: string = no_image;
                if (course.images.length > 0) {
                    const i = course.images[0];
                    course_image = i.content.url;
                }

                var takeout = course.tag_list.indexOf('テイクアウト') != -1;

                courses.push(
                    <CommonCourseCell
                        active={(this.state.course && this.state.course.id == course.id)}
                        label={takeout ? 'テイクアウト可能' : ''}
                        image={course_image}
                        onClick={(e: any) => this.setState({course: course, show_desc: false})}
                        title={course.name}
                        sub_title={course.benefits ? course.benefits.split('\n')[0] : ''}
                        price={course.price}
                        price_origin={course.list_price > course.price ? course.list_price : 0 }
                        time={course.time}
                    />
                );
            }
        }

        // 特典
        var benefits = [];
        if (this.state.store && this.state.benefits) {
            for (const b of this.state.benefits) {
                benefits.push(
                    <CommonCourseCell
                        active={(this.state.benefit && this.state.benefit.id == b.id)}
                        title={b.description}
                        label={b.favor_flag ? '贔屓特典' : '通常特典'}
                        terms={this.getTerms(b)}
                    />
                );
            }
        }
        if (benefits.length == 0) {
            benefits.push(
                <div className="col-12 detail">
                    <p className="desc">選択できる特典がありません</p>
                </div>
            );
        }

        // 特典一覧
        var benefit_list = [];
        if (this.state.store && this.state.benefits) {
            for (const b of this.state.benefits) {
                benefit_list.push(
                    <CommonCourseCell
                        active={false}
                        title={b.description}
                        label={b.favor_flag ? '贔屓特典' : '通常特典'}
                        terms={this.getTerms(b)}
                    />
                );
            }
        }
        if (benefit_list.length == 0) {
            benefit_list.push(
                <div className="col-12 detail">
                    <p className="desc">選択できる特典がありません</p>
                </div>
            );
        }

        // コース画像
        var course_image: string = no_image;
        if (this.state.course && this.state.course.images.length > 0) {
            const i = this.state.course.images[0];
            course_image = i.content.url;
        }

        const params = {
            iOSEdgeSwipeDetection: true,
            shouldSwiperUpdate: true,
            on: {
                slideChange: () => {
                    if (this.state.swiper) {
                        this.setState({
                            image_index: this.state.swiper.activeIndex,
                        });
                    }
                },
            },
        }

        // メタ 座席
        // var sum = 0;
        // var max = 0;
        // var min = 100;
        // for (let s of this.state.seats) {
        //     sum += s.number_of_seats;
        //     max = (max < s.number_of_people) ? s.number_of_people : max;
        //     min = (min > s.number_of_people) ? s.number_of_people : min;
        // }
        // var desc_seat = `座席数${sum}席 / 最小${min}名 - 最大${max}名`;
        //
        // // メタ 個室
        // var desc_private = '個室なし';
        // for (let s of this.state.seats) {
        //     if (s.type_of_seat == 4) {
        //         desc_private = '個室あり';
        //         break;
        //     }
        // }

        // メタ 分煙
        // var smoking = false;
        // var no_smoking = false;
        // for (let s of this.state.seats) {
        //     if (s.smoking_is_enabled) {
        //         smoking = true;
        //     } else {
        //         no_smoking = true;
        //     }
        // }
        // var desc_smoking = '';
        // if (smoking && !no_smoking) {
        //     desc_smoking = '喫煙席のみ';
        // } else if (!smoking && no_smoking) {
        //     desc_smoking = '禁煙席のみ';
        // } else {
        //     desc_smoking = '分煙';
        // }

        return (
            <div className="StoreDetail row">

                {this.state.store &&
                <Fragment>

                    <CommonImageSwiper
                        name={this.state.store.name}
                        desc={this.state.store.place}
                        images={images}
                        leftButton={<FontAwesome name='angle-left'/>}
                        leftButtonAction={() => {
                            this.props.history.goBack();
                        }}
                    />

                    <div className="col-12 detail border-bottom">
                        {/*<p className="desc">*/}
                        {/*<img src={reduction_ratio} alt="" className="icon"/>10 人が参加しました*/}
                        {/*</p>*/}
                        <p className="text">{this.state.store.description}</p>
                        {/*<div>*/}
                        {/*<span className="tag">飲食</span>*/}
                        {/*<span className="tag">お出かけ</span>*/}
                        {/*</div>*/}
                    </div>

                    {this.state.store.address &&
                    <div className="col-12 detail">
                        <CommonIconTitle
                            src={icon_directions}
                            text={this.state.store.address}
                            map={this.state.store.address}
                        />
                        <CommonIconTitle
                            src={icon_bus}
                            text={this.state.store.place}
                        />
                        <CommonIconTitle
                            src={icon_tel}
                            tel={this.state.store.phone_number}
                        />
                        {/*<CommonIconTitle*/}
                            {/*src={icon_seat}*/}
                            {/*text={desc_seat}*/}
                        {/*/>*/}
                        {/*<CommonIconTitle*/}
                            {/*src={icon_exit}*/}
                            {/*text={desc_private}*/}
                        {/*/>*/}
                        {/*<CommonIconTitle*/}
                            {/*src={icon_smoking}*/}
                            {/*text={desc_smoking}*/}
                        {/*/>*/}
                        {this.state.store.url &&
                        <CommonIconTitle
                            src={icon_web}
                            text={this.state.store.url}
                            onClick={() => {
                                if (this.state.store && this.state.store.url) {
                                    window.location.href = this.state.store.url;
                                }
                            }}
                        />
                        }
                    </div>
                    }

                    <CommonTitle
                        title="コース"
                        logo
                    />
                    {courses}

                    {this.state.course &&
                    <CommonTitle
                        title="コース詳細"
                        logo
                    />
                    }

                    {this.state.course &&
                    <div className="col-12 detail">
                        <p className={`desc ${this.state.show_desc ? '' : 'short'}`}>
                            {this.state.course.overview}
                        </p>
                        <p className="desc">
                            { !this.state.show_desc ? '' : this.state.course.description}
                        </p>
                        { !this.state.show_desc &&
                        <p className="next cursor" onClick={e => this.setState({show_desc: true})}>
                            <a>続きを読む</a>
                        </p>
                        }
                    </div>
                    }

                    <CommonTitle
                        title="特典"
                        logo
                    />
                    {benefits}
                </Fragment>
                }
                <div className="button">
                    <div className="row">
                        <div className="col-12">
                            <input
                                type="button"
                                className={`w100 cursor`}
                                value={'ログインして利用する'}
                                onClick={this._handlePressUse}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//

const PageControl = ({numberOfPages, currentPage}: { numberOfPages: number, currentPage: number }) => {
    return (<div className="main-image-page-control">
        {range(0, numberOfPages - 1).map(i => {
            const _className = i == currentPage ? 'active page-control-item' : 'page-control-item'
            return (<div className={_className}/>)
        })}
    </div>)
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreDetail);