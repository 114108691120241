import React from 'react';
import {Transition, CSSTransition} from 'react-transition-group';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';

import './ImagePreview.css';
import FontAwesome from "react-fontawesome";

import CommonImageSwiper from './CommonImageSwiper';

interface ImagePreviewProps {
    imagePreview: {
        show: boolean;
        images: string[];
        selectedIndex: number;
    },
    showImagePreview: (show: boolean, images: string[], selectedIndex: number) => void;
}

interface ImagePreviewStates {

}

class ImagePreview extends React.Component<ImagePreviewProps, ImagePreviewStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    onClickClose = () => {
        this.props.showImagePreview(false, [], 0);
    }

    render() {
        return (
            <CSSTransition 
            in={this.props.imagePreview.show && this.props.imagePreview.images 
                && (this.props.imagePreview.images.length > 0)} 
            timeout={0} unmountOnExit>
                <div className="imagePreview">
                    <div className="close-button" onClick={this.onClickClose}>
                        <FontAwesome
                            name='close'
                            size='2x'
                        />
                    </div>
                    <CommonImageSwiper 
                    images={this.props.imagePreview.images} 
                    selectedIndex={this.props.imagePreview.selectedIndex}/>
                </div>
            </CSSTransition>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        imagePreview: state.imagePreview.imagePreview,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showImagePreview: (show: boolean, images: string[], selectedIndex: number) => {
            dispatch(actionCreator.imagePreview.imagePreview({
                show: show,
                images: images,
                selectedIndex: selectedIndex,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagePreview);