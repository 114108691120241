import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import firebase, {auth, database} from "../../firebase";
import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';
import Moment from 'moment';
import {_dateMsg} from '../../helpers/utils';

import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import UserManagementApi from '../../helpers/api/UserManagementApi';

import no_image from 'assets/no_image.png';

import './TalkList.css';

interface TalkListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface TalkListStates {
    render: boolean;
    user_list: {
        profile: {
            first_name: string | null;
            last_name: string | null;
        };
    }[];
    purchase_list: {
        key: string;
        image_url: string;
        name: string;
        comment: string;
        user_ids: [];
        updated_at: string;
    }[];
    product_list: {
        key: string;
        image_url: string;
        name: string;
        comment: string;
        user_ids: [];
        updated_at: string;
    }[];
    type: number;
}

class TalkList extends React.Component<TalkListProps, TalkListStates> {

    private usersRef: any;
    private currentUser: any;

    constructor(props: any) {
        super(props);
        this.state = {
            render: false,
            user_list: [],
            purchase_list: [], // 購入
            product_list: [], // 出品
            type: 0,
        };

        this.currentUser = StorageHelper.getData(CURRENT_USER);
        this.usersRef = database.ref(`users/${this.currentUser.firebase_uid}/rooms`);
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.props.showSpinner(true);
        this.usersRef.orderByChild('updated_at').on('value', this._handleUpdate);
    }

    componentWillUnmount() {
        this.usersRef.off('value', this._handleUpdate);
    }

    _handleUpdate = (snapshot: any) => {

        var user_ids = [];
        var purchase_list = [];
        var product_list = [];
        // console.log('snapshot', snapshot.val());
        for (let key in snapshot.val()) {
            // console.log(key);
            // console.log(snapshot.val()[key]);

            var room: any = snapshot.val()[key];
            room.key = key;

            if (room.owner_uid == this.currentUser.firebase_uid) {
                // オーナーが自分の場合は購入
                purchase_list.unshift(room);
            } else {
                product_list.unshift(room);
            }

            for (let key in room.user_ids) {
                if (user_ids.indexOf(key) == -1) {
                    user_ids.push(key);
                }
            }
        }

        // console.log('purchase_list', purchase_list);
        // console.log('product_list', product_list);
        // console.log('user_ids', user_ids);

        // ユーザーを取得する
        this._fetchUsers(user_ids);

        this.setState({
            render: true,
            purchase_list: purchase_list, // 購入
            product_list: product_list, // 出品
        });
    }

    _fetchUsers = (user_ids: any) => {

        this.props.showSpinner(false);

        for (let key of user_ids) {
            // ユーザープロフィール
            UserManagementApi._fetchUsers(
                key,
                {},
                (responseJson: any) => {


                    var user_list = this.state.user_list;
                    user_list[responseJson.id] = responseJson;

                    this.setState({
                        user_list: user_list,
                    });
                },
                (error: any) => {
                    alert(error);
                },
            );
        }
    }

    render() {

        var list: {}[] = [];
        var rooms = this.state.type == 0 ? this.state.purchase_list : this.state.product_list;

        for (let room of rooms) {

            // console.log('room', room);

            var user_name = '　';
            for (let user_id in room.user_ids) {
                if (this.currentUser.user_id != user_id) {
                    const user = this.state.user_list[user_id];
                    if (user) {
                        user_name = `${user.profile ? user.profile.first_name : ''} ${user.profile ? user.profile.last_name : ''}`;
                    }
                }
            }

            // let updatedAt = Moment(room.updated_at).format('YYYY-MM-DD h:mm');

            list.push(
                <div className="col-12 cell cursor" onClick={e => {
                    this.props.history.push(`/talk/${room.key}`);
                }}>
                    <div className="row">
                        <div className="col-2">
                            <img src={room.image_url || no_image} className="room-image"/>
                        </div>
                        <div className="col-10 room-box">
                            <p className="room-comment">{_dateMsg(room.updated_at)}</p>
                            <p className="room-name">{room.name}</p>
                            { user_name &&
                            <p className="room-user-name">{user_name}</p>
                            }
                            <p className="room-comment">{room.comment}</p>
                        </div>
                    </div>
                </div>
            );
        }

        // no data
        if (list.length == 0) {
            list.push(
                <div className="col-12">
                    <p className="room-nodata">{this.state.type == 0 ? '購入' : '出品'}がない場合はトークがありません</p>
                </div>
            );
        }

        return (
            <div className="TalkList row">
                <Header
                    title={'トーク'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                <CommonSegmentButton lists={['購入', '出品']} index={this.state.type} onChange={(i) => {
                    this.setState({
                        type: i,
                    });
                }}/>
                { this.state.render &&
                <div className="col-12">
                    {list}
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TalkList);
