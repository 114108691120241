import React from 'react';
import BaseApi from './BaseApi';

export default class BenefitApi extends BaseApi {

    /**
     * GET /api/v1/benefit_applications
     */
    static _fetchBenefit(params, success, error) {
        super._fetch('/api/v1/benefit_applications', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/benefit_applications/:id
     */
    static _fetchBenefitDetail(id, params, success, error) {
        super._fetch('/api/v1/benefit_applications/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/benefit_applications
     */
    static _fetchBenefitCreate(params, success, error) {
        super._fetch('/api/v1/benefit_applications/', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/benefit_applications/:id/decision
     */
    static _fetchBenefitDecision(id, params, success, error) {
        super._fetch('/api/v1/benefit_applications/' + id + '/decision', 'POST', params, success, error);
    }



    /**
     * GET /api/v1/stores/:store_id/benefits
     */
    static _fetchBenefits(store_id, params, success, error) {
        // success({
        //     benefits: [
        //         {
        //             "id": "1",
        //             "number_of_people": "4",
        //             "price": "2000",
        //             "visit_status": "1",
        //             "discount": "5000",
        //             "description": "テスト",
        //         }
        //     ],
        // });
        super._fetch('/api/v1/stores/' + store_id + '/benefits', 'GET', params, success, error);
    }

    // /**
    //  * GET /api/v1/stores/:store_id/benefits/:id
    //  */
    // static _fetchBenefitsShow(store_id, id, params, success, error) {
    //     // success({
    //     //     "id": "1",
    //     //     "number_of_people": "4",
    //     //     "price": "2000",
    //     //     "visit_status": "1",
    //     //     "discount": "5000",
    //     //     "description": "テスト",
    //     // });
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits/' + id, 'GET', params, success, error);
    // }
    //
    // /**
    //  * POST /api/v1/stores/:store_id/benefits
    //  */
    // static _fetchBenefitsCreate(store_id, params, success, error) {
    //     // success({
    //     //     status: true,
    //     //     success: 200,
    //     // });
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits', 'POST', params, success, error);
    // }
    //
    // /**
    //  * PATCH /api/v1/stores/:store_id/benefits/:id
    //  */
    // static _fetchBenefitsEdit(store_id, id, params, success, error) {
    //     // success({
    //     //     status: true,
    //     //     success: 200,
    //     // });
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits/' + id, 'PATCH', params, success, error);
    // }
    //
    // /**
    //  * DELETE /api/v1/stores/:store_id/benefits/:id
    //  */
    // static _fetchBenefitsDelete(store_id, id, params, success, error) {
    //     // success({
    //     //     status: true,
    //     //     success: 200,
    //     // });
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits/' + id, 'DELETE', params, success, error);
    // }
    //
    // /**
    //  * POST /api/v1/stores/:store_id/benefits/:benefit_id/communities
    //  */
    // static _fetchBenefitsCommunities(store_id, benefit_id, params, success, error) {
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits/' + benefit_id + '/communities', 'POST', params, success, error);
    // }
    //
    // /**
    //  * DELETE /api/v1/stores/:store_id/benefits/:benefit_id/communities/:communitie_id
    //  */
    // static _fetchBenefitsCommunitiesDelete(store_id, benefits_id, communitie_id, params, success, error) {
    //     super._fetch('/api/v1/stores/' + store_id + '/benefits/' + benefits_id + '/communities/' + communitie_id, 'DELETE', params, success, error);
    // }
}