import React from 'react';
import {Link} from 'react-router-dom';
import firebase, {auth, providerFacebook, googleAuthProvider, twitterAuthProvider} from "../../firebase";
import {LINE_CLIENT_ID} from "../../Construct";
import FontAwesome from 'react-fontawesome';
import queryString from 'query-string';

import './Signup.css';
import 'bootstrap/dist/css/bootstrap.css';

import signup_logo from 'assets/signup_logo.png';
import signup_logo2 from 'assets/signup_logo2.png';
import line_logo from 'assets/line_logo.png';
import or from 'assets/or.png';

import logo from 'assets/logo.png';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

interface Props {
    history: any;
    setCurrentUser: (name: string, token: string, user_id: string) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface State {
    checked: boolean;
    type: string | string[];
}

class Signup extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const values = queryString.parse(props.location.search);

        this.state = {
            checked: false,
            type: values.t || '',
        };
    }

    // handleFormSubmit(e: any) {
    //     e.preventDefault();
    //     auth.signInWithEmailAndPassword(this.emailForm.email.value, this.emailForm.password.value)
    //         .catch((error) => {
    //             alert(error.message)
    //         });
    // }

    handleFormFBButton = (e: any) => {

        if (!this.state.checked) {
            this.props.showDialog(
                "確認",
                `利用規約とプライバシーポリシーに同意してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showSpinner(true);

        auth.signInWithRedirect(providerFacebook)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormTwitterButton = (e: any) => {

        if (!this.state.checked) {
            this.props.showDialog(
                "確認",
                `利用規約とプライバシーポリシーに同意してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showSpinner(true);

        auth.signInWithRedirect(twitterAuthProvider)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormGoogleButton = (e: any) => {

        if (!this.state.checked) {
            this.props.showDialog(
                "確認",
                `利用規約とプライバシーポリシーに同意してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showSpinner(true);

        auth.signInWithRedirect(googleAuthProvider)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormLineButton = (e: any) => {

        if (!this.state.checked) {
            this.props.showDialog(
                "確認",
                `利用規約とプライバシーポリシーに同意してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        const client_id = LINE_CLIENT_ID;
        const redirect_uri = encodeURIComponent(window.location.origin + '/signin');
        const scope = encodeURIComponent('openid profile');
        const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&`
            + `redirect_uri=${redirect_uri}&state=state&scope=${scope}`;
        window.location.href = url;
    }

    handleFormSlide = (e: any) => {
        this.props.history.push('/slide');
    }

    handleChange(e: any) {
        this.setState({
            checked: e.target.checked,
        });
    };

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        return (
            <div className="Signup row">
                <div className="col-12">
                    <img src={signup_logo2} className="logo"/>
                </div>

                {this.state.type != 'non' &&
                <div className="col-12">
                    <button type="button" className="btn btn-congregate" onClick={this.handleFormSlide}>
                        イベント をためしてみる
                    </button>
                </div>
                }
                {this.state.type != 'non' &&
                <div className="col-12">
                    <img src={or} className="or" alt=""/>
                </div>
                }

                <div className="col-12">
                    <button type="button" className="btn btn-line" onClick={this.handleFormLineButton}>
                        <img src={line_logo} style={{width: 38, marginRight: 8, left: 15, top: 10, position: 'absolute'}} alt=""/>LINEでログイン
                    </button>
                </div>

                <div className="col-12">
                    <button type="button" className="btn btn-facebook" onClick={this.handleFormFBButton.bind(this)}>
                        <FontAwesome name='facebook-official fa-2x' style={{marginRight: 8, left: 15, top: 10, position: 'absolute'}}/>Facebookで登録
                    </button>
                </div>

                <div className="col-12">
                    <button type="button" className="btn btn-twitter" onClick={this.handleFormTwitterButton}>
                        <FontAwesome name='twitter-square fa-2x' style={{marginRight: 8, left: 15, top: 10, position: 'absolute'}}/>Twitterで登録
                    </button>
                </div>

                <div className="col-12">
                    <button type="button" className="btn btn-google" onClick={this.handleFormGoogleButton}>
                        <FontAwesome name='google fa-2x' style={{marginRight: 8, left: 17, top: 10, position: 'absolute'}}/>Googleで登録
                    </button>
                </div>

                <div className="col-12 consent">
                    <p>
                        <input type="checkbox" checked={this.state.checked} onChange={this.handleChange.bind(this)}/>
                        <Link to="/terms">利用規約</Link>
                        と
                        <Link to="/policy">プライバシーポリシー</Link>
                        に同意する
                    </p>
                </div>
                <div className="footer row cursor" onClick={e => {
                    this.props.history.push('/signin');
                }}>
                    <div className="col-8">
                        アカウントをお持ちですか？
                    </div>
                    <div className="col-4">
                        ログイン
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signup);