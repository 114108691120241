import {currentUser, CurrentUserAction} from './CurrentUser';

type Actions
    = CurrentUserAction;

export type State = {
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
};

const init = (): State => {
    return {
        currentUser: {
            name: '',
            token: '',
            user_id: '',
            avatar_url: null,
            profile: null,
            communities: [],
        }
    };
};

export const reducer = (state: State = init(), action: Actions) => {
    switch (action.type) {
        case 'CURRENT_USER':
            return {
                currentUser: {
                    name: action.payload.name,
                    token: action.payload.token,
                    user_id: action.payload.user_id,
                    avatar_url: action.payload.avatar_url,
                    profile: {
                        address: action.payload.profile!.address,
                        birth: action.payload.profile!.birth,
                        email: action.payload.profile!.email,
                        first_name: action.payload.profile!.first_name,
                        gender: action.payload.profile!.gender,
                        last_name: action.payload.profile!.last_name,
                        user_id: action.payload.profile!.user_id,
                    },
                    communities: action.payload.communities,
                },
            };
        default:
            return state;
    }
};

export const actionCreator = {
    currentUser,
};