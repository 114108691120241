import firebase, {analytics} from "../firebase";

export default class GAHelper {

    static _logEvent(name, params) {
        console.log('LogEvent : ' + name, params);
        // const defaultAnalytics = firebase.analytics();
        // defaultAnalytics.logEvent(
        //     name,
        //     params
        // );
    }
}
