import React from 'react';

import './CommonCell.css';
import no_image from 'assets/no_image.png';
import icon_location from 'assets/feed/locail.png';

interface CommonCellProps {

    image?: any;

    title?: string;
    location?: string;
    sub_title?: string;
    desc?: string;
    text?: string;
    date?: string;
    community_name?: string;
    community_image_url?: string;

    active?: boolean; // 選択済みか
    onClick?: (e: any) => void;
}

interface CommonCellStates {

}

class CommonCell extends React.Component<CommonCellProps, CommonCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        var cursor = '';

        if (this.props.onClick) {
            cursor = ' cursor';
        }

        var c = 'box row' + cursor;
        if (this.props.active) {
            c = 'box row active' + cursor;
        }

        return (
            <div className="col-12 cell CommonCell" onClick={this.props.onClick}>
                <div className={c}>

                    <img src={this.props.image || no_image} className="image"/>

                    <div className="detail">

                        {this.props.date &&
                        <p className="text">{this.props.date}</p>
                        }
                        {this.props.title &&
                        <p className="title">{this.props.title}</p>
                        }
                        {this.props.location &&
                        <p className="location">
                            <img src={icon_location} alt=""/>
                            {this.props.location}
                        </p>
                        }
                        {this.props.sub_title &&
                        <p className="sub_title">{this.props.sub_title}</p>
                        }
                        {this.props.desc &&
                        <p className="desc">{this.props.desc}</p>
                        }
                        {this.props.text &&
                        <p className="text">{this.props.text}</p>
                        }
                        {(this.props.community_image_url && this.props.community_name) &&
                        <p className="community_name">
                            <img src={this.props.community_image_url} className="community_image" alt=""/>{this.props.community_name}
                        </p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonCell;
