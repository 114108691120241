import React from 'react';
import BaseApi from './BaseApi';

export default class SeatsApi extends BaseApi {

    /**
     * GET /api/v1/stores/:store_id/seats
     */
    static _fetchSeats(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/seats', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/stores/:store_id/seats/:id
     */
    static _fetchSeatsShow(store_id, id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/seats/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/stores/:store_id/seats
     */
    static _fetchSeatsCreate(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/seats', 'POST', params, success, error);
    }

    /**
     * PATCH /api/v1/stores/:store_id/seats/:id
     */
    static _fetchSeatsEdit(store_id, id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/seats/' + id, 'PATCH', params, success, error);
    }

    /**
     * DELETE /api/v1/stores/:store_id/seats/:id
     */
    static _fetchSeatsDelete(store_id, id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/seats/' + id, 'DELETE', params, success, error);
    }
}
