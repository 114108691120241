import React, {useState, Fragment} from 'react';
import Swiper from 'react-id-swiper';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';

interface HomeProps {
    key: string; // Swiper毎に一意の値を指定すること
    content: any;
    content_width?: number;
    next?: (page: number) => void;
}

interface HomeState {
    index: number,
    swiper: any | null,
}

class CongregateSwiper extends React.Component<HomeProps, HomeState> {

    page = 1;

    constructor(props: any) {
        super(props);

        const index = parseInt(window.sessionStorage.getItem(this.props.key) || '0');
        this.state = {
            index: index,
            swiper: null,
        };
    }

    _next = () => {
        if (this.props.next) {
            this.page++;
            this.props.next(this.page);
        }
    }

    _getSwiper = (swiper: any) => {
        this.setState({
            swiper: swiper,
        });
    }

    render() {

        // console.log('Home props', this.props);
        // console.log('Home state', this.state);

        const params = {
            iOSEdgeSwipeDetection: true,
            shouldSwiperUpdate: true,
            width: this.props.content_width || 260,
            on: {
                touchStart: () => {
                    if (this.state.swiper) {
                        this.state.swiper.update();
                    }
                },
                slideChange: () => {
                    if (this.state.swiper) {

                        this.setState({
                            index: this.state.swiper.activeIndex,
                        });

                        if (this.state.swiper.activeIndex == (this.props.content.length - 1) && this.props.next) {
                            this._next();
                        }
                    }
                },
            }
        }


        return (
            this.props.content ?
                <Fragment>
                    <Swiper
                        getSwiper={this._getSwiper}
                        {...params}
                    >
                        {this.props.content}
                    </Swiper>
                </Fragment> : null
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CongregateSwiper);