import React from 'react';
import BaseApi from './BaseApi';

export default class BankApi extends BaseApi {

    /**
     * GET /api/v1/banks/search
     */
    static _fetchBanks(params, success, error) {
        super._fetch('/api/v1/banks/search', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/banks/{銀行コード}/branches/search
     */
    static _fetchBanksBranches(code, params, success, error) {
        super._fetch('/api/v1/banks/' + code + '/branches/search', 'GET', params, success, error);
    }
}