import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import './Terms.css';
import Header from '../../components/Header';

interface TermsProps {

}

interface TermsStates {

}

class Terms extends React.Component<TermsProps, TermsStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="Terms row">
                <Header title={'利用規約'}/>
                <div className="col-12">
                    <p></p>
                    <p>この規約は、お客様が、シェイカー株式会社（以下「当社」）が提供するインターネットサービス「TiTi」（以下「本サービス」）をご利用頂く際の取扱いにつき定めるものです。本規約に同意した上で本サービスをご利用ください。</p>
                    <p></p>
                    <p></p>
                    <p>第１条（定義）</p>
                    <p></p>
                    <p>1.本規約上で使用する用語の定義は、次に掲げるとおりとします。</p>
                    <p>（1）「本規約」とは本利用規約、プライバシーポリシーをいい、これらはすべて本規約を構成するものとします。</p>
                    <p>（2）「プライバシーポリシー」とは、弊社が定めるプライバシーポリシーをいいます。</p>
                    <p>（3）「ユーザー」とは、本規約の内容に同意して、日本国内において本サービスを利用される個人及び弊社の指定する事業者を指します。</p>
                    <p>（4）「個人情報」とは、個人情報保護法に定める「個人情報」を指すものとし、ユーザーが入力した氏名、住所、生年月日、性別、職業、電話番号、アカウント情報（電子メールアドレス及びパスワード等をいう。）、プロフィール情報（ニックネーム、年齢その他の個人に関する属性情報）、クレジットカード情報及び利用履歴等で、かつ特定の個人を識別できる情報をいいます。</p>
                    <p>（5）「コミュニティ」とは本サービス内でユーザーが作成できるグループを意味します。</p>
                    <p>（6）「オーナー」とは、本サービス内でコミュニティを作成したユーザーのことを意味します。</p>
                    <p>（7）「コミュニティメンバー」とは本サービス内でオーナーが招待してコミュニテイに加入したユーザーのことを意味します。</p>
                    <p>（8）「コンテンツ」とは、弊社又はユーザーが本サービスに発信した、情報をいいます。</p>
                    <p>（9）「出品」とは、ユーザーが、本サービスにて、商品の取引に必要なコンテンツを掲載し、他のユーザーが閲覧可能かつユーザーが商品を取引できる状態にすることをいいます。</p>
                    <p>（10）「出品者」とは、本サービスを通じて商品を出品するユーザーをいいます。</p>
                    <p>（11）「購入者」とは、本サービスにて商品を購入するユーザーをいいます。</p>
                    <p>（12）「利用料」とは、本サービスの利用に関連して、弊社がユーザーから徴収する料金（なお、販売手数料、コミュニティ還元手数料など名称の如何を問わないものとします）をいいます。</p>
                    <p>（13）「販売価格」とは、出品者が本サービスにおいて商品を出品する際に設定する商品の価格をいいます。</p>
                    <p>（14）「商品代金」とは、本サービスにおいて商品が購入された場合の、出品者と購入者との間の売買契約における当該商品の売買価格をいいます。</p>
                    <p></p>
                    <p>2.本条の定義は、別途定義されている場合及び文脈上別異に解すべき場合を除き、本利用規約のほか、プライバシーポリシーにおいても、適用されるものとします。</p>
                    <p></p>
                    <p>第２条（サービス内容） </p>
                    <p>本サービスは、本サービス上でコミュニティと呼ばれるグループを作成したり、本サービス上で作成されるコミュニティ内で、コミュニティのメンバー同士が生産者と消費者になり、商材を売買できるサービスです。</p>
                    <p></p>
                    <p>第３条（本規約への同意）</p>
                    <p>１　利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします。</p>
                    <p>２　利用者が、本サービスをスマートフォンその他の情報端末にダウンロードし、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。</p>
                    <p>３　利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。</p>
                    <p>４　未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。</p>
                    <p>５ 本規約の同意時に未成年であった利用者が成年に達した後に本サービスを利用した場合、当該利用者は本サービスに関する一切の法律行為を追認したものとみなされます。</p>
                    <p></p>
                    <p>第４条（規約の変更）</p>
                    <p>１　当社は、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。</p>
                    <p>２　当社は、本規約を改定するときは、その内容について当社所定の方法により利用者に通知します。</p>
                    <p>３　前本規約の改定の効力は、当社が前項により通知を行った時点から生じるものとします。</p>
                    <p>４　利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。</p>
                    <p></p>
                    <p>第５条（会員の入会手続）</p>
                    <p>１　本サービスへの入会を希望する方（以下「登録希望者」）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。</p>
                    <p>２　入会の申込をした方は、弊社がその申込を承諾し、ＩＤ登録が完了した時点から登録ユーザとなります。</p>
                    <p>３　弊社は、登録ユーザ向けにメールで連絡事項の告知や広告その他の情報提供を行います。あらかじめご了承ください。</p>
                    <p>４　弊社は、登録希望者が次の各号のいずれか一つに該当する場合は、弊社の判断により入会申込を承諾しないことがあります。</p>
                    <p>一　登録希望者が、弊社の定める方法によらず入会の申込を行った場合 </p>
                    <p>二　登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合</p>
                    <p>三　登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合</p>
                    <p>四　登録希望者が、本人以外の情報を登録している場合</p>
                    <p>五　その他弊社が不適切と判断した場合</p>
                    <p></p>
                    <p>第６条（アカウントの管理）</p>
                    <p>１　利用者は、利用に際して登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。</p>
                    <p>２　当社は、登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。 </p>
                    <p>３　利用者は、登録情報の不正使用によって当社または第三者に損害が生じた場合、当社および第三者に対して、当該損害を賠償するものとします。 </p>
                    <p>４　登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、当社は責任を負わないものとします。 </p>
                    <p>５　登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</p>
                    <p></p>
                    <p>第７条（個人情報等の取り扱い） </p>
                    <p>個人情報及び利用者情報については、当社が別途定める「TiTi プライバシーポリシー」に則り、適正に取り扱うこととします。</p>
                    <p></p>
                    <p>第８条　商品の出品</p>
                    <p>1.出品手続等</p>
                    <p>出品者は、弊社所定の手続に従い商品の出品を行うものとします。出品者は、本規約に加え、弊社が定める加盟店規約に同意をしたうえで、加盟店規約の定めに従って本サービスを利用するものとします。 </p>
                    <p></p>
                    <p>2.出品禁止商品</p>
                    <p>出品者は禁止されている出品物（以下「出品禁止物」といいます）に記載された商品の出品ができないことについて予め了承します。出品禁止物に該当する商品を出品した場合は、出品者の故意又は過失に関わらず、本規約違反行為とみなします。</p>
                    <p></p>
                    <p>3.商品説明等</p>
                    <p>ユーザーは、出品する際に、真に売却する意思のない出品、その商品情報だけでは正しく商品を理解できない又は混乱する可能性のある出品、商品説明で十分な説明を行わない出品等を行ってはなりません。また、出品者は、出品する商品と関係のない画像等を当該出品情報として掲載してはいけません。</p>
                    <p></p>
                    <p>4.法令遵守</p>
                    <p>ユーザーは、出品にあたっては、古物営業法、特定商取引に関する法律、不当景品類及び不当表示防止法、不正競争防止法、商標法、著作権法その他の法令を遵守しなければなりません。</p>
                    <p></p>
                    <p>5.出品に関する本規約違反</p>
                    <p>出品に関して弊社が本規約又は加盟店規約に違反すると判断した場合又は不適切と判断した場合、弊社は、その出品やその出品に対して発生していた購入行為等を弊社の判断で取り消すことができるものとします。本項に基づく措置によってユーザーに生じる損害について、弊社は一切責任を負わないものとします。</p>
                    <p></p>
                    <p>6.弊社の免責</p>
                    <p>ユーザーの出品等によって、ユーザー及び第三者に生じる損害につき、弊社は一切責任を負わないものとします。</p>
                    <p></p>
                    <p>第９条　出品物の購入</p>
                    <p>1.購入手続</p>
                    <p>ユーザーは、弊社の定める手続により購入の意思をもって、注文を行うものとします。</p>
                    <p></p>
                    <p>2.購入意思等</p>
                    <p>ユーザーは、購入する意思のない注文、転売等の営利を目的とした出品物の購入等、また弊社の判断でいたずら目的と見受けられる注文を行うことはできません。</p>
                    <p></p>
                    <p>3.必要データ</p>
                    <p>弊社が定める取引開始可能な時間内に、弊社の管理するサーバーに出品物代金に関するデータ、販売意思のアクションデータなどの弊社所定のデータの到達が確認できなかった場合、注文は無効になるものとし、ユーザーはあらかじめこれを承諾するものとします。</p>
                    <p></p>
                    <p>4.自らの出品物の購入禁止等</p>
                    <p>出品者は、自らの出品物を購入することはできません。出品を取り下げたい場合は、ユーザーは、弊社所定の手続に従って行うものとします。</p>
                    <p></p>
                    <p>5.弊社の免責</p>
                    <p>ユーザーの注文又は購入等によって、ユーザー及び第三者に生じる損害につき、弊社は一切責任を負わないものとします。</p>
                    <p></p>
                    <p>第１０条　支払及び取引の実行</p>
                    <p>1.売買契約の成立</p>
                    <p>購入者が出品された特定の商品の購入完了手続をした時をもって当該商品の売買契約が成立するものとします。出品者及び購入者は、売買契約に基づき発生した権利・義務を第三者に譲渡、担保提供その他の処分することはできないものとします。</p>
                    <p></p>
                    <p>2.支払期限及び送料</p>
                    <p>売買契約が成立した場合、購入者は、弊社の定める方法により出品物代金と利用料の合計額を支払うものとします。</p>
                    <p></p>
                    <p>3.商品等に関するトラブル</p>
                    <p>出品者、購入者の間で商品等に関してトラブル発生した場合は当該ユーザー間で解決するものとします。ただし、弊社の判断により、弊社も協議に入ることができるものとします。</p>
                    <p></p>
                    <p>4.キャンセル</p>
                    <p>本サービスの利用にあたり、出品者、購入者間の同意がある場合を除き、出品後、注文後のキャンセルはできないものとします。 </p>
                    <p></p>
                    <p>5.支払手続</p>
                    <p>支払又は決済が本サービスに関して必要となる場合、本サービスのオンラインシステムを通じて行われるものとします。なお、本サービス利用に関し、ユーザーによって支払われた代金決済についての領収書等は発行されないものとします。また、支払いに必要な振込手数料に関してはユーザーの負担とします。出品者は、弊社に対して、購入者から支払われる代金（決済事業者または収納代行業者から支払われる代金に相当する金員を含みます。）を代理受領する権限を付与するものとします。出品者は、弊社が決済事業者および収納代行業者を指定した場合には、当該決済事業者および収納代行業者に対して、商品代金を代理受領する権限を付与するものとします。また、弊社は、地域を限定して決済事業者および収納代行業者を指定することができるものとします。さらに、出品者は、弊社が決済事業者又は収納代行業者との間で締結する決済サービスに関する加盟店契約により必要となる場合には、代金債権を弊社に譲渡することに同意します。</p>
                    <p>弊社は、弊社所定の審査を通過したユーザーが、商品購入に際して希望する場合において、出品者に対して立替払いを行い、商品代金の決済を行うことがあります（以下「立替払決済」という。）。この場合、当該購入者は、購入手続きが完了した日の属する月の翌月末日（以下「支払期日」という。）までに代金及び利用料（以下「支払債務」という。）を弊社に対して支払うものとします。</p>
                    <p>6 .不払・支払遅延等</p>
                    <p>ユーザーが本規約に従って必要な支払いを行わない場合もしくは遅延した場合又は本サービスに入力したクレジットカードもしくは金融機関の口座の利用が停止された場合には、弊社は、当該ユーザーに通知することなく、当該ユーザーによる本サービスの利用を停止することができます。</p>
                    <p>弊社所定の審査を通過した購入者が、規定する支払期日までに支払債務を弁済することができなかった場合、弊社は当該ユーザーに対する立替払決済の提供を停止することができるものとします。</p>
                    <p>未払いの支払債務が存在している場合、弊社は、未払いの支払債務の回収を第三者に委託することができるものとします。</p>
                    <p>支払期日までに購入者が支払債務を支払わなかった場合、弊社は、当該購入者に対し、年率14.6％の遅延損害金を請求することができるものとします。</p>
                    <p>7.売買契約の取消</p>
                    <p>売買契約が成立した場合においても、購入者が代金及び利用料の合計額を支払わない場合もしくは支払いを遅延した場合、出品者が商品提供を行わない場合、その他弊社が必要と認める場合には、弊社は、当該売買契約を取り消すことができるものとします。かかる売買契約の取消しその他の事由により弊社が必要と認める場合には、ユーザーは、弊社の指示に従い、出品物の所有権を無償で弊社に譲渡するものとします。</p>
                    <p></p>
                    <p>9.弊社の免責</p>
                    <p>ユーザーが本サービスに入力した決済手段又は金融機関の情報が第三者に利用されたこともしくは入力情報の内容が不正確であったこと又は弊社が本条に基づく措置を行ったこともくしは行わなかったことによってユーザーに生じた損害に関して、弊社は一切責任を負わないものとします。</p>
                    <p></p>
                    <p></p>
                    <p>第１１条（禁止行為）　</p>
                    <p>本サービスの利用に際し、当社は、利用者に対し、次に掲げる行為を禁止します。当社において、利用者が禁止事項に違反したと認めた場合、利用者用の一時停止、退会処分その他当社が必要と判断した措置を取ることができます。 </p>
                    <p></p>
                    <p>（１）当社または第三者の知的財産権を侵害する行為</p>
                    <p>（２）当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為</p>
                    <p>（３）当社または第三者の財産を侵害する行為、または侵害する恐れのある行為 </p>
                    <p>（４）当社または第三者に経済的損害を与える行為</p>
                    <p>（５）当社または第三者に対する脅迫的な行為</p>
                    <p>（６）コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為 </p>
                    <p>（７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為 </p>
                    <p>（８）当サイトのサーバーやシステム、セキュリティへの攻撃 </p>
                    <p>（９）当社提供のインターフェース以外の方法で当社サービスにアクセスを試みる行為</p>
                    <p>（１０）一人の利用者が、複数の利用者IDを取得する行為</p>
                    <p>（１１）上記の他、当社が不適切と判断する行為</p>
                    <p></p>
                    <p>第１２条（免責）</p>
                    <p>１　当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。</p>
                    <p>２　当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。</p>
                    <p>３　当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。</p>
                    <p>４　当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。</p>
                    <p>５　利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。</p>
                    <p>６　当社は、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。</p>
                    <p>７　当社は、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、当社が係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。</p>
                    <p>８　第１項乃至前項の規定は、当社に故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。</p>
                    <p>９　前項が適用される場合であっても、当社は、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。　</p>
                    <p>１０　本サービスの利用に関し当社が損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。</p>
                    <p>１１　利用者と他の利用者との間の紛争及びトラブルについて、当社は一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、当社には一切の請求をしないものとします。</p>
                    <p>１２　利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、当社には一切の迷惑や損害を与えないものとします。</p>
                    <p>１３　利用者の行為により、第三者から当社が損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。当社が、当該第三者に対して、損害賠償金を支払った場合には、利用者は、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。</p>
                    <p>１４　利用者が本サービスの利用に関連して当社に損害を与えた場合、利用者の費用と責任において当社に対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします。</p>
                    <p></p>
                    <p>第１３条（広告の掲載について） </p>
                    <p>利用者は、本サービス上にあらゆる広告が含まれる場合があること、当社またはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、当社によって随時変更されます。 </p>
                    <p></p>
                    <p>第１４条（権利譲渡の禁止）</p>
                    <p>１　利用者は、予め当社の書面による承諾がない限り、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。</p>
                    <p>２　当社は、本サービスの全部または一部を当社の裁量により第三者に譲渡することができ、その場合、譲渡された権利の範囲内で利用者のアカウントを含む、本サービスに係る利用者の一切の権利が譲渡先に移転するものとします。</p>
                    <p></p>
                    <p>第１５条（分離可能性）</p>
                    <p>本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>
                    <p></p>
                    <p>第１６条（当社への連絡方法）</p>
                    <p>本サービスに関する利用者の当社へのご連絡・お問い合わせは、本サービスまたは当社が運営するwebサイト内の適宜の場所に設置するお問い合わせフォームからの送信または当社が別途指定する方法により行うものとします。</p>
                    <p></p>
                    <p>第１７</p>
                    <p>条（準拠法、管轄裁判所）</p>
                    <p>１　本規約の有効性，解釈及び履行については，日本法に準拠し，日本法に従って解釈されるものとする。</p>
                    <p>２　当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします｡</p>
                    <p></p>
                    <p>2018年9月17日　施行</p>
                    <p style={{marginBottom: 16,}}></p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Terms);
