import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import FontAwesome from "react-fontawesome";
import {INQUIRY_MAIL_ADDRESS} from '../../Construct';

import './CongregateHelp.css';

import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';

enum Type {
    PLAN, // 取引中
    INVITE, // 過去の取引
}

interface CongregateHelpProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface CongregateHelpStates {
    type: Type;
}

class CongregateHelp extends React.Component<CongregateHelpProps, CongregateHelpStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            type: Type.PLAN,
        };
    }

    render() {


        return (
            <div className="CongregateHelp row">
                <Header
                    title={'使い方'}
                    leftButtonTitle={(this.props.history.length > 0) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            window.close();
                        }
                    }}>
                </Header>
                <CommonSegmentButton lists={['企画者', '招待者']} index={this.state.type} onChange={(i) => {
                    this.setState({
                        type: i,
                    });
                }}/>
                {this.state.type == Type.PLAN &&
                <div className="col-12 help">
                    <h1>1. イベントの作成・招待</h1>
                    <p>お店、コース、日時および、イベントの成立条件を
                        確認して、還元されるコミュニティを選択します。<br/>
                        <br/>
                        任意でイベントのタイトルを決めた上で、イベント
                        企画を作成して誘いたい人を招待しましょう。<br/>
                        <br/>
                        参加者はシェアされたURLから企画内容を確認、成
                        立時のご案内のためにニックネームとメールアドレ
                        スを入れていただくだけで参加することができます。
                    </p>
                    <h1>2. イベント成立後</h1>
                    <p>
                        特定の人数があつまったら、イベント企画は成立し
                        ます。成立すると、メールでご案内が届きます。<br/>
                        <br/>
                        あとは、決まった日時で、決まったコースの料金を
                        現金で持って、コミュニティの友だちとあつまるだ
                        けです。（決済は今後、カード決済に対応して行く
                        予定です。事前に決済を行って手ぶらでいくことも
                        可能となります。）
                    </p>
                </div>
                }
                {this.state.type == Type.INVITE &&
                <div className="col-12 help">
                    <h1>1. 企画内容を確認・参加</h1>
                    <p>
                        招待URLかQRコードからイベント企画ページを閲覧できます。<br/>
                        <br/>
                        お店、コース、日時などイベントの企画内容を確認
                        して、参加します。アカウントがない場合は、ニッ
                        クネームとメールアドレスを登録するだけでお試し
                        参加が可能です。<br/>
                        <br/>
                        もし、イベント企画に参加ができなくなった場合は
                        キャンセルボタンを押しましょう。
                    </p>
                    <h1>2. イベント成立後</h1>
                    <p>
                        特定の人数があつまったら、イベント企画は成立し
                        ます。成立すると、メールでご案内が届きます。<br/>
                        <br/>
                        あとは、決まった日時で、決まったコースの料金を
                        現金で持って、コミュニティの友だちとあつまるだ
                        けです。（決済は今後、カード決済に対応して行く
                        予定です。事前に決済を行って手ぶらでいくことも
                        可能となります。）
                    </p>
                </div>
                }
                <div className="col-12 text-center help">
                    <input
                        type="button"
                        className={`w100 cursor`}
                        value={'問い合わせはこちらから'}
                        onClick={() => {
                            window.location.href = 'mailto:' + INQUIRY_MAIL_ADDRESS + '?subject=&body=';
                        }}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CongregateHelp);
