import React from 'react';
import BaseApi from './BaseApi';

export default class ApprovalsApi extends BaseApi {

    /**
     * POST /approvals
     */
    static _fetchApprovals(params, success, error) {
        super._fetch('/api/v1/approvals', 'POST', params, success, error);
    }
}