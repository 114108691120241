import React from 'react';
import BaseApi from './BaseApi';

export default class InvitationManagementApi extends BaseApi {

    /**
     * /communities/${community_name}/invitation_code
     */
    static _fetchInvitationCode(community_name, params, success, error) {
        super._fetch(`/api/v1/communities/${community_name}/invitation_code`, 'GET', params, success, error);
    }

    /**
     * /communities/:community_name/invitation_code
     */
    static _fetchInvitationCodeEdit(community_name, params, success, error) {
        super._fetch(`/api/v1/communities/${community_name}/invitation_code`, 'PUT', params, success, error);
    }

    /**
     * /invitations
     */
    static _fetchInvitation(params, success, error) {
        super._fetch(`/api/v1/invitations`, 'POST', params, success, error);
    }

}
