import React from 'react';
import BaseApi from './BaseApi';

export default class Production extends BaseApi {

    /**
     * /product
     */
    static _fetchProducts(params, success, error) {
        super._fetch('/api/v1/product_lists', 'GET', params, success, error);
    }

    /**
     * /product/:id
     */
    static _fetchProductsShow(id, params, success, error) {
        super._fetch('/api/v1/products/' + id, 'GET', params, success, error);
    }

    /**
     * /product/
     */
    static _fetchProductsCreate(params, success, error) {
        super._fetch('/api/v1/products', 'POST', params, success, error);
    }

    /**
     * /products/:id/edit
     */
    static _fetchProductsEdit(id, params, success, error) {
        super._fetch('/api/v1/products/' + id + '/edit', 'GET', params, success, error);
    }

    /**
     * /product/:id
     */
    static _fetchProductsEdit(id, params, success, error) {
        super._fetch('/api/v1/products/' + id, 'PUT', params, success, error);
    }

    /**
     * /product/:id
     */
    static _fetchProductsDelete(id, params, success, error) {
        super._fetch('/api/v1/products/' + id, 'DELETE', params, success, error);
    }
}