import React, { Children } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { actionCreator, RootState } from '../../reducers';

import CommonImage from '../../components/CommonImage'
import CommonUserInfo from '../../components/CommonUserInfo'

import ProductionApi from '../../helpers/api/ProductionApi';
import './ProductDetail.css';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import locail_image from 'assets/productDetail/locail.png';
import ribon_image from 'assets/productDetail/ribon.png';
import tag_image from 'assets/productDetail/tag.png';
import time_image from 'assets/productDetail/time.png';
import user_image from 'assets/productDetail/user.png';

import { Product, Review } from '../../helpers/schemas/Api';
import { range, _dateToString } from '../../helpers/utils';

import FontAwesome from 'react-fontawesome'
import ReviewApi from '../../helpers/api/ReviewApi';
import CommonStars from '../../components/CommonStars';

interface ProductDetailProps {
    history: any;
    currentUser: any;
    showSpinner: (show: boolean) => void;
}

interface ProductDetailStates {
    product?: Product,
    productId: number,
    currentImageIndex: number,
    reviewList: Review[],
}

class ProductDetail extends React.Component<ProductDetailProps, ProductDetailStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            productId: props.match.params.id,
            currentImageIndex: 0,
            reviewList: [],
        };
    }

    componentDidMount() {
        this._fetch()
    }

    _fetch = () => {
        this.props.showSpinner(true);


        const __handle_error = (error: any) => {
            alert(error);
            console.log(error);
            this.props.showSpinner(false);
        }

        ProductionApi._fetchProductsShow(
            this.state.productId,
            {},
            (product: Product) => {
                this.setState({ product });
                ReviewApi._fetchProductReviews(
                    this.state.productId,
                    {},
                    (res: any) => {
                        const reviews = res.reviews
                        for (let review of res.reviews) {
                            review.created_at = new Date(review.created_at);
                            review.updated_at = new Date(review.updated_at);
                        }
                        this.setState({ reviewList: reviews });
                        this.props.showSpinner(false);
                    },
                    __handle_error,
                )
            },
            __handle_error,
        )
    }

    _onPressBottomBarButton = () => {
        if (this._isProductOwner()) {
            this.props.history.push(`/product/${this.state.productId}/edit`);
        } else {
            this.props.history.push(`/product/purchase/${this.state.productId}`);
        }
    }

    _onClickDeleteButton = () => {
        if (window.confirm('この商品を削除しますか？')) {
            this.props.showSpinner(true);
            ProductionApi._fetchProductsDelete(
                this.state.productId,
                {},
                (success: any) => {
                    this.props.history.goBack();
                    this.props.showSpinner(false);
                }, (error: any) => {
                    alert(error);
                    console.log(error);
                    this.props.showSpinner(false);
                }
            )
        }
    }

    _isProductOwner = (): boolean => {
        if (!this.state.product) { return false }
        return this.state.product.user.id == this.props.currentUser.user_id
    }

    _onPressMoreReview = () => {
        this.props.history.push(`/product/${this.state.productId}/review`);
    }

    render() {
        if (!this.state.product) {
            return (<div />)
        }
        const { product, currentImageIndex, reviewList } = this.state;
        const images_component = product.images.map((image, index) => (
            <div key={`main-image-${index}`}>
                <img className="main-image-item-image" src={image.content.url} />
            </div>
        ))
        const req_time = (product.required_time_day != 0 ? product.required_time_day + "日" : "") + (product.required_time_hour != 0 ? product.required_time_hour + "時間" : "") + (product.required_time_min != 0 ? product.required_time_min + "分" : "")

        const purchase_title = product.type_of_product == 'time' ? '開催日程へ' : '購入する'
        const is_owner = this._isProductOwner();
        const button_title = is_owner ? "編集する" : purchase_title;

        console.log('product', product)
        console.log('reviewList', reviewList)
        console.log(currentImageIndex)
        return (
            <div className="ProductDetail row">
                <div className="header">
                    {is_owner && <FontAwesome name='trash-o' className='trash-icon' onClick={this._onClickDeleteButton} />}
                </div>
                <div className="main-image-list-wrapper">
                    <Carousel showStatus={false} showThumbs={false} showArrows={false} showIndicators={false}
                        infiniteLoop={images_component.length > 1} autoPlay={true} selectedItem={currentImageIndex}
                        transitionTime={500} interval={5000}
                        onChange={(i) => { this.setState({ currentImageIndex: i }); }}>
                        {images_component}
                    </Carousel>
                    <div className="main-image-bottom-wrapper">
                        <div className="main-title">{product.title}</div>
                        <PageControl numberOfPages={product.images.length} currentPage={currentImageIndex} />
                    </div>
                </div>
                <div className="info-list-wrapper">
                    <div className="product-info-list">
                        {product.place && <LightInfoCell iconUrl={locail_image} description={product.place} />}
                        {req_time && <LightInfoCell iconUrl={time_image} description={req_time} />}
                        {product.number_of_stocks && <LightInfoCell iconUrl={ribon_image} description={`残り${product.number_of_stocks}個`} />}
                        <LightInfoCell iconUrl={tag_image} description={product.tag_list.join('・')} />
                        {/* <LightInfoCell iconUrl={ribon_image} description='大分県' />
                        <LightInfoCell iconUrl={user_image} description='大分県' /> */}
                    </div>
                    <Border />
                    <div className="user-info content-cell">
                        <Section title={'出品者について'} />
                        <CommonUserInfo upperText={`${product.communities_user.nickname} さん`} imageUrl={product.user.avatar_url} />
                        <TextArea>{product.communities_user.introduction}</TextArea>
                    </div>
                    <Border />
                    <div className="content-cell">
                        <Section title={'詳細内容'} />
                        <TextArea className='text-margin-top'>{product.description}</TextArea>
                    </div>
                    {product.notes && <div>
                        <Border />
                    <div className="content-cell">
                        <Section title={'注意事項'} />
                        <TextArea className='text-margin-top'>{product.notes}</TextArea>
                    </div></div>}
                    {reviewList.length > 0 && <div>
                        <Border />
                    <div className="content-cell">
                        <Section title={'レビュー'} />
                        <ReviewCell review={reviewList[0]} />
                    </div></div>}
                    {reviewList.length > 0 && <div>
                        <Border />
                        <MoreReview product={product} onPress={this._onPressMoreReview}/>
                    </div>}
                </div>
                <div className='margin-for-bottom-bar' />
                <BottomBar product={product} onPress={this._onPressBottomBarButton} title={button_title} />
            </div>
        );
    }
}

const MoreReview = ({ product, onPress }: { product: Product, onPress?: () => void }) => (<div onClick={onPress} className='more-review-cell'>
    <div className='more-review-text'>{product.reviews_count}件のレビューを見る</div>
    {product.reviews_count > 5 && <div className='stars-list-wrapper'>-<CommonStars numberOfStars={5} rating={product.review_average_rate}
        starDimension='5vw' starSpacing='0.2vw' />
    </div>}
</div>)

const ReviewCell = ({ review }: { review: Review }) => (<div><CommonUserInfo upperText={`${review.communities_user.nickname} さん`}
    descripion={_dateToString(review.created_at)}
    imageUrl={review.communities_user.avatar_url} />
    <TextArea className='text-margin-top'>{review.comment}</TextArea>
</div>)

const PageControl = ({ numberOfPages, currentPage }: { numberOfPages: number, currentPage: number }) => {
    return (<div className="main-image-page-control">
        {range(0, numberOfPages - 1).map(i => {
            const _className = i == currentPage ? 'active page-control-item' : 'page-control-item'
            return (<div className={_className} />)
        })}
    </div>)
}

const Section = ({ title }: { title: string }) => (<div className='section'>{title}</div>)

const Border = () => (<div className='border' />)

const LightInfoCell = ({ iconUrl, description }: { iconUrl: string, description: string }) => {
    return (<div className="product-info-item">
        <CommonImage src={iconUrl} className='product-info-item-icon' />
        <div className='product-info-item-description tail-text'>{description}</div>
    </div>)
}

const TextArea = ({ children, className }: { children: any, className?: string }) => {
    const _className = `text-area ${className}`
    return (<div className={_className}>{children}</div>)
}

const BottomBar = ({ product, onPress, title }: { product: Product, onPress: () => void, title: string }) => {
    const unit = product.type_of_product == 'time' ? '人' : '個'
    return (
        <div className='bottom-bar'>
            <div className='bottom-bar-info'>¥{product.price} / {unit}</div>
            <div className='bottom-bar-button cursor' onClick={onPress}>{title}</div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetail);
