import {hideDialog, showDialog, ShowAction, HideAction} from './Dialog';

type Actions = ShowAction | HideAction;

export type State = {
    dialog: {
        show: boolean;
        title?: string;
        text?: string;
        onClickYes?: (e: any) => void;
        onClickNo?: (e: any) => void;
    };
};

const init = (): State => {
    return {
        dialog: {
            show: false,
            title: '',
            text: '',
            onClickYes: (e: any) => {
            },
            onClickNo: (e: any) => {
            },
        }
    };
};

export const reducer = (state: State = init(), action: Actions) => {
    switch (action.type) {
        case 'SHOW_DIALOG':
            return {
                dialog: action.payload,
            };
        case 'HIDE_DIALOG':
            state.dialog.show = false;
            return {
                dialog: state.dialog,
            };
        default:
            return state;
    }
};

export const actionCreator = {
    showDialog,
    hideDialog,
};