import React, {useState} from 'react';

import './Home.css';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

// import NewsApi from '../../helpers/api/NewsApi';
import UsersCommunityApi from '../../helpers/api/UsersCommunityApi';

import Header from '../../components/Header';
import {string} from "prop-types";
import no_image from 'assets/no_image.png';
import no_community from 'assets/no_community.png';

interface HomeProps {
    history: any;
    setCurrentUser: (name: string, token: string) => void;
    currentUser: {
        communities?: {
            id: string;
            name: string;
            communities_users: {}[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface HomeState {
    render: boolean;
}

class Home extends React.Component<HomeProps, HomeState> {

    constructor(props: any) {
        super(props);
        this.state = {
            render: true,
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }


    render() {

        // console.log('Home props', this.props);
        // console.log('Home state', this.state);

        var list = [];
        if (this.props.currentUser.communities) {
            for (let data of this.props.currentUser.communities) {

                let name = data ? data!.name! : '';

                let community_image = no_image;
                if (data.community_image) {
                    community_image = data.community_image!.image!.content!.url || no_image;
                }

                list.push(
                    <div className="col-12 community cursor" onClick={e => {
                        this.props.history.push(`/community/${data.name}`);
                    }}>
                        <div className="row">
                            <div className="col-4 col-sm-2">
                                <div className="community-image">
                                    <img src={community_image}/>
                                </div>
                            </div>
                            <div className="col-8 col-sm-10 community-box">
                                <p className="community-name">{name}</p>
                                <p className="community-count">参加メンバー {data.communities_users.length}名</p>
                            </div>
                        </div>
                    </div>
                );
            }
        }
        if (list.length == 0) {
            list.push(
                <img src={no_community} className="no_community"/>
            );
        }

        return (
            <div className="Home row">
                { this.state.render &&
                <div className="col-12">
                    {list}
                </div>
                }
            </div>
        );
    }
}

interface MenuButtonProps {
    image: any,
    title: string,
    onClick?: any,
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);