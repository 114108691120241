import {Action} from 'redux';

export type SpinnerPayload = {    // todoを追加する時に必要なのはtodoの内容くらい
    show: boolean;
};

export interface SpinnerAction extends Action {
    type: 'SPINNER';
    payload: SpinnerPayload;
}

export const spinner = (payload: SpinnerPayload): SpinnerAction => {
    return {
        payload,
        type: 'SPINNER',
    };
};