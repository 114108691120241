import React, {useState, Fragment, Component}  from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import FontAwesome from "react-fontawesome";

import BenefitApi from '../../helpers/api/BenefitApi';
import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';

import Header from '../../components/Header';
import CommonUserCell from '../../components/CommonUserCell';

import './BenefitDetail.css';
import no_image from 'assets/no_image.png';
import reduction_ratio from 'assets/reduction_ratio.png';
import community_name from 'assets/community_name.png';
import member_number from 'assets/member_number.png';
import detail from 'assets/detail.png';
import bank from 'assets/bank.png';

enum VIEW_TYPE {
    DETAIL,     // 0
    CONFIRM,   // 1
}

interface BenefitDetailProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface BenefitDetailStates {
    benefit_id: number;
    view: VIEW_TYPE;
    benefit?: {
        id: number;
        title: string;
        description: string;
        applied_price: number;
        benefit_price: number;
        apply_status: string;
        message: string;
        images: [];
        benefit_members: {
            nickname: string;
            role: string;
            avatar_url: string;
        }[];
        community: {
            id: number;
            name: string;
            reduction_amount: number;
        };
    };

    price: number;
    nickname: string;
    avatar_url: string;
    message: string;
}

class BenefitDetail extends React.Component<BenefitDetailProps, BenefitDetailStates> {

    constructor(props: any) {
        super(props);

        this.state = {
            benefit_id: props.match.params.id,
            view: VIEW_TYPE.DETAIL,
            message: '',
            price: 0,
            nickname: '',
            avatar_url: '',
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchBenefitDetail();
    }

    _fetchBenefitDetail = () => {

        this.props.showSpinner(true);

        BenefitApi._fetchBenefitDetail(
            this.state.benefit_id,
            {},
            (responseJson: any) => {
                console.log('_fetchBenefitDetail', responseJson);

                this.props.showSpinner(false);

                // 申請中以外
                // if (responseJson.apply_status != 'applied') {
                //     this.props.showDialog(
                //         "確認",
                //         `すでに承認・不承認済みのデータです。`,
                //         (e: any) => {
                //             this.props.hideDialog();
                //             if (this.props.history.length > 0) {
                //                 this.props.history.goBack();
                //             } else {
                //                 this.props.history.push('/');
                //             }
                //         },
                //         null,
                //     );
                //     return;
                // }

                // 申請者取得
                var nickname = '';
                var avatar_url = '';
                for (let m of responseJson.benefit_members) {

                    if (m.role == 'applicant') {
                        nickname = m.nickname;
                        avatar_url = m.avatar_url;
                    }
                }

                this.setState({
                    price: responseJson.applied_price,
                    benefit: responseJson,
                    nickname,
                    avatar_url,
                });
            },
            (error: any) => {

                this.props.showSpinner(false);

                alert(error);
            },
        );
    }

    _handlePressReject = (e: any) => {
        e.preventDefault();

        BenefitApi._fetchBenefitDecision(
            this.state.benefit_id,
            {
                apply_status: 2, // 不承認
                // benefit_price: 0,
                // message: '',
            },
            (responseJson: any) => {
                console.log('_fetchBenefitDecision', responseJson);

                this.props.showSpinner(false);

                this.props.showDialog(
                    "確認",
                    `給付金を却下しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push('/');
                        }
                    },
                    null,
                );
            },
            (error: any) => {

                this.props.showSpinner(false);

                alert(error);
            },
        );
    }

    _handlePressSubmit = (e: any) => {
        e.preventDefault();

        if (this.state.benefit && this.state.price > this.state.benefit.applied_price) { // TODO
            this.props.showDialog(
                "確認",
                `承認金額は支援金残高以下にしてください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        if (this.state.view == VIEW_TYPE.DETAIL) {
            this.setState({
                view: VIEW_TYPE.CONFIRM,
            });
            return;
        }

        BenefitApi._fetchBenefitDecision(
            this.state.benefit_id,
            {
                apply_status: 1, // 承認
                benefit_price: this.state.price,
                message: this.state.message,
            },
            (responseJson: any) => {
                console.log('_fetchBenefitDecision', responseJson);

                this.props.showSpinner(false);

                this.props.showDialog(
                    "確認",
                    `給付金を承認しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push('/');
                        }
                    },
                    null,
                );
            },
            (error: any) => {

                this.props.showSpinner(false);

                alert(error);
            },
        );
    }

    render() {

        var members = [];
        if (this.state.benefit) {

            for (let m of this.state.benefit.benefit_members) {
                members.push(
                    <div className="user-box">
                        <img src={m.avatar_url} className="selected-user-image"/>
                        <p>{m.nickname}</p>
                    </div>
                );
            }
        }

        return (
            <div className="BenefitDetail row">
                <Header
                    title={'給付金を承認する'}
                    leftButtonTitle={((this.props.history.length > 0) || this.state.view == VIEW_TYPE.CONFIRM) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {

                        if (this.state.view == VIEW_TYPE.CONFIRM) {
                            this.setState({
                                view: VIEW_TYPE.DETAIL,
                            });
                            return;
                        }

                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            window.close();
                        }
                    }}
                />

                {this.state.view == VIEW_TYPE.DETAIL && this.state.benefit &&
                <Fragment>

                    <div className="col-12 main">
                        <p className="title">{this.state.benefit.title}</p>
                    </div>

                    <div className="col-12 price-box">

                        {this.state.benefit.apply_status != 'applied' &&
                        <Fragment>
                            <p className="title">承認金額</p>
                            <div className="price">
                                {this.state.price}円
                            </div>
                        </Fragment>
                        }
                        {this.state.benefit.apply_status == 'applied' &&
                        <Fragment>
                            <p className="title">給付金金額</p>
                            <div className="price">
                                <input className='form-input-text price' placeholder={'0'} name="" value={this.state.price} type="number" onChange={(e) => this.setState({price: Number(e.target.value)})}/>
                                <span className="right">円</span>
                            </div>
                        </Fragment>
                        }

                        <p className="meta">
                            <img src={reduction_ratio} alt="" className="icon"/>
                            還元金：{this.state.benefit.applied_price}円を希望
                        </p>

                        <p className="meta">
                            <img src={bank} alt="" className="icon"/>
                            支援金残高：{Number(this.state.benefit.community.reduction_amount).toLocaleString()}円
                        </p>
                    </div>

                    <div className="col-12 price-box">
                        <p className="title">詳細内容</p>
                        <p className="meta">
                            <img src={community_name} alt="" className="icon"/>
                            {this.state.benefit.community.name}
                        </p>
                        <p className="meta">
                            <img src={member_number} alt="" className="icon"/>
                            参加メンバー {this.state.benefit.benefit_members.length}人
                        </p>
                        <div className="members-list">
                            {members}
                        </div>
                        <p className="meta">
                            <img src={detail} alt="" className="icon"/>
                            詳細
                        </p>
                        <p className="description">
                            {this.state.benefit.description}
                        </p>
                    </div>
                </Fragment>
                }

                {this.state.view == VIEW_TYPE.CONFIRM &&
                <Fragment>
                    <div className="col-12 main">
                        <CommonUserCell
                            image={this.state.avatar_url || no_image}
                            onClick={e => {

                            }}
                            title={this.state.price + '円'}
                            sub_title={this.state.nickname}
                        />
                        <textarea className='form-input-text large' name="" placeholder="承認理由やその他メッセージをご記入ください" value={this.state.message} onChange={(e) => this.setState({message: e.target.value})}></textarea>
                    </div>
                </Fragment>
                }

                {this.state.benefit && this.state.benefit.apply_status == 'applied' &&
                <div className="button">
                    <div className="row">

                        {this.state.view == VIEW_TYPE.DETAIL &&
                        <Fragment>
                            <div className="col-6">
                                <input
                                    type="button"
                                    className="btn reject cursor"
                                    value="不承認"
                                    onClick={this._handlePressReject}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="button"
                                    className="btn cursor"
                                    value="次へ"
                                    onClick={this._handlePressSubmit}
                                />
                            </div>
                        </Fragment>
                        }

                        {this.state.view == VIEW_TYPE.CONFIRM &&
                        <Fragment>
                            <div className="col-12">
                                <input
                                    type="button"
                                    className="btn cursor"
                                    value="給付金申請を承認する"
                                    onClick={this._handlePressSubmit}
                                />
                            </div>
                        </Fragment>
                        }
                    </div>
                </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    console.log('RootState', state);
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenefitDetail);
