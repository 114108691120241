import React from 'react';
import BaseApi from './BaseApi';

export default class TalkApi extends BaseApi {
    /**
     * GET /talks
     */
    static _fetchTalks(params, success, error) {
        super._fetch(`/api/v1/talks`, 'GET', params, success, error);
    }

    /**
     * POST /talks
     */
    static _fetchTalksCreate(params, success, error) {
        super._fetch(`/api/v1/talks`, 'POST', params, success, error);
    }

    /**
     * GET /talks/:talk_id
     */
    static _fetchTalksShow(talk_id, params, success, error) {
        super._fetch(`/api/v1/talks/${talk_id}`, 'POST', params, success, error);
    }

    /**
     * GET /talks/:talk_id/messages
     */
    static _fetchTalkMessages(talk_id, params, success, error) {
        super._fetch(`/api/v1/talks/${talk_id}/messages`, 'GET', params, success, error);
    }

    /**
     * POST /talks/:talk_id/messages
     */
    static _fetchTalkMessagesCreate(talk_id, params, success, error) {
        super._fetch(`/api/v1/talks/${talk_id}/messages`, 'POST', params, success, error);
    }
}
