import {imagePreview, ImagePreviewAction} from './ImagePreview';

type Actions
    = ImagePreviewAction;

export type State = {
    imagePreview: {
        show: boolean;
        images: string[];
        selectedIndex: number;
    };
};

const init = (): State => {
    return {
        imagePreview: {
            show: false,
            images: [],
            selectedIndex: 0,
        }
    };
};

export const reducer = (state: State = init(), action: Actions) => {
    console.log('imagePreviewer', action.type);
    switch (action.type) {
        case 'SHOW_IMAGE_PREVIEW':
            return {
                imagePreview: {
                    show: action.payload.show,
                    images: action.payload.images,
                    selectedIndex: action.payload.selectedIndex,
                },
            };
        default:
            return state;
    }
};

export const actionCreator = {
    imagePreview,
};