import React, {Fragment}from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {PREF_LIST} from '../../Construct.js';

import Header from '../../components/Header';
import StorageHelper, {USER_COMMUNITIES} from '../../helpers/StorageHelper';
import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import UsersCommunityApi from '../../helpers/api/UsersCommunityApi';
import TagsInput from 'react-tagsinput';

import './CommunityRegist.css';
import btn_add_image from 'assets/btn_add_image.png';
import btn_camera from 'assets/btn_camera.png';
import community_logo from 'assets/community_logo.png';

interface CommunityRegistProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showSpinner: (show: boolean) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: any;
}

interface CommunityRegistStates {
    render: boolean;
    community_name: string,
    image_data?: string | null;

    name?: string;
    description?: string;
    url?: string,
    tag_list: string[],
    tag_list_area: string[],
    tag_list_category: string[],
    tag_list_attribute: string[],
    tag_word: string,
    tag_word_area: string,
    tag_word_category: string,
    tag_word_attribute: string,

    community?: {
        name?: string;
        description?: string;
        communities_users?: [];
        community_image?: {
            image: {
                content: {
                    url: string;
                }
            }
        } | null;
    };
}

/**
 * プロフィール登録・更新
 */
class CommunityRegist extends React.Component<CommunityRegistProps, CommunityRegistStates> {

    private form: any;
    private is_edit = false;

    constructor(props: any) {
        super(props);

        this.is_edit = props.match.params.name != 'create';

        // console.log('this.is_edit', this.is_edit);

        this.state = {
            render: false,
            community_name: this.is_edit ? props.match.params.name : '',
            tag_list: [],
            tag_list_area: [],
            tag_list_category: [],
            tag_list_attribute: [],
            tag_word: '',
            tag_word_area: '',
            tag_word_category: '',
            tag_word_attribute: '',
        };
    }

    componentDidMount() {

        this.props.onSetSidebarOpen(false);

        // console.log(`MODE:${this.is_edit ? 'プロフィール編集' : 'プロフィール登録'}`);

        if (this.is_edit) {
            this.fetchCommunitiesShow();
        } else {
            this.setState({
                render: true,
            });
        }
    }

    handleTagChange = (tag_list: string[]) => {
        console.log('tag_list', tag_list);

        this.setState({tag_list});
    }

    handleFormSubmit(e: any) {
        e.preventDefault();


        if (this.is_edit) {
            this._fetchCommuntiesEdit();
        } else {
            // タグ結合
            this.setState({
                tag_list : this.state.tag_list_area.concat(this.state.tag_list_attribute.concat(this.state.tag_list_category)),
            }, () => {
                this._fetchCommunitiesCreate();
            });
        }
    }

    /**
     * コミュニティ詳細取得
     */
    fetchCommunitiesShow = () => {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommunitiesShow(
            this.state.community_name,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesShow', responseJson);

                this.props.showSpinner(false);

                this.setState({
                    render: true,
                    name: responseJson.name,
                    description: responseJson.description,
                    url: responseJson.url,
                    tag_list: responseJson.tag_list,
                    community: responseJson
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * コミュニティ作成
     * @private
     */
    _fetchCommunitiesCreate() {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommunitiesCreate(
            {
                name: this.state.name,
                description: this.state.description,
                url: this.state.url,
                image: this.state.image_data,
                tag_list: this.state.tag_list,
            },
            (responseJson: any) => {
                this.props.showSpinner(false);
                this.fetchUsers(`/community/${this.state.name}/profile`);
            },
            (error: any) => {
                this.props.showSpinner(false);
                console.log(error);
                alert(error);
            },
        );
    }


    /**
     * ユーザープロフィールを編集する
     * @private
     */
    _fetchCommuntiesEdit() {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommuntiesEdit(
            this.state.community_name,
            {
                update_name: this.state.name,
                description: this.state.description,
                image: this.state.image_data,
                url: this.state.url,
                tag_list: this.state.tag_list,
            },
            (responseJson: any) => {

                // console.log('_fetchCommuntiesEdit', responseJson);

                this.props.showSpinner(false);
                this.fetchUsers(`/community/${this.state.name}/detail`);

                // setTimeout(() => {
                //     window.location.reload();
                // }, 10);
            },
            (error: any) => {
                this.props.showSpinner(false);
                console.log(error);
                alert(error);
            },
        );
    }

    handleChangeFile = (e: any) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        reader.onloadend = () => {
            this.setState({
                image_data: `${reader.result}`,
            });
        }
        reader.readAsDataURL(file);
    }

    fetchUsers = (path: string) => {

        this.props.showSpinner(true);

        // 所属コミュニティ
        UsersCommunityApi._fetchUsersCommunity(
            {},
            (responseJson: any) => {
                // console.log('_fetchUsersCommunity3 responseJson', responseJson);
                this.props.showSpinner(false);
                var currentUser = this.props.currentUser;
                currentUser.communities = responseJson.communities;
                this.props.setCurrentUser(currentUser);

                StorageHelper.setData(USER_COMMUNITIES, responseJson.communities);
                this.props.history.push(path);
            },
            (error: any) => {
                alert(error);
            },
        );
    }

    render() {

        var renderCameraBtn = false;
        var user_image = btn_add_image;
        if (this.state.image_data) {
            user_image = this.state.image_data;
            renderCameraBtn = true;
        } else if (this.state.community && this.state.community.community_image) {
            user_image = this.state.community.community_image.image.content.url;
            renderCameraBtn = true;
        }

        return (
            <div className="CommunityRegist row">

                <Header
                    title={ this.is_edit ? 'コミュニティ編集' : 'コミュニティ作成'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>


                <form
                    onSubmit={this.handleFormSubmit.bind(this)}
                    ref={el => this.form = el && el.elements}
                >
                    { !this.is_edit &&
                    <div className="col-12 community_logo">
                        <img src={community_logo} alt=""/>
                    </div>
                    }

                    { this.state.render &&

                    <div className="col-12 image">
                        <label htmlFor="name">メイン画像</label>
                        <label>
                            <div className="img_area">
                                <img src={user_image} className="logo cursor"/>
                                <input type="file" className="user-image" name="upfile" id="upfile" accept="image/*" onChange={this.handleChangeFile.bind(this)}/>
                                {/*<img src={btn_camera} className="add"/>*/}
                            </div>
                        </label>
                    </div>
                    }
                    { this.state.render &&
                    <div className="col-12 detail">
                        <label htmlFor="name">コミュニティ名</label>
                        <input name="name" type="text" className="signup-input" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} placeholder="" required></input>

                        <label htmlFor="description">コミュニティ説明</label>
                        <textarea className='form-input-text large' name="description" placeholder="" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})}></textarea>

                        { this.is_edit &&
                        <Fragment>
                            <label htmlFor="tag_list">コミュニティタグ</label>
                            <TagsInput
                                className='form-input-text input-tags'
                                value={this.state.tag_list}
                                onChange={this.handleTagChange}
                                inputProps={{placeholder: ''}}
                                inputValue={this.state.tag_word}
                                onChangeInput={(tag_word: string) => {
                                    // androidで改行が検知できないため半角スペースでタグ追加
                                    if (tag_word.endsWith(' ')) {
                                        var tag_list = this.state.tag_list;
                                        tag_list.push(tag_word);
                                        this.setState({
                                            tag_list,
                                            tag_word: '',
                                        });
                                    } else {
                                        this.setState({
                                            tag_word,
                                        });
                                    }
                                }}
                                onlyUnique
                                addOnBlur
                            />
                        </Fragment>
                        }
                        { !this.is_edit &&
                        <Fragment>
                            <label htmlFor="tag_list">コミュニティの地域タグ</label>
                            <TagsInput
                                className='form-input-text input-tags'
                                value={this.state.tag_list_area}
                                onChange={(tag_list_area: string[]) => {
                                    this.setState({tag_list_area});
                                }}
                                inputValue={this.state.tag_word_area}
                                onChangeInput={(tag_word_area: string) => {
                                    // androidで改行が検知できないため半角スペースでタグ追加
                                    if (tag_word_area.endsWith(' ')) {
                                        var tag_list_area = this.state.tag_list_area;
                                        tag_list_area.push(tag_word_area);
                                        this.setState({
                                            tag_list_area,
                                            tag_word_area: '',
                                        });
                                    } else {
                                        this.setState({
                                            tag_word_area,
                                        });
                                    }
                                }}
                                onlyUnique
                                addOnBlur
                                inputProps={{placeholder: ''}}/>

                            <label htmlFor="tag_list">趣味 / カテゴリ タグ</label>
                            <TagsInput
                                className='form-input-text input-tags'
                                value={this.state.tag_list_category}
                                onChange={(tag_list_category: string[]) => {
                                    this.setState({tag_list_category});
                                }}
                                inputValue={this.state.tag_word_category}
                                onChangeInput={(tag_word_category: string) => {
                                    // androidで改行が検知できないため半角スペースでタグ追加
                                    if (tag_word_category.endsWith(' ')) {
                                        var tag_list_category = this.state.tag_list_category;
                                        tag_list_category.push(tag_word_category);
                                        this.setState({
                                            tag_list_category,
                                            tag_word_category: '',
                                        });
                                    } else {
                                        this.setState({
                                            tag_word_category,
                                        });
                                    }
                                }}
                                onlyUnique
                                addOnBlur
                                inputProps={{placeholder: ''}}/>

                            <label htmlFor="tag_list">属性タグ</label>
                            <TagsInput
                                className='form-input-text input-tags'
                                value={this.state.tag_list_attribute}
                                onChange={(tag_list_attribute: string[]) => {
                                    this.setState({tag_list_attribute});
                                }}
                                inputValue={this.state.tag_word_attribute}
                                onChangeInput={(tag_word_attribute: string) => {
                                    // androidで改行が検知できないため半角スペースでタグ追加
                                    if (tag_word_attribute.endsWith(' ')) {
                                        var tag_list_attribute = this.state.tag_list_attribute;
                                        tag_list_attribute.push(tag_word_attribute);
                                        this.setState({
                                            tag_list_attribute,
                                            tag_word_attribute: '',
                                        });
                                    } else {
                                        this.setState({
                                            tag_word_attribute,
                                        });
                                    }
                                }}
                                onlyUnique
                                addOnBlur
                                inputProps={{placeholder: ''}}/>
                        </Fragment>
                        }

                        <label htmlFor="url">HP / twitter など</label>
                        <input name="url" type="text" className="signup-input" value={this.state.url} onChange={(e) => this.setState({url: e.target.value})} placeholder=""></input>

                        <button type="submit" className="btn btn-secondary">{ this.is_edit ? '変更' : '作成'}</button>
                    </div>
                    }
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            // console.log('setCurrentUser', data)
            // console.log('setCurrentUser', data)
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityRegist);
