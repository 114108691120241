import React from 'react';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';

import './Dialog.css';
import {Transition, CSSTransition} from 'react-transition-group';

interface DialogProps {
    dialog: {
        dialog: {
            show: boolean;
            title: string;
            text: string;
            onClickYes: (e: any) => void;
            onClickNo?: (e: any) => void;
        };
    };
}

interface DialogStates {
}

class Dialog extends React.Component<DialogProps, DialogStates> {

    constructor(props: any) {
        super(props);
    }

    render() {

        // console.log('Dialog props', this.props);
        // console.log('Dialog state', this.state);

        return (
            this.props.dialog.dialog ?
                <CSSTransition in={this.props.dialog.dialog.show} timeout={100} classNames="fade" unmountOnExit>
                    <div className="Dialog">
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                {this.props.dialog.dialog.title &&
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleDialogLabel">{this.props.dialog.dialog.title}</h5>
                                </div>
                                }
                                {this.props.dialog.dialog.text &&
                                <div className="modal-body">
                                    {this.props.dialog.dialog.text}
                                </div>
                                }
                                { this.props.dialog.dialog.onClickNo && this.props.dialog.dialog.onClickYes &&
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={(e) => {
                                        if (this.props.dialog.dialog.onClickNo) {
                                            this.props.dialog.dialog.onClickNo(e);
                                        }
                                    }}>
                                        いいえ
                                    </button>
                                    <span style={{width: '6px'}}></span>
                                    <button type="button" className="btn btn-primary" onClick={(e) => {
                                        this.props.dialog.dialog.onClickYes(e);
                                    }}>
                                        はい
                                    </button>
                                </div>
                                }
                                { !this.props.dialog.dialog.onClickNo && this.props.dialog.dialog.onClickYes &&
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary w100" onClick={(e) => {
                                        this.props.dialog.dialog.onClickYes(e);
                                    }}>
                                        はい
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </CSSTransition> : null
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        dialog: state.dialog,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dialog);