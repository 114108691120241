import React from 'react';
import BaseApi from './BaseApi';

export default class PurchasesApi extends BaseApi {

    /**
     * POST /products/:product_id/purchases
     */
    static _fetchPurchases(id, params, success, error) {
        super._fetch(`/api/v1/products/${id}/purchases`, 'POST', params, success, error);
    }
}