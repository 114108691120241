import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Moment from 'moment';
import TalkHelper from '../../helpers/TalkHelper';

import './UserPurchaseList.css';
import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';
import PurchaseDetailCell, {PurchaseProps} from './components/PurchaseDetailCell';

import HistoryApi from '../../helpers/api/HistoryApi';
import CancelApi from '../../helpers/api/CancelApi';


enum Type {
    Captured, // 取引中
    Uncaptured, // 過去の取引
}

interface UserPurchaseListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface UserPurchaseListStates {
    type: Type;
    captured_list: PurchaseProps[],
    uncaptured_list: PurchaseProps[],
}

class UserPurchaseList extends React.Component<UserPurchaseListProps, UserPurchaseListStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            type: Type.Captured,
            captured_list: [],
            uncaptured_list: [],
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.fetch();
    }

    fetch = () => {

        this.props.showSpinner(true);

        HistoryApi._fetchPurchaseHistories(
            {},
            (responseJson: any) => {

                this.props.showSpinner(false);

                // レスポンス値が統一されていないので整形する
                var captured_list = [];
                var uncaptured_list = [];
                if (responseJson.purcahses) {
                    for (let obj of responseJson.purcahses) {

                        if (!obj.product) {
                            continue;
                        }

                        var quantity;
                        if (obj.number_of_item) {
                            quantity = obj.number_of_people + `個`;
                        } else {
                            quantity = obj.number_of_people + `名`;
                        }

                        var item = {
                            id: parseInt(obj.id),
                            name: obj.title,
                            user_name: obj.product.communities_user ? obj.product.communities_user.nickname : '',
                            name_desc: 'さんの商品',
                            date: Moment(obj.created_at).format('YYYY-MM-DD h:mm'),
                            start_time: obj.product.schedules.length > 0 ? Moment(obj.product.schedules[0].start_time).format('YYYY-MM-DD h:mm') : '',
                            quantity: quantity,
                            value: `${obj.charge.amount}円`,
                            talk: true,
                            onCreateRoom: this.onCreateRoom,
                            approval: false,
                            cancel: true,
                            onCancel: this.onCancel,
                            product_image: obj.product.communities_user ? obj.product.communities_user.user.avatar_url : '',
                            user_id: parseInt(obj.product.communities_user.id),
                            uid: obj.product.communities_user.user.firebase_uid,
                        }
                        if (obj.charge.capture === 'refunded') {
                            // キャンセルは非表示
                        } else if (obj.charge.capture === 'captured') {
                            captured_list.push(item);
                        } else {
                            uncaptured_list.push(item);
                        }
                    }
                }
                this.setState({
                    captured_list,
                    uncaptured_list,
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    onCreateRoom = (production_id: number, title: string, user_id: number, uid: string, avatar_url: string) => {

        this.props.showSpinner(true);

        // チャットルーム作成
        TalkHelper._createRooms(
            title,
            0,
            [
                user_id
            ],
            [
                uid,
            ],
            1,
            avatar_url,
            (room: any) => {
                // console.log('c room', room);
                this.props.showSpinner(false);
                this.props.history.push(`/talk/${room.key}`);
            },
            production_id,
        );
    }


    onCancel = (purchase_id: number) => {
        // 購入申請
        this.props.showSpinner(true);
        CancelApi._fetchCancels(
            {
                purchase_id: purchase_id,
            },
            (responseJson: any) => {
                this.props.showSpinner(false);
                this.fetch();
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    render() {

        const purchases = this.state.type != Type.Captured ? this.state.captured_list : this.state.uncaptured_list;

        var list = [];
        for (let data of purchases) {
            list.push(
                <PurchaseDetailCell data={data}/>
            );
        }

        return (
            <div className="UserPurchaseList row">
                <Header
                    title={'購入した商品'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                <CommonSegmentButton lists={['取引中', '過去の取引']} index={this.state.type} onChange={(i) => {
                    this.setState({
                        type: i,
                    });
                    this.fetch();
                }}/>
                <div className="col-12">
                    {list}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPurchaseList);
