import React from 'react';
import BaseApi from './BaseApi';

export default class BankAccountsApi extends BaseApi {

    /**
     * POST /api/v1/stripe_accounts/bank_accounts
     */
    static _fetchBankAccounts(params, success, error) {
        super._fetch('/api/v1/stripe_accounts/bank_accounts', 'POST', params, success, error);
    }
}