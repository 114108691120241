import React from 'react';

import './FormImageList.css';
import camera_image from 'assets/productCreation/camera.png'
import { range } from '../helpers/utils';

import CommonImage from './CommonImage';
const imageCompression = require('browser-image-compression');

interface FormImageListProps {
    imageList: any[],
    onChange: (imageList:any[])=>void,
    imageOptionText?: string,
    numberOfImages: number,
}

interface FormImageListStates {

}

class FormImageList extends React.Component<FormImageListProps, FormImageListStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    _isLastImageIndex = (index: number) => {
        const imageLength = this.props.imageList.length
        return index == imageLength
    }

    _updateImageData = (index: number, data: any) => {
        let imageList = [...this.props.imageList]
        if (index < imageList.length) {
            imageList[index] = data
        } else {
            imageList.push(data)
        }
        this.props.onChange(imageList);
    }

    _handleChangeFile = (index: number) => (e: any) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log('_handleChangeFile', index, e.target);
        if (!file) {
            return;
        }

        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024} KB`);
        const self = this;

        var options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        }
        imageCompression.default(file, options)
            .then(function (compressedFile: any) {
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
                console.log(`compressedFile size ${compressedFile.size / 1024} KB`);

                reader.onloadend = () => {
                    const res = `${reader.result}`
                    self._updateImageData(index, res);
                }
                reader.readAsDataURL(compressedFile);

            })
            .catch(function (error: any) {
                console.log(error.message);
            });
    }

    _onPressImageDelete = (index: number) => (e: any) => {
        e.stopPropagation();
        const newImageList = this.props.imageList.filter((_, i) => i !== index);
        this.props.onChange(newImageList);
    }

    render() {
        return (
            <div className="input-image-wrapper">
                {range(0, this.props.numberOfImages-1).map(i => (
                            <FormInputImage src={this.props.imageList[i]} key={i} index={i}
                                showPlaceholder={this._isLastImageIndex(i)}
                                onChange={this._handleChangeFile(i)}
                                onPressDelete={this._onPressImageDelete(i)} 
                                imageOptionText={this.props.imageOptionText}
                                />
                ))}
            </div>
        );
    }
}

const FormInputImage = ({ index, showPlaceholder, onChange, onPressDelete, src, imageOptionText }: { index: number, showPlaceholder: boolean, onChange?: (e: any) => void, onPressDelete?: (e: any) => void, src?: string, imageOptionText?:string }) => {
    return (
        <div className='input-image'>
            {src && <div className='input-image-delete' onClick={onPressDelete}>×</div>}
            <label className='input-image-content'>
                <div className='input-image-index'>{index + 1}</div>
                {src && <CommonImage src={src} className='input-image-image' />}
                {showPlaceholder &&
                    <div className='input-image-placeholder'>
                        <img className='input-image-logo' src={camera_image} />
                        <div>{imageOptionText}</div>
                    </div>}
                <input className='form-input-text' type="file" name="upfile" id="upfile" accept="image/*" onChange={onChange} />
            </label>
        </div>
    )
}

export default FormImageList;
