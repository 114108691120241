import React, {Fragment} from 'react';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Moment from 'moment';
import {SEAT_TYPES, APPROVAL_STATUS} from '../../Construct';
import StorageHelper, {CURRENT_USER, DEFAULT_HREF} from '../../helpers/StorageHelper';
import FontAwesome from "react-fontawesome";
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from "qrcode.react";

import CongregationsApi from '../../helpers/api/CongregationsApi';

import CommonImageSwiper from '../../components/CommonImageSwiper';
import CommonCourseCell from '../../components/CommonCourseCell';
import CommonUserCell from '../../components/CommonUserCell';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import CommonTitle from '../../components/CommonTitle';
import CommonIconTitle from '../../components/CommonIconTitle';


import './CongregateDetail.css';
import no_image from 'assets/no_image.png';
import reduction_ratio from 'assets/reduction_ratio.png';
import community_name from 'assets/community_name.png';
import member_number from 'assets/member_number.png';
import local from 'assets/local.png';
import btn_social_link from 'assets/btn_social_link.png';
import btn_social_qr from 'assets/btn_social_qr.png';

import icon_titi from 'assets/icon_titi.png';
import icon_user from 'assets/icon_user.png';
import icon_calendar from 'assets/icon_calendar.png';
import icon_shape from 'assets/icon_shape.png';
import icon_location from 'assets/icon_location.png';
import icon_directions from 'assets/icon_directions.png';
import icon_bus from 'assets/icon_bus.png';
import icon_food from 'assets/icon_food.png';
import icon_price from 'assets/icon_price.png';
import icon_exclamation from 'assets/icon_exclamation.png';
import { EventDetail } from '../../helpers/schemas/Api';

interface CongregateDetailProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface CongregateDetailStates {
    is_login: boolean;
    invitation_code?: any;
    invite_url: string;
    renderQR: boolean;

    congregate_id: number;
    show_desc: boolean;
    show_input: boolean;
    show_course: boolean;

    nickname?: string;
    email?: string;
    remark?: string;

    congregate?: EventDetail;
    benefit?: {
        id: number;
        number_of_people: number;
        price: number;
        visit_status: number;
        discount: number;
        description: string;
        favor_flag: boolean;
        communities: {
            id: number;
        }[];
    };
}

type UserRole = "organizer" | ""

class CongregateDetail extends React.Component<CongregateDetailProps, CongregateDetailStates> {

    constructor(props: any) {
        super(props);

        const congregate_id = props.match.params.id;
        const values = queryString.parse(props.location.search);
        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        const port = window.location.port;

        let currentUser = StorageHelper.getData(CURRENT_USER);
        // console.log('this.props.currentUser', currentUser);

        /**
         * type=1はコミュニティ招待
         * @type {string}
         */
            // const params = `type=1&i=${id}&c=${content}&n=${community_name}`;
        let uri = encodeURI(`${protocol}//${hostname}${port ? `:${port}` : ''}/congregate/detail/${congregate_id}`);
        // console.log('uri', uri);

        this.state = {
            is_login: currentUser && Number(currentUser.user_id) > 0,
            invitation_code: values.c,
            congregate_id: congregate_id,
            invite_url: uri,
            show_desc: false,
            show_input: false,
            show_course: false,
            renderQR: false,
        };
    }

    /**
     * ユーザーIDからロールを取得する
     * @param user_id
     * @private
     */
    _getRole = (user_id: string) => {
        // return 0;
        if (!this.state.congregate) {
            return -1;
        }

        if (!this.props.currentUser.communities) {
            return -1;
        }

        // TODO 簡素化

        var communities_user_id = 0;

        for (var c of this.props.currentUser.communities) {

            if (c.name != this.state.congregate.community_name) {
                continue;
            }

            for (var cu of c.communities_users) {
                if (user_id == cu.user_id) {
                    communities_user_id = cu.id;
                }
            }
        }

        if (!communities_user_id) {
            return -1;
        }

        for (var cr of this.state.congregate.congregation_roles) {
            if (cr.communities_user_id == communities_user_id) { // 招待者
                // console.log('user.role', cr.role);
                return cr.role;
            }
        }
        return -1; // 存在なし
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchCongregationsShow();

    }

    _fetchCongregationsShow = () => {


        if (this.state.is_login) {

            // ログイン状態
            this.props.showSpinner(true);

            CongregationsApi._fetchCongregationsShow(
                this.state.congregate_id,
                {},
                (responseJson: any) => {
                    // console.log('_fetchCongregationsShow', responseJson);
                    this.props.showSpinner(false);

                    var hostname = window.location.hostname;
                    var protocol = window.location.protocol;
                    var port = window.location.port;
                    let uri = encodeURI(`${protocol}//${hostname}${port ? `:${port}` : ''}/congregate/detail/${this.state.congregate_id}?c=${responseJson.invitation_code}`);

                    // responseJson.status = 0; // TODO

                    this.setState({
                        congregate: responseJson,
                        benefit: responseJson.benefit,
                        invite_url: uri,
                    });
                },
                (error: any) => {
                    this.props.showSpinner(false);
                    alert(error);
                    this.props.history.push('/');
                },
            );
        } else {

            // 未ログイン
            if (!this.state.invitation_code) {
                this.props.showDialog(
                    "確認",
                    `URLが不正です。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
                return;
            }

            this.props.showSpinner(true);

            CongregationsApi._fetchCongregationsNoLogin(
                this.state.congregate_id,
                {
                    invitation_code: this.state.invitation_code,
                },
                (responseJson: any) => {
                    // console.log('_fetchCongregationsNoLogin', responseJson);
                    this.props.showSpinner(false);

                    // responseJson.status = 0; // TODO

                    this.setState({
                        congregate: responseJson,
                    });
                },
                (error: any) => {
                    this.props.showSpinner(false);
                    alert(error);
                },
            );
        }
    }

    /**
     * ログイン済みユーザーの参加処理
     * @param e
     */
    _handlePressJoinSubmit = (e: any) => {
        // console.log('_handlePressJoinSubmit');

        this.props.showSpinner(true);

        CongregationsApi._fetchCongregationsJoin(
            this.state.congregate_id,
            {},
            (responseJson: any) => {
                console.log('_fetchCongregationsJoin', responseJson);
                this.props.showSpinner(false);

                this.setState({
                    congregate: undefined,
                }, () => {
                    this._fetchCongregationsShow();
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    /**
     * 未ログインユーザーの参加処理
     * @param e
     */
    _handlePressJoinTempSubmit = (e: any) => {
        // console.log('_handlePressJoinTempSubmit');

        this.props.showSpinner(true);

        CongregationsApi._fetchCongregationsJoinTemp(
            this.state.congregate_id,
            {
                invitation_code: this.state.invitation_code,
                email: this.state.email,
                nickname: this.state.nickname,
                remark: this.state.remark,
            },
            (responseJson: any) => {
                // console.log('_fetchCongregationsJoinTemp', responseJson);
                this.props.showSpinner(false);

                this.setState({
                    congregate: undefined,
                }, () => {
                    this.setState({show_input: false})
                    this._fetchCongregationsShow();
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    /**
     * ログイン済みユーザーの参加キャンセル処理
     * @param e
     */
    _handlePressLeaveSubmit = (e: any) => {
        // console.log('_handlePressLeaveSubmit');

        // TODO

        this.props.showDialog(
            "確認",
            "参加をキャンセルしますか？",
            (e: any) => {

                this.props.hideDialog();
                this.props.showSpinner(true);

                CongregationsApi._fetchCongregationsLeave(
                    this.state.congregate_id,
                    {},
                    (responseJson: any) => {
                        // console.log('_fetchCongregationsLeave', responseJson);

                        this.props.showSpinner(false);
                        this.props.history.push('/');
                    },
                    (error: any) => {
                        this.props.showSpinner(false);
                        alert(error);
                    },
                );
            },
            (e: any) => {
                this.props.hideDialog();
            }
        );
    }

    /**
     * イベント削除処理
     * @param e
     */
    _handlePressDeleteSubmit = (e: any) => {

        // console.log('_handlePressDeleteSubmit');

        this.props.showDialog(
            "確認",
            "削除しますか？",
            (e: any) => {

                this.props.hideDialog();
                this.props.showSpinner(true);

                CongregationsApi._fetchCongregationsDelete(
                    this.state.congregate_id,
                    {},
                    (responseJson: any) => {
                        // console.log('_fetchCongregationsDelete', responseJson);
                        this.props.showSpinner(false);
                        this.setState({
                            congregate: undefined,
                        }, () => {
                            this.props.history.push('/');
                        });
                    },
                    (error: any) => {
                        this.props.showSpinner(false);
                        alert(error);
                    },
                );
            },
            (e: any) => {
                this.props.hideDialog();
            }
        );
    }

    _handlePressLoginSubmit = (e: any) => {

        var defaultPath = window.location.href;
        StorageHelper.setValue(DEFAULT_HREF, defaultPath, true);

        this.props.history.push('/signin');
    }

    handlePressLink = (e: any) => {

        let copy_text = this.getCopyText();

        this.props.showDialog(
            "招待用URLをコピーしました",
            copy_text,
            (e: any) => {
                this.props.hideDialog();
            },
            null,
        );
    }

    handlePressQR = (e: any) => {
        e.preventDefault();

        this.setState({
            renderQR: !this.state.renderQR,
        });
    }

    getTerms = (b: any) => {
        var terms = '';
        if (b.favor_flag) {
            terms += '贔屓コミュニティのみ';
        }
        if (b.price > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.price + '円以上';
        }
        if (b.number_of_people > 0) {
            if (terms.length > 0) terms += '、';
            terms += b.number_of_people + '人以上';
        }
        if (terms.length > 0) terms += '、';
        terms += b.visit_status == 0 ? '来店で特典付与' : '予約で特典付与';
        return '条件：' + terms;
    }

    getCopyText = () => {
        return `【${this.state.congregate ? this.state.congregate.title : ''}】\n`
            + `URL :${this.state.invite_url}\n\n`
            + `【TiTi のイベントとは？】\n`
            + `TiTiのイベントは、みんながあつまることで、お得がうけられるサービスです。\n`
            + (this.state.benefit ? `招待された方々から${this.state.benefit ? this.state.benefit.number_of_people : ''}名参加すると特典が成立します。` : '');

    }

    render() {

        // console.log('CongregateCreate props', this.props);
        // console.log('CongregateCreate state', this.state);

        // // コース画像
        // var course_image: string = no_image;
        // if (this.state.congregate && this.state.congregate.course.images.length > 0) {
        //     const i = this.state.congregate.course.images[0];
        //     if (i.content.url) {
        //         course_image = i.content.url;
        //     }
        // }

        // 幹事画像
        var user_image: string = no_image;
        if (this.state.congregate) {
            for (var cr of this.state.congregate.congregation_roles) {
                if (cr.role == 0 && cr.avatar_url) {
                    user_image = cr.avatar_url;
                    break;
                }
            }
        }

        // 参加者一覧
        var members = [];
        if (this.state.congregate) {
            for (var user of this.state.congregate.congregation_roles) {
                if (user.role == 2) { // 招待者
                    continue;
                }
                members.push(
                    <div className="user-box" key={user.id}>
                        <img src={user.avatar_url || no_image} className="selected-user-image"/>
                        <p>{user.nickname}</p>
                    </div>
                );
            }
        }

        // ボタン出し分け
        var btn = null;
        let role = null;
        if (this.state.congregate) {
            role = this._getRole(this.props.currentUser.user_id);
            console.log('current user role:', role);

            if (this.state.is_login) {
                // ログイン中
                if (this.state.congregate.status === 0) {
                    // 募集中
                    if (role === 0) {
                        // 幹事は削除ボタン表示
                        btn = <button type="submit" className="btn btn-secondary" onClick={this._handlePressDeleteSubmit}>イベントを削除する</button>;
                    } else if (role === 1) {
                        // 招待メンバーはボタン非表示
                    } else if (role === 2 || role === -1) {
                        // ログイン、招待
                        btn = <button type="submit" className="btn btn-primary" onClick={this._handlePressJoinSubmit}>参加する</button>;
                    } else if (role === 3) {
                        // ログイン、参加者
                        btn = <button type="submit" className="btn btn-secondary" onClick={this._handlePressLeaveSubmit}>参加をキャンセルする</button>;
                    }
                } else if (this.state.congregate.status === 1) {
                    // 成立 最大人数以下での招待ユーザーはボタン表示
                    if (this.state.congregate.congregation_roles.length < this.state.congregate.number_of_people && role == 2) {
                        btn = <button type="submit" className="btn btn-primary" onClick={this._handlePressJoinSubmit}>参加する</button>;
                    }
                } else if (this.state.congregate.status === 2) {
                    // キャンセル(期限切れ)
                } else if (this.state.congregate.status === 3) {
                    // キャンセル(ユーザー操作)
                } else if (this.state.congregate.status === 4) {
                    // キャンセル(店舗操作)
                }
            } else {
                // 未ログイン
                if (this.state.congregate.status === 0) {
                    // 募集中
                    btn = <button type="submit" className="btn btn-primary" onClick={this._handlePressLoginSubmit}>ログインして参加する</button>;
                } else if (this.state.congregate.status === 1) {
                    // 成立 最大人数以下はボタン表示
                    if (this.state.congregate.congregation_roles.length < this.state.congregate.number_of_people) {
                        btn = <button type="submit" className="btn btn-primary" onClick={this._handlePressLoginSubmit}>ログインして参加する</button>;
                    }
                } else if (this.state.congregate.status === 2) {
                    // キャンセル(期限切れ)
                } else if (this.state.congregate.status === 3) {
                    // キャンセル(ユーザー操作)
                } else if (this.state.congregate.status === 4) {
                    // キャンセル(店舗操作)
                }
            }
        }

        let copy_text = this.getCopyText();

        var images = [];
        if (this.state.congregate && this.state.congregate.image) {
            images.push(this.state.congregate.image.content!.url);
        } else {
            images.push(no_image);
        }

        return (
            <div className="CongregateDetail row">

                <Header
                    title={'イベント詳細'}
                    leftButtonTitle={(this.props.history.length > 0) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push('/');
                        }
                    }}
                />

                {this.state.congregate &&
                <Fragment>

                    <CommonImageSwiper
                        images={images}
                    />

                    <div className="col-12 title-box">
                        <p className="date">
                            {Moment(this.state.congregate.reserved_time).format('MM月DD日 HH:mm')}
                        </p>

                        <p className="title">
                            {this.state.congregate.title}
                        </p>
                        <p className="spot">
                            {this.state.congregate.place}
                        </p>
                    </div>

                    {this.state.congregate.approval_status == APPROVAL_STATUS.APPROVAL &&
                    <div className="col-12 detail">
                        <div className="text-center">
                            {btn}
                        </div>
                    </div>
                    }

                    <div className="col-12 detail">

                        {!this.state.is_login && this.state.congregate && this.state.congregate.congregation_roles.length < this.state.congregate.number_of_people &&
                        <p className="text">
                            <small>
                                ※参加にはメールアドレスとニックネームを入力していただくか、アカウント作成をしていただく必要があります。
                            </small>
                        </p>
                        }
                        {!this.state.is_login && this.state.congregate && this.state.congregate.congregation_roles.length < this.state.congregate.number_of_people &&
                        <p className="next cursor" onClick={e => this.setState({show_input: true})}>
                            <a>登録せずに参加</a>
                        </p>
                        }

                        <CommonIconTitle
                            src={icon_titi}
                            text={'主催者：' + this.state.congregate.organizer.nickname}
                        />

                        <CommonIconTitle
                            src={icon_user}
                            text={'コミュニティ：' + this.state.congregate.community_name}
                        />

                        <CommonIconTitle
                            src={icon_calendar}
                            text={Moment(this.state.congregate.reserved_time).format('MM月DD日 HH:mm 開始')}
                        />

                        <CommonIconTitle
                            src={icon_shape}
                            text={'募集人数：' + this.state.congregate.number_of_people + '名'}
                        />

                        <CommonIconTitle
                            src={icon_location}
                            text={this.state.congregate.place}
                        />

                        <CommonIconTitle
                            src={icon_directions}
                            text={this.state.congregate.address}
                            map={this.state.congregate.address}
                        />

                        {this.state.congregate.course && 
                        <>
                        <CommonIconTitle
                            src={icon_food}
                            text={this.state.congregate.course.name}
                        />
                        <CommonIconTitle
                            src={icon_price}
                            text={'参加費：' + this.state.congregate.course.price + '円'}
                        />
                        </>
                        }
                        {/*
                        <CommonIconTitle
                            src={icon_exclamation}
                            text={'ご飲食代は、当日お店でお会計となります。'}
                        />
                         */}
                    </div>

                    <div className="col-12 border"></div>

                    {this.state.congregate.approval_status === APPROVAL_STATUS.APPROVAL && (role === 0 || role === 1) &&  
                    <Fragment>
                        <CommonTitle
                            title="招待"
                            logo
                        />
                        {this.state.congregate.status === 0 &&
                        <div className="col-12 detail">
                            <CopyToClipboard onCopy={this.handlePressLink} text={copy_text}>
                                <div className="row invite cursor">
                                    <img src={btn_social_link} className="invite-image" alt="invite"/>
                                    <div className="invite-icon">
                                        <p className="community-name">招待リンクを共有してください</p>
                                        <p className="community-link">メッセンジャー、Twitter、Line、メールアドレスで共有しよう</p>
                                    </div>
                                </div>
                            </CopyToClipboard>
                            <div className="row invite cursor" onClick={this.handlePressQR}>
                                <img src={btn_social_qr} className="invite-image" alt="qr"/>
                                <div className="invite-icon">
                                    <p className="community-name">QRコードを表示する</p>
                                    <p className="community-link">QRコードをコミュニティの友だちに読み込んでもらって招待</p>
                                </div>
                            </div>
                        </div>
                        }
                        <div className="col-12 border"></div>
                    </Fragment>
                    }


                    <CommonTitle
                        title="詳細"
                        logo
                    />

                    {/* <CommonUserCell
                        image={user_image}
                        title={this.state.congregate.organizer.nickname || ''}
                        sub_title={'主催者'}
                    /> */}

                    {this.state.congregate.comment &&
                    <div className="col-12 detail">
                        <p className="desc">
                            {this.state.congregate.comment}
                        </p>
                    </div>
                    }

                    <CommonTitle
                        title={`参加予定：${members.length} / ${this.state.congregate.number_of_people}`}
                        logo
                    />
                    <div className="col-12 detail">
                        {members.length > 0 &&
                        <div className="members-list">
                            {members}
                        </div>
                        }
                    </div>

                    {/* <CommonTitle
                        title={`特典`}
                        logo
                    />

                    {this.state.benefit &&
                    <CommonCourseCell
                        active={true}
                        title={this.state.benefit.description}
                        label={this.state.benefit.favor_flag ? '贔屓特典' : '通常特典'}
                        terms={this.getTerms(this.state.benefit)}
                    />
                    } */}

                    {/* <CommonTitle
                        title="座席"
                        logo
                    />
                    {seat} */}

                    <CommonTitle
                        title="コミュニティにおける禁止行為"
                        logo
                    />

                    <div className="col-12 detail">
                        <p className="desc">
                            もし同席したユーザーから迷惑を受けたり、禁止行為を見かけた場合は、TiTi 運営管理事務所までご報告ください。
                        </p>
                    </div>

                </Fragment>
                }

                <Modal show={this.state.show_input}
                       onClose={e => this.setState({show_input: false})}
                >
                    <div className="col-12 meta-box">
                        <p className="title">ニックネーム</p>
                        <input name="nickname" type="text" className="signup-input" value={this.state.nickname} onChange={(e) => this.setState({nickname: e.target.value})} placeholder=""></input>
                        <p className="title">メールアドレス</p>
                        <input name="email" type="text" className="signup-input" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} placeholder=""></input>

                        <div className="col-12 title">
                            イベント参加者に届くコメント
                        </div>
                        <div className='input-wrapper'>
                            <textarea className='form-input-text large' name="" placeholder="例）一緒に飲みたいです" value={this.state.remark} onChange={(e) => this.setState({remark: e.target.value})}></textarea>
                        </div>

                    </div>
                    <div className="col-12 text-center">
                        <button type="submit" className="btn btn-secondary" onClick={this._handlePressJoinTempSubmit}>参加</button>
                    </div>
                </Modal>

                {/* <Modal show={this.state.show_course}
                       onClose={e => this.setState({show_course: false})}
                       hiddenRightButton
                >
                    {this.state.congregate && this.state.congregate.course &&
                    <div className="col-12 meta-box">
                        <CommonCourseCell
                            active={true}
                            image={course_image}
                            title={this.state.congregate.course.name}
                            sub_title={this.state.congregate.course.benefits}
                            price={this.state.congregate.course.price}
                            time={this.state.congregate.course.time}
                        />
                        <p className="desc">
                            {this.state.congregate.course.overview}
                        </p>
                        <p className="desc">
                            {this.state.congregate.course.description}
                        </p>
                    </div>
                    }
                </Modal> */}

                {/*<Alert {...this.state.alert}/>*/}

                {this.state.renderQR &&
                <div className="qr" onClick={this.handlePressQR}>
                    <QRCode size={200} value={this.state.invite_url}/>
                </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CongregateDetail);
