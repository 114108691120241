import React from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { actionCreator, RootState } from '../../reducers';

import './ReviewList.css';
import { Review } from '../../helpers/schemas/Api';
import ReviewApi from '../../helpers/api/ReviewApi';
import Header from '../../components/Header';
import CommonUserInfo from '../../components/CommonUserInfo';
import { _dateToString } from '../../helpers/utils';

import throttle from 'lodash';

interface ReviewListProps {
    history: any;
    currentUser: any;
    showSpinner: (show: boolean) => void;
}

interface ReviewListStates {
    productId: number,
    reviewList: Review[],
    page: number,
    requested_page: number,
    is_ended: boolean,
}

class ReviewList extends React.Component<ReviewListProps, ReviewListStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            productId: props.match.params.id,
            reviewList: [],
            page: 1,
            requested_page: -1,
            is_ended: false,
        };
    }

    componentDidMount() {
        // NOTE: サーバ側がページングに対応していないので保留
        // window.addEventListener('scroll', this.handleScroll, true);
        this._fetch()
    }

    componentWillUnmount() {
        // window.removeEventListener('scroll', this.handleScroll, true);        
    }

    _fetch = () => {
        let { is_ended, page, requested_page } = this.state;
        if (is_ended || requested_page >= page) { return; }
        requested_page = page;
        this.setState({ requested_page })
        this.props.showSpinner(true);
        ReviewApi._fetchProductReviews(
            this.state.productId,
            { page: page },
            (res: any) => {
                const reviews = res.reviews
                for (let review of res.reviews) {
                    review.created_at = new Date(review.created_at);
                    review.updated_at = new Date(review.updated_at);
                }
                this.setState({
                    reviewList: [...this.state.reviewList, ...reviews],
                    page: page + 1,
                    is_ended: reviews.length == 0,
                });
                this.props.showSpinner(false);
            },
            (error: any) => {
                alert(error);
                console.log(error);
                this.props.showSpinner(false);
            }
        )
    }

    handleScroll = throttle.throttle((e) => {
        const preMargin = 300;
        const isBottom = (e.target.scrollTop + e.target.clientHeight + preMargin) >= e.target.scrollHeight;
        if (isBottom) {
            this._fetch();
        }
    }, 100)

    _renderReviewList = () => {
        return (<div className='review-list-wrapper'>
            {this.state.reviewList.map(review => (
                <div className='review-cell-wrapper' key={review.id}>
                    <ReviewCell review={review} />
                    <Border />
                </div>
            ))}
        </div>)
    }

    render() {
        const { reviewList } = this.state;
        return (
            <div className="ReviewList row">
                <Header title={'レビュー一覧'}></Header>
                {this._renderReviewList()}
            </div>
        );
    }
}

const Border = () => (<div className='border' />)

const ReviewCell = ({ review }: { review: Review }) => (<div><CommonUserInfo upperText={`${review.communities_user.nickname} さん`}
    descripion={_dateToString(review.created_at)}
    imageUrl={review.communities_user.avatar_url} />
    <div className='text-area'>{review.comment}</div>
</div>)

const mapStateToProps = (state: RootState) => {
    console.log('RootState', state);
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewList);
