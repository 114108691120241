import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Moment from 'moment';
import queryString from 'query-string';
import {PREF_LIST} from '../../Construct.js';

import Header from '../../components/Header';
import GAHelper from "../../helpers/GAHelper";
import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import UserSessionApi from '../../helpers/api/UserSessionApi';
import ImageManagementApi from '../../helpers/api/ImageManagementApi';
import UserManagementApi from '../../helpers/api/UserManagementApi';

import './Regist.css';
import logo from 'assets/logo.png';
import btn_add_image from 'assets/btn_add_image.png';
import btn_camera from 'assets/btn_camera.png';

interface RegistProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showSpinner: (show: boolean) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface RegistStates {
    render: boolean;
    token?: string | string[] | null,
    first_name?: string;
    last_name?: string;
    email?: any;
    birthday?: string;
    gender?: string;
    address?: string;
    user_image_data?: string | null;
    avatar_url?: string;

    duplicate_email: boolean;
    checked: boolean;
}

/**
 * プロフィール登録・更新
 */
class Regist extends React.Component<RegistProps, RegistStates> {

    private form: any;
    private is_edit = false;

    constructor(props: any) {
        super(props);

        const values = queryString.parse(props.location.search);

        console.log('TOKEN', values.token);

        this.state = {
            render: false,
            gender: '',
            duplicate_email: false,
            birthday: '2000-01-01',
            email: values.email || '',
            token: values.token || '',
            checked: false,
        };
    }

    componentDidMount() {
        let currentUser = StorageHelper.getData(CURRENT_USER);
        this.is_edit = (currentUser && currentUser.isRegistered);

        // console.log(`MODE:${this.is_edit ? 'プロフィール編集' : 'プロフィール登録'}`);

        if (this.is_edit) {
            this.fetchUsers();
        } else {

            // var user_image_data = '';
            // if (user && user.photoURL != null) {
            //
            //     // 投稿用にSNS画像をbase64に変換する
            //     this.toDataUrl(user.photoURL, (data: any) => {
            //         console.log('reader.result', data);
            //         this.setState({
            //             user_image_data: data,
            //         });
            //     });
            // }

            if (!this.state.token) {
                this.props.showDialog(
                    "確認",
                    `招待URLからアクセスしてください。`,
                    (e: any) => {
                        this.props.hideDialog();
                        this.props.history.push('/signin');
                    },
                    null,
                );
                return;
            }

            this.setState({
                render: true,
                // email,
                // user_image_data,
            });
        }
    }

    handleFormSubmit(e: any) {
        e.preventDefault();

        // 生年月日チェック
        if (Moment(this.state.birthday).isBefore('1900-01-01') || Moment(this.state.birthday).isAfter(Moment())) {
            this.props.showDialog(
                "エラー",
                `生年月日が不正です。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        if (!this.is_edit && (this.form.password.value != this.form.password_c.value)) {
            this.props.showDialog(
                "エラー",
                `パスワードが一致しません。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        if (!this.is_edit && !this.state.checked) {
            this.props.showDialog(
                "エラー",
                `利用規約とプライバシーポリシーに同意してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        // if (this.state.duplicate_email) {
        //     this.props.showDialog(
        //         "確認",
        //         `すでに登録済みのメールアドレスです。`,
        //         (e: any) => {
        //             this.props.hideDialog();
        //         },
        //         null,
        //     );
        //     return;
        // }

        if (this.is_edit) {
            this._fetchUsersProfilesEdit();
        } else {
            /*
             3段階の登録処理を行う。
             １．_fetchUsersRegistrations
             ２．_fetchUsersProfiles
             ３．Firebaseにメールアドレス登録
             */

            this._fetchUsersActivation();
        }
    }

    /**
     * ユーザーを作成する
     * @private
     */
    _fetchUsersActivation() {

        this.props.showSpinner(true);

        // let currentUser = StorageHelper.getData(CURRENT_USER);
        // console.log('localStorage currentUser   : ', currentUser);

        UserManagementApi._fetchUsersActivation({

                password: this.form.password.value,
                device_kind: 'ios', // TODO
                device_name: 'iPhone8', // TODO
                version: 2, // チェックはフロント側で実装すること
                password_confirmation: this.form.password.value,
                first_name: this.form.first_name.value,
                last_name: this.form.last_name.value,
                email: this.form.email.value,
                gender: this.form.gender.value,
                // user_id: currentUser.user_id,
                birth: this.form.birthday.value ? Moment(this.form.birthday.value).format('YYYY-MM-DD') : null,
                address: this.form.address.value,
                token: this.state.token,
            },
            (responseJson: any) => {
                // console.log('ATTH SUCCESS.');
                console.log('_fetchUsersActivation', responseJson);

                let currentUser = StorageHelper.getData(CURRENT_USER);
                StorageHelper.setData(CURRENT_USER, {
                    user_id: responseJson.user_id,
                    access_token: responseJson.access_token,
                });

                // this._fetchUsersProfiles(responseJson.user_id);

                if (this.state.user_image_data) {
                    this._fetchImage();
                } else {
                    this._fetchUsersSessionNewVer();
                    // this.props.history.push('/mypage');
                }
            },
            (error: any) => {
                this.props.showSpinner(false);
                console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    /**
     * ログイン処理
     * @param e
     * @private
     */
    _fetchUsersSessionNewVer = () => {

        this.props.showSpinner(true);

        UserSessionApi._fetchUsersSessionNewVer(
            {
                device_kind: 'ios', // TODO
                device_name: 'iPhone8', // TODO
                version: 2,
                password: this.form.password.value,
                email: this.form.email.value,
            },
            (responseJson: any) => {
                console.log('_fetchUsersSessionNewVer', responseJson);

                this.props.showSpinner(false);

                GAHelper._logEvent(
                    'login',
                    {
                        method: 'email',
                    }
                );

                let user = {
                    isAuthenticated: true,
                    isRegistered: responseJson.registered,
                    user_id: responseJson.user_id,
                    access_token: responseJson.access_token,
                };

                StorageHelper.setData(CURRENT_USER, user);
                window.location.reload();
            },
            (error: any) => {
                this.props.showSpinner(false);
                // console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    /**
     * ユーザープロフィールを登録する
     * _fetchUsersActivationで一括登録するため廃止
     * @private
     */
    /*
     _fetchUsersProfiles(user_id: number) {

     let currentUser = StorageHelper.getData(CURRENT_USER);
     console.log('localStorage currentUser   : ', currentUser);

     UserSessionApi._fetchUsersProfiles(
     user_id,
     {
     user_profile: {
     first_name: this.form.first_name.value,
     last_name: this.form.last_name.value,
     // email: this.form.email.value,
     gender: this.form.gender.value,
     user_id: user_id,
     birth: this.form.birthday.value ? Moment(this.form.birthday.value).format('YYYY-MM-DD') : null,
     address: this.form.address.value,
     }
     },
     (responseJson: any) => {
     console.log('_fetchUsersProfiles', responseJson);

     let currentUser = StorageHelper.getData(CURRENT_USER);
     currentUser.isRegistered = true;
     StorageHelper.setData(CURRENT_USER, currentUser);

     if (this.state.user_image_data) {
     this._fetchImage();
     } else {
     this.props.history.push('/mypage');
     }

     },
     (error: any) => {
     this.props.showSpinner(false);
     // console.log('AUTH FAILED.', error);
     alert(error);
     },
     );
     }
     */

    /**
     * ユーザープロフィールを編集する
     * @private
     */
    _fetchUsersProfilesEdit() {

        this.props.showSpinner(true);

        let currentUser = StorageHelper.getData(CURRENT_USER);

        UserSessionApi._fetchUsersProfilesEdit(
            currentUser.user_id,
            {
                user_profile: {
                    first_name: this.form.first_name.value,
                    last_name: this.form.last_name.value,
                    // email: this.state.email,
                    gender: this.form.gender.value,
                    user_id: currentUser.user_id,
                    birth: this.form.birthday.value ? Moment(this.form.birthday.value).format('YYYY-MM-DD') : null,
                    address: this.form.address.value,
                }
            },
            (responseJson: any) => {

                this.props.showSpinner(false);

                // redux更新
                let currentUser = StorageHelper.getData(CURRENT_USER);

                this.props.setCurrentUser({
                    name: currentUser.name,
                    token: currentUser.access_token,
                    user_id: currentUser.user_id,
                    avatar_url: this.state.avatar_url,
                    profile: {
                        birth: this.form.birthday.value ? Moment(this.form.birthday.value).format('YYYY-MM-DD') : null,
                        address: this.form.address.value,
                        first_name: this.form.first_name.value,
                        last_name: this.form.last_name.value,
                        gender: this.form.gender.value,
                        email: this.state.email,
                        user_id: currentUser.user_id,
                    },
                });

                if (this.state.user_image_data) {
                    this._fetchImage();
                } else {
                    this.props.history.push('/mypage');
                }
            },
            (error: any) => {
                this.props.showSpinner(false);
                // console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    /**
     * メールアドレス重複チェック
     * @private
     */
    _fetchUsersRegistrationsCheckDuplicateEmail() {

        UserSessionApi._fetchUsersRegistrationsCheckDuplicateEmail(
            {
                email: this.state.email,
            },
            (responseJson: any) => {

                // console.log('_fetchUsersRegistrationsCheckDuplicateEmail', responseJson);
                this.setState({
                    duplicate_email: !responseJson.result, // true:重複なし false:重複あり
                });
            },
            (error: any) => {
                // console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    /**
     * ユーザー画像を登録する
     * @private
     */
    _fetchImage() {

        let currentUser = StorageHelper.getData(CURRENT_USER);

        this.props.showSpinner(true);

        ImageManagementApi._fetchImage({
                imageable_id: currentUser.user_id,
                imageable_type: 'User',
                name: 'avatar',
                content: this.state.user_image_data,
            },
            (responseJson: any) => {
                // 画像登録
                this.props.showSpinner(false);
                // console.log('ImageManagementApi responseJson', responseJson);
                if (this.is_edit) {
                    this.props.history.push('/mypage');
                } else {
                    this._fetchUsersSessionNewVer();
                }
            },
            (error: any) => {
                this.props.showSpinner(false);
                // console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    /**
     * ユーザーのプロフィールを取得する
     */
    fetchUsers = () => {

        this.props.showSpinner(true);

        let currentUser = StorageHelper.getData(CURRENT_USER);

        UserManagementApi._fetchUsers(
            currentUser.user_id,
            {},
            (responseJsonUsers: any) => {

                this.props.showSpinner(false);

                this.setState({
                    render: true,
                    first_name: responseJsonUsers.profile.first_name,
                    last_name: responseJsonUsers.profile.last_name,
                    email: responseJsonUsers.profile.email,
                    gender: responseJsonUsers.profile.gender,
                    birthday: Moment(responseJsonUsers.profile.birth).format('YYYY-MM-DD'),
                    address: responseJsonUsers.profile.address,
                    avatar_url: responseJsonUsers.avatar_url,
                });
            },
            (error: any) => {
                alert(error);
                // firebase.auth().signOut();
                this.props.showSpinner(false);

                this.setState({
                    render: true
                });
            },
        );
    }

    handleChangeGender(event: any) {
        this.setState({gender: event.target.value});
    }

    handleChangeFile = (e: any) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        reader.onloadend = () => {
            this.setState({
                user_image_data: `${reader.result}`,
            });
        }
        reader.readAsDataURL(file);
    }

    handleChangeAddress(event: any) {
        this.setState({address: event.target.value});
    }

    toDataUrl(url: string, callback: any) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    handleChange(e: any) {
        this.setState({
            checked: e.target.checked,
        });
    };

    render() {

        var renderCameraBtn = false;
        var user_image = btn_add_image;
        if (this.state.user_image_data) {
            user_image = this.state.user_image_data;
            renderCameraBtn = true;
        } else if (this.state.avatar_url) {
            user_image = this.state.avatar_url;
            renderCameraBtn = true;
        }

        return (
            <div className="Regist row">
                { !this.is_edit && this.state.render &&
                <div className="col-12">
                    <h1>会員登録</h1>
                </div>
                }

                { this.is_edit &&
                <Header
                    title={'プロフィール編集'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                }

                { this.state.render &&
                <div className="col-12 image">
                    <label>
                        <div className="img_area">
                            <img src={user_image} className="logo cursor"/>
                            <input type="file" className="user-image" name="upfile" id="upfile" accept="image/*" onChange={this.handleChangeFile.bind(this)}/>
                            {/*<img src={btn_camera} className="add"/>*/}
                        </div>
                    </label>
                </div>
                }
                { this.state.render &&
                <div className="col-12 detail">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.form = el && el.elements}
                    >
                        <label htmlFor="last_name">姓</label>
                        <input name="last_name" type="text" className="signup-input" value={this.state.last_name} onChange={(e) => this.setState({last_name: e.target.value})} placeholder="" required></input>

                        <label htmlFor="first_name">名</label>
                        <input name="first_name" type="text" className="signup-input" value={this.state.first_name} onChange={(e) => this.setState({first_name: e.target.value})} placeholder="" required></input>

                        { !this.is_edit &&
                        <label htmlFor="email">
                            メールアドレス
                        </label>
                        }
                        { !this.is_edit &&
                        <input name="email" type="email" className="signup-input" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} onBlur={(e) => this._fetchUsersRegistrationsCheckDuplicateEmail()} placeholder="" required disabled></input>
                        }

                        {this.state.duplicate_email &&
                        <p className="error">すでに登録済みのメールアドレスです</p>
                        }

                        { !this.is_edit &&
                        <label htmlFor="password">パスワード</label>
                        }
                        { !this.is_edit &&
                        <input name="password" type="password" className="signup-input" placeholder="パスワード" required></input>
                        }


                        { !this.is_edit &&
                        <label htmlFor="password">パスワード（確認）</label>
                        }
                        { !this.is_edit &&
                        <input name="password_c" type="password" className="signup-input" placeholder="パスワード（確認）" required></input>
                        }

                        <label htmlFor="gender">性別</label>
                        <select name="gender" className="signup-input" value={this.state.gender} onChange={this.handleChangeGender.bind(this)}>
                            <option value="">性別を選択する</option>
                            <option value="male">男性</option>
                            <option value="female">女性</option>
                        </select>

                        <label htmlFor="birthday">生年月日</label>
                        <input name="birthday" type="date" className="signup-input" value={Moment(this.state.birthday).format('YYYY-MM-DD')} onChange={(e) => this.setState({birthday: e.target.value})} placeholder=""></input>

                        <label htmlFor="address">都道府県(現住所)</label>
                        <select name="address" className="signup-input" value={this.state.address} onChange={this.handleChangeAddress.bind(this)}>
                            <option value="">都道府県を選択する</option>
                            { PREF_LIST.map(d => <option value={d}>{d}</option>)}
                        </select>

                        { !this.is_edit &&
                        <div className="col-12 terms">
                            <input type="checkbox" id="terms" checked={this.state.checked} onChange={this.handleChange.bind(this)}/>
                            <label htmlFor="terms">
                                <a href="/terms" target="_blank">
                                    利用規約
                                </a>
                                と
                                <a href="/policy" target="_blank">
                                    プライバシーポリシー
                                </a>
                                に同意する
                            </label>
                        </div>
                        }

                        <button type="submit" className="btn btn-secondary">{ this.is_edit ? '変更' : '登録'}</button>
                        { !this.is_edit &&
                        <button type="button" className="btn btn-secondary" onClick={e => {
                            this.props.history.push('/signin');
                        }}>キャンセル
                        </button>
                        }
                    </form>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            // console.log('setCurrentUser', data)
            // console.log('setCurrentUser', data)
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Regist);
