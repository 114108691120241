import React from 'react';
import BaseApi from './BaseApi';

export default class UserSessionApi extends BaseApi {

    /**
     * /api/v1/users/registrations
     */
    static _fetchUsersRegistrations(params, success, error) {
        super._fetch('/api/v1/users/registration', 'POST', params, success, error);
    }

    /**
     * /api/v1/users/sessions
     * 旧方式(Firebase)の認証
     */
    static _fetchUsersSession(params, success, error) {
        super._fetch('/api/v1/users/session', 'POST', params, success, error);
    }

    /**
     * /api/v1/sessions
     * 新方式のid/pass認証
     */
    static _fetchUsersSessionNewVer(params, success, error) {
        super._fetch('/api/v1/sessions', 'POST', params, success, error);
    }

    /**
     * /api/v1/users/profile
     */
    static _fetchUsersProfiles(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/profile', 'POST', params, success, error);
    }

    /**
     * /api/v1/users/profile/edit
     */
    static _fetchUsersProfilesShow(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/profile', 'GET', params, success, error);
    }

    /**
     * /api/v1/users/profile
     */
    static _fetchUsersProfilesEdit(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/profile', 'PATCH', params, success, error);
    }

    /**
     * /api/v1/users/registration/check_duplicate_email
     */
    static _fetchUsersRegistrationsCheckDuplicateEmail(params, success, error) {
        super._fetch('/api/v1/users/registration/check_duplicate_email', 'GET', params, success, error);
    }
}
