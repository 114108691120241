import React from 'react';
import BaseApi from './BaseApi';

export default class UsersCommunityApi extends BaseApi {

    /**
     * /users/communities
     */
    static _fetchUsersCommunity(params, success, error) {
        super._fetch(`/api/v1/users/communities`, 'GET', params, success, error);
    }
}
