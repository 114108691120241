import React from 'react';
import Moment from 'moment';


import './CommonSwiperCell.css';
import icon_location from 'assets/feed/locail.png';
import no_image from 'assets/no_image.png';

interface CommonSwiperCellProps {

    image?: any;

    date?: string;
    location?: string;
    title?: string;
    text1?: string;
    text2?: string;
    comment?: string;

    active?: boolean; // 選択済みか
    onClick?: (e: any) => void;
}

interface CommonSwiperCellStates {

}

class CommonSwiperCell extends React.Component<CommonSwiperCellProps, CommonSwiperCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className="CommonSwiperCell cursor" onClick={this.props.onClick}>
                {this.props.date &&
                <p className="date">{this.props.date}</p>
                }
                <div className="image-box" >
                    <img src={this.props.image || no_image} className="main" alt=""/>
                </div>
                <div className="meta-box">
                    {this.props.location &&
                    <p className="location">
                        <img src={icon_location} alt=""/>
                        {this.props.location}
                    </p>
                    }
                    {this.props.title &&
                    <p className="title">{this.props.title}</p>
                    }
                    {this.props.text1 &&
                    <p className="text">{this.props.text1}</p>
                    }
                    {this.props.text2 &&
                    <p className="text">{this.props.text2}</p>
                    }
                </div>
                {this.props.comment &&
                <div className="comment-box">
                    {this.props.comment}
                </div>
                }
            </div>
        );

    }
}

export default CommonSwiperCell;
