import Signin from './containers/auth/Signin';
import Signup from './containers/auth/Signup';
import Remind from './containers/auth/Remind';
import Slide from './containers/auth/Slide';
import Regist from './containers/regist/Regist';
import HomeSwiper from './containers/home/HomeSwiper';
// import Home from './containers/home/HomeSwiper';
// import CommunityHome from './containers/home/CommunityHome';
// import CommunitySwiper from './containers/home/CommunitySwiper';
import CommunityHomeSwiper from './containers/home/CommunityHomeSwiper';
import Mypage from './containers/mypage/Mypage';
import Password from './containers/mypage/Password';
import UserProductList from './containers/mypage/UserProductList';
import UserPurchaseList from './containers/mypage/UserPurchaseList';
import NotificationList from './containers/notification/NotificationList';
import Purchase from './containers/product/Purchase';
import ProductDetail from './containers/product/ProductDetail'
import ProductCreateSelectType from './containers/product/CreateSelectType';
import ProductCreateEdit from './containers/product/CreateEdit';
import CongregateDetail from './containers/congregate/CongregateDetail';
import CongregateCreate from './containers/congregate/CongregateCreate';
import StoreDetail from './containers/store/StoreDetail';
import StoreRouter from './containers/store/StoreRouter';
import ReviewList from './containers/review/ReviewList';
import ReviewCreate from './containers/review/Create';
import TalkList from './containers/talk/TalkList';
import Talk from './containers/talk/Talk';
import Stripe from './containers/stripe/Stripe';
import TalkBoard from "./containers/talk/TalkBoard";
import PrivacyPolicy from './containers/page/PrivacyPolicy';
import Terms from './containers/page/Terms';
import CommunitySetting from './containers/community/CommunitySetting';
import CommunityInvite from './containers/community/CommunityInvite';
import CommunityRegist from './containers/community/CommunityRegist';
import CommunityAccount from './containers/community/CommunityAccount';
import CommunityMember from './containers/community/CommunityMember';
import CommunityProfile from './containers/community/CommunityProfile';
import CommunityEventList2 from './containers/community/CommunityEventList';
import Balance from './containers/balance/Balance';
import BenefitCreate from './containers/benefit/BenefitCreate';
import BenefitDetail from './containers/benefit/BenefitDetail';
import CongregateHelp from './containers/help/CongregateHelp';
import UI from './containers/UI/UI';

import 'App.css';
import 'bootstrap/dist/css/bootstrap.css';
import EventDetail from './containers/nigata-kenzin/event/EventDetail';
import CommunityTop from './containers/nigata-kenzin/community/CommunityTop';
import NiigataStoreDetail from './containers/nigata-kenzin/store/NiigataStoreDetail';
import CommunityEventList from './containers/nigata-kenzin/community/CommunityEventList';
import CommunityStoreList from './containers/nigata-kenzin/community/CommunityStoreList';

/**
 * path: パス
 * component: 表示対象
 * exact: 完全一致でのみ表示する
 * public: 未ログインのみ表示する
 * regist: ログイン済みでプロフィール未入力の場合のみ表示する
 * @type {[*]}
 */
export const RUTER = [
    {path: '/signin', component: Signin, exact: true, public: true, regist: false},
    // {path: '/signup', component: Signup, exact: true, public: true, regist: false},
    {path: '/regist', component: Regist, exact: true, public: true, regist: true},

    {path: '/remind', component: Remind, exact: true, public: true, regist: false},
    {path: '/slide', component: Slide, exact: true, public: true, regist: false},
    {path: '/profile/edit', component: Regist, exact: true, public: false, regist: false},
    // {path: '/community/:name', component: CommunityHome, exact: true, public: false, regist: false},
    {path: '/community/:name', component: CommunityHomeSwiper, exact: true, public: false, regist: false},
    {path: '/mypage', component: Mypage, exact: true, public: false, regist: false},
    {path: '/mypage/password', component: Password, exact: true, public: false, regist: false},
    {path: '/mypage/product', component: UserProductList, exact: true, public: false, regist: false},
    {path: '/mypage/purchase', component: UserPurchaseList, exact: true, public: false, regist: false},
    {path: '/notification', component: NotificationList, exact: true, public: false, regist: false},
    // {path: '/talk/:id', component: Talk, exact: true, public: false, regist: false},
    // {path: '/talk', component: TalkList, exact: true, public: false, regist: false},
    // {path: '/product/purchase/:id', component: Purchase, exact: true, public: false, regist: false},
    // {path: '/product/:id', component: ProductDetail, exact: true, public: false, regist: false},
    // {path: '/product/:id/edit', component: ProductCreateEdit, exact: true, public: false, regist: false},
    // {path: '/product/:id/review', component: ReviewList, exact: true, public: false, regist: false},
    // {path: '/review/:id', component: ReviewCreate, exact: true, public: false, regist: false},
    {path: '/community/:name/product/create', component: ProductCreateSelectType, exact: true, public: false, regist: false},
    {path: '/community/:name/product/create/:type', component: ProductCreateEdit, exact: true, public: false, regist: false},
    // {path: '/stripe', component: Stripe, exact: true, public: false, regist: false},
    // {path: '/balance', component: Balance, exact: true, public: false, regist: false},
    {path: '/community/:name/detail', component: CommunitySetting, exact: true, public: false, regist: false},
    {path: '/community/:name/invite', component: CommunityInvite, exact: true, public: false, regist: false},
    {path: '/community/:name/edit', component: CommunityRegist, exact: true, public: false, regist: false},
    {path: '/community/:name/member/:user_id', component: CommunityAccount, exact: true, public: false, regist: false},
    {path: '/community/:name/member', component: CommunityMember, exact: true, public: false, regist: false},
    {path: '/community/:name/profile', component: CommunityProfile, exact: true, public: false, regist: false},
    {path: '/community/:name/benefit', component: BenefitCreate, exact: true, public: false, regist: false},
    {path: '/community/:name/talkboard', component: TalkBoard, exact: true, public: false, regist: false},
    {path: '/community/:name/event_list', component: CommunityEventList2, exact: true, public: false, regist: false},
    {path: '/benefit/:id', component: BenefitDetail, exact: true, public: false, regist: false},
    {path: '/terms', component: Terms, exact: true, public: true, regist: false},
    {path: '/policy', component: PrivacyPolicy, exact: true, public: true, regist: false},
    {path: '/home', component: HomeSwiper, exact: true, public: false, regist: false},
    {path: '/', component: HomeSwiper, exact: true, public: false, regist: false},
];

/**
 * すべてのユーザーに公開される画面
 * path: パス
 * component: 表示対象
 * exact: 完全一致でのみ表示する
 * public: 未ログインのみ表示する
 * regist: ログイン済みでプロフィール未入力の場合のみ表示する
 * @type {[*]}
 */
export const PUBLIC_RUTER = [
    {path: '/store/:id', component: StoreRouter, exact: true, public: false, regist: false},
    {path: '/congregate/detail/:id', component: CongregateDetail, exact: true, public: false, regist: false},
    {path: '/help/congregate', component: CongregateHelp, exact: true},

    // 新潟県人会向け
    {path: '/niigata/store_list', component: CommunityStoreList, exact: true, public: false, regist: false},
    {path: '/niigata/event_list', component: CommunityEventList, exact: true, public: false, regist: false},
    {path: '/niigata/event/:id', component: EventDetail, exact: true, public: false, regist: false},
    {path: '/niigata/store/:id', component: NiigataStoreDetail, exact: true, public: false, regist: false},
    {path: '/community/top/:name', component: CommunityTop, exact: true, public: false, regist: false},
];