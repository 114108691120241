import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {PREF_LIST} from '../../Construct.js';

import Header from '../../components/Header';
import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';

import './CommunityMember.css';
import no_image from 'assets/no_image.png';
import CommonUserCell from '../../components/CommonUserCell';

interface CommunityMemberProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showSpinner: (show: boolean) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface CommunityMemberStates {
    render: boolean;
    community_name: string;

    communities_users: {
        id: number;
        nickname: string;
        avatar_url: string;
        user_id: number;
    }[];
}

/**
 * プロフィール登録・更新
 */
class CommunityMember extends React.Component<CommunityMemberProps, CommunityMemberStates> {

    constructor(props: any) {
        super(props);

        this.state = {
            render: false,
            community_name: props.match.params.name,
            communities_users: [],
        };
    }

    componentDidMount() {
        this._fetchCommunitiesUsers();
    }

    /**
     * ユーザー一覧取得
     */
    _fetchCommunitiesUsers = () => {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommunitiesShow(
            this.state.community_name,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesUsers', responseJson);

                this.props.showSpinner(false);

                this.setState({
                    render: true,
                    communities_users: responseJson.communities_users
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    render() {

        let user_list = [];
        for (let cu of this.state.communities_users) {

            var img = no_image;
            if (cu.avatar_url) {
                img = cu.avatar_url;
            }

            if (!cu.nickname) {
                continue;
            }

            user_list.push(
                <CommonUserCell
                    image={img}
                    onClick={e => {
                        this.props.history.push(`/community/${this.state.community_name}/member/${cu.user_id}`);
                    }}
                    title={cu.nickname || ''}
                />
            );
        }


        return (
            <div className="CommunityMember row">

                <Header
                    title={this.state.community_name}
                    leftButtonTitle="戻る"
                    leftButtonAction={() => {
                        this.props.history.goBack();
                    }}
                >
                </Header>

                { this.state.render && user_list}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            // console.log('setCurrentUser', data)
            // console.log('setCurrentUser', data)
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityMember);
