import React from 'react';

import './CommonSegmentButton.css';

interface CommonSegmentButtonProps {
    lists: any,
    index: number,
    onChange: (index: number) => void;
}

interface CommonSegmentButtonStates {

}

class CommonSegmentButton extends React.Component<CommonSegmentButtonProps, CommonSegmentButtonStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        const {lists, index, onChange} = this.props;

        var list = [];
        var i = 0;

        for (let text of lists) {

            let num = i;
            if (i == index) {
                list.push(
                    <label className="btn btn-secondary cursor active">
                        <input type="radio" name="options" id="option1" onChange={(e) => onChange(num)}/> {text}
                    </label>
                );
            } else {
                list.push(
                    <label className="btn btn-secondary cursor">
                        <input type="radio" name="options" id="option2" onChange={(e) => onChange(num)}/> {text}
                    </label>
                );
            }
            i++;
        }

        return (
            <div className="CommonSegmentButton col-12">
                <div className="col-12 title">
                    <div className="btn-group btn-group-toggle">
                        {list}
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonSegmentButton;
