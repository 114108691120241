import React from 'react';
import BaseApi from './BaseApi';

export default class CancelApi extends BaseApi {

    /**
     * POST /cancels
     */
    static _fetchCancels(params, success, error) {
        super._fetch('/api/v1/cancels', 'POST', params, success, error);
    }
}