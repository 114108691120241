import React from 'react';
import BaseApi from './BaseApi';

export default class ProductCreationApi extends BaseApi {

    /**
     * /users/communities
     */
    static _fetchUsersCommunities(params, success, error) {
        super._fetch('/api/v1/users/communities', 'GET', params, success, error);
    }

    /**
     * /products
     */
    static _fetchProducts(params, success, error) {
        super._fetch('/api/v1/products', 'POST', params, success, error);
    }
}
