import React from 'react';

import './CommonButton';

import './CommonButton.css';

interface CommonButtonProps {
    text: string;
    onClick: any;
}

interface CommonButtonStates {

}

class CommonButton extends React.Component<CommonButtonProps, CommonButtonStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="col-12 CommonButton">
                <button onClick={this.props.onClick}>
                    {this.props.text}
                </button>
            </div>
        );
    }
}

export default CommonButton;
