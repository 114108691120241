/**
 * 定義ファイル
 * @type {string}
 */
// ture = 本番
// false = ステージング
const IS_PROD = true;

// 問い合わせメールアドレス
export const INQUIRY_MAIL_ADDRESS = 'titi-support@shakr.org';

// API URL
export const BASE_URL = IS_PROD ? 'https://titi-shakr.com' : 'http://52.199.147.123';

// stripe
export const STRIPE_PUBLISH_KEY = IS_PROD ? 'pk_live_FjHodGkjtNODUJQIm49B6JBM' : 'pk_test_brdSpzKsd0QpLOamWyjSCxoS';

// LINE
export const LINE_CLIENT_ID = IS_PROD ? '1653294194' : '1653294194'; // TODO STG設定

// 都道府県一覧
export const PREF_LIST = [
    '北海道', '青森県', '岩手県', '宮城県', '秋田県',
    '山形県', '福島県', '茨城県', '栃木県', '群馬県',
    '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県',
    '富山県', '石川県', '福井県', '山梨県', '長野県',
    '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県',
    '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県',
    '鳥取県', '島根県', '岡山県', '広島県', '山口県',
    '徳島県', '香川県', '愛媛県', '高知県', '福岡県',
    '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県',
    '鹿児島県', '沖縄県'
];

export const CATEGORY_LIST_DICT = {
    'time': ['ビジネス', 'ソーシャル', 'カルチャー', 'ライフ', 'テクノロジー', 'デザイン', 'アート', 'キャリア', '体験', 'スペース', 'その他'],
    'goods': ['ファッション', 'ベビー・キッズ', 'インテリア・雑貨', '本・音楽・ゲーム', 'おもちゃ・ホビー', 'コスメ・美容', '家電・カメラ', 'スポーツ・レジャー', 'ハンドメイド', 'その他'],
}

export const HIRAGANA_LIST = [
    'あ', 'い', 'う', 'え', 'お',
    'か', 'き', 'く', 'け', 'こ',
    'さ', 'し', 'す', 'せ', 'そ',
    'た', 'ち', 'つ', 'て', 'と',
    'な', 'に', 'ぬ', 'ね', 'の',
    'は', 'ひ', 'ふ', 'へ', 'ほ',
    'ま', 'み', 'む', 'め', 'も',
    'や', 'ゆ', 'よ',
    'ら', 'り', 'る', 'れ', 'ろ',
    'わ',
]

export const SEAT_TYPES = {
    0: 'テーブル',
    1: 'カウンター',
    2: '座敷',
    3: '掘りごたつ',
    4: '完全個室',
    5: '半個室',
    6: 'テラス',
    7: 'テイクアウト',
}

export const JOINED_STATUS = {
    PUBLIC: 'global', // 公開(参加も招待もされていない)
    INVITE: 'invited', // 招待(公開/非公開も含む)
    JOIN: 'joined', // 参加
    ELSE: '',
}

export const APPROVAL_STATUS = {
    WAITING: 'waiting', // 予約リクエスト中
    APPROVAL: 'approval', // 承認済
    REJECTED: 'rejected', // 却下
    ALL: '',
}
