import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../../reducers';

import CommonTitle from '../../../components/CommonTitle';
import Guide from '../components/Guide';
import CommonButton from '../components/CommonButton';

import './CommunityTop.css';
import CommonCell from '../components/CommonCell';
import CommonSwiperCell from '../components/CommonSwiperCell';
import UserCell from '../components/UserCell';
import CongregateSwiper from '../../../components/CongregateSwiper';

import FireStoreHelper from '../../../helpers/FireStoreHelper';
import {FavorStore, Event, Store, Campaign, FavorStoreUser} from '../../../helpers/schemas/FireStore';

import './CommunityTop.css';

interface CommunityTopProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface CommunityTopStates {
    favors_list: FavorStore[],
    event_list: Event[],
    store_list: Store[],
    campaign_list: Campaign[],
    favor_store_user?: FavorStoreUser,
}

class CommunityTop extends React.Component<CommunityTopProps, CommunityTopStates> {

    load = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            favors_list: [],
            event_list: [],
            store_list: [],
            campaign_list: [],
        };
        this._fetchStoresAll();
        this._fetchEventsAll();
        this._fetchCampaignAll();
        this._fetchFavorStoreUserInfo();
        this._fetchFavorStoresAll();
    }

    async componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    _showSpinner = (show: boolean) => {
        if (show) {
            this.load++;
        } else {
            this.load--;
        }
        this.props.showSpinner(this.load > 0);
    }

    // ご贔屓店でイベントを行う
    _fetchStoresAll = async (): Promise<void> => {

        this._showSpinner(true);

        var store_list = await FireStoreHelper.getStoresAll();
        console.log('_fetchStoresAll', store_list);

        this._showSpinner(false);

        this.setState({
            store_list: store_list
        });
    }

    // 過去イベントを参考にする
    _fetchEventsAll = async (): Promise<void> => {

        this._showSpinner(true);

        var event_list = await FireStoreHelper.getEventsAll();
        console.log('_fetchEventsAll', event_list);

        this._showSpinner(false);

        this.setState({
            event_list: event_list
        });
    }

    // キャンペーン（新潟県にゆかりあるお店で集まる）
    _fetchCampaignAll = async (): Promise<void> => {

        this._showSpinner(true);

        var campaign_list = await FireStoreHelper.getCampaignAll();
        console.log('_fetchCampaignAll', campaign_list);

        this._showSpinner(false);

        this.setState({
            campaign_list: campaign_list
        });
    }

    // メンバーのご贔屓店
    _fetchFavorStoreUserInfo = async (): Promise<void> => {

        this._showSpinner(true);

        var favor_store_user = await FireStoreHelper.getFavorStoreUserInfo();
        console.log('_fetchFavorStoreUserInfo', favor_store_user);

        this._showSpinner(false);

        this.setState({
            favor_store_user
        });
    }

    // メンバーのご贔屓店
    _fetchFavorStoresAll = async (): Promise<void> => {

        this._showSpinner(true);

        var favors_list = await FireStoreHelper.getFavorStoresAll();
        console.log('_fetchFavorStoresAll', favors_list);

        this._showSpinner(false);

        this.setState({
            favors_list: favors_list
        });
    }

    render() {

        // ご贔屓店でイベントを行う
        var swiper_store_list = [];
        for (let store of this.state.store_list) {

            var text2 = '';
            if (store.last_use_date) {
                text2 += '前回：' + store.last_use_date;
            }

            swiper_store_list.push(
                <div className="cell-kenjin">
                    <CommonSwiperCell
                        image={store.image_urls[0]}
                        location={store.access}
                        title={store.name}
                        text1={store.tag}
                        text2={text2}
                        onClick={(event: any) => {
                            this.props.history.push('/niigata/store/' + store.id);
                        }}
                    />
                </div>
            );
        }

        // 過去イベントを参考にする
        var swiper_event_list = [];
        for (let event of this.state.event_list) {

            var text1 = '';
            if (event.participant_count > 0) {
                text1 += '参加者：' + event.participant_count + '名';
            }

            var text2 = '';
            if (event.organizer) {
                text2 += '企画者：' + event.organizer;
            }

            swiper_event_list.push(
                <div className="cell-kenjin">
                    <CommonSwiperCell
                        image={event.image_url}
                        date={event.date_detail}
                        location={event.store_name}
                        title={event.name}
                        text1={text1}
                        text2={text2}
                        onClick={(event: any) => {
                            this.props.history.push('/niigata/event/' + event.id);
                        }}
                    />
                </div>
            );
        }

        // 新潟県にゆかりのあるお店であつまる
        var store_list = [];
        for (let campaign of this.state.campaign_list) {

            var course;
            var favor_benefits;

            if (campaign.favor_benefits) {
                favor_benefits = 'ご贔屓特典：' + campaign.favor_benefits;
            }
            if (campaign.course) {
                course = campaign.course;
            }

            store_list.push(
                <CommonCell
                    image={campaign.store.image_urls[0]}
                    title={campaign.store.name}
                    location={campaign.store.access}
                    sub_title={favor_benefits}
                    desc={course}
                    text={campaign.store.description}
                    onClick={() => {
                        this.props.history.push('/niigata/store/' + campaign.store.id);
                    }}
                />
            );
        }

        // メンバーのご贔屓店
        var swiper_favors_list = [];
        for (let favor of this.state.favors_list) {

            console.log('favor', favor);

            swiper_favors_list.push(
                <div className="cell-kenjin">
                    <CommonSwiperCell
                        image={favor.store.image_urls[0]}
                        location={favor.store.access}
                        title={favor.store.name}
                        text1={favor.store.tag}
                        onClick={(event: any) => {
                            this.props.history.push('/niigata/store/' + favor.store.id);
                        }}
                        comment={favor.comment}
                    />
                </div>
            );
        }

        // swiper_favors_list.push(
        //     <div className="cell-kenjin">
        //         <MemberFavorShop
        //             text2="+ ご贔屓店を登録する"
        //             text3="自分の好きなお店・集まりたいお店を登録しよう"
        //             onClick={(event: any) => {
        //                 // TODO URL直す
        //                 this.props.history.push('/niigata/store/');
        //             }}
        //         />
        //     </div>
        // );

        return (
            <Fragment>

                <Guide
                    onClick={() => {
                        this.props.history.push('/niigata/store/');
                    }}
                />

                <CommonTitle
                    title="ご贔屓店でイベントを行う"
                    logo
                />
                <div className="col-12 swiper">
                    {swiper_store_list.length > 0 &&
                    <CongregateSwiper
                        key="swiper_store_list"
                        content={swiper_store_list}
                    />
                    }
                </div>


                <CommonButton
                    text="すべてのご贔屓店を表示する"
                    onClick={(event: any) => {
                        this.props.history.push('/niigata/store_list');
                    }}
                />

                <CommonTitle
                    title="過去イベントを参考にする"
                    logo
                />
                <div className="col-12 swiper">
                    {swiper_event_list.length > 0 &&
                    <CongregateSwiper
                        key="swiper_event_list"
                        content={swiper_event_list}
                    />
                    }
                </div>

                <CommonButton
                    text="すべてのイベントを表示する"
                    onClick={(event: any) => {
                        this.props.history.push('/niigata/event_list');
                    }}
                />
                {this.load == 0 && store_list}

                <CommonTitle
                    title="メンバーのご贔屓点"
                    logo
                />
                {this.state.favor_store_user &&
                <UserCell
                    image={this.state.favor_store_user.user_image_url}
                    name={this.state.favor_store_user.user_name}
                    desc={this.state.favor_store_user.user_register_year}
                />
                }
                <div className="col-12 swiper">
                    {swiper_favors_list.length > 0 &&
                    <CongregateSwiper
                        key="swiper_favors_list"
                        content={swiper_favors_list}
                    />
                    }
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityTop);
