class SlackWebhookHelper {
    static requestReserveUrl = 'https://hooks.slack.com/services/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';

    static baseRequest(str: string, url: string, success_callback: (responseJson: any)=> void, error_callback: (error: any)=> void) {
        const options: RequestInit = {
            method: 'POST',
            body: JSON.stringify({"text": str}),
        }

        // fetch
        fetch(url, options)
            .then((response) => {
                console.log('response', response);

                // 成否の確認
                if (response.ok) {
                    success_callback(null);
                } else {
                    error_callback(null);
                }
            })
            .catch((error) => {
                console.log('response error', error);
                if (error.message) {
                    error_callback(error.message);
                } else {
                    error_callback(null);
                }
            });
    }

    // 予約リクエストを通知
    static requestReserve(str: string, success_callback: (responseJson: any)=> void, error_callback: (error: any)=> void) {
        this.baseRequest(str, this.requestReserveUrl, success_callback, error_callback);
    }
}

export default SlackWebhookHelper;