import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import Header from '../../components/Header';
import firebase, {auth} from "../../firebase";

import no_image from 'assets/no_image.png';

import './Mypage.css';

interface MypageProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
    };
}

interface MypageStates {

}

class Mypage extends React.Component<MypageProps, MypageStates> {

    menu = [
        // {
        //     title: 'メールアドレス・パスワード変更',
        //     onClick: () => {
        //         this.props.history.push(`/mypage/password`);
        //     }
        // },
        {
            title: 'プロフィールを編集',
            onClick: () => {
                this.props.history.push(`/profile/edit`);
            }
        },
        // {
        //     title: '出金申請',
        //     onClick: () => {
        //         this.props.history.push(`/balance`);
        //     }
        // },
        {
            title: 'ログアウト',
            onClick: () => {
                StorageHelper.remove(CURRENT_USER);
                window.location.reload(true);
            }
        },
    ]

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    render() {

        let user = StorageHelper.getData(CURRENT_USER);

        let image = this.props.currentUser.avatar_url || no_image;
        let user_name = this.props.currentUser.profile ? `${this.props.currentUser.profile.last_name} ${this.props.currentUser.profile.first_name}` : '';

        var list = [];
        for (let d of this.menu) {
            list.push(
                <li onClick={d.onClick} className="cursor">
                    {d.title}
                </li>
            );
        }

        return (
            <div className="Mypage row">
                <Header
                    title={'マイページ'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                { this.props.currentUser.profile &&
                <div className="col-12 user">
                    <img src={image} className="user-image"/>
                    <div className="user-name">
                        {user_name}
                    </div>
                </div>
                }
                { this.props.currentUser.profile &&
                <div className="col-12 common">
                    <ul>
                        {list}
                    </ul>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Mypage);
