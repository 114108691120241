import {Action} from 'redux';

export type SideMenuPayload = {    // todoを追加する時に必要なのはtodoの内容くらい
    sidebarOpen: boolean;
};

export interface SideMenuAction extends Action {
    type: 'SIDE_MENU';
    payload: SideMenuPayload;
}

export const sideMenu = (payload: SideMenuPayload): SideMenuAction => {
    return {
        payload,
        type: 'SIDE_MENU',
    };
};