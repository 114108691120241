import React from 'react';

import CommonIconTitle from '../../../components/CommonIconTitle';

import './StoreDetailProperties.css';

import icon_titi from 'assets/icon_titi.png';
import icon_user from 'assets/icon_user.png';
import icon_calendar from 'assets/icon_calendar.png';
import icon_shape from 'assets/icon_shape.png';
import icon_location from 'assets/icon_location.png';
import icon_directions from 'assets/icon_directions.png';
import icon_bus from 'assets/icon_bus.png';
import icon_food from 'assets/icon_food.png';
import icon_price from 'assets/icon_price.png';
import icon_exclamation from 'assets/icon_exclamation.png';

import { Event } from '../../../helpers/schemas/FireStore';

interface EventDetailPropertiesProps {
    event: Event;
}

interface EventDetailPropertiesStates {

}

class EventDetailProperties extends React.Component<EventDetailPropertiesProps, EventDetailPropertiesStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="EventDetailProperties col-12">
                {this.props.event.organizer &&
                <CommonIconTitle
                    src={icon_titi}
                    text={'主催者：' + this.props.event.organizer}
                />
                }
                {this.props.event.community_name &&
                <CommonIconTitle
                    src={icon_user}
                    text={'コミュニティ：' + this.props.event.community_name}
                />
                }
                {this.props.event.date_detail &&
                <CommonIconTitle
                    src={icon_calendar}
                    tel={this.props.event.date_detail}
                />
                }
                {this.props.event.participant_count &&
                <CommonIconTitle
                    src={icon_shape}
                    text={'参加者数 ' + this.props.event.participant_count + '名'}
                />
                }
                {this.props.event.access &&
                <CommonIconTitle
                    src={icon_location}
                    text={this.props.event.access}
                />
                }
                {this.props.event.address &&
                <CommonIconTitle
                    src={icon_directions}
                    text={this.props.event.address}
                    map={this.props.event.address}
                />
                }
                {this.props.event.station &&
                <CommonIconTitle
                    src={icon_bus}
                    text={this.props.event.station}
                />
                }
                {this.props.event.course &&
                <CommonIconTitle
                    src={icon_food}
                    text={this.props.event.course}
                />
                }
                {this.props.event.course &&
                <CommonIconTitle
                    src={icon_price}
                    text={'参加費：' + this.props.event.course + ' 円'}
                />
                }
                {this.props.event.precaution &&
                <CommonIconTitle
                    src={icon_exclamation}
                    text={this.props.event.precaution}
                />
                }
            </div>
        );
    }
}

export default EventDetailProperties;