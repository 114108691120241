import React from 'react';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';

import './Modal.css';
import FontAwesome from "react-fontawesome";

interface ModalProps {
    show: boolean;
    onClose: (e: any) => void;
    title?: string;
    hiddenRightButton?: boolean;
}

interface ModalStates {

}

class Modal extends React.Component<ModalProps, ModalStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        // console.log('Modalprops', this.props);

        return (
            this.props.show ?
                <div className="Modal">
                    <div className="modal-box">
                        <header>
                            <div className="row Header">
                                <div className="col-3 left-button cursor" onClick={this.props.onClose}>
                                    <p>閉じる</p>
                                </div>
                                <div className="col-6 title">
                                    {this.props.title || ''}
                                </div>
                                {!this.props.hiddenRightButton &&
                                <div className="col-3 right-button cursor" onClick={this.props.onClose}>
                                    <p>決定</p>
                                </div>
                                }
                            </div>
                        </header>
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                </div> : null
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Modal);