import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_COMMUNITIE} from '../../helpers/StorageHelper';

import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';

import Home from './Home';
import Congregate from '../congregate/Congregate';

interface HomeProps {
    history: any;
    setCurrentUser: (name: string, token: string) => void;
    currentUser: {
        communities?: {
            id: string;
            name: string;
            communities_users: {}[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface HomeState {
    index: number;
}

const SELECTED_HOME_MENU_KEY = 'SELECTED_HOME_MENU_KEY';

class HomeSwiper extends React.Component<HomeProps, HomeState> {

    // TOOD フィード向けに改修する
    tabs = [
        {
            title: 'イベント',
            component: <Congregate {...this.props}/>,
        },
        {
            title: 'コミュニティ一覧',
            component: <Home {...this.props}/>,
        },
    ];

    constructor(props: any) {
        super(props);


        // コミュニティ選択を解除
        StorageHelper.remove(CURRENT_COMMUNITIE);

        // キャッシュがあれば適用
        let state = StorageHelper.getData(SELECTED_HOME_MENU_KEY, true);
        if (state) {
            this.state = state
        } else {
            this.state = {
                index: 0,
            };
        }
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    render() {

        // console.log('Home props', this.props);
        // console.log('Home state', this.state);

        var buttons = [];
        for (let c of this.tabs) {
            buttons.push(c.title);
        }

        return (
            <div className="HomeSwiper row">
                <Header
                    title={'ホーム'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                <CommonSegmentButton lists={buttons} index={this.state.index} onChange={(index) => {
                    this.setState({
                        index,
                    }, () => {
                        StorageHelper.setData(SELECTED_HOME_MENU_KEY, this.state, true);
                    });
                }}/>
                {this.tabs[this.state.index].component}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeSwiper);