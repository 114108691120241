import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {PREF_LIST} from '../../Construct.js';

import Header from '../../components/Header';
import TagsInput from 'react-tagsinput';
import StorageHelper, {CURRENT_USER, USER_COMMUNITIES} from '../../helpers/StorageHelper';
import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import UsersCommunityApi from '../../helpers/api/UsersCommunityApi';
import UserManagementApi from '../../helpers/api/UserManagementApi';
import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';

import './CommunityProfile.css';
import btn_add_image from 'assets/btn_add_image.png';
import btn_camera from 'assets/btn_camera.png';

interface CommunityProfileProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showSpinner: (show: boolean) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: any;
}

interface CommunityProfileStates {
    render: boolean;
    is_edit: boolean;
    community_name: string,

    nickname: string;
    introduction: string;
    tag_list: string[];
    tag_word: string,

    community?: {
        name?: string;
        description?: string;
        communities_users?: [];
        community_image?: {
            image: {
                content: {
                    url: string;
                }
            }
        } | null;
    };

    communities_user?: {
        role: string;
    };
}

/**
 * プロフィール登録・更新
 */
class CommunityProfile extends React.Component<CommunityProfileProps, CommunityProfileStates> {

    private form: any;

    constructor(props: any) {
        super(props);

        this.state = {
            is_edit: true,
            render: false,
            community_name: props.match.params.name,

            nickname: '',
            introduction: '',
            tag_list: [],
            tag_word: '',
        };
    }

    componentDidMount() {
        this.fetchCommunitiesShow();
    }

    /**
     * コミュニティ詳細取得
     */
    fetchCommunitiesShow = () => {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommunitiesShow(
            this.state.community_name,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesShow', responseJson);

                var is_regist = true;
                for (let cu of responseJson.communities_users) {
                    if (cu.user_id && cu.user_id == this.props.currentUser.user_id) {
                        is_regist = false;
                    }
                }

                this.setState({
                    community: responseJson,
                });

                this.fetchCommunitiesUsersShow();
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * コミュニティプロフィール取得
     */
    fetchCommunitiesUsersShow = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersShow(
            this.state.community_name,
            currentUser.user_id,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesUsersShow', responseJson);

                this.props.showSpinner(false);
                this.setState({
                    render: true,
                    communities_user: responseJson,
                    nickname: responseJson.nickname,
                    introduction: responseJson.introduction,
                    tag_list: responseJson.introduction_tag_list,
                });
            },
            (error: any) => {

                console.error('_fetchCommunitiesUsersShow', error);

                this.props.showSpinner(false);
                // TODO 新規登録と編集を厳密にする必要がある。
                if (error != '指定されたコミュニティに所属していません。') {
                    alert(error);
                } else {
                    this.setState({
                        is_edit: false,
                    });
                }
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * コミュニティプロフィール作成
     */
    fetchCommunitiesUsersCreate = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersCreate(
            this.state.community_name,
            {
                nickname: this.form.nickname.value,
                introduction: this.form.introduction.value,
                introduction_tag_list: this.state.tag_list,
            },
            (responseJson: any) => {
                // console.log('_fetchCommunitiesUsersCreate', responseJson);
                this.props.showSpinner(false);
                this.setState({
                    communities_user: responseJson,
                    nickname: responseJson.nickname,
                    introduction: responseJson.introduction,
                });
                this.props.showDialog(
                    "確認",
                    `コミュニティプロフィールを登録しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        this.props.history.push(`/community/${this.state.community_name}`);
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    },
                    null,
                );

            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    /**
     * コミュニティプロフィール編集
     */
    fetchCommunitiesUsersEdit = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersEdit(
            this.state.community_name,
            {
                nickname: this.form.nickname.value,
                introduction: this.form.introduction.value,
                introduction_tag_list: this.state.tag_list,
            },
            (responseJson: any) => {
                // console.log('_fetchCommunitiesUsersEdit', responseJson);
                this.props.showSpinner(false);
                this.props.showDialog(
                    "確認",
                    `コミュニティプロフィールを保存しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        this.fetchUsers(`/community/${this.state.community_name}/detail`);
                    },
                    null,
                );
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
        if (this.state.communities_user) {
            this.fetchCommunitiesUsersEdit();
        } else {
            this.fetchCommunitiesUsersCreate();
        }
    }

    handleTagChange = (tag_list: string[]) => {
        this.setState({tag_list});
    }

    fetchUsers = (path: string) => {

        this.props.showSpinner(true);

        // 所属コミュニティ
        UsersCommunityApi._fetchUsersCommunity(
            {},
            (responseJson: any) => {

                // console.log('_fetchUsersCommunity3 responseJson', responseJson);
                this.props.showSpinner(false);
                var currentUser = this.props.currentUser;
                currentUser.communities = responseJson.communities;
                this.props.setCurrentUser(currentUser);

                StorageHelper.setData(USER_COMMUNITIES, responseJson.communities);
                this.props.history.push(path);
            },
            (error: any) => {
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    render() {

        let {nickname, introduction} = this.state;

        return (
            <div className="CommunityProfile row">

                <Header
                    title={'コミュニティプロフィール編集'}
                    leftButtonTitle="戻る"
                    leftButtonAction={() => {
                        this.props.history.goBack();
                    }}
                />

                { this.state.render &&
                <div className="col-12 detail">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.form = el && el.elements}
                    >
                        <label htmlFor="nickname">表示名</label>
                        <input name="nickname" type="text" className="signup-input" value={nickname} placeholder="表示名を入力" onChange={(e) => this.setState({nickname: e.target.value})} required maxLength={20}></input>

                        <label htmlFor="tag_list">自己紹介タグ</label>
                        <TagsInput
                            className='form-input-text input-tags'
                            value={this.state.tag_list}
                            onChange={this.handleTagChange}
                            inputProps={{placeholder: '入会年度や属性を入力'}}
                            inputValue={this.state.tag_word}
                            onChangeInput={(tag_word: string) => {
                                // androidで改行が検知できないため半角スペースでタグ追加
                                if (tag_word.endsWith(' ')) {
                                    var tag_list = this.state.tag_list;
                                    tag_list.push(tag_word);
                                    this.setState({
                                        tag_list,
                                        tag_word: '',
                                    });
                                } else {
                                    this.setState({
                                        tag_word,
                                    });
                                }
                            }}
                            onlyUnique
                            addOnBlur
                        />

                        <label htmlFor="introduction">自己紹介</label>
                        <input name="introduction" type="text" className="signup-input" value={introduction} placeholder="自己紹介を入力" onChange={(e) => this.setState({introduction: e.target.value})} maxLength={255}></input>

                        <button type="submit" className="btn btn-secondary">登録</button>

                    </form>
                </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            // console.log('setCurrentUser', data)
            // console.log('setCurrentUser', data)
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityProfile);
