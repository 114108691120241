import {Action} from 'redux';

export type DialogPayload = {
    show: boolean;
    title?: string;
    text?: string;
    onClickYes?: (e: any) => void;
    onClickNo?: (e: any) => void;
};

export interface ShowAction extends Action {
    type: 'SHOW_DIALOG';
    payload: DialogPayload;
}

export const showDialog = (payload: DialogPayload): ShowAction => {

    // console.log('showDialog');

    return {
        payload,
        type: 'SHOW_DIALOG',
    };
};

export interface HideAction extends Action {
    type: 'HIDE_DIALOG';
}

export const hideDialog = (): HideAction => {

    // console.log('showDialog');

    return {
        type: 'HIDE_DIALOG',
    };
};