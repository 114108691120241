import React from 'react';
import Header from '../../components/Header';

import './Slide.css';

import signup_logo from 'assets/signup_logo.png';
import slide1 from 'assets/signup/slide1.png';
import slide2 from 'assets/signup/slide2.png';
import slide3 from 'assets/signup/slide3.png';
import slide4 from 'assets/signup/slide4.png';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import Swiper from 'react-id-swiper';

interface Props {
    history: any;
    setCurrentUser: (name: string, token: string, user_id: string) => void;
    showSpinner: (show: boolean) => void;
}

interface State {
    index: number,
    swiper: any | null,
}

class Slide extends React.Component<Props, State> {

    views = [
        <div key={'0'}>
            <img src={slide1} className="logo"/>
        </div>,
        <div key={'1'}>
            <img src={slide2} className="logo"/>
        </div>,
        <div key={'2'}>
            <img src={slide3} className="logo"/>
        </div>,
        <div key={'3'}>
            <img src={slide4} className="logo"/>
        </div>,
    ];

    texts = [
        'TiTi のイベントはぴったりの人数や\n◯人以上でお店に行くとお得があります。',
        '幹事がアカウントをつくってお店とコースを\n選んだら、URLをあつめたい人にシェア。',
        'あつまる人は内容をチェックして\n参加ボタンを押すだけ。',
        '当日参加した人たちのコミュニティに\n支払い金額の一部が還元されるから\nまた、あつまりやすくなります。',
    ]

    constructor(props: any) {
        super(props);

        this.state = {
            index: 0,
            swiper: null,
        };
    }

    getSwiper = (swiper: any) => {
        this.setState({
            swiper: swiper,
        });
    }

    handleNext = (e: any) => {
        if (this.state.index == this.views.length - 1) {
            // 次の画面
            this.props.history.push('/signup?t=non');
        } else {
            this.setState({
                index: this.state.index + 1,
            });
            this.state.swiper.slideNext();
        }
    };

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        const params = {
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            on: {
                slideChange: () => {
                    if (this.state.swiper) {
                        this.setState({
                            index: this.state.swiper.activeIndex,
                        });
                    }
                },
            }
        }

        return (
            <div className="Slide row">

                <Header
                    title={''}
                    rightButtonTitle="Skip"
                    rightButtonAction={() => {
                        this.props.history.push('/signup?t=non');
                    }}
                />

                <div className="col-12">
                    <Swiper
                        activeSlideKey={`${this.state.index}`}
                        getSwiper={this.getSwiper}
                        {...params}
                    >
                        {this.views}
                    </Swiper>
                </div>
                <div className="col-12">
                    <p>{this.texts[this.state.index]}</p>
                </div>

                <div className="col-12">
                    <button type="button" className="btn btn-congregate" onClick={this.handleNext}>
                        次へ
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Slide);