import React from 'react';

import './CommonUserInfo.css';
import CommonImage from './CommonImage'

const CommonUserInfo = ({ upperText, descripion, imageUrl, className }: { upperText?: string, descripion?: string, imageUrl?: string, className?: string }) => (
    <div className={`CommonUserInfo ${className}`}>
        <CommonImage className='user-info-image' src={imageUrl} />
        <div className='user-info-cell-right'>
            <div className='user-info-name tail-text'>{upperText}</div>
            <div className='user-info-light-description tail-text'>{descripion}</div>
        </div>
    </div>)

export default CommonUserInfo;
