import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper from '../../helpers/StorageHelper';

import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';

import CommunityProductList from '../product/CommunityProductList';
import CommunityFeed from '../feed/CommunityFeed';
import Congregate from '../congregate/Congregate';

import './CommunityHomeSwiper.css';

interface CommunityHomeProps {
    history: any;
    setCurrentUser: (name: string, token: string) => void;
    currentUser: {
        communities?: {
            id: string;
            name: string;
            communities_users: {}[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    location: {
        pathname: string;
    }
    match: {
        url: string;
        params: {
            name: string;
        }
    }
}

interface ComminityHomeState {
    index: number;
    communityName: string,
}

const SELECTED_COMMUNITY_HOME_MENU_KEY = 'SELECTED_COMMUNITY_HOME_MENU_KEY';

class ComminityHomeSwiper extends React.Component<CommunityHomeProps, ComminityHomeState> {

    // TOOD フィード向けに改修する
    tabs = [
        // {
        //     title: 'タイムライン',
        //     component: <CommunityFeed {...this.props} />,
        // },
        // {
        //     title: 'うる・かう',
        //     component: <CommunityProductList {...this.props} />,
        // },
        {
            title: 'イベント',
            component: <Congregate {...this.props}/>,
        }
    ];

    constructor(props: any) {
        super(props);

        // キャッシュがあれば適用
        let index = StorageHelper.getValue(SELECTED_COMMUNITY_HOME_MENU_KEY, true) || '0';
        this.state = {
            communityName: props.match.params.name,
            index: Number(index),
        };
        StorageHelper.remove(SELECTED_COMMUNITY_HOME_MENU_KEY, true);
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        // URL変更を検知
        if (this.props.location.pathname != prevProps.location.pathname && this.props.location.pathname == this.props.match.url) {
            window.location.reload();
        }
    }

    render() {

        // console.log('Home props', this.props);
        // console.log('Home state', this.state);

        var buttons = [];
        for (let c of this.tabs) {
            buttons.push(c.title);
        }

        return (
            <div className="CommunityHomeSwiper row">
                <Header
                    title={this.state.communityName}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                    rightButtonTitle={'招待'}
                    rightButtonAction={() => this.props.history.push(`/community/${this.state.communityName}/detail`)}>
                </Header>
                {/*<CommonSegmentButton lists={buttons} index={this.state.index} onChange={(index) => {*/}
                    {/*this.setState({*/}
                        {/*index,*/}
                    {/*}, () => {*/}
                        {/*StorageHelper.setValue(SELECTED_COMMUNITY_HOME_MENU_KEY, `${this.state.index}`, true);*/}
                    {/*});*/}
                {/*}}/>*/}
                <Congregate {...this.props}/>
                {/*{this.tabs[this.state.index].component}*/}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComminityHomeSwiper);