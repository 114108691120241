import React, {Component} from 'react';
import ReactDOM from 'react-dom';

import firebase, {auth} from "./firebase";
import {RUTER, PUBLIC_RUTER} from "./Config";
import Spinner from "./components/Spinner";
import Dialog from "./components/Dialog";
import Sidebar from "react-sidebar";

import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';

import StorageHelper, {CURRENT_USER, DEFAULT_PATH, SOURCE_VER, USER_COMMUNITIES, DEFAULT_HREF} from './helpers/StorageHelper';
import UserSessionApi from './helpers/api/UserSessionApi';
import UserManagementApi from './helpers/api/UserManagementApi';
import UsersCommunityApi from './helpers/api/UsersCommunityApi';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from './reducers';

// menu
import Menu from './containers/menu/Menu';

import 'App.css';
import 'bootstrap/dist/css/bootstrap.css';
import ImagePreview from './components/ImagePreview';

enum AuthStatus {
    Unknown,
    Login,
    Logout,
}

/**
 * 認証が必要ない場合に使用するRoute
 * ログイン状態の場合/homeにリダイレクトさせる。
 * @param component
 * @param isAuthenticated
 * @param rest
 * @returns {any}
 * @constructor
 */
const PublicRoute = ({component, isAuthenticated, ...rest}: any) => {
    const routeComponent = (props: any) => (
        !isAuthenticated
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/home'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

/**
 * 認証が必要な場合に使用するRoute
 * 未ログイン状態の場合/loginにリダイレクトさせる。
 * プロフィール未登録の場合/registにリダイレクトさせる。
 * @param component
 * @param isAuthenticated
 * @param rest
 * @returns {any}
 * @constructor
 */
const PrivateRoute = ({component, isAuthenticated, isRegistered, ...rest}: any) => {
    const routeComponent = (props: any) => (
        isAuthenticated
            ?
            isRegistered ? React.createElement(component, props)
                : <Redirect to={{pathname: '/regist'}}/>
            : <Redirect to={{pathname: '/signin'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

/**
 * 新規登録用Route
 * 未ログイン状態の場合/loginにリダイレクトさせる。
 * プロフィール未登録の場合/registにリダイレクトさせる。
 * @param component
 * @param isAuthenticated
 * @param rest
 * @returns {any}
 * @constructor
 */
const RegistRoute = ({component, isAuthenticated, isRegistered, ...rest}: any) => {
    // console.log(`RegistRoute isAuthenticated:${isAuthenticated} isRegistered:${isRegistered}`);
    const routeComponent = (props: any) => (
        isAuthenticated
            ?
            isRegistered ? <Redirect to={{pathname: '/home'}}/>
                : React.createElement(component, props)
            : <Redirect to={{pathname: '/signin'}}/>
    );
    return <Route {...rest} render={routeComponent}/>;
};

interface Props {
    currentUser: any;
    sideMenu: any;
    setCurrentUser: (data: any) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface State {
    sidebarOpen: boolean;
    user: any | null;
    render: boolean;
}

class Router extends React.Component<Props, State> {

    authStatus = AuthStatus.Unknown;
    // isFirstRender: boolean = true;

    constructor(props: any) {
        super(props);

        this.state = {
            sidebarOpen: false,
            user: null,
            render: false,
        };
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    componentDidMount() {

        let currentUser = StorageHelper.getData(CURRENT_USER);
        console.log('localStorage currentUser   : ', currentUser);
        this._onAuthStateChanged();

        this.revison();
    }

    /**
     * revison.jsonをアップデートすることで強制的にキャッシュクリアする
     */
    revison() {
        fetch('/revison.json')
            .then((response) => response.json())
            .then((responseJson) => {

                var source_ver = StorageHelper.getValue(SOURCE_VER, true);
                var required_ver = responseJson.ver;

                console.log('REQUIRED VER : ', required_ver);
                console.log('SOURCE VER   : ', source_ver);

                StorageHelper.setValue(SOURCE_VER, required_ver, true);

                if (Number(required_ver) > Number(source_ver)) {
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {

        // console.log('Router default path', defaultPath);
        // console.log('Router props', this.props);
        // console.log('Router state', this.state);

        // if (this.isFirstRender) {
        //     /*
        //      * 初回表示のみDEFAULT_PATHにパスを入れ認証後の画面遷移に利用する
        //      */
        //     var defaultPath = window.location.href;
        //     StorageHelper.setValue(DEFAULT_PATH, defaultPath, true);
        //     console.log('DEFAULT_PATH set', defaultPath);
        //
        //     this.isFirstRender = false;
        // }

        /**
         * 認証判定
         * トークンが保存されている場合認証されているとみなす
         * @type {boolean}
         */

        let user = StorageHelper.getData(CURRENT_USER);
        console.log('Router user', user);

        let isAuthenticated = user ? user.isAuthenticated : false;
        let isRegistered = user ? user.isRegistered : false;

        let menu = <Menu {...this.props}/>;

        if (!isAuthenticated && window.location.pathname != '/signin' && window.location.pathname != '/regist') {
            /*
             * 未ログイン時のみDEFAULT_PATHにパスを入れ認証後の画面遷移に利用する
             */
            var defaultPath = window.location.href;
            StorageHelper.setValue(DEFAULT_PATH, defaultPath, true);
            // console.log('DEFAULT_PATH set', defaultPath);
        }

        /**
         * PublicRoute : 認証がいらない画面に使用する
         * PrivateRoute : 認証が必要な場合に使用する
         * Route : 認証状態問わず表示できる
         */

        var list = [];
        PUBLIC_RUTER.forEach((r, index) => {
            list.push(<Route {...r} key={index}/>);
        })

        for (let r of RUTER) {
            if (r.path) {
                if (r.public) {
                    list.push(<PublicRoute isRegistered={isRegistered} isAuthenticated={isAuthenticated} {...r}/>);
                } else if (r.regist) {
                    list.push(<RegistRoute isRegistered={isRegistered} isAuthenticated={isAuthenticated} {...r}/>);
                } else {
                    list.push(<PrivateRoute isRegistered={isRegistered} isAuthenticated={isAuthenticated} {...r}/>);
                }
            } else {
                list.push(<Route component={r.component}/>);
            }
        }

        return (
            <div className="App container">
                <Spinner/>
                <ImagePreview/>
                <Dialog/>
                <BrowserRouter>
                    <Sidebar
                        sidebar={menu}
                        open={this.props.sideMenu.sidebarOpen}
                        onSetOpen={this.onSetSidebarOpen}
                        styles={{
                            sidebar: {
                                background: "white",
                                zIndex: '999',
                            }
                        }}
                    >
                        {this.state.render &&
                        <div className="container">
                            <Switch>
                                {list}
                                <Route component={NotFound}/>
                            </Switch>
                        </div>
                        }
                    </Sidebar>
                </BrowserRouter>
            </div>
        );
    }

    onSetSidebarOpen(open: boolean) {
        this.props.onSetSidebarOpen(open)
        // this.setState({sidebarOpen: open});
    }

    fetchUsers = () => {

        // console.log('fetchUsers');


        this.props.showSpinner(true);

        UserManagementApi._fetchUsers(
            this.state.user.user_id,
            {},
            (responseJsonUsers: any) => {

                // 所属コミュニティ
                UsersCommunityApi._fetchUsersCommunity(
                    {},
                    (responseJson: any) => {

                        // console.log('_fetchUsersCommunity3 responseJson', responseJson);

                        this.props.showSpinner(false);

                        this.props.setCurrentUser({
                            name: this.state.user.name,
                            token: this.state.user.access_token,
                            user_id: this.state.user.user_id,
                            avatar_url: responseJsonUsers.avatar_url,
                            profile: {
                                address: responseJsonUsers.profile.address,
                                birth: responseJsonUsers.profile.birth,
                                email: responseJsonUsers.profile.email,
                                first_name: responseJsonUsers.profile.first_name,
                                gender: responseJsonUsers.profile.gender,
                                last_name: responseJsonUsers.profile.last_name,
                                user_id: responseJsonUsers.profile.user_id,
                            },
                            communities: responseJson.communities,
                        });

                        StorageHelper.setData(USER_COMMUNITIES, responseJson.communities);

                        this.setState({
                            render: true,
                        }, () => {

                        });

                        //
                        // this.setState(() => ({
                        //     render: true,
                        //     communities: responseJson.communities,
                        // }));
                    },
                    (error: any) => {
                        alert(error);
                        this.setState({
                            render: true,
                        });
                    },
                );
            },
            (error: any) => {
                alert(error);
                firebase.auth().signOut();
                this.props.showSpinner(false);
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * Firebaseの認証状態を監視する
     */
    _onAuthStateChanged = () => {

        let currentUser = StorageHelper.getData(CURRENT_USER);
        console.log('localStorage currentUser   : ', currentUser);

        // 登録済みであればプロフィールを種取得する
        if (currentUser && currentUser.isRegistered) {
            this.setState({
                user: currentUser,
            }, () => {
                this.fetchUsers();
            });
        } else {
            this.props.showSpinner(false);
            StorageHelper.remove(CURRENT_USER);
            this.setState(() => ({
                user: null,
                render: true,
            }));
        }
    }
}

const NotFound = () => (<p>404 NotFound</p>);

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
        sideMenu: state.sideMenu.sideMenu,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Router);
