import React from 'react';
import './UserCell.css';
import no_image from 'assets/no_image.png';

interface UserCellProps {
    image?: string;
    name?: string;
    desc?: string;
}

interface UserCellStates {
    image?: any;
    name?: string;
    desc?: string;
}

class UserCell extends React.Component<UserCellProps, UserCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        var desc;
        if (this.props.desc) {
            desc = `${this.props.desc}年よりメンバー`;
        }

        return (
            <div className="UserCell col-12">
                <div className="img">
                    <img src={this.props.image || no_image} className="icon" alt=""/>
                </div>
                <div className="text">
                    {this.props.name &&
                    <p className="name">{this.props.name}</p>
                    }
                    {desc &&
                    <p>{desc}</p>
                    }
                </div>
            </div>
        );
    }
}

export default UserCell;
