import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import Header from '../../components/Header';
import firebase, {auth} from "../../firebase";

import CommonUserCell from '../../components/CommonUserCell';
import CommonCell from '../../components/CommonCell';

import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';

import no_image from 'assets/no_image.png';

import './CommunityAccount.css';

interface CommunityAccountProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
    };
    showSpinner: (show: boolean) => void;
}

interface CommunityAccountStates {
    render: boolean;
    community_name: string;
    user_id: string;
    communities_user?: {
        nickname: string;
        introduction: string;
        introduction_tag_list: string[];
        user?: {
            avatar_url: string;
        };
    };
}

class CommunityAccount extends React.Component<CommunityAccountProps, CommunityAccountStates> {

    menu = [
        // {
        //     title: 'メールアドレス・パスワード変更',
        //     onClick: () => {
        //         this.props.history.push(`/CommunityAccount/password`);
        //     }
        // },
    ]

    constructor(props: any) {
        super(props);
        this.state = {
            render: false,
            community_name: props.match.params.name,
            user_id: props.match.params.user_id,
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this._fetchCommunitiesUsersShow();
    }

    /**
     * ユーザー一覧取得
     */
    _fetchCommunitiesUsersShow = () => {

        this.props.showSpinner(true);

        CommunitiesUserApi._fetchCommunitiesUsersShow(
            this.state.community_name,
            this.state.user_id,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesUsersShow', responseJson);

                this.props.showSpinner(false);

                this.setState({
                    render: true,
                    communities_user: responseJson
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    render() {

        let image = no_image;
        if (this.state.communities_user && this.state.communities_user.user) {
            image = this.state.communities_user.user.avatar_url || no_image;
        }

        let user_name = this.state.communities_user ? this.state.communities_user.nickname : '';

        // var list = [];
        // for (let d of this.menu) {
        //     list.push(
        //         <li onClick={d.onClick} className="cursor">
        //             {d.title}
        //         </li>
        //     );
        // }

        var tag_list = [];
        if (this.state.communities_user && this.state.communities_user.introduction_tag_list) {
            for (let tag of this.state.communities_user.introduction_tag_list) {
                tag_list.push(
                    <p className="tag">
                        {tag}
                    </p>
                );
            }
        }

        return (
            <div className="CommunityAccount row">
                <Header
                    title={this.state.community_name}
                    leftButtonTitle="戻る"
                    leftButtonAction={() => {
                        this.props.history.goBack();
                    }}/>
                { this.state.render && this.state.communities_user &&
                <div className="col-12 user">
                    <img src={image} className="user-image"/>
                    <div className="user-name">
                        {user_name}
                    </div>
                </div>
                }

                { this.state.render && this.state.communities_user &&
                <div className="col-12 community-desc">
                    <p className="">{this.state.communities_user.introduction || ''}</p>
                </div>
                }

                { this.state.render && tag_list.length > 0 &&
                <div className="col-12 community-tag">
                    {tag_list}
                </div>
                }

                {/*
                <div className="col-12 community-invite">
                    <p className="title">{user_name}</p>
                    <CommonUserCell
                        image={no_image}
                        onClick={e => {
                            // TODO リンク
                        }}
                        title={'USERNAMSEさんに招待されました'}
                        sub_title={'2021年04月03日'}
                    />
                </div>

                <div className="col-12 community-event">
                    <p className="title">参加予定の最新イベント</p>
                    <CommonCell
                        date={'2021年04月03日'}
                        image={no_image}
                        title={'タイトル'}
                        location={'場所'}
                        onClick={() => {
                            // this.props.history.push('/event/' + event.id);
                        }}
                    />
                </div>
                 */}


                {/*{ this.props.currentUser.profile &&*/}
                {/*<div className="col-12 common">*/}
                {/*<ul>*/}
                {/*{list}*/}

                {/*</ul>*/}
                {/*</div>*/}
                {/*}*/}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityAccount);
