import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import * as serviceWorker from './serviceWorker';
import {StripeProvider} from 'react-stripe-elements';

import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import Router from './Router';
import {rootReducer} from './reducers';
import {STRIPE_PUBLISH_KEY} from './Construct.js';

const store = createStore(
    rootReducer,
    applyMiddleware(thunk, logger)
);

ReactDOM.render((
    <Provider store={store}>
        <StripeProvider apiKey={STRIPE_PUBLISH_KEY}>
            <Router/>
        </StripeProvider>
    </Provider>
), document.getElementById('root'));

serviceWorker.register();
