import React from 'react';
import BaseApi from './BaseApi';

export default class HistoryApi extends BaseApi {

    /**
     * GET /approvals
     */
    static _fetchApproval(params, success, error) {
        super._fetch(`/api/v1/approvals`, 'GET', params, success, error);
    }

    /**
     * GET /purchase_histories
     */
    static _fetchPurchaseHistories(params, success, error) {
        super._fetch(`/api/v1/purchase_histories`, 'GET', params, success, error);
    }

    /**
     * GET /now_on_sales
     */
    static _fetchNowOnSales(params, success, error) {
        super._fetch(`/api/v1/now_on_sales`, 'GET', params, success, error);
    }

    /**
     * GET /sales_histories
     */
    static _fetchSalesHistories(params, success, error) {
        super._fetch(`/api/v1/sales_histories`, 'GET', params, success, error);
    }
}
