import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import FontAwesome from "react-fontawesome";
import queryString from 'query-string';

import BenefitApi from '../../helpers/api/BenefitApi';

import no_image from 'assets/no_image.png';

import Header from '../../components/Header';
import Modal from '../../components/Modal';
import CommonUserCell from '../../components/CommonUserCell';

import './BenefitCreate.css';

interface BenefitCreateProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;

    currentUser: {
        // name: string;
        // token: string;
        user_id: string;
        // avatar_url?: string | null;
        // profile?: {
        //     address: string;
        //     birth: string;
        //     email: string;
        //     first_name: string;
        //     gender: string;
        //     last_name: string;
        //     user_id: number;
        // } | null;
        communities?: {
            id: string;
            name: string;
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
            communities_users: {
                id: number;
                user_id: string;
                nickname?: string;
                avatar_url?: string;
            }[];
        }[] | null;
    };

    // コミュニティ情報
    community?: {
        id: string;
        name: string;
        communities_users: {
            id: number;
            user_id: string;
            nickname?: string;
            avatar_url?: string;
        }[];
    };
}

interface BenefitCreateStates {

    show_user_list: boolean;

    community: {
        id: string;
        name: string;
        communities_users: {
            id: number;
            user_id: string;
            nickname?: string;
            avatar_url?: string;
        }[];
    } | null;
    selected_user_list: {
        id: number;
        user_id: string;
        nickname?: string;
        avatar_url?: string;
    }[]; // 選択済みユーザー

    community_name: string;
    title: string;
    description: string;
    price: string;
}

class BenefitCreate extends React.Component<BenefitCreateProps, BenefitCreateStates> {

    constructor(props: any) {
        super(props);

        // const values = queryString.parse(props.location.search);

        var community: any | null = null;
        for (let c of props.currentUser.communities) {
            if (c.name == props.match.params.name) {
                community = c;
                break;
            }
        }

        this.state = {
            show_user_list: false,
            community_name: props.match.params.name,
            title: '',
            description: '',
            price: '',
            community,
            selected_user_list: [],
        };
    }

    _handlePressSubmit = (e: any) => {
        e.preventDefault();

        // this.props.history.push('/benefit/1');

        this.props.showSpinner(true);

        var benefit_members = [];
        for (let cu of this.state.selected_user_list) {
            benefit_members.push(cu.id);
        }

        BenefitApi._fetchBenefitCreate(
            {
                community_name: this.state.community_name,
                title: this.state.title,
                description: this.state.description,
                applied_price: this.state.price,
                benefit_members: benefit_members,
                tag_list: [],
            },
            (responseJson: any) => {
                console.log('_fetchBenefitCreate', responseJson);

                this.props.showSpinner(false);

                if (responseJson.success) {
                    this.props.showDialog(
                        "確認",
                        `申請が完了しました。`,
                        (e: any) => {
                            this.props.hideDialog();
                            if (this.props.history.length > 0) {
                                this.props.history.goBack();
                            } else {
                                this.props.history.push('/');
                            }
                        },
                        null,
                    );
                }
            },
            (error: any) => {

                this.props.showSpinner(false);

                alert(error);
                // this.setState({
                //     stores: [],
                //     load_stores: false,
                // });
            },
        );
    }

    render() {

        // コミュニティのユーザーをセット(モーダル表示)
        let user_list = [];
        if (this.state.community) {

            for (let cu of this.state.community.communities_users) {
                // console.log('communities_users', cu);

                // 自分自身は選択させない
                if (this.props.currentUser.user_id == cu.user_id) {
                    continue;
                }

                var img = no_image;
                if (cu.avatar_url) {
                    img = cu.avatar_url;
                }

                user_list.push(
                    <CommonUserCell
                        active={this.state.selected_user_list.indexOf(cu) >= 0}
                        image={img}
                        onClick={e => {

                            if (this.state.selected_user_list.indexOf(cu) == -1) {
                                // 存在しないので追加
                                this.setState({
                                    selected_user_list: this.state.selected_user_list.concat([cu]),
                                });
                            } else {
                                // 削除
                                var selected_user_list = this.state.selected_user_list;
                                selected_user_list.splice(selected_user_list.indexOf(cu), 1);
                                this.setState({
                                    selected_user_list,
                                });
                            }
                        }}
                        title={cu.nickname || ''}
                    />
                );
            }
        }

        // 選択済みのユーザーをセット
        var selected_user_name_list = '';
        for (let cu of this.state.selected_user_list) {
            selected_user_name_list = selected_user_name_list + cu.nickname + ',';
        }

        return (
            <div className="BenefitCreate row">
                <Header
                    title={'給付金を申請する'}
                    leftButtonTitle={(this.props.history.length > 0) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            window.close();
                        }
                    }}
                />

                <div className="col-12 form-box">
                    <div className="row">
                        <div className="col-12 title">
                            説明
                        </div>
                        <div className='input-wrapper'>
                            <input className='form-input-text' placeholder={'タイトル(必須 40 文字まで)'} name="" value={this.state.title} onChange={(e) => this.setState({title: e.target.value})} type="text"/>
                        </div>
                        <div className='input-wrapper'>
                            <textarea className='form-input-text large' name="" placeholder="詳細" value={this.state.description} onChange={(e) => this.setState({description: e.target.value})}></textarea>
                        </div>

                        <div className="col-12 title">
                            給付金の詳細
                        </div>

                        <div className='input-wrapper' onClick={e => {
                            if (this.state.community && this.state.community.communities_users.length == 1) {
                                this.props.showDialog(
                                    "確認",
                                    `招待できるユーザーが存在しません。`,
                                    (e: any) => {
                                        this.props.hideDialog();
                                    },
                                    null,
                                );
                                return;
                            }
                            this.setState({show_user_list: true});
                        }}>
                            <label className='form-input-label'>メンバー</label>
                            <input className='form-input-text-with-label' placeholder={'未選択'} name="" value={selected_user_name_list} type="text"/>
                        </div>

                        <div className='input-wrapper'>
                            <label className='form-input-label'>投稿先コミュニティ</label>
                            <input className='form-input-text-with-label' placeholder={''} name="" value={this.state.community_name} type="text"/>
                        </div>

                        <div className="col-12 title">
                            希望給付金 (1,000~1,000,000円)
                        </div>

                        <div className='input-wrapper'>
                            <input className='form-input-text price' placeholder={'0'} name="" value={this.state.price} type="number" onChange={(e) => this.setState({price: e.target.value})}/>
                            <span className="float-right">円</span>
                        </div>

                        <div className="col-12">
                            <input
                                type="button"
                                className="w100 cursor btn"
                                value={'投稿する'}
                                onClick={this._handlePressSubmit}
                            />
                        </div>

                    </div>
                </div>

                <Modal show={this.state.show_user_list}
                       onClose={e => this.setState({show_user_list: false})}
                >
                    {user_list}
                </Modal>

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenefitCreate);
