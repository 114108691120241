import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Moment from 'moment';
import TalkHelper from '../../helpers/TalkHelper';

import './UserProductList.css';
import Header from '../../components/Header';
import CommonSegmentButton from '../../components/CommonSegmentButton';
import PurchaseDetailCell, {PurchaseProps} from './components/PurchaseDetailCell';
import no_image from 'assets/no_image.png';

import HistoryApi from '../../helpers/api/HistoryApi';
import ApprovalsApi from '../../helpers/api/ApprovalsApi';


enum Type {
    NowOnSales, // 出品中
    Approval, // 取引中
    SalesHistories, // 取引済み
}

interface UserProductListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface UserProductListStates {
    type: Type;
    purchases: PurchaseProps[],
}

class UserProductList extends React.Component<UserProductListProps, UserProductListStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            type: Type.NowOnSales,
            purchases: [],
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.fetch(Type.NowOnSales);
    }

    fetch = (type: Type) => {

        this.setState({
            type
        });

        if (type == Type.NowOnSales) {
            // 出品中
            this.props.showSpinner(true);
            HistoryApi._fetchNowOnSales(
                {},
                (responseJson: any) => {

                    this.props.showSpinner(false);
                    // レスポンス値が統一されていないので整形する
                    var purchases = [];
                    if (responseJson.products) {
                        for (let obj of responseJson.products) {
                            var item = {
                                id: parseInt(obj.id),
                                name: obj.title,
                                user_name: '',
                                name_desc: '',
                                start_time: obj.schedules.length > 0 ? Moment(obj.schedules[0].start_time).format('YYYY-MM-DD h:mm') : '',
                                value: `${obj.price}円`,
                                talk: false,
                                approval: false,
                                cancel: false,
                                product_image: obj.images[0].content.url,
                            }
                            purchases.push(item);
                        }
                    }
                    this.setState({
                        purchases
                    });
                },
                (error: any) => {

                    this.props.showSpinner(false);
                    alert(error);
                },
            );
        } else if (type == Type.Approval) {
            // 取引中
            this.props.showSpinner(true);
            HistoryApi._fetchApproval(
                {},
                (responseJson: any) => {

                    // console.log('_fetchApproval responseJson', responseJson);

                    this.props.showSpinner(false);

                    // レスポンス値が統一されていないので整形する
                    var purchases = [];
                    if (responseJson.charges) {
                        for (let obj of responseJson.charges) {

                            if (obj.capture != "uncaptured") {
                                continue;
                            }

                            var quantity;
                            if (obj.purchase.number_of_item) {
                                quantity = obj.purchase.number_of_people + `個`;
                            } else {
                                quantity = obj.purchase.number_of_people + `名`;
                            }

                            var item = {
                                id: parseInt(obj.id),
                                name: obj.purchase.product.title,
                                user_name: obj.purchase.communities_user.nickname,
                                name_desc: 'さんから購入申請がありました。',
                                date: Moment(obj.purchase.created_at).format('YYYY-MM-DD h:mm'),
                                quantity: quantity,
                                value: `${obj.amount}円`,
                                talk: false,
                                approval: true,
                                onApprovals: this.onApprovals,
                                cancel: false,
                                product_image: obj.purchase.product.images[0].content.url,
                            }

                            purchases.push(item);
                        }
                    }

                    this.setState({
                        purchases,
                    });
                },
                (error: any) => {

                    this.props.showSpinner(false);
                    alert(error);
                },
            );


        } else {
            // 取引済み
            this.props.showSpinner(true);
            HistoryApi._fetchSalesHistories(
                {},
                (responseJson: any) => {

                    // console.log('_fetchSalesHistories responseJson', responseJson);

                    this.props.showSpinner(false);

                    // レスポンス値が統一されていないので整形する
                    var purchases = [];
                    if (responseJson.charges) {
                        for (let obj of responseJson.charges) {

                            var quantity;
                            if (obj.purchase.number_of_item) {
                                quantity = obj.purchase.number_of_people + `個`;
                            } else {
                                quantity = obj.purchase.number_of_people + `名`;
                            }

                            var item = {
                                id: parseInt(obj.id),
                                name: obj.purchase.product.title,
                                user_name: obj.purchase.communities_user.nickname,
                                name_desc: '',
                                quantity: quantity,
                                value: `${obj.amount}円`,
                                talk: false,
                                approval: false,
                                cancel: false,
                            }
                            purchases.push(item);
                        }
                    }

                    this.setState({
                        purchases,
                    });
                },
                (error: any) => {

                    this.props.showSpinner(false);

                    alert(error);
                },
            );
        }
    }

    onApprovals = (charge_id: number, capture: boolean) => {
        // 購入申請
        this.props.showSpinner(true);
        ApprovalsApi._fetchApprovals(
            {
                charge_id: charge_id,
                capture: capture,
            },
            (responseJson: any) => {
                this.props.showSpinner(false);
                this.fetch(this.state.type);
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
            },
        );
    }

    render() {

        var list = [];
        for (let data of this.state.purchases) {
            list.push(
                <PurchaseDetailCell data={data}/>
            );
        }

        return (
            <div className="UserProductList row">
                <Header
                    title={'出品した商品'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                <CommonSegmentButton lists={['出品中', '取引中', '取引済み']} index={this.state.type} onChange={(i) => {
                    this.fetch(i);
                }}/>
                <div className="col-12">
                    {list}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProductList);
