import React from 'react';
import {connect} from 'react-redux'
import {STRIPE_PUBLISH_KEY} from '../../Construct.js';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import TalkHelper from '../../helpers/TalkHelper';

import ProductionApi from '../../helpers/api/ProductionApi';
import PurchasesApi from '../../helpers/api/PurchasesApi';
import UserSessionApi from '../../helpers/api/UserSessionApi';
import Moment from 'moment';

import './Purchase.css';
import Header from '../../components/Header';

// calendar
import Calendar, {CalendarTileProperties} from 'react-calendar';

// stripe
import StripeCheckout from 'react-stripe-checkout';

import no_image from 'assets/no_image.png';
import btn_add_image from 'assets/btn_add_image.png';

import FontAwesome from "react-fontawesome";

enum Type {
    Time,
    Goods,
}

interface PurchaseProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        avatar_url?: string | null;
        profile?: {
            first_name: string;
            last_name: string;
            user_id: number;
        } | null;
    };
}

interface ScheduleStates {
    id: number;
    production_id: any | null;
    date: any | null;
    format: string;
    start_time: any | null;
    end_time: any | null;
}

interface PurchaseStates {
    type: Type;
    isAddressShow: boolean;
    render: boolean;
    production_id: number;
    production: {
        title: string;
        notes: string | null;
        description: string | null;
        price: number;
        schedules: ScheduleStates[];
        community_id: number;
        user: {
            id: number,
            avatar_url: string,
            firebase_uid: string,
        }
        images?: {
            content: {
                url: string;
            };
        }[] | null;
    } | null;
    date: any | null;
    schedule: ScheduleStates | null;
    stripe: {
        id: string;
        type: string;
        card: {
            last4: string;
            brand: string;
        }
    } | null;
    users: string[];

    // モノ送付先情報
    isChangeAddress: boolean; // trueにすると送付先情報の上書きを確認する。
    address_name?: string;
    phone_number?: string;
    post_number?: string;
    address1?: string;
    address2?: string;
}

class Purchase extends React.Component<PurchaseProps, PurchaseStates> {

    private addressForm: any;

    constructor(props: any) {
        super(props);
        this.state = {
            type: Type.Time,
            isAddressShow: false,
            render: false,
            production_id: props.match.params.id,
            production: null,
            date: null,
            schedule: null,
            stripe: null,
            users: [],
            isChangeAddress: false,
        };
    }

    componentDidMount() {
        this.fetchProduct();
        this.fetchUsersProfilesShow();
    }


    // fetchStripeAccountShow = () => {
    //
    //     this.props.showSpinner(true);
    //
    //     StripeAccountApi._fetchStripeAccountShow(
    //         {},
    //         (responseJson: any) => {
    //             this.props.showSpinner(false);
    //             if (responseJson.details_submitted) {
    //                 this.fetchProduct();
    //             } else {
    //                 this.props.history.push(`/stripe`);
    //             }
    //         },
    //         (error: any) => {
    //             this.props.showSpinner(false);
    //             alert(error);
    //             this.props.history.push(`/home`);
    //         },
    //     );
    // }

    fetchProduct = () => {
        this.props.showSpinner(true);

        ProductionApi._fetchProductsShow(
            this.state.production_id,
            {},
            (responseJson: any) => {

                this.props.showSpinner(false);

                // スケジュールを扱いやすい形式に整形する
                if (responseJson.schedules) {
                    for (let obj of responseJson.schedules) {
                        obj.current_date = Moment(obj.start_time).format('YYYY-MM-DD');
                        obj.format = Moment(obj.start_time).format('YYYYMMDD');
                    }
                }

                // console.log('_fetchProductsShow responseJson', responseJson);
                this.setState({
                    type: responseJson.type_of_product == 'time' ? Type.Time : Type.Goods,
                    render: true,
                    production: responseJson
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
                this.props.history.push(`/home`);
            },
        );
    }

    /**
     * ユーザープロフィールを取得する
     * @private
     */
    fetchUsersProfilesShow() {

        let currentUser = StorageHelper.getData(CURRENT_USER);
        // console.log('localStorage currentUser   : ', currentUser);

        UserSessionApi._fetchUsersProfilesShow(
            currentUser.user_id,
            {},
            (responseJson: any) => {
                // console.log('_fetchUsersProfilesShow', responseJson);

                // 過去の取引から送付先データを取得する
                if (responseJson.shipping_addresses.length > 0) {

                    const addresses = responseJson.shipping_addresses[0];
                    // console.log('set address ', addresses);
                    this.setState({
                        address_name: addresses.shipping_address.name,
                        phone_number: addresses.shipping_address.phone_number,
                        post_number: addresses.shipping_address.post_number,
                        address1: addresses.shipping_address.address1,
                        address2: addresses.shipping_address.address2,
                    });
                } else {
                    this.setState({
                        isAddressShow: true,
                    });
                }
            },
            (error: any) => {
                this.props.showSpinner(false);
                // console.log('_fetchUsersProfilesShow error.', error);
                alert(error);
            },
        );
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
        this.setState({
            isAddressShow: false,
        });
    }

    onChange = (date: Date | Date[]) => {
        let d: Date = Array.isArray(date) ? date[0] : date;
        let target = Moment(d).format('YYYYMMDD');
        if (!this.state.production) {
            return null;
        }
        for (let schedule of this.state.production.schedules) {
            // console.log('schedule2', schedule);
            if (schedule.format == target) {
                this.setState({
                    date: d,
                    schedule: schedule,
                });
            }
        }
    }

    tileDisabled = (props: CalendarTileProperties) => {
        let target = Moment(props.date).format('YYYYMMDD');
        if (!this.state.production) {
            return true;
        }
        for (let schedule of this.state.production.schedules) {
            if (schedule.format == target) {
                return false;
            }
        }
        return true;
    }

    onToken = (stripe: any) => {
        // console.log('stripe onToken', stripe);
        this.setState({stripe});
    }

    onAddUser = (e: any) => {
        let user_name = window.prompt("ユーザー名を入力してください", "");

        if (user_name) {
            var users = this.state.users;
            users.push(user_name);
            this.setState({
                users: users,
                stripe: null,
            });
        }
    }

    onAddress = (e: any) => {
        // console.log('onAddress', this.state.isAddressShow);
        this.setState({
            isAddressShow: !this.state.isAddressShow,
        });
    }

    onRemoveUser = (index: number) => {
        let ret = window.confirm("ゲストを削除しますか");

        if (ret) {
            var users = this.state.users;
            users.splice(index, 1);
            this.setState({
                users: users,
                stripe: null,
            });
        }
    }

    onCreateRoom = () => {

        this.props.showSpinner(true);

        // チャットルーム作成
        TalkHelper._createRooms(
            this.state.production!.title,
            this.state.production!.community_id,
            [
                this.state.production!.user.id
            ],
            [
                this.state.production!.user!.firebase_uid,
            ],
            1,
            this.state.production!.user!.avatar_url,
            (room: any) => {
                // console.log('c room', room);
                this.props.showSpinner(false);
                this.props.history.push(`/talk/${room.key}`);
            },
            this.state.production_id,
        );
    }

    onSubmitPurchase = (e: any) => {

        if (this.state.type == Type.Time && !this.state.schedule && this.state.production!.schedules!.length > 0) {
            this.props.showDialog(
                "確認",
                `日時を選択してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }
        if (this.state.type == Type.Goods &&
            (!this.state.address_name || !this.state.phone_number || !this.state.post_number || !this.state.address1 || !this.state.address2)) {
            this.props.showDialog(
                "確認",
                `送付先情報を入力してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }
        this.setState({
            isAddressShow: !this.state.isAddressShow,
        });

        if (!this.state.stripe) {
            this.props.showDialog(
                "確認",
                `決済情報を入力してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        this.props.showDialog(
            "確認",
            `購入処理を送信します。`,
            (e: any) => {
                this.props.hideDialog();

                // 送信

                if (!this.state.stripe) {
                    return;
                }

                var changeAddress = false;
                if (this.state.type == Type.Goods && this.state.isChangeAddress) {
                    changeAddress = window.confirm("新しい送付先情報を保存しますか？");
                }

                // req param
                var param: {
                    stripe_card_token?: string;
                    number_of_people?: number;
                    product_schedule_id?: number;
                    number_of_item?: number;
                    save_flag?: boolean;
                    shipping_address_attributes?: {
                        name?: string;
                        phone_number?: string;
                        post_number?: string;
                        address1?: string;
                        address2?: string;
                    },
                } = {};

                if (this.state.type == Type.Time) {
                    param = {
                        stripe_card_token: this.state.stripe.id,
                        // 時間
                        number_of_people: 1,
                        product_schedule_id: 0,
                    }
                    if (this.state.schedule) {
                        param.product_schedule_id = this.state.schedule.id;
                    }
                } else {
                    param = {
                        stripe_card_token: this.state.stripe.id,
                        save_flag: changeAddress,
                        number_of_item: 1,
                        shipping_address_attributes: {
                            name: this.state.address_name,
                            phone_number: this.state.phone_number,
                            post_number: this.state.post_number,
                            address1: this.state.address1,
                            address2: this.state.address2,
                        },
                    }
                }

                this.props.showSpinner(true);

                PurchasesApi._fetchPurchases(
                    this.state.production_id,
                    param,
                    (responseJson: any) => {

                        if (responseJson.success) {
                            this.props.showDialog(
                                "確認",
                                `購入が完了しました。`,
                                (e: any) => {
                                    this.props.hideDialog();
                                },
                                null,
                            );
                        } else {
                            this.props.showDialog(
                                "確認",
                                `購入に失敗しました。`,
                                (e: any) => {
                                    this.props.hideDialog();
                                },
                                null,
                            );
                        }

                        // TODO ログ送信
                        // this.props.history.push(`/products/${this.state.production_id}`);
                        this.onCreateRoom();
                    },
                    (error: any) => {
                        this.props.showSpinner(false);
                        alert(error);
                    },
                );

            },
            (e: any) => {
                this.props.hideDialog();
            },
        );
    }

    render() {

        // console.log('render type', this.state.type);
        // console.log('props', this.props);
        // console.log('state', this.state);

        let user = StorageHelper.getData(CURRENT_USER);

        var image = no_image;
        if (this.state.production && this.state.production.images) {
            image = this.state.production.images[0].content.url;
        }

        let user_image = this.props.currentUser.avatar_url || no_image;
        let user_name = this.props.currentUser.profile ? `${this.props.currentUser.profile.last_name} ${this.props.currentUser.profile.first_name}` : '';

        let price: number = this.state.production ? this.state.production.price : 0;
        let num: number = this.state.users.length + 1;
        let sum = price * num;

        let title = this.state.production ? this.state.production.title : '';
        let description = this.state.production ? this.state.production.description : '';
        let notes = this.state.production ? this.state.production.notes : '';

        let start_time = this.state.schedule ? Moment(this.state.schedule.start_time).format('YYYY-MM-DD h:mm:ss') : '';
        let end_time = this.state.schedule ? Moment(this.state.schedule.end_time).format('YYYY-MM-DD h:mm:ss') : '';

        // users
        var list = [];
        var i = 0;
        for (let i in this.state.users) {
            let user_name = this.state.users[i];
            list.push(
                <div className="col-12 cell cursor" onClick={(e: any) => this.onRemoveUser(parseInt(i))}>
                    <img src={image} className="member-image"/>
                    <div className="member-box">
                        <p className="member-name">{user_name}</p>
                    </div>
                </div>
            );
        }

        // 送付先住所
        const address_name = this.state.address_name;
        const phone_number = this.state.phone_number;
        const post_number = this.state.post_number;
        const address1 = this.state.address1;
        const address2 = this.state.address2;

        return (
            <div className="Purchase row">

                <Header
                    title={'購入'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                />

                { this.state.render &&
                <div className="col-12 detail">
                    <div className="row">
                        <div className="col-3">
                            <img src={image} className="detail-image"/>
                        </div>
                        <div className="col-9">
                            <h1>{title || ''}</h1>
                            <p>{description || ''}</p>
                        </div>
                    </div>
                </div>
                }


                { this.state.type == Type.Time && this.state.render && this.state.production && this.state.production!.schedules!.length > 0 &&
                <div className="col-12 date">
                    <p className="title">日程を決めましょう</p>
                    <Calendar
                        onChange={this.onChange}
                        value={this.state.date}
                        tileDisabled={this.tileDisabled}
                    />
                    { this.state.schedule &&
                    <ul>
                        <li>開始日時：{start_time}</li>
                        <li>終了日時：{end_time}</li>
                    </ul>
                    }
                </div>
                }
                { this.state.type == Type.Time && this.state.render && this.state.production &&
                <div className="col-12 desc">
                    <p>※日程の候補がない場合、下記のボタンからメッセージで問い合わせましょう。</p>
                    <button type="submit" className="btn btn-secondary" onClick={(e: any) => this.onCreateRoom()}>希望の日時を問い合わせる</button>
                    { notes!.length > 0 &&
                    <p className="title">出品者より</p>
                    }
                    { notes!.length > 0 &&
                    <p className="notes">{notes}</p>
                    }
                </div>
                }

                { this.state.type == Type.Time && this.state.render && this.state.production!.schedules!.length > 0 &&
                <div className="col-12 member">

                    <p className="title">お連れの方は？</p>

                    <div className="row">
                        <div className="col-12 cell">
                            <img src={user_image} className="member-image"/>
                            <div className="member-box">
                                <p className="member-name">{user_name}</p>
                            </div>
                        </div>
                        {list}
                        <div className="col-12 cell cursor" onClick={this.onAddUser}>
                            <img src={btn_add_image} className="member-image"/>
                            <div className="member-box">
                                <p className="member-name">ゲストを追加する</p>
                            </div>
                        </div>
                    </div>
                </div>
                }

                { this.state.render && ( this.state.type == Type.Goods || this.state.production!.schedules!.length > 0) &&
                <div className="col-12 payment">

                    <p className="title">支払情報</p>

                    <div className="row cell">
                        <div className="col-7">
                            { this.state.stripe &&
                            <p>**** **** **** {this.state.stripe.card.last4}</p>
                            }

                        </div>
                        <div className="col-5">
                            <StripeCheckout
                                token={this.onToken}
                                stripeKey={STRIPE_PUBLISH_KEY}
                                image="https://stripe.com/img/documentation/checkout/marketplace.png"
                                name={title || ''}
                                panelLabel="購入"
                                amount={sum}
                                currency="JPY"
                                locale="ja"
                            />
                        </div>
                    </div>
                    {  this.state.type == Type.Time &&
                    <div className="row cell">
                        <div className="col-6">
                            <p>￥{price.toLocaleString()} x {num}名</p>
                        </div>
                        <div className="col-6 right">
                            <p>￥{sum.toLocaleString()}</p>
                        </div>
                    </div>
                    }

                    <div className="row cell">
                        <div className="col-6">
                            <p>合計金額</p>
                        </div>
                        <div className="col-6 right">
                            <p>￥{sum.toLocaleString()}</p>
                        </div>
                    </div>

                    <div className="row space">
                    </div>

                    { this.state.type == Type.Goods &&
                    <div className="row cell" onClick={this.onAddress}>
                        <div className="col-3 title">
                            <p>送付先</p>
                        </div>
                        { !this.state.isAddressShow &&
                        <div className="col-8 right address">
                            <p>{address_name}</p>
                            <p>{phone_number}</p>
                            <p>{post_number ? '〒' : ''}{post_number}</p>
                            <p>{address1}{address2}</p>
                        </div>
                        }
                        { this.state.isAddressShow &&
                        <div className="col-8 right address">
                        </div>
                        }
                        <div className="col-1 right arrow">
                            <p>
                                <FontAwesome name={this.state.isAddressShow ? 'chevron-down' : 'chevron-right'}/>
                            </p>
                        </div>
                    </div>
                    }
                    { this.state.type == Type.Goods && this.state.isAddressShow &&
                    <div className="row cell">

                        <div className="col-12 address-inputs">
                            <form
                                onSubmit={this.handleFormSubmit.bind(this)}
                                ref={el => this.addressForm = el && el.elements}
                            >
                                <label htmlFor="first_name">送付先氏名</label>
                                <input name="name" type="text" className="signup-input" placeholder="" value={address_name} onChange={(e) => this.setState({address_name: e.target.value, isChangeAddress: true})}></input>
                                <label htmlFor="first_name">電話番号</label>
                                <input name="phone_number" type="text" className="signup-input" placeholder="" value={phone_number} onChange={(e) => this.setState({phone_number: e.target.value, isChangeAddress: true})}></input>
                                <label htmlFor="first_name">郵便番号</label>
                                <input name="post_number" type="text" className="signup-input" placeholder="" value={post_number} onChange={(e) => this.setState({post_number: e.target.value, isChangeAddress: true})}></input>
                                <label htmlFor="first_name">住所１</label>
                                <input name="address1" type="text" className="signup-input" placeholder="〇県〇市番地" value={address1} onChange={(e) => this.setState({address1: e.target.value, isChangeAddress: true})}></input>
                                <label htmlFor="first_name">住所２</label>
                                <input name="address2" type="text" className="signup-input" placeholder="建物・部屋番号" value={address2} onChange={(e) => this.setState({address2: e.target.value, isChangeAddress: true})}></input>
                            </form>
                        </div>
                    </div>
                    }

                    <button type="submit" className="btn btn-secondary" onClick={this.onSubmitPurchase}>購入</button>
                </div>
                }
            </div>
        )
            ;
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Purchase);
