import {Action} from 'redux';

export type ImagePreviewPayload = {
    show: boolean;
    images: string[];
    selectedIndex: number;
};

export interface ImagePreviewAction extends Action {
    type: 'SHOW_IMAGE_PREVIEW';
    payload: ImagePreviewPayload;
}

export const imagePreview = (payload: ImagePreviewPayload): ImagePreviewAction => {
    return {
        payload,
        type: 'SHOW_IMAGE_PREVIEW',
    };
};