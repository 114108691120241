import React from 'react';

import './CommonStars.css';
const StarRatings = require("react-star-ratings").default;

interface CommonStarsProps {
    numberOfStars: number,
    rating: number,
    changeRating?: (rating: number)=>void,
    starDimension: string,
    starSpacing: string,
    className?: string,
}

interface CommonStarsStates {

}

class CommonStars extends React.Component<CommonStarsProps, CommonStarsStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={`CommonStars ${this.props.className}`}>
                <StarRatings 
                     numberOfStars={this.props.numberOfStars}
                     rating={this.props.rating}
                     changeRating={this.props.changeRating}
                     starHoverColor={'#F9CD16'}
                     starRatedColor={'#F9CD16'}
                     starDimension={this.props.starDimension}
                     starSpacing={this.props.starSpacing}
                />
            </div>
        );
    }
}

export default CommonStars;
