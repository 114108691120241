import React from 'react';

import './PurchaseDetailCell.css';
import no_image from 'assets/no_image.png';


export interface PurchaseProps {
    id: number;
    name: string;
    name_desc: string;
    user_name: string;
    date?: string;
    start_time?: string;
    quantity?: string;
    value?: string;
    product_image?: string;
    talk: boolean;
    onCreateRoom?: (production_id: number, title: string, user_id: number, uid: string, avatar_url: string) => void;
    approval: boolean;
    onApprovals?: (charge_id: number, capture: boolean) => void;
    cancel: boolean;
    onCancel?: (purchase_id: number) => void;

    // talk用
    user_id?: number;
    uid?: string;
}

interface PurchaseDetailCellProps {
    data: PurchaseProps;
}

interface PurchaseDetailCellStates {

}

class PurchaseDetailCell extends React.Component<PurchaseDetailCellProps, PurchaseDetailCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        const {data} = this.props;

        return (
            <div className="row cell PurchaseDetailCell">
                <div className="col-12 cursor">
                    <div className="row product">
                        <div className="col-12 cursor">
                            <div className="row product">

                            </div>
                            <div className="product-image">
                                <img src={data.product_image || no_image}/>
                            </div>
                            <div className="product-box">
                                <p className="title"><span className="orange">{data.user_name}</span>{data.name_desc}</p>
                                <p className="date">{data.date}</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="desc">{data.name}</p>
                        </div>
                        { data.start_time && data.start_time.length > 0 &&
                        <div className="col-6">
                            <p className="sub-title">日時</p>
                        </div>
                        }
                        { data.start_time && data.start_time.length > 0 &&
                        <div className="col-6">
                            <p className="sub-desc">{data.start_time}</p>
                        </div>
                        }
                        <div className="col-6">
                            <p className="sub-title">合計金額</p>
                        </div>
                        <div className="col-6">
                            <p className="sub-desc">{data.quantity ? data.quantity + '/' : ''} {data.value}</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 buttons">
                    { data.talk &&
                    <button type="button" className="btn btn-nomal" onClick={(e) => {
                        if (data.onCreateRoom && data.user_id && data.uid && data.product_image) {
                            data.onCreateRoom(data.id, data.name, data.user_id, data.uid, data.product_image);
                        }
                    }}>メッセージを送る
                    </button>
                    }
                    { data.approval &&
                    <button type="button" className="btn btn-orange" onClick={(e) => {
                        if (data.onApprovals) {
                            data.onApprovals(data.id, true);
                        }
                    }}>承認
                    </button>
                    }
                    { data.approval &&
                    <button type="button" className="btn btn-nomal" onClick={(e) => {
                        if (data.onApprovals) {
                            data.onApprovals(data.id, false);
                        }
                    }}>否認
                    </button>
                    }
                    { data.cancel &&
                    <button type="button" className="btn btn-nomal" onClick={(e) => {
                        if (data.onCancel) {
                            data.onCancel(data.id);
                        }
                    }}>キャンセル
                    </button>
                    }
                </div>
            </div>
        );
    }
}

export default PurchaseDetailCell;
