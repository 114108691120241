export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_COMMUNITIE = "CURRENT_COMMUNITIE";
export const DEFAULT_PATH = "DEFAULT_PATH";
export const SOURCE_VER = "SOURCE_VER";
export const USER_COMMUNITIES = "USER_COMMUNITIES";
export const DEFAULT_HREF = "DEFAULT_HREF"; // ログイン後の強制遷移先。window.location.hashの値を保存する。

export default class StorageHelper {

    static setValue(key: string, value: string, isSession: boolean = false) {
        // console.log(`StorageHelper setValue key:${key} value:${value}`);
        if (isSession) {
            window.sessionStorage.setItem(key, value);
        } else {
            window.localStorage.setItem(key, value);
        }
    }

    static getValue(key: string, isSession: boolean = false) {
        // console.log('StorageHelper getValue', key);
        return isSession ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
    }

    static setData(key: string, data: any, isSession: boolean = false) {
        // console.log(`StorageHelper setData key:${key}`, data);
        if (isSession) {
            window.sessionStorage.setItem(key, JSON.stringify(data));
        } else {
            window.localStorage.setItem(key, JSON.stringify(data));
        }
    }

    static getData(key: string, isSession: boolean = false) {
        // console.log('StorageHelper getValue', key);
        let json = isSession ? window.sessionStorage.getItem(key) : window.localStorage.getItem(key);
        if (!json) {
            return null;
        }
        return JSON.parse(json);
    }

    static remove(key: string, isSession: boolean = false) {
        // console.log('StorageHelper remove', key);
        if (isSession) {
            window.sessionStorage.removeItem(key);
        } else {
            window.localStorage.removeItem(key);
        }
    }
}