import React from 'react';
import BaseApi from './BaseApi';

export default class StripeAccountApi extends BaseApi {

    /**
     * POST /stripe_accounts
     */
    static _fetchStripeAccount(params, success, error) {
        super._fetch('/api/v1/stripe_accounts', 'POST', params, success, error);
    }


    /**
     * GET /stripe_account
     */
    static _fetchStripeAccountShow(params, success, error) {
        super._fetch('/api/v1/stripe_accounts', 'GET', params, success, error);
    }

    /**
     * PATCH /stripe_account
     */
    static _fetchStripeAccountEdit(params, success, error) {
        super._fetch('/api/v1/stripe_accounts', 'PUT', params, success, error);
    }
}