import React, {useState, Fragment} from 'react';
import Swiper from 'react-id-swiper';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';
import {range, _dateToString} from '../helpers/utils';
import no_image from 'assets/no_image.png';

import './CommonImageSwiper.css';
import FontAwesome from 'react-fontawesome';

interface CommonImageSwiperProps {
    name?: any; // タイトル
    desc?: any; // タイトル下部の説明文
    sub_title?: any; // タイトル上部の説明文
    images: any[]; // 画像リスト
    leftButton?: any;
    leftButtonAction?: any | null;
    rightButton?: any;
    rightButtonAction?: any | null;
    onClickHelp?: any;
    selectedIndex?: number;
}

interface CommonImageSwiperState {
    swiper: any | null;
    index: number;
}

class CommonImageSwiper extends React.Component<CommonImageSwiperProps, CommonImageSwiperState> {

    constructor(props: any) {
        super(props);

        this.state = {
            swiper: null,
            index: this.props.selectedIndex || 0,
        };
    }

    getSwiper = (swiper: any) => {
        this.setState({
            swiper: swiper,
        });
    }

    render() {
        // 画像
        var swiper_img = [];
        for (var url of this.props.images) {
            swiper_img.push(
                <div key={url} className="main-image">
                    <img src={url} alt=""/>
                </div>
            );
        }
        if (swiper_img.length == 0) {
            swiper_img.push(
                <div className="main-image">
                    <img src={no_image} alt=""/>
                </div>
            );
        }

        const params = {
            iOSEdgeSwipeDetection: true,
            shouldSwiperUpdate: true,
            on: {
                slideChange: () => {
                    if (this.state.swiper) {
                        this.setState({
                            index: this.state.swiper.activeIndex,
                        });
                    }
                },
            },
        }

        return (
            <div className="col-12 CommonImageSwiper">
                <Swiper
                    getSwiper={this.getSwiper}
                    {...params}
                >
                    {swiper_img}
                </Swiper>
                {this.props.name &&
                <div className="box">
                    {this.props.sub_title &&
                    <p className="desc">{this.props.sub_title}</p>
                    }
                    {this.props.name &&
                    <p className="title">
                        {this.props.name}
                        {this.props.onClickHelp &&
                        <button className="btn btn-help btn-primary" onClick={this.props.onClickHelp}>
                            使い方を確認
                        </button>
                        }
                    </p>
                    }
                    {this.props.desc &&
                    <p className="desc">{this.props.desc}</p>
                    }
                    {this.props.images.length > 1 &&
                    <PageControl numberOfPages={this.props.images.length} currentPage={this.state.index}/>
                    }
                </div>
                }
                {this.props.leftButton && this.props.leftButtonAction &&
                <div className="left-button cursor" onClick={(e: any) => {
                    this.props.leftButtonAction();
                }}>
                    {this.props.leftButton}
                </div>
                }

                {this.props.rightButton && this.props.rightButtonAction &&
                <div className="right-button cursor" onClick={(e: any) => {
                    this.props.rightButtonAction();
                }}>
                    {this.props.rightButton}
                </div>
                }

            </div>
        );
    }
}

const PageControl = ({numberOfPages, currentPage}: { numberOfPages: number, currentPage: number }) => {
    return (<div className="main-image-page-control">
        {range(0, numberOfPages - 1).map(i => {
            const _className = i == currentPage ? 'active page-control-item' : 'page-control-item'
            return (<div className={_className}/>)
        })}
    </div>)
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommonImageSwiper);