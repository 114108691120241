import React from 'react';
import './CommonTitle.css';
import logo from 'assets/logo-m.png';

interface CommonTitleProps {
    logo?: boolean;
    title: string;
}

interface CommonTitleStates {

}

class CommonTitle extends React.Component<CommonTitleProps, CommonTitleStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="CommonTitle col-12">
                <div className="title">
                    {this.props.logo &&
                    <img src={logo} className="icon" alt=""/>
                    }
                    {this.props.title}
                </div>
            </div>
        );
    }
}

export default CommonTitle;
