import {combineReducers} from 'redux';
import * as CurrentUser from '../actions/currentUser';
import * as SideMenu from '../actions/sideMenu';
import * as Spinner from '../actions/spinner';
import * as Dialog from '../actions/dialog';
import * as ImagePreview from '../actions/imagePreview';

export type RootState = {
    currentUser: CurrentUser.State;
    sideMenu: SideMenu.State;
    spinner: Spinner.State;
    dialog: Dialog.State;
    imagePreview: ImagePreview.State;
};

export const rootReducer = combineReducers({
    currentUser: CurrentUser.reducer,
    sideMenu: SideMenu.reducer,
    spinner: Spinner.reducer,
    dialog: Dialog.reducer,
    imagePreview: ImagePreview.reducer,
});

export const actionCreator = {
    currentUser: CurrentUser.actionCreator,
    sideMenu: SideMenu.actionCreator,
    spinner: Spinner.actionCreator,
    dialog: Dialog.actionCreator,
    imagePreview: ImagePreview.actionCreator,
};