import React from 'react';
import no_image from 'assets/no_image.png';
import './CommonImage.css';

interface CommonImageProps {
    style?: any,
    src?: string,
    alt?: string,
    onClick?: ()=>void,
    className?: string | undefined,
}

interface CommonImageStates {

}

class CommonImage extends React.Component<CommonImageProps, CommonImageStates> {
    render() {
        return (
            <img onClick={this.props.onClick} alt={this.props.alt} 
            src={this.props.src || no_image} style={this.props.style} 
            className={this.props.className}/>
        );
    }
}

export default CommonImage;
