import React from 'react';
import queryString from 'query-string';
import firebase, {auth, providerFacebook, googleAuthProvider, twitterAuthProvider} from "../../firebase";
import {LINE_CLIENT_ID} from "../../Construct";
import FontAwesome from 'react-fontawesome';

import GAHelper from "../../helpers/GAHelper";

import signup_logo from 'assets/signup_logo.png';
import line_logo from 'assets/line_logo.png';
import or from 'assets/or.png';
import './Remind.css';
import 'bootstrap/dist/css/bootstrap.css';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

interface Props {
    history: any;
    location: any;
    setCurrentUser: (name: string, token: string, user_id: string) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface State {

}

class Remind extends React.Component<Props, State> {

    private emailForm: any;

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

        // LINE認証コードがある場合はカスタム認証を行う
        const qs = queryString.parse(this.props.location.search);
        const code = qs.code;
        if (code) {
            const params = {
                code
            };

            // console.log('line lineLogin:', params);
            const lineLogin = firebase.functions().httpsCallable('lineLogin');
            lineLogin(params).then(({data}) => {
                const custom_token = data.custom_token;
                console.log('line lineLogin OK', custom_token);
                return firebase.auth().signInWithCustomToken(custom_token);
            }).then((user) => {
                console.log('line signInWithCustomToken OK ', user);
            }).catch(httpsError => {
                console.log('httpsCallable line NG ', httpsError);
            });
        }
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
        auth.sendPasswordResetEmail(this.emailForm.email.value)
            .then((result) => {
                this.props.showDialog(
                    "確認",
                    `メールアドレス再設定用メールを送信しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );

            }).catch((error) => {
                alert(error.message);
            }
        );
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        return (
            <div className="Remind row">
                <div className="col-12">
                    <img src={signup_logo} className="logo"/>
                </div>
                <div className="col-12">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.emailForm = el && el.elements}
                    >
                        <input name="email" type="email" className="signup-input" placeholder="登録されたメールアドレス" required></input>
                        <button type="submit" className="btn btn-secondary">パスワード再発行メール送信</button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    // console.log('RootState', state);
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Remind);