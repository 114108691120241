import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
// import "firebase/analytics";
import "firebase/performance";

const config = {
    apiKey: "AIzaSyCRNzI7ylHNPpspU9IV4whGcGRmi4V7o84",
    authDomain: "titi-f34fc.firebaseapp.com",
    databaseURL: "https://titi-f34fc.firebaseio.com",
    projectId: "titi-f34fc",
    storageBucket: "titi-f34fc.appspot.com",
    messagingSenderId: "258443300975",
    appId: "1:258443300975:web:f81d309fdb0ae986"
};

if (!firebase.apps.length) {
    console.log('initialize firebase.', config);
    firebase.initializeApp(config);
}

export default firebase;
export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const perf = firebase.performance();
export const providerFacebook = new firebase.auth.FacebookAuthProvider();
export const emailAuthProvider = new firebase.auth.EmailAuthProvider();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
