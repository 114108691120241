import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_USER, USER_COMMUNITIES} from '../../helpers/StorageHelper';
import CopyToClipboard from 'react-copy-to-clipboard';
import QRCode from "qrcode.react";

import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import CommunitiesUserApi from '../../helpers/api/CommunitiesUserApi';
import InvitationManagementApi from '../../helpers/api/InvitationManagementApi';
import UsersCommunityApi from '../../helpers/api/UsersCommunityApi';

import './CommunitySetting.css';
import Header from '../../components/Header';
import TagsInput from 'react-tagsinput';
import no_image from 'assets/no_image.png';
import btn_social_link from 'assets/btn_social_link.png';
import btn_social_qr from 'assets/btn_social_qr.png';

interface CommunitySettingProps {
    history: any;
    setCurrentUser: (data: any) => void;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: any;
}

interface CommunitySettingStates {
    render: boolean;
    is_edit: boolean;
    renderQR: boolean;
    community_name: string,
    community?: {
        name?: string;
        description?: string;
        communities_users?: [];
        community_image?: {
            image: {
                content: {
                    url: string;
                }
            }
        } | null;
    };
    communities_user?: {
        role: string;
    };
    invitation?: {
        id?: string;
        content?: string;
    };
    nickname: string;
    introduction: string;
    invite_url: string;

    tag_list: string[];
    tag_word: string,
}

class CommunitySetting extends React.Component<CommunitySettingProps, CommunitySettingStates> {

    menu = [
        {
            title: 'コミュニティメンバー一覧',
            onClick: () => {
                this.props.history.push(`/community/${this.state.community_name}/member`);
            },
            is_organizer: false,
        },
        {
            title: 'コミュニティ情報編集',
            onClick: () => {
                this.props.history.push(`/community/${this.state.community_name}/edit`);
            },
            is_organizer: true,
        },
        {
            title: 'コミュニティプロフィール編集',
            onClick: () => {
                this.props.history.push(`/community/${this.state.community_name}/profile`);
            },
        },
        {
            title: 'コミュニティ削除',
            onClick: () => {
                this.fetchCommuntiesDelete();
            },
            is_organizer: true,
        },
        {
            title: 'コミュニティ脱退',
            onClick: () => {
                this.fetchCommunitiesUsersDelete();
            },
            is_not_organizer: true,
        },
    ]

    private form: any;

    constructor(props: any) {
        super(props);
        this.state = {
            render: false,
            is_edit: true,
            renderQR: false,
            community_name: props.match.params.name,
            nickname: '',
            introduction: '',
            invite_url: '',
            tag_list: [],
            tag_word: '',
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.fetchCommunitiesShow();
    }

    /**
     * コミュニティ詳細取得
     */
    fetchCommunitiesShow = () => {

        this.props.showSpinner(true);

        CommunityManagementApi._fetchCommunitiesShow(
            this.state.community_name,
            {},
            (responseJson: any) => {

                console.log('_fetchCommunitiesShow', responseJson);

                this.setState({
                    community: responseJson
                });

                this.fetchCommunitiesUsersShow();
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * コミュニティプロフィール取得
     */
    fetchCommunitiesUsersShow = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersShow(
            this.state.community_name,
            currentUser.user_id,
            {},
            (responseJson: any) => {

                // console.log('_fetchCommunitiesUsersShow', responseJson);

                this.props.showSpinner(false);
                this.setState({
                    communities_user: responseJson,
                    nickname: responseJson.nickname,
                    introduction: responseJson.introduction,
                    tag_list: responseJson.introduction_tag_list,
                });

                this.fetchInvitationCode();
            },
            (error: any) => {
                this.props.showSpinner(false);
                // TODO 新規登録と編集を厳密にする必要がある。
                if (error != '指定されたコミュニティに所属していません。') {
                    alert(error);
                } else {
                    this.setState({
                        is_edit: false,
                    });
                }
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * 招待コード生成
     */
    fetchInvitationCode = () => {

        // 招待コード
        InvitationManagementApi._fetchInvitationCode(
            this.state.community_name,
            {},
            (responseJson: any) => {
                // console.log('_fetchInvitationCode', responseJson);

                // // TODO ダイナミックリンクのURLを生成する
                this.setState({
                    render: true,
                    invitation: responseJson,
                });

                this.invitation();
            },
            (error: any) => {
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    /**
     * コミュニティプロフィール作成
     */
    fetchCommunitiesUsersCreate = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersCreate(
            this.state.community_name,
            {
                nickname: this.form.nickname.value,
                introduction: this.form.introduction.value,
                introduction_tag_list: this.state.tag_list,
            },
            (responseJson: any) => {
                // console.log('_fetchCommunitiesUsersCreate', responseJson);
                this.props.showSpinner(false);
                this.setState({
                    communities_user: responseJson,
                    nickname: responseJson.nickname,
                    introduction: responseJson.introduction,
                });
                this.props.showDialog(
                    "確認",
                    `コミュニティプロフィールを登録しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        window.location.reload();
                    },
                    null,
                );

            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );
    }

    /**
     * コミュニティプロフィール編集
     */
    fetchCommunitiesUsersEdit = () => {

        this.props.showSpinner(true);
        let currentUser = StorageHelper.getData(CURRENT_USER);

        CommunitiesUserApi._fetchCommunitiesUsersEdit(
            this.state.community_name,
            {
                nickname: this.form.nickname.value,
                introduction: this.form.introduction.value,
                introduction_tag_list: this.state.tag_list,
            },
            (responseJson: any) => {
                // console.log('_fetchCommunitiesUsersEdit', responseJson);
                this.props.showSpinner(false);
                this.props.showDialog(
                    "確認",
                    `コミュニティプロフィールを保存しました。`,
                    (e: any) => {
                        this.props.hideDialog();
                        this.setState({
                            communities_user: responseJson,
                            nickname: responseJson.nickname,
                            introduction: responseJson.introduction,
                        });
                    },
                    null,
                );
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(false);
            },
        );

    }

    invitation = () => {

        if (!this.state.invitation) {
            return;
        }

        var {community_name} = this.state;
        const {id, content} = this.state.invitation;

        var hostname = window.location.hostname;
        var protocol = window.location.protocol;
        var port = window.location.port;

        /**
         * type=1はコミュニティ招待
         * @type {string}
         */
        const params = `type=1&i=${id}&c=${content}&n=${community_name}`;
        let uri = encodeURI(`${protocol}//${hostname}${port ? `:${port}` : ''}/community/${community_name}/invite?${params}`);
        // console.log('uri', uri);

        this.setState({
            invite_url: uri
        });
    }


    /**
     * コミュニティ削除
     */
    fetchCommuntiesDelete = () => {
        this.props.showDialog(
            "確認",
            "コミュニティを削除しますか？\n削除したコミュニティは復元することはできません。",
            (e: any) => {

                this.props.hideDialog();
                this.props.showSpinner(true);

                CommunityManagementApi._fetchCommuntiesDelete(
                    this.state.community_name,
                    {},
                    (responseJson: any) => {
                        // console.log('_fetchCommuntiesDelete', responseJson);
                        this.props.showSpinner(false);
                        this.fetchUsers(`/`);
                    },
                    (error: any) => {
                        this.props.showSpinner(false);
                        console.log(error);
                        alert(error);
                    },
                );
            },
            (e: any) => {
                this.props.hideDialog();
            }
        );
    }

    /**
     * コミュニティ脱退
     */
    fetchCommunitiesUsersDelete = () => {
        this.props.showDialog(
            "確認",
            "コミュニティを脱退します。",
            (e: any) => {

                this.props.hideDialog();
                this.props.showSpinner(true);

                CommunitiesUserApi._fetchCommunitiesUsersDelete(
                    this.state.community_name,
                    {},
                    (responseJson: any) => {

                        // console.log('_fetchCommuntiesDelete', responseJson);

                        this.props.showSpinner(false);
                        this.fetchUsers(`/`);

                    },
                    (error: any) => {
                        this.props.showSpinner(false);
                        console.log(error);
                        alert(error);
                    },
                );
            },
            (e: any) => {
                this.props.hideDialog();
            }
        );
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
        if (this.state.communities_user) {
            this.fetchCommunitiesUsersEdit();
        } else {
            this.fetchCommunitiesUsersCreate();
        }
    }

    handlePressLink = (e: any) => {
        this.props.showDialog(
            "招待用URLをコピーしました。",
            this.state.invite_url,
            (e: any) => {
                this.props.hideDialog();
            },
            null,
        );
    }

    handlePressQR = (e: any) => {
        e.preventDefault();

        this.setState({
            renderQR: !this.state.renderQR,
        });
    }

    handleTagChange = (tag_list: string[]) => {
        this.setState({tag_list});
    }

    fetchUsers = (path: string) => {

        this.props.showSpinner(true);

        // 所属コミュニティ
        UsersCommunityApi._fetchUsersCommunity(
            {},
            (responseJson: any) => {

                // console.log('_fetchUsersCommunity3 responseJson', responseJson);
                this.props.showSpinner(false);
                var currentUser = this.props.currentUser;
                currentUser.communities = responseJson.communities;
                this.props.setCurrentUser(currentUser);

                StorageHelper.setData(USER_COMMUNITIES, responseJson.communities);
                this.props.history.push(path);
            },
            (error: any) => {
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        let name = this.state.community ? this.state.community.name : '';
        let description = this.state.community ? this.state.community.description : '';
        let num = this.state.community ? this.state.community.communities_users!.length : 0;

        let community_image = no_image;
        if (this.state.community && this.state.community.community_image) {
            community_image = this.state.community!.community_image!.image!.content!.url || no_image;
        }

        let {nickname, introduction} = this.state;

        // let image = this.props.currentUser.avatar_url || no_image;

        var list = [];
        for (let d of this.menu) {

            if (d.is_organizer && (this.state.communities_user && this.state.communities_user.role != "organizer")) {
                continue;
            }
            if (d.is_not_organizer && (this.state.communities_user && this.state.communities_user.role == "organizer")) {
                continue;
            }

            list.push(
                <li onClick={d.onClick} className="cursor">
                    {d.title}
                </li>
            );
        }

        return (
            <div className="CommunitySetting row">
                <Header
                    title={'コミュニティ情報'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                />

                { this.state.render &&
                <div className="col-12 detail">
                    <p className="community-title">コミュニティ情報</p>
                    <div className="row">
                        <div className="col-2 community-image">
                            <img src={community_image} className="image"/>
                        </div>
                        <div className="col-10">
                            <p className="community-name">{name}</p>
                            <p className="community-num">参加メンバー{num}名</p>
                        </div>
                        <div className="col-12 community-desc">
                            <p className="">{description}</p>
                        </div>
                    </div>
                </div>
                }

                { this.state.render && this.state.communities_user &&
                <div className="col-12 common">
                    <p className="community-title">コミュニティ設定</p>
                    <ul>
                        {list}
                    </ul>
                </div>
                }

                { this.state.render && !this.state.communities_user &&
                <div className="col-12 detail">
                    <p className="community-title">コミュニティでのプロフィール</p>
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.form = el && el.elements}
                    >
                        <label htmlFor="nickname">表示名</label>
                        <input name="nickname" type="text" className="signup-input" value={nickname} placeholder="表示名を入力" onChange={(e) => this.setState({nickname: e.target.value})} required maxLength={20}></input>

                        <label htmlFor="tag_list">自己紹介タグ</label>
                        <TagsInput
                            className='form-input-text input-tags'
                            value={this.state.tag_list}
                            onChange={this.handleTagChange}
                            inputProps={{placeholder: '入会年度や属性を入力'}}
                            inputValue={this.state.tag_word}
                            onChangeInput={(tag_word: string) => {
                                // androidで改行が検知できないため半角スペースでタグ追加
                                if (tag_word.endsWith(' ')) {
                                    var tag_list = this.state.tag_list;
                                    tag_list.push(tag_word);
                                    this.setState({
                                        tag_list,
                                        tag_word: '',
                                    });
                                } else {
                                    this.setState({
                                        tag_word,
                                    });
                                }
                            }}
                            onlyUnique
                            addOnBlur
                        />

                        <label htmlFor="introduction">自己紹介</label>
                        <input name="introduction" type="text" className="signup-input" value={introduction} placeholder="自己紹介を入力" onChange={(e) => this.setState({introduction: e.target.value})} maxLength={255}></input>

                        <button type="submit" className="btn btn-secondary">登録</button>

                    </form>
                </div>
                }

                { this.state.is_edit && this.state.render &&
                <div className="col-12 detail">
                    <p className="community-title">コミュニティ招待</p>
                    <CopyToClipboard onCopy={this.handlePressLink} text={this.state.invite_url}>
                        <div className="row invite cursor">
                            <div className="col-3">
                                <img src={btn_social_link} className="invite-image"/>
                            </div>
                            <div className="col-9">
                                <p className="community-name">招待リンクを共有してください</p>
                                <p className="community-link">メッセンジャー、Twitter、Line、メールアドレスで共有しよう</p>
                            </div>
                        </div>
                    </CopyToClipboard>
                    <div className="row invite cursor" onClick={this.handlePressQR}>
                        <div className="col-3">
                            <img src={btn_social_qr} className="invite-image"/>
                        </div>
                        <div className="col-9">
                            <p className="community-name">QRコードを表示する</p>
                            <p className="community-link">QRコードをコミュニティの友だちに読み込んでもらって招待</p>
                        </div>
                    </div>
                </div>
                }

                {this.state.renderQR &&
                <div className="qr" onClick={this.handlePressQR}>
                    <QRCode size={200} value={this.state.invite_url}/>
                </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        setCurrentUser: (data: any) => {
            dispatch(actionCreator.currentUser.currentUser(data));
        },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunitySetting);
