import React from 'react';
import BaseApi from './BaseApi';

export default class StoresApi extends BaseApi {

    /**
     * GET /api/v1/stores
     */
    static _fetchStores(params, success, error) {
        super._fetch('/api/v1/stores', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/stores/:id
     */
    static _fetchStoresShow(id, params, success, error) {
        super._fetch('/api/v1/stores/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/stores
     */
    static _fetchStoresCreate(params, success, error) {
        super._fetch('/api/v1/stores', 'POST', params, success, error);
    }

    /**
     * PATCH /api/v1/stores/:id
     */
    static _fetchStoresEdit(id, params, success, error) {
        super._fetch('/api/v1/stores/' + id, 'PATCH', params, success, error);
    }

    /**
     * DELETE /api/v1/stores/:id
     */
    static _fetchStoresDelete(id, params, success, error) {
        super._fetch('/api/v1/stores/' + id, 'DELETE', params, success, error);
    }


    /**
     * POST /api/v1/stores/:store_id/visitors
     */
    static _fetchStoresVisitors(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/visitors', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/stores/:store_id/rsv_requests
     */
    static _fetchStoresRsvRequests(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/rsv_requests', 'POST', params, success, error);
    }
}
