export default class ImageUtil {
    static convertEventToBase64 = (e: any, loadCallback:
        (image: Image, index?:number) => void) => {
        // Base64に変換. 変換が終わり次第loadCallbackを呼び出す.
        // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        for (let index = 0; index < e.target.files.length; index++) {
            const file = e.target.files[index];
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if (typeof (reader.result) == "string") {
                    loadCallback({
                        image: reader.result,
                        extension_type: file.type.replace('image/', '')
                    }, index);
                }
            }, false);
            reader.readAsDataURL(file);
        }
    }
}

export type Image = {
    image: string,
    extension_type: string,
}