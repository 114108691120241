import React from 'react';
import queryString from 'query-string';
import firebase, {auth, providerFacebook, googleAuthProvider, twitterAuthProvider} from "../../firebase";
import {LINE_CLIENT_ID} from "../../Construct";
import FontAwesome from 'react-fontawesome';

import GAHelper from "../../helpers/GAHelper";
import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import UserSessionApi from '../../helpers/api/UserSessionApi';
import UserManagementApi from '../../helpers/api/UserManagementApi';

import signup_logo from 'assets/signup_logo.png';
import line_logo from 'assets/line_logo.png';
import or from 'assets/or.png';
import './Signin.css';
import 'bootstrap/dist/css/bootstrap.css';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

interface Props {
    history: any;
    location: any;
    setCurrentUser: (name: string, token: string, user_id: string) => void;
    showSpinner: (show: boolean) => void;
}

interface State {

}

class Signin extends React.Component<Props, State> {

    private emailForm: any;

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    componentDidMount() {

        // LINE認証コードがある場合はカスタム認証を行う
        const qs = queryString.parse(this.props.location.search);
        const code = qs.code;
        if (code) {
            const params = {
                code
            };

            // console.log('line lineLogin:', params);
            const lineLogin = firebase.functions().httpsCallable('lineLogin');
            lineLogin(params).then(({data}) => {
                const custom_token = data.custom_token;
                console.log('line lineLogin OK', custom_token);
                return firebase.auth().signInWithCustomToken(custom_token);
            }).then((user) => {
                console.log('line signInWithCustomToken OK ', user);
            }).catch(httpsError => {
                console.log('httpsCallable line NG ', httpsError);
            });
        }
    }

    handleFormSubmit(e: any) {
        e.preventDefault();

        this.props.showSpinner(true);

        UserSessionApi._fetchUsersSessionNewVer(
            {
                device_kind: 'ios', // TODO
                device_name: 'iPhone8', // TODO
                version: 2,
                email: this.emailForm.email.value,
                password: this.emailForm.password.value,
            },
            (responseJson: any) => {
                console.log('_fetchUsersSessionNewVer', responseJson);

                this.props.showSpinner(false);

                GAHelper._logEvent(
                    'login',
                    {
                        method: 'email',
                    }
                );

                let user = {
                    isAuthenticated: true,
                    isRegistered: responseJson.registered,
                    user_id: responseJson.user_id,
                    access_token: responseJson.access_token,
                };

                StorageHelper.setData(CURRENT_USER, user);

                console.log('CURRENT_USER', user);

                window.location.reload();

                // UserManagementApi._fetchUsersInvitations({
                //         email: 'F5vxUatwno@gmail.com',
                //         community_name: 'テストコミュニティ２０２１',
                //     },
                //     (responseJson: any) => {
                //         // console.log('ATTH SUCCESS.');
                //         console.log('_fetchUsersInvitations', responseJson);
                //         window.location.reload();
                //     },
                //     (error: any) => {
                //         this.props.showSpinner(false);
                //         alert(error);
                //         console.log('AUTH FAILED.', error);
                //     },
                // );
            },
            (error: any) => {
                this.props.showSpinner(false);
                // console.log('AUTH FAILED.', error);
                alert(error);
            },
        );
    }

    handleFormFBButton = (e: any) => {

        this.props.showSpinner(true);

        auth.signInWithRedirect(providerFacebook)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormGoogleButton = (e: any) => {

        this.props.showSpinner(true);

        auth.signInWithRedirect(googleAuthProvider)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormTwitterButton = (e: any) => {

        this.props.showSpinner(true);

        auth.signInWithRedirect(twitterAuthProvider)
            .catch((error) => {
                this.props.showSpinner(false);
                alert(error.message)
            });
    }

    handleFormLineButton = (e: any) => {
        const client_id = LINE_CLIENT_ID;
        const redirect_uri = encodeURIComponent(window.location.origin + '/signin');
        const scope = encodeURIComponent('openid profile');
        const url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&`
            + `redirect_uri=${redirect_uri}&state=state&scope=${scope}`;
        window.location.href = url;
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        return (
            <div className="Signin row">
                <div className="col-12">
                    <img src={signup_logo} className="logo"/>
                </div>
                <div className="col-12">
                    <form
                        onSubmit={this.handleFormSubmit.bind(this)}
                        ref={el => this.emailForm = el && el.elements}
                    >
                        <input name="email" type="email" className="signup-input" placeholder="登録されたメールアドレス" required></input>
                        <input name="password" type="password" className="signup-input" placeholder="パスワード" required></input>
                        <button type="submit" className="btn btn-secondary">ログイン</button>
                    </form>
                    {/*<p onClick={e => {*/}
                        {/*this.props.history.push('/remind');*/}
                    {/*}} className="reminder">*/}
                        {/*パスワードを忘れた方*/}
                    {/*</p>*/}
                </div>
                {/*<div className="footer row cursor" onClick={e => {*/}
                    {/*this.props.history.push('/regist');*/}
                {/*}}>*/}
                    {/*<div className="col-8">*/}
                        {/*アカウントをお持ちではないですか？*/}
                    {/*</div>*/}
                    {/*<div className="col-4">*/}
                        {/*登録する*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    // console.log('RootState', state);
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Signin);