import React from 'react';
import BaseApi from './BaseApi';

export default class BalanceApi extends BaseApi {

    /**
     * GET /api/v1/balance
     */
    static _fetchBalance(params, success, error) {
        super._fetch('/api/v1/balance', 'GET', params, success, error);
    }
}