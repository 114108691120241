import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../../reducers';
import Calendar, {CalendarTileProperties} from 'react-calendar';
import Moment from 'moment';
import FontAwesome from "react-fontawesome";

import CommonSegmentButton from '../../../components/CommonSegmentButton';
import CommonImageSwiper from '../../../components/CommonImageSwiper';
import CommonTitle from '../../../components/CommonTitle';
import StorageHelper, {CURRENT_USER} from '../../../helpers/StorageHelper';
import StoreDetailProperties from '../components/StoreDetailProperties';
import CommonComments from '../components/CommonComments';
import CommonCell from '../components/CommonCell';

import './NiigataStoreDetail.css';

import FireStoreHelper from '../../../helpers/FireStoreHelper';
import { Event, Store } from '../../../helpers/schemas/FireStore';
import SlackWebhookHelper from '../../../helpers/SlackWebhookHelper';

enum STORE_PAGE_TYPE {
    RESERVE,        // 0
    EVENT_LIST,     // 1
}

// デフォルトのリクエスト時間
const REQ_TIME = '19:00';

interface StoreDetailProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface StoreDetailStates {
    storeId: string;
    store?: Store;
    event?: Event;
    type: STORE_PAGE_TYPE;
    event_list: Event[];
    number_of_people: number;
    select_date: any | null;
    select_time: string;
    user_name: string;
    user_phone_number: string;
}

class NiigataStoreDetail extends React.Component<StoreDetailProps, StoreDetailStates> {
    constructor(props: any) {
        super(props);
        const storeId = props.match.params.id;
        console.log(props.location)
        let currentUser = StorageHelper.getData(CURRENT_USER);
        this.state = {
            storeId: storeId,
            type: STORE_PAGE_TYPE.RESERVE,
            event_list: [],
            number_of_people: 0,
            select_date: null,
            select_time: REQ_TIME,
            user_name: "",
            user_phone_number: "",
        };
        this._fetchPageInfo();
    }

    async componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    // Get the store from FireStore
    _fetchStore = async(): Promise<void> => {
        const store = await FireStoreHelper.getStore(this.state.storeId);
        console.log('fetched Store', this.state.storeId, store);
        this.setState({store: store});
        if (!store) {
            alert('店舗情報を取得できません。')
        }
    }

    // Get the event list in the store
    _fetchEventList = async(): Promise<void> => {
        if (this.state.store) {
            const event_list = await FireStoreHelper.getEventsByStoreName(this.state.store.name);
            console.log('_fetchEventList', event_list);

            this.setState({
                event_list: event_list
            });

            // Get the latest event with comments from FireStore
            for (let event of event_list) {
                if (event.participant_comments.length > 0) {
                    console.log('fetched Event', event);
                    this.setState({event: event});
                    break;
                }
            }
        }
    }

    _fetchPageInfo = async(): Promise<void> => {
        await this._fetchStore();
        await this._fetchEventList();
    }

    _onChange = (date: Date | Date[]) => {
        let d: Date = Array.isArray(date) ? date[0] : date;

        this.setState({select_date: d});
    }

    _tileDisabled = (props: CalendarTileProperties) => {
        if (Moment(props.date).isBefore(Date())) {
            return true;
        }

        return false;
    }

    // 予約リクエスト送信処理
    _requestReserve = (reserve_info_str: string) => {
        const message = '[予約リクエスト]' + '\n' + reserve_info_str;

        // Slackに予約リクエストの内容を通知
        SlackWebhookHelper.requestReserve(
            message,
            (responseJson: any) => {
                this.props.showDialog(
                    "確認",
                    `予約リクエストを受け付けました。`,
                    (e: any) => {
                        this.props.hideDialog();
                    },
                    null,
                );
            },
            (error: any) => {
                alert(error);
            },
        );
    }

    _handleSelectNumberOfPeople = (number_of_people: number) => {
        console.log('_handleSelectNumberOfPeople', number_of_people);

        this.setState({number_of_people: number_of_people});
    }

    // 「予約リクエスト」ボタン押下時の処理
    _handlePressSubmit = (e: any) => {
        e.preventDefault();

        // 必要データ
        if (this.state.store == undefined) {
            return;
        }

        if (!this.state.select_date || !this.state.select_time) {
            this.props.showDialog(
                "確認",
                `開催日時を選択してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        if (Number(this.state.number_of_people) < 2) {
            this.props.showDialog(
                "確認",
                `人数を選択してください。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        if ((this.state.user_name.length == 0) &&
            (this.state.user_phone_number.length == 0)) {
            this.props.showDialog(
                "確認",
                `幹事様氏名、ご連絡先電話番号のご入力をお願いします。`,
                (e: any) => {
                    this.props.hideDialog();
                },
                null,
            );
            return;
        }

        const reserve_info_str =
            '店名：' + this.state.store.name + '\n' +
            '開催人数：' + this.state.number_of_people + '\n' +
            '開催日時：' + Moment(this.state.select_date).format('YYYY/MM/DD ') + this.state.select_time + '\n' +
            '氏名：' + this.state.user_name + '\n' +
            '電話番号：' + this.state.user_phone_number;
        
        // ボタンを押下したときのダイアログを表示
        this.props.showDialog(
            "確認",
            '以下でご予約を受け付けてもよろしいでしょうか？\n' + reserve_info_str,
            (e: any) => {
                this.props.hideDialog();
                this._requestReserve(reserve_info_str);
            },
            (e: any) => {
                this.props.hideDialog();
            },
        );
    }

    render() {
        console.log('store', this.state.storeId, this.state.store);

        // 過去イベントを参考にする
        var swiper_event_list = [];
        for (let event of this.state.event_list) {

            var image_url;
            if (event.image_url) {
                image_url = event.image_url;
            }

            swiper_event_list.push(
                <CommonCell
                    date={event.date_detail}
                    image={image_url}
                    title={event.name}
                    community_name={event.community_name}
                    community_image_url={(this.state.store && this.state.store.community_image_url) ? this.state.store.community_image_url : ""}
                    onClick={() => {
                        this.props.history.push('/niigata/event/' + event.id);
                    }}
                />
            );
        }

        // 開催人数入力
        var number_of_people = [];
        for (var i = 2; i < 100; i++) {
            number_of_people.push(
                <option value={i}>{i}名</option>
            )
        }

        // 時刻入力
        let time_list = [];
        const m = Moment().hour(0).minute(0);
        for (let i = 0; i < 96; i++) {
            const k = m.format('HH:mm');
            var v = m.format('HH時mm分');
            time_list.push(
                <option value={k}>{v}開始</option>
            )
            m.add(15, 'm');
        }

        return (
            <div className="NiigataStoreDetail row">

                {this.state.store &&
                <Fragment>

                    <CommonImageSwiper
                        name={this.state.store.name}
                        desc={this.state.store.access}
                        images={this.state.store.image_urls}
                        leftButton={<FontAwesome name='angle-left'/>}
                        leftButtonAction={() => {
                            this.props.history.goBack();
                        }}
                    />

                    <div className="col-12 detail border-bottom">
                        <p className="text">{this.state.store.description}</p>
                    </div>

                    {this.state.store.community_image_url &&
                    <Fragment>
                        <CommonTitle
                            title="ご贔屓コミュニティ"
                            logo
                        />
                        <div className="col-12 list" data-tip='ご贔屓にしているコミュニティのアイコン が表示されます' data-place='top'>
                            <div className="community-list">
                                <img src={this.state.store.community_image_url} className="community-image-list" alt=""/>
                            </div>
                        </div>
                    </Fragment>
                    }

                    {(this.state.event && (this.state.event.participant_comments.length > 0)) &&
                    <Fragment>
                        <CommonTitle
                            title={this.state.event.community_name + ' 参加者のコメント'}
                            logo
                        />
                        <CommonComments
                            comments={this.state.event.participant_comments}
                        />
                    </Fragment>
                    }

                    <div className="col-12 border"></div>

                    <StoreDetailProperties
                        store={this.state.store}
                    />

                    <CommonSegmentButton lists={['予約する', '開催されたイベント']} index={this.state.type} onChange={(i) => {
                        this.setState({
                            type: i,
                        });
                    }}/>

                    {this.state.type == STORE_PAGE_TYPE.RESERVE &&
                    <Fragment>
                        <div className="col-12 meta-box">
                            <p className="title">予約のご利用人数</p>
                            <select name="number_of_people" className="signup-input number_of_people custom-select" value={this.state.number_of_people} onChange={(e) => this._handleSelectNumberOfPeople(Number(e.target.value))}>
                                <option value="0">人数を選択する</option>
                                {number_of_people}
                            </select>
                        </div>

                        <div className="col-12 date meta-box">
                            <p className="title">開催日を選択</p>
                            <Calendar
                                onChange={this._onChange}
                                value={this.state.select_date}
                                tileDisabled={this._tileDisabled}
                            />
                        </div>

                        <div className="col-12 meta-box">
                            <p className="title">時刻を入力</p>
                            <select name="time" className="signup-input time custom-select" value={this.state.select_time} onChange={(e) => this.setState({select_time: e.target.value})}>
                                <option value="0">時刻を選択する</option>
                                {time_list}
                            </select>
                        </div>

                        <div className="col-12 meta-box">
                            <p className="title">氏名</p>
                            <input name="name" type="text" className="signup-input" value={this.state.user_name} onChange={(e) => this.setState({user_name: e.target.value})}></input>
                        </div>

                        <div className="col-12 meta-box">
                            <p className="title">ご連絡先電話番号(ハイフン不要)</p>
                            <input name="tel" type="tel" className="signup-input" value={this.state.user_phone_number} onChange={(e) => this.setState({user_phone_number: e.target.value})}></input>
                        </div>

                        <div className="col-12 detail">
                            <p className="title">注意事項</p>
                            <p className="text">
                                TiTi契約がまだ行われていない店舗です。予約リクエストを行っていただくと、コンシェルジュが、予約を代行させていただきます。また、契約が済んだ際には特典が追加された旨をおしらせします。
                            </p>
                        </div>

                        <div className="col-12 button">
                            <div className="row">
                                <div className="col-12">
                                    <input
                                        type="button"
                                        className={`w100 cursor`}
                                        value="予約リクエスト"
                                        onClick={this._handlePressSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    }

                    {this.state.type == STORE_PAGE_TYPE.EVENT_LIST &&
                    <Fragment>
                        {swiper_event_list}
                    </Fragment>
                    }


                </Fragment>
                }

            </div>
        );
    }
};

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NiigataStoreDetail);
