import React from 'react';
import BaseApi from './BaseApi';

export default class ReviewApi extends BaseApi {

    /**
     * GET /products/:product_id/reviews
     */
    static _fetchProductReviews(product_id, params, success, error) {
        super._fetch(`/api/v1/products/${product_id}/reviews`, 'GET', params, success, error);
    }

    /**
     * GET /products/:product_id/reviews/:id
     */
    static _fetchProductReviewDetail(product_id, review_id, params, success, error) {
        super._fetch(`/api/v1/products/${product_id}/reviews/${review_id}`, 'GET', params, success, error);
    }

    /**
     * POST /products/:product_id/reviews
     */
    static _fetchProductReviewCreate(product_id, params, success, error) {
        super._fetch(`/api/v1/products/${product_id}/reviews`, 'POST', params, success, error);
    }

    /**
     * PUT /products/:product_id/reviews/:id
     */
    static _fetchProductReviewUpdate(product_id, review_id, params, success, error) {
        super._fetch(`/api/v1/products/${product_id}/reviews/${review_id}`, 'PUT', params, success, error);
    }

    /**
     * DELETE /products/:product_id/reviews/:id
     */
    static _fetchProductReviewDelete(product_id, review_id, params, success, error) {
        super._fetch(`/api/v1/products/${product_id}/reviews/${review_id}`, 'DELETE', params, success, error);
    }

    /**
     * GET products/review_unsubmitted
     */
    static _fetchProductReviewUnsubmitted(params, success, error) {
        super._fetch(`/api/v1/products/review_unsubmitted`, 'GET', params, success, error);
    }
    
}
