import React from 'react';
import BaseApi from './BaseApi';

export default class CommunityManagementApi extends BaseApi {

    /**
     * /communities
     */
    static _fetchCommunities(params, success, error) {
        super._fetch('/api/v1/communities' , 'GET', params, success, error);
    }

    /**
     * /communities/:name
     */
    static _fetchCommunitiesShow(name, params, success, error) {
        super._fetch('/api/v1/communities/' + name , 'GET', params, success, error);
    }

    /**
     * /communities/:name
     */
    static _fetchCommunitiesCreate(params, success, error) {
        super._fetch('/api/v1/communities/', 'POST', params, success, error);
    }

    /**
     * /communities/:name/products
     */
    static _fetchCommunitiesProducts(name, params, success, error) {
        super._fetch('/api/v1/communities/' + name + '/product_lists', 'GET', params, success, error);
    }

    /**
     * /communities/:name/edit
     */
    static _fetchCommunitiesEdit(name, params, success, error) {
        super._fetch('/api/v1/communities/' + name + '/edit', 'GET', params, success, error);
    }

    /**
     * /communities/:name
     */
    static _fetchCommuntiesEdit(name, params, success, error) {
        super._fetch('/api/v1/communities/' + name , 'PUT', params, success, error);
    }

    /**
     * /communities/:name
     */
    static _fetchCommuntiesDelete(name, params, success, error) {
        super._fetch('/api/v1/communities/' + name , 'DELETE', params, success, error);
    }

    /**
     * /community_invites
     */
    static _fetchCommunityInvites(params, success, error) {
        super._fetch('/api/v1/community_invites', 'DELETE', params, success, error);
    }

    /**
     * /api/v1/community/:id/invitation_codes
     */
    static _fetchCommunityInvitationCodes(id, params, success, error) {
        super._fetch('/api/v1/community/' + id + '/invitation_codes', 'POST', params, success, error);
    }

    /**
     * /community_invites
     */
    static _fetchCommunityInvites(params, success, error) {
        super._fetch('/api/v1/community_invites', 'POST', params, success, error);
    }

    /**
     * /communities
     */
    static _fetch(params, success, error) {
        super._fetch('/api/v1/communities', 'GET', params, success, error);
    }

}
