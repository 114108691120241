import React, {useState, Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {_dateMsg} from '../../helpers/utils';

import Header from '../../components/Header';
import CommonUserCell from '../../components/CommonUserCell';

import no_image from 'assets/no_image.png';
import NotificationsApi from '../../helpers/api/NotificationsApi';

import throttle from 'lodash';

import './NotificationList.css';

const PER = 30;

interface NotificationListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface NotificationListStates {
    render: boolean;
    notifications: {
        id: number;
        notification_type: number;
        notification_at: string;
        content: string;
        content_id: number;
        read_flag: number;
        image_url: string;
    }[];
}

class NotificationList extends React.Component<NotificationListProps, NotificationListStates> {

    page = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            render: false,
            notifications: [],
        };
    }

    componentDidMount() {

        this.props.onSetSidebarOpen(false);

        this._fetchNotifications();
        window.addEventListener('scroll', this._handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll, true);
    }

    _handleScroll = throttle.throttle((e) => {
        const preMargin = 300;
        const isBottom = (e.target.scrollTop + e.target.clientHeight + preMargin) >= e.target.scrollHeight;
        if (isBottom) {
            this._fetchNotifications();
        }
    }, 100)

    _fetchNotifications = () => {

        if (PER * this.page != this.state.notifications.length) {
            // reset
            return;
        }

        this.page++;

        this.props.showSpinner(true);
        NotificationsApi._fetchNotifications(
            {
                page: this.page,
                per: PER,
            },
            (responseJson: any) => {

                console.log('_fetchNotifications', responseJson);

                this.props.showSpinner(false);
                this.setState({
                    render: true,
                    notifications: this.state.notifications.concat(responseJson.notifications),
                });
            },
            (error: any) => {
                this.props.showSpinner(false);
                alert(error);
                this.setState({
                    render: true,
                });
            },
        );
    }


    render() {

        var list = [];
        for (const notification of this.state.notifications) {
            list.push(
                <CommonUserCell
                    image_detal={notification.image_url || no_image}
                    text={notification.content}
                    sub_title={_dateMsg(notification.notification_at)}
                    onClick={e => {
                        if (notification.notification_type == 1) {
                            // イベント
                            this.props.history.push('/congregate/detail/' + notification.content_id);
                        } else if (notification.notification_type == 2) {
                            // 給付金
                            this.props.history.push('/benefit/' + notification.content_id);
                        } else {
                            this.props.showDialog(
                                "通知",
                                notification.content,
                                (e: any) => {
                                    this.props.hideDialog();
                                },
                                null,
                            );
                        }
                    }}
                />
            );
        }

        if (list.length == 0) {
            list.push(
                <p className="no-data">通知はありません</p>
            )
        }

        return (
            <div className="NotificationList row">
                <Header
                    title={'通知'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                {this.state.render &&
                <Fragment>
                    {list}
                </Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    console.log('RootState', state);
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationList);
