import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../../reducers';
import FontAwesome from "react-fontawesome";

import Header from '../../../components/Header';
import CommonCell from '../components/CommonCell';

import FireStoreHelper from '../../../helpers/FireStoreHelper';
import {Event} from '../../../helpers/schemas/FireStore';

import './CommunityEventList.css';

interface CommunityEventListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface CommunityEventListStates {
    event_list: Event[],
}

class CommunityEventList extends React.Component<CommunityEventListProps, CommunityEventListStates> {

    load = 0;

    constructor(props: any) {
        super(props);
        this.state = {
            event_list: [],
        };
        this._fetchEventsAll();
    }

    async componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    _showSpinner = (show: boolean) => {
        if (show) {
            this.load++;
        } else {
            this.load--;
        }
        this.props.showSpinner(this.load > 0);
    }

    _fetchEventsAll = async (): Promise<void> => {

        this._showSpinner(true);

        var event_list = await FireStoreHelper.getEventsAll();
        console.log('_fetchEventsAll', event_list);

        this._showSpinner(false);

        this.setState({
            event_list: event_list
        });
    }

    render() {

        // 過去イベントを参考にする
        var swiper_event_list = [];
        for (let event of this.state.event_list) {

            var image_url;
            if (event.image_url) {
                image_url = event.image_url;
            }

            swiper_event_list.push(
                <CommonCell
                    date={event.date_detail}
                    image={image_url}
                    title={event.name}
                    location={event.store_name}
                    text={event.participant_count > 0 ? ('参加者：' + event.participant_count) : undefined}
                    onClick={() => {
                        this.props.history.push('/niigata/event/' + event.id);
                    }}
                />
            );
        }

        return (
            <div className="CommunityEventList row">
                <Header
                    title={'イベント一覧'}
                    leftButtonTitle={(this.props.history.length > 0) ? <FontAwesome name='angle-left'/> : <FontAwesome name='times'/>}
                    leftButtonAction={() => {
                        if (this.props.history.length > 0) {
                            this.props.history.goBack();
                        } else {
                            this.props.history.push('/');
                        }
                    }}
                />
                {this.load == 0 && swiper_event_list}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityEventList);
