import {sideMenu, SideMenuAction} from './SideMenu';

type Actions
    = SideMenuAction;

export type State = {
    sideMenu: {
        sidebarOpen: boolean;
    };
};

const init = (): State => {
    return {
        sideMenu: {
            sidebarOpen: false,
        }
    };
};

export const reducer = (state: State = init(), action: Actions) => {
    switch (action.type) {
        case 'SIDE_MENU':
            return {
                sideMenu: {
                    sidebarOpen: action.payload.sidebarOpen,
                },
            };
        default:
            return state;
    }
};

export const actionCreator = {
    sideMenu,
};