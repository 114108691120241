import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import './PrivacyPolicy.css';
import Header from '../../components/Header';

interface PrivacyPolicyProps {

}

interface PrivacyPolicyStates {

}

class PrivacyPolicy extends React.Component<PrivacyPolicyProps, PrivacyPolicyStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="PrivacyPolicy row">
                <Header title={'プライバシーポリシー'}/>
                <div className="col-12">
                    <p>シェイカー株式会社（以下「当社」といいます。）が提供するインターネットサービス「TiTi」（以下「本サービス」といいます。）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。</p>
                    <p></p>
                    <p>第１条　個人情報の定義</p>
                    <p>個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報（氏名、生年月日、その他の特定の個人を識別することができる情報）、ならびに特定の個人と結びついて使用されるメールアドレス、ユーザーＩＤ、パスワード、クレジットカードなどの情報、及び個人情報と一体となった趣味、家族構成、年齢その他の個人に関する属性情報をいいます。ただし特定の個人を識別できない匿名性のあるユーザーデータにつきましては個人情報とはみなしません。</p>
                    <p></p>
                    <p>第２条　クッキー・IPアドレス情報</p>
                    <p>クッキー及びＩＰアドレス情報については、それら単独では特定の個人を識別することができないため、個人情報とは考えておりません。ただしこれら情報と個人情報が一体となって使用される場合にはこれら情報も個人情報とみなします。なお、クッキー情報については、ブラウザの設定で拒否することが可能です。ただしクッキーを拒否するとサービスが受けられない場合があります。</p>
                    <p></p>
                    <p>第３条　利用目的</p>
                    <p>当社が個人情報を収集・利用する目的は、以下のとおりです。</p>
                    <p>（１）本サービス及び本サービスから派生するサービスの運営（当社から会員に対して、あらゆる情報を提供することを含みます）</p>
                    <p>（２）当社が会員にとって有益だと判断する当社のサービス（本サービスに限りません）又は、広告主や提携先企業の商品、サービス等に関する情報の提供</p>
                    <p>（３）本サービス、その他当社のサービスについての調査・データ集積、研究開発のため</p>
                    <p>（４）会員から個人情報の取扱いに関する同意を求めるための連絡</p>
                    <p>（５）当社と会員の間での必要な連絡を行うため</p>
                    <p>（６）その他当社と利用者の間で同意した目的のため</p>
                    <p>（７）前各号に附随する目的のため</p>
                    <p></p>
                    <p>第４条　個人情報利用の制限</p>
                    <p>当社は、あらかじめ会員ご本人の同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。合併その他の理由により個人情報を取得した場合にも、あらかじめ会員ご本人の同意を得ないで、承継前の利用目的の範囲を超えて取扱うことはありません。ただし、次の場合はこの限りではありません。</p>
                    <p>（１）法令に基づく場合</p>
                    <p>（２）人の生命、身体又は財産の保護のために必要がある場合であって、会員ご本人の同意を得ることが困難であるとき</p>
                    <p>（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、会員ご本人の同意を得ることが困難であるとき</p>
                    <p>（４）国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、会員ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
                    <p></p>
                    <p>第５条　個人情報の適正な取得</p>
                    <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。また、１５歳未満の子供から親権者の同意なく個人に関する情報をみだりに収集しないよう留意します。</p>
                    <p></p>
                    <p>第６条　個人情報の利用目的の変更</p>
                    <p>当社は、個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えては行わず、変更された利用目的について、会員に通知し又は公表します。</p>
                    <p></p>
                    <p>第７条　個人情報の安全管理・従業員の監督</p>
                    <p>当社は、個人情報の漏洩、滅失又はき損の防止その他の個人情報の安全管理が図られるよう、個人情報保護規程を定め、従業員に対する必要かつ適切な監督を行います。</p>
                    <p></p>
                    <p>第８条　委託先の監督</p>
                    <p>当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結、又は、当社が定める約款に合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>
                    <p></p>
                    <p>第９条　第三者提供の制限</p>
                    <p>当社は、次に掲げる場合を除くほか、あらかじめ会員ご本人の同意を得ないで、個人情報を第三者に提供しません。</p>
                    <p>（１）法令に基づく場合</p>
                    <p>（２）人の生命、身体又は財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
                    <p>（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
                    <p>（４）国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
                    <p>（５）予め次の事項を告知あるいは公表をしている場合</p>
                    <p>１．利用目的に第三者への提供を含むこと</p>
                    <p>２．第三者に提供されるデータの項目</p>
                    <p>３．第三者への提供の手段又は方法</p>
                    <p>４．ご本人の求めに応じて個人情報の第三者への提供を停止すること</p>
                    <p>ただし次に掲げる場合は上記に定める第三者には該当しません。</p>
                    <p>① 当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</p>
                    <p>② 合併その他の事由による事業の承継に伴って個人情報が提供される場合</p>
                    <p>③ 個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ会員ご本人に通知し、又は会員ご本人が容易に知り得る状態に置いているとき</p>
                    <p></p>
                    <p>第１０条　個人情報の開示</p>
                    <p>当社は、会員ご本人から個人情報の開示を求められたときは、会員ご本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、１件あたり３,000円の事務手数料を申し受けます。</p>
                    <p>（１）会員ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p>
                    <p>（２）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p>
                    <p>（３）その他法令に違反することとなる場合</p>
                    <p>前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。</p>
                    <p></p>
                    <p>第１１条　個人情報の訂正等</p>
                    <p>当社は、会員ご本人から、個人情報が真実でないという理由によって、内容の訂正、追加又は削除（以下「訂正等」といいます）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨会員ご本人に通知します。</p>
                    <p></p>
                    <p>第１２条　個人情報の利用停止等</p>
                    <p>当社は、会員ご本人から、会員ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、又は偽りその他不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨会員ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、会員ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>
                    <p></p>
                    <p>第１３条　理由の説明</p>
                    <p>当社は、会員ご本人からの要求にもかかわらず、</p>
                    <p>（１）利用目的を通知しない</p>
                    <p>（２）個人情報の全部又は一部を開示しない</p>
                    <p>（３）個人情報の利用停止等を行わない</p>
                    <p>（４）個人情報の第三者提供を停止しない</p>
                    <p>のいずれかを決定する場合、その旨会員ご本人に通知する際に理由を説明するよう努めます。</p>
                    <p></p>
                    <p>第１４条　お問い合わせ</p>
                    <p>当社のプライバシーポリシーに関するお問い合わせは、下記担当までお願い致します。</p>
                    <p>（お問い合わせ先）</p>
                    <p>〒153-0064 東京都目黒区下目黒1-5-21</p>
                    <p>シェイカー株式会社 TiTi プライバシーポリシー担当</p>
                    <p>support@shakr.org</p>
                    <p style={{marginBottom: 16,}}></p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPolicy);
