import React, {Fragment} from 'react';
import no_image from 'assets/no_image.png';
import member from 'assets/congregate/member.png';
import time from 'assets/congregate/time.png';

import './CommonCourseCell.css';

interface CommonCourseCellProps {
    active?: boolean; // 選択済みか
    image?: any;
    image_small?: any;
    onClick?: (e: any) => void;
    title?: string;
    sub_title?: string;
    price?: number;
    price_origin?: number;
    min_number?: number
    max_number?: number;
    time?: number;
    label?: string;
    terms?: string;
    tag_list?: string[];
}

interface CommonCourseCellStates {

}

class CommonCourseCell extends React.Component<CommonCourseCellProps, CommonCourseCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        var cursor = '';
        if (this.props.onClick) {
            cursor = ' cursor';
        }

        var c = 'box row' + cursor;
        if (this.props.active) {
            c = 'box row active' + cursor;
        }

        // const course_index = i;

        // 人数
        var max_number = null;
        if (this.props.min_number && this.props.max_number) {
            if (this.props.max_number != this.props.min_number) {
                max_number = `${this.props.min_number}〜${this.props.max_number}人`;
            } else {
                max_number = `${this.props.max_number}人限定`;
            }
        }

        // タグ
        var tag_list = [];
        if (this.props.tag_list) {
            for (let t of this.props.tag_list) {
                tag_list.push(<span className="tag">{t}</span>);
            }
        }

        //
        // console.log('this.props.', this.props);

        let show_price_origin: boolean = (this.props.price_origin != undefined && this.props.price_origin > 0);

        // console.log('this.props. show_price_origin', show_price_origin);

        return (
            <div className="col-12 CommonCourseCell course">
                <div className={c} onClick={this.props.onClick}>
                    {this.props.image &&
                    <img src={this.props.image} alt=""/>
                    }
                    {this.props.image_small &&
                    <img className="small" src={this.props.image_small} alt=""/>
                    }
                    <div className="course-detail">
                        {this.props.label &&
                        <p className="label">{this.props.label}</p>
                        }

                        {this.props.sub_title &&
                        <p className="desc">{this.props.sub_title}</p>
                        }
                        {this.props.title &&
                        <p className="title">{this.props.title}</p>
                        }
                        {tag_list}
                        <div>
                            {show_price_origin &&
                            <p className="price_origin">¥{Number(this.props.price_origin).toLocaleString()}</p>
                            }
                            {this.props.price &&
                            <p className="price">¥{Number(this.props.price).toLocaleString()} / 人</p>
                            }
                            {this.props.max_number &&
                            <p className="option"><img src={member} alt=""/> {max_number}</p>
                            }
                            {this.props.time &&
                            <p className="option"><img src={time} alt=""/> {this.props.time}分まで</p>
                            }
                        </div>
                        {this.props.terms &&
                        <p className="terms">{this.props.terms}</p>
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default CommonCourseCell;
