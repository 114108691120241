import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { actionCreator, RootState } from '../../reducers';

import './CreateEdit.css';

import Header from '../../components/Header';

import camera_image from 'assets/productCreation/camera.png'
import add_image from 'assets/productCreation/add.png'
import file_upload_tag from 'assets/productCreation/file_upload_tag.png'

import CommonImage from '../../components/CommonImage';

import { range, convertDateToIso } from '../../helpers/utils';

import TagsInput from 'react-tagsinput'

import CommunityManagementApi from '../../helpers/api/CommunityManagementApi';
import ProductCreationApi from '../../helpers/api/ProductCreationApi';
import ProductionApi from '../../helpers/api/ProductionApi';

import { PREF_LIST, CATEGORY_LIST_DICT } from '../../Construct.js';

import { Product } from '../../helpers/schemas/Api';

const imageCompression = require('browser-image-compression');

interface CreateProps {
    history: any;
    currentUser: any;
    showSpinner: (show: boolean) => void;
}

interface ProductDateRange {
    id?: number,
    _destroy?: boolean,
    start_time: string,
    end_time: string,
}

interface CreateStates {
    communityId?: number,
    communityName: string,
    productImageDataList: string[],
    schedulesAttributes: ProductDateRange[],
    tags: string[],
    // NOTE: create用
    productType: string,
    // NOTE: edit用
    productId: string,
}

interface PostProduct {
    type_of_product: string,
    title: string,
    price: number,
    description: string,
    notes: string,
    tag_list: string[],

    // time のみ
    place: string,
    required_time_day: number,
    required_time_hour: number,
    required_time_min: number,
    schedules_attributes: PostProductSchedulesAttribute[],
    // goods のみ
    shipping_fee_burden: number,
    shipping_method: string,
    from_shipping: string,
    number_of_item: number,
}

interface PostProductSchedulesAttribute {
    id?: number,
    _destroy?: number,
    start_time?: number[],
    end_time?: number[],
    all_day?: number,
}

const initPostProduct = (): PostProduct => {
    let _product = {
        type_of_product: '',
        title: '',
        price: 0,
        description: '',
        notes: '',
        tag_list: [],
        // time
        place: '',
        required_time_day: 0,
        required_time_hour: 0,
        required_time_min: 0,
        schedules_attributes: [],
        // goods
        shipping_fee_burden: 0,
        shipping_method: '',
        from_shipping: '',
        number_of_item: 0,
    }
    return _product
}

interface SelectOption { label: string, value?: string | number };

const categoryListDict: { [key: string]: SelectOption[] } = {
    time: CATEGORY_LIST_DICT.time.map(c => { return { label: c } }),
    goods: CATEGORY_LIST_DICT.goods.map(c => { return { label: c } }),
}

const shippingFeeBurdenList: SelectOption[] = [
    { label: '送料込み/出品者負担', value: 1 },
    { label: '着払い/購入者負担', value: 2 },
];

const shippingMethodList: SelectOption[] = [
    { label: 'クロネコヤマト' },
    { label: 'ゆうパック' },
    { label: '普通郵便' },
];

const shippingPrefList: SelectOption[] = PREF_LIST.map(c => { return { label: c } })

const makeDateRangeNow = () => ({ start_time: convertDateToIso(new Date()), end_time: convertDateToIso(new Date()) })

const toSelectDict = (array: any[], suffix?: string) => {
    return array.map(i => {
        return {
            value: i,
            label: `${i}${suffix}`,
        }
    });
}
const rangeSelectDict = (start: number, end: number, suffix?: string) => { return toSelectDict(range(start, end), suffix); }
// {return array.reduce((obj, x) => Object.assign(obj, { [key_maker(x)]: value_maker(x) }), {})}
const requireTime = {
    day: rangeSelectDict(0, 365, '日'),
    hour: rangeSelectDict(0, 23, '時間'),
    min: rangeSelectDict(0, 59, '分'),
}

console.log('requireTime', requireTime)

class CreateEdit extends React.Component<CreateProps, CreateStates> {

    _postProduct: PostProduct = initPostProduct();
    _category: string = 'default'

    constructor(props: any) {
        super(props);
        this.state = {
            communityName: props.match.params.name,
            productType: props.match.params.type,
            productId: props.match.params.id,
            productImageDataList: [],
            schedulesAttributes: [],
            tags: [],
        };
    }

    componentDidMount() {
        // NOTE: create
        if (this.state.communityName) {
            this._fetchCommunity();
        }
        if (this.state.productType) {
            this._postProduct.type_of_product = this.state.productType;
        }

        // NOTE: edit
        if (this.state.productId) {
            this._fetchProduct();
        }
    }

    _conevrtFetchedToPutProduct = (product: Product): PostProduct => {
        const _postProduct = {
            type_of_product: product.type_of_product,
            title: product.title,
            price: product.price,
            description: product.description,
            notes: product.notes,
            tag_list: product.tag_list,
            // time
            place: product.place,
            required_time_day: product.required_time_day,
            required_time_hour: product.required_time_hour,
            required_time_min: product.required_time_min,
            schedules_attributes: [],
            // goods
            shipping_fee_burden: product.shipping_fee_burden,
            shipping_method: product.shipping_method,
            from_shipping: product.from_shipping,
            number_of_item: product.number_of_item,
        }
        // this.setState({schedulesAttributes: product.schedules});
        return _postProduct;
    }

    _fetchProduct = () => {
        this.props.showSpinner(true);
        ProductionApi._fetchProductsShow(
            this.state.productId,
            {},
            (product: Product) => {
                this._postProduct = this._conevrtFetchedToPutProduct(product);
                if (product.tag_list.length > 0) {
                    this._category = product.tag_list[0];
                    this.setState({
                        tags: product.tag_list.slice(1),
                        productImageDataList: product.images.map(img => img.content.url),
                        communityId: product.community_id,
                        schedulesAttributes: product.schedules.map(d => {
                            return {
                                id: d.id,
                                start_time: convertDateToIso(new Date(d.start_time)),
                                end_time: convertDateToIso(new Date(d.end_time)),
                            }
                        }),
                    });
                }
                this.forceUpdate();
                this.props.showSpinner(false);
            },
            (error: any) => {
                alert(error);
                console.log(error);
                this.props.showSpinner(false);
            },
        )
    }

    _fetchCommunity = () => {
        CommunityManagementApi._fetchCommunitiesShow(
            this.state.communityName,
            {},
            (res: any) => {
                this.setState({ communityId: res.id })
            },
            (error: any) => {
                console.log(error);
                alert(error);
                this.props.history.push(`/community/${this.state.communityName}`);
            })
    }

    _addImageData = (index: number, data: any) => {
        let imageList = [...this.state.productImageDataList]
        if (index < imageList.length) {
            imageList[index] = data
        } else {
            imageList.push(data)
        }
        this.setState({
            productImageDataList: imageList,
        });
    }

    _handleChangeFile = (index: number) => (e: any) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        console.log('_handleChangeFile', index, e.target);
        if (!file) {
            return;
        }

        console.log('originalFile instanceof Blob', file instanceof Blob); // true
        console.log(`originalFile size ${file.size / 1024} KB`);
        const self = this;

        var options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        }
        imageCompression.default(file, options)
            .then(function (compressedFile: any) {
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob);
                console.log(`compressedFile size ${compressedFile.size / 1024} KB`);

                reader.onloadend = () => {
                    const res = `${reader.result}`
                    self._addImageData(index, res);
                }
                reader.readAsDataURL(compressedFile);

            })
            .catch(function (error: any) {
                console.log(error.message);
            });
    }

    _onPressImageDelete = (index: number) => (e: any) => {
        e.stopPropagation();
        const productImageDataList = this.state.productImageDataList.filter((_, i) => i !== index);
        this.setState({ productImageDataList })
    }

    _onChangeProductAttribute = (key: keyof PostProduct, value: any) => {
        this._postProduct[key] = value;
        console.log(`change postProduct ${key} ${value}`);
        console.log(this._postProduct);
    }

    _onChangeDate = (date: string, index: number, key: keyof ProductDateRange) => {
        let preDateList = this.state.schedulesAttributes;
        if (key == 'start_time' || key == 'end_time') {
            preDateList[index][key] = date;
            this.setState({ schedulesAttributes: [...preDateList] });
        }
    }

    _onPressAddDate = () => {
        this.setState({ schedulesAttributes: [...this.state.schedulesAttributes, makeDateRangeNow()] })
    }

    _onPressDeleteDate = (index: number) => {
        let newScheduleAttributes = this.state.schedulesAttributes;
        const item = newScheduleAttributes[index];
        console.log(index, item);
        // id持ちだったらput用なのでdestroyをつける
        if (item.id) {
            item._destroy = true;
        } else {
            newScheduleAttributes = newScheduleAttributes.splice(index, 1);
        }
        this.setState({ schedulesAttributes: newScheduleAttributes });
    }

    _isLastImageIndex = (index: number) => {
        const imageLength = this.state.productImageDataList.length
        return index == imageLength
    }

    _onTagChange = (tags: string[]) => {
        this.setState({ tags })
    }

    _onChangeCategory = (category: string) => {
        this._category = category;
        console.log('change category', this._category);
    }

    _errorMessages = () => {
        const { title, price, place,
            required_time_day, required_time_hour, required_time_min,
            shipping_fee_burden, shipping_method, from_shipping, number_of_item,
            description, type_of_product,
        } = this._postProduct;
        const { productImageDataList, communityId, tags } = this.state;
        const { _category } = this;
        var msgs = []

        if (!communityId) {
            msgs.push("コミュニティ名が不正です")
        }

        if (!title) {
            msgs.push("商品名を入力してください")
        }

        if (tags.length <= 0) {
            msgs.push("タグは一つ以上入力してください")
        }


        if (!description) {
            msgs.push("説明文を入力してください")
        }

        if (!_category) {
            msgs.push("カテゴリを選択してください")
        }

        if (type_of_product == 'time') {
            if (!place) {
                msgs.push("場所を入力してください")
            }

            if (required_time_day == 0
                && required_time_hour == 0
                && required_time_min == 0) {
                msgs.push("所要時間を入力してください")
            }
        } else if (type_of_product == 'goods') {
            if (shipping_fee_burden == 0) {
                msgs.push("配送料の負担を選択してください")
            }

            if (!shipping_method) {
                msgs.push("配送方法を入力してください")
            }

            if (!from_shipping) {
                msgs.push("配送元を選択してください")
            }

            if (number_of_item == 0) {
                msgs.push("商品の個数を1個以上で入力してください")
            }
        }

        if (productImageDataList.length == 0) {
            msgs.push("最低1つ画像をアップロードしてください")
        }

        if (!price || isNaN(price)) {
            msgs.push("値段を入力してください")
        }

        if (price < 100 || 1000000 < price) {
            msgs.push("値段は100円-1,000,000円の範囲で入力してください")
        }

        return msgs;
    }

    _dateToArray = (date: Date) => {
        return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()]
    }

    _onPressSubmit = () => {
        var error_msgs = this._errorMessages()
        if (error_msgs.length > 0) {
            error_msgs = error_msgs.map(msg => '・' + msg);
            alert(error_msgs.join("\n"));
            return
        }

        const { type_of_product } = this._postProduct;
        const { productImageDataList, schedulesAttributes, communityId, tags } = this.state;
        const { _category } = this;
        this._postProduct.tag_list = [_category, ...tags];

        this.props.showSpinner(true);

        if (type_of_product == 'time') {
            // スケジュール
            this._postProduct.schedules_attributes = schedulesAttributes.map(attr => {
                // POST
                let data: PostProductSchedulesAttribute = {
                    start_time: this._dateToArray(new Date(attr.start_time)),
                    end_time: this._dateToArray(new Date(attr.end_time)),
                    all_day: 0,
                }
                if (attr._destroy) { data._destroy = 1 }
                if (attr.id) { data.id = attr.id }
                return data
            })
            // timeに関係ないものを削除
            delete this._postProduct.shipping_fee_burden
            delete this._postProduct.shipping_method
            delete this._postProduct.from_shipping
            delete this._postProduct.number_of_item
        }

        // 画像
        const images = productImageDataList.map(image => {
            return { content: image }
        })

        // 最終的なデータ
        const data = {
            product: this._postProduct,
            community_ids: [communityId],
            images: images,
        }

        console.log(data);

        // PUT
        if (this.state.productId) {
            ProductionApi._fetchProductsEdit(
                this.state.productId,
                data,
                (res: any) => {
                    this.props.showSpinner(false);
                    this.props.history.push(`/product/${this.state.productId}`);
                },
                (error: any) => {
                    this.props.showSpinner(false);
                    console.log(error);
                    alert(error);
                },
            );
        } else {
            ProductCreationApi._fetchProducts(
                data,
                (res: any) => {
                    this.props.showSpinner(false);
                    alert('出品が成功しました！')
                    this.props.history.push(`/community/${this.state.communityName}`);
                },
                (error: any) => {
                    this.props.showSpinner(false);
                    console.log(error);
                    alert(error);
                },
            )
        }
    }

    render() {
        console.log('images:', this.state.productImageDataList);
        console.log('dates', this.state.schedulesAttributes);

        const onTextInputChange = (key: keyof PostProduct, isNum?: boolean) => (e: any) => {
            const value = isNum ? parseInt(e.currentTarget.value) : e.currentTarget.value
            this._onChangeProductAttribute(key, value)
            this.forceUpdate();
        }

        const { price, number_of_item, type_of_product, shipping_fee_burden, shipping_method, from_shipping,
            title, description, notes, place, required_time_day, required_time_hour, required_time_min } = this._postProduct;
        const reduction_rate_text = 5
        const sales_commissions = Math.round(price * 0.05) | 0
        const sales_profits = Math.round(price * 0.90) | 0
        const categoryList = categoryListDict[type_of_product]

        if (!type_of_product) return (<div></div>)

        return (
            <div className="Create row">
                <Header
                    title={this.state.productId ? '商品を編集する' : '商品を出品する'}>
                </Header>
                <form autoComplete='none'>
                    <FormSection>商品と説明</FormSection>
                    <FormOneLineInput name='name' placeholder='商品・イベント名（必須40文字まで）' onChange={onTextInputChange('title')} value={title} />
                    <TagsInput className='form-input-text input-tags' value={this.state.tags} onChange={this._onTagChange}
                        inputProps={{ placeholder: 'タグを追加（必須）' }} addKeys={[9, 13, 32, 35, 27, 188]} addOnBlur={true} />
                    <Border />
                    <FormMultiLineInput name='detail' placeholder='詳細（必須）' onChange={onTextInputChange('description')} value={description} />
                    <FormMultiLineInput name='note' placeholder='注意事項（任意）' onChange={onTextInputChange('notes')} value={notes} />
                    <FormSection>商品の詳細</FormSection>
                    <FormSelectInput name='category' label='カテゴリー' placeholder='必須' options={categoryList} onChange={e => {
                        this._onChangeCategory(e.currentTarget.value);
                        this.forceUpdate();
                    }} value={this._category} />
                    <Border />

                    {/* NOTE: time のみ */}
                    {type_of_product == 'time' &&
                        <div>
                            <FormOneLineInputWithLabel name='place' label='開催場所' placeholder='必須' onChange={onTextInputChange('place')} value={place} />
                            <div className='input-wrapper multi-select-list'>
                                <label className='multi-select-label'>所要時間</label>
                                <input className='form-input-text react-multi-select-input' name='name' pattern="[0-9]*" placeholder='日' type='number' onChange={onTextInputChange('required_time_day', true)} value={required_time_day} />
                                <input className='form-input-text react-multi-select-input' name='name' pattern="[0-9]*" placeholder='時間' type='number' onChange={onTextInputChange('required_time_hour', true)} value={required_time_hour} />
                                <input className='form-input-text react-multi-select-input' name='name' pattern="[0-9]*" placeholder='分' type='number' onChange={onTextInputChange('required_time_min', true)} value={required_time_min} />
                            </div>
                            <Border />
                            <div>
                                <div>
                                    {this.state.schedulesAttributes.map((d, i) => (!d._destroy && <div key={i}>
                                        <div className='form-input-date'>
                                            <div className='form-input-date-item-label'>開催日付{i + 1}</div> <div className='form-input-date-item-delete' onClick={e => this._onPressDeleteDate(i)}>×</div>
                                            <div className='form-input-date-item-wrapper'>
                                                <input className='form-input-date-item' name='date-from' type='datetime-local' value={d.start_time} onChange={e => this._onChangeDate(e.currentTarget.value, i, 'start_time')} />
                                                <div className='form-input-date-suffix'>から</div>
                                            </div>
                                            <div className='form-input-date-item-wrapper'>
                                                <input className='form-input-date-item' name='date-to' type='datetime-local' value={d.end_time} onChange={e => this._onChangeDate(e.currentTarget.value, i, 'end_time')} />
                                                <div className='form-input-date-suffix'>まで</div>
                                            </div>
                                        </div>
                                        <Border />
                                    </div>
                                    ))}
                                </div>
                                <div className='date-add-cell' onClick={this._onPressAddDate}>開催日時を追加する（任意）<CommonImage src={add_image} className='date-add' /></div>
                            </div>
                            <Border />
                        </div>
                    }

                    {/* NOTE: goods のみ */}
                    {type_of_product == 'goods' &&
                        <div>
                            <FormSelectInput name='shipping_fee_burden' label='配送料の負担' placeholder='必須' options={shippingFeeBurdenList}
                                onChange={onTextInputChange('shipping_fee_burden', true)} value={shipping_fee_burden > 0 ? shipping_fee_burden : 'default'} />
                            <Border />
                            <FormSelectInput name='shipping_method' label='配送方法' placeholder='必須' options={shippingMethodList}
                                onChange={onTextInputChange('shipping_method', false)} value={shipping_method || 'default'} />
                            <Border />
                            <FormSelectInput name='from_shipping' label='発送元' placeholder='必須' options={shippingPrefList}
                                onChange={onTextInputChange('from_shipping', false)} value={from_shipping || 'default'} />
                        </div>
                    }

                    <FormSection>写真</FormSection>
                    <div className='input-image-wrapper'>
                        {range(0, 3).map(i => (
                            <FormInputImage src={this.state.productImageDataList[i]} key={i} index={i}
                                showPlaceholder={this._isLastImageIndex(i)}
                                onChange={this._handleChangeFile(i)}
                                onPressDelete={this._onPressImageDelete(i)} />
                        ))}
                    </div>

                    {/* NOTE: goods のみ */}
                    {type_of_product == 'goods' &&
                        <div>
                            <FormSection>販売個数（1個から）</FormSection>
                            <FormOneLineInput right={true} name='number_of_item' placeholder='個数を入力してください' type='number' pattern="[0-9]*" unit='個'
                                onChange={onTextInputChange('number_of_item', true)} value={String(number_of_item)} />
                        </div>
                    }

                    <FormSection>販売価格（100-1,000,000円）</FormSection>
                    <FormOneLineInput right={true} name='price' placeholder='金額を入力してください' type='number' pattern="[0-9]*" unit='¥'
                        onChange={onTextInputChange('price', true)} value={String(price)} />
                    <FormSection></FormSection>

                    <FormLabelCell label={'還元率'} value={reduction_rate_text} unit='%' />
                    <FormLabelCell label={'販売手数料'} value={sales_commissions} unit='¥' />
                    <FormLabelCell label={'販売利益'} value={sales_profits} unit='¥' />

                    <FormSection></FormSection>
                    <SubmitButton onPress={this._onPressSubmit} />
                </form>
            </div>
        );
    }
}

const SubmitButton = ({ onPress }: { onPress?: (e: any) => void }) => (
    <div className='submit-button-wrapper'>
        <div className='submit-button' onClick={onPress}>
            <CommonImage src={file_upload_tag} className='submit-button-image' />
            投稿する
        </div>
    </div>
)

const FormInputImage = ({ index, showPlaceholder, onChange, onPressDelete, src }: { index: number, showPlaceholder: boolean, onChange?: (e: any) => void, onPressDelete?: (e: any) => void, src?: string }) => {
    return (
        <div className='input-image'>
            {src && <div className='input-image-delete' onClick={onPressDelete}>×</div>}
            <label className='input-image-content'>
                <div className='input-image-index'>{index + 1}</div>
                {src && <CommonImage src={src} className='input-image-image' />}
                {showPlaceholder &&
                    <div className='input-image-placeholder'>
                        <img className='input-image-logo' src={camera_image} />
                        <div>必須</div>
                    </div>}
                <input className='form-input-text' type="file" name="upfile" id="upfile" accept="image/*" onChange={onChange} />
            </label>
        </div>
    )
}

const FormSelectInput = ({ name, value, label, placeholder, options, onChange }: { name: string, value?: string | number, label?: string, placeholder?: string, options: SelectOption[], onChange?: (e: any) => void, }) => (
    <div className='input-wrapper'>
        <div style={{ overflow: 'hidden' }}>
            <label className='form-input-label'>{label}</label>
            <select className='form-input-text-with-label' onChange={onChange} dir='rtl' value={value}>
                <option className='select-option-disable' value='default' disabled style={{ display: 'none' }}>{placeholder}</option>
                {options.map(op => (<option value={op.value || op.label} key={op.label}>{op.label}</option>))}
            </select>
        </div>
    </div>
)

const FormSection = ({ children }: { children?: ReactNode }) => (<div className="form-section">{children}</div>)

const FormOneLineInput = ({ name, value, placeholder, type, onChange, pattern, right, unit }: { name: string, value?: string, placeholder?: string, type?: string, onChange?: (e: any) => void, pattern?: string, right?: boolean, unit?: string }) => {
    const _value = unit ? `${value}${unit}` : value
    const _formInputTextWrapperClassName = unit ? 'form-input-text-wrapper-with-unit' : ''
    const _inputClassName = unit ? 'form-input-text-with-unit' : 'form-input-text'
    return (<div className='input-wrapper'>
        <div className={_formInputTextWrapperClassName}>
            <input className={_inputClassName} style={{ textAlign: right ? 'right' : 'left' }} name={name} value={value} placeholder={placeholder} type={type} pattern={pattern} onChange={onChange} />
            {unit && <div className='form-input-text-unit'>{unit}</div>}
        </div>
        <Border />
    </div>)
}

const FormLabelCell = ({ label, value, unit }: { label: string, value: string | number, unit: string }) => (
    <div>
        <div className='input-wrapper form-label-cell'>
            <div className='form-label-cell-label'>{label}</div>
            <div className='form-label-cell-value'>{value} {unit}</div>
        </div>
        <Border />
    </div>
)

const FormOneLineInputWithLabel = ({ name, value, label, placeholder, type, onChange, pattern, unit }: { name: string, value?: string, label?: string, placeholder?: string, type?: string, onChange?: (e: any) => void, pattern?: string, unit?: string }) => {
    const _value = unit ? value + unit : value
    return (
        <div className='input-wrapper'>
            <div style={{ overflow: 'hidden' }}>
                <label className='form-input-label'>{label}</label>
                <input className='form-input-text-with-label' placeholder={placeholder} name={name} value={_value} type={type} pattern={pattern} onChange={onChange} />
            </div>
            <Border />
        </div>
    )
}

const FormMultiLineInput = ({ name, value, placeholder, onChange }: { name: string, value?: string, placeholder?: string, onChange?: (e: any) => void, }) => (
    <div className='input-wrapper'>
        <textarea className='form-input-text large' name={name} placeholder={placeholder} onChange={onChange} value={value}></textarea>
        <Border />
    </div>
)

const Border = () => (<div className='form-border' />)

const mapStateToProps = (state: RootState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEdit);
