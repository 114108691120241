import React from 'react';

import CommonIconTitle from '../../../components/CommonIconTitle';

import './StoreDetailProperties.css';

import icon_directions from 'assets/icon_directions.png';
import icon_bus from 'assets/icon_bus.png';
import icon_tel from 'assets/icon_tel.png';
import icon_seat from 'assets/icon_seat.png';
import icon_exit from 'assets/icon_exit.png';
import icon_smoking from 'assets/icon_smoking.png';
import icon_web from 'assets/icon_web.png';
import icon_card from 'assets/icon_card.png';

import { Store } from '../../../helpers/schemas/FireStore';

interface StoreDetailPropertiesProps {
    store: Store;
}

interface StoreDetailPropertiesStates {

}

class StoreDetailProperties extends React.Component<StoreDetailPropertiesProps, StoreDetailPropertiesStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="StoreDetailProperties col-12">
                {this.props.store.address &&
                <CommonIconTitle
                    src={icon_directions}
                    text={this.props.store.address}
                    map={this.props.store.address}
                />
                }
                {this.props.store.access &&
                <CommonIconTitle
                    src={icon_bus}
                    text={this.props.store.access}
                />
                }
                {this.props.store.phone_number &&
                <CommonIconTitle
                    src={icon_tel}
                    tel={this.props.store.phone_number}
                />
                }
                {this.props.store.seat_info &&
                <CommonIconTitle
                    src={icon_seat}
                    text={this.props.store.seat_info}
                />
                }
                {this.props.store.room_info &&
                <CommonIconTitle
                    src={icon_exit}
                    text={this.props.store.room_info}
                />
                }
                {this.props.store.smoking_info &&
                <CommonIconTitle
                    src={icon_smoking}
                    text={this.props.store.smoking_info}
                />
                }
                {this.props.store.hp_url &&
                <CommonIconTitle
                    src={icon_web}
                    text={this.props.store.hp_url}
                    onClick={() => {
                        if (this.props.store && this.props.store.hp_url) {
                            window.location.href = this.props.store.hp_url;
                        }
                    }}
                />
                }
                {this.props.store.payment &&
                <CommonIconTitle
                    src={icon_card}
                    text={this.props.store.payment}
                />
                }
            </div>
        );
    }
}

export default StoreDetailProperties;