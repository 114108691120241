import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

import './TalkBoard.css';
import TalkApi from '../../helpers/api/TalkApi';
import CommonImage from '../../components/CommonImage';
import DateUtil from '../../utils/DateUtil';
import { Talk, Message } from '../../helpers/schemas/Api';
import FontAwesome from 'react-fontawesome'
import ImageUtil, { Image } from '../../helpers/ImageUtil';
import Linkfy from 'react-linkify';

import {throttle} from 'lodash';
import Header from '../../components/Header';
import StorageHelper, { CURRENT_COMMUNITIE } from '../../helpers/StorageHelper';

interface TalkBoardProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showImagePreview: (show: boolean, images: string[], selectedIndex: number) => void;
}

interface TalkBoardStates {
    inputText: string;
    inputImages: Image[];
    talk?: Talk;
    messages: Message[];
    page: number;
    per: number;
    community_id: number;
}


class TalkBoard extends React.Component<TalkBoardProps, TalkBoardStates> {
    talkType = "community"
    is_loading = false
    is_loaded_last = false
    is_first_load = true
    community = StorageHelper.getData(CURRENT_COMMUNITIE);

    constructor(props: any) {
        super(props);
        this.state = {
            inputText: '',
            messages: [],
            inputImages: [],
            page: 1,
            per: 30,
            community_id: this.community.id,
        };
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
        this.props.showSpinner(true);
        this._fetch()
    }

    _fetch = () => {
        console.log('fetch talks')
        TalkApi._fetchTalks({talk_type: this.talkType},
            (res: any) => {
                console.log('fetched talks', res);
                const communityTalks = res.talks.filter((t:Talk) => t.community_id === this.state.community_id)
                if (communityTalks.length === 0) {
                    this._createTalk();
                    return
                }
                const talk: Talk = communityTalks[0];
                talk.created_at = new  Date(talk.created_at)
                // talk.updated_at = new Date(talk.updated_at)
                this.setState({ talk: talk });
                this._fetchMessages(talk.id);
            },
            (error: any) => {
                alert(error);
            });
    }

    _fetchMessages = (talk_id:number) => {
        console.log('fetchMessage:', this.state.page, this.state.per, this.is_loaded_last, this.is_loading);
        if (this.is_loaded_last || this.is_loading){
            return;
        }
        this.is_loading = true;
        TalkApi._fetchTalkMessages(talk_id, {
            page: this.state.page,
            per: this.state.per,
        }, 
            (res: any) => {
                console.log('fetched messages', res);
                for (let message of res.messages) {
                    message.created_at = new Date(message.created_at);
                    message.updated_at = new Date(message.updated_at);
                }
                if (res.messages.length === 0){
                    this.is_loaded_last = true;
                }
                const messages = [...this.state.messages, ...res.messages].sort(
                    (a, b) => {return b.created_at - a.created_at}
                )
                this.setState({
                    messages: messages,
                    page: this.state.page + 1
                });
                this.is_loading = false;
                this.props.showSpinner(false);
                if (this.is_first_load){
                    this._scrollMessagesBottom();
                }
                this.is_first_load = false;
            },
            (error: any) => {
                this.props.showSpinner(false);
                this.is_loading = false;
                alert(error);
            })
    }

    _postMessage = () => {
        if (this.state.inputText === ''){
            return;
        }
        this.props.showSpinner(true);
        const talk: Talk = this.state.talk!
        TalkApi._fetchTalkMessagesCreate(
            talk.id, 
            {
            content: this.state.inputText,
            images: this.state.inputImages.map(image => image.image),
        }, (res: any) => {
            console.log('post message: ', res);
            this.setState({
                messages: [res, ...this.state.messages], 
                inputText: '',
                inputImages: []
            });
            this._scrollMessagesBottom();
            this.props.showSpinner(false);
        }, (error: any) => {
            this.props.showSpinner(false);
            alert(error);
        })
    }

    _scrollMessagesBottom = () => {
        const messageList = document.getElementsByClassName('message-list')[0];
        messageList.scroll(0, messageList.scrollHeight);
    }

    _scrollBodyBottom = () => {
        window.scroll(0, document.documentElement.scrollHeight);
    }

    onChangeImageInput = (e: any) => {
        ImageUtil.convertEventToBase64(e, (image, index) => {
            console.log(image, index);
            const newImages = [...this.state.inputImages, image]
            if (newImages.length > 4) {
                alert('投稿できる画像は4枚までです');
                return;
            }
            this.setState({ inputImages: newImages });
            this._scrollBodyBottom();
        })
    }

    onClickCloseButton = (index: number) => {
        const newImages = this.state.inputImages.filter((_, _index) => _index !== index);
        this.setState({ inputImages: newImages });
    }

    _createTalk = () => {
        console.log('create talk')
        TalkApi._fetchTalksCreate({
            title: 'コミュニティ専用',
            talk_type: this.talkType,
            talk_relation_id: this.state.community_id,
            member: [],
        }, (responseJson: any) => {
            console.log('fetched talks create', responseJson);
            this._fetch();
        },
        (error: any) => {
            this.props.showSpinner(false);
            alert(error);
        });
    }

    componentWillUnmount() {
    }

    _onScroll = (e:any) => {
        if(!e.target){return;}
        // console.log('onScroll', e.target.scrollTop, e.target.offsetHeight, e.target.scrollHeight);
        const topMargin = e.target.scrollHeight - e.target.offsetHeight + e.target.scrollTop
        if (topMargin < 100 && this.state.talk) {
            this._fetchMessages(this.state.talk.id);
            console.log('onScroll try fetch');
        }
    }

    onClickImage = (images: string[], selectedIndex: number) => {
        this.props.showImagePreview(true, images, selectedIndex);
    }

    _renderMessage = () => {
        
    }

    render() {
        console.log(this.state.messages);
        const _throttle = throttle(this._onScroll, 500);
        return (
            <div className="TalkBoard row">
                <Header
                    title={`${this.community.name}の掲示板`}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                <div className="board-margin"/>
                <div className="message-list" onScroll={(e) => {
                    e.persist();
                    _throttle(e);
                }}>
                    <div style={{flex: 1}} />
                    {this.state.messages.map((m, i) => {
                        let section = null;
                        if (this.state.messages.length === i + 1
                            || ((DateUtil.parseDateForTalkboard(m.created_at) 
                               !== DateUtil.parseDateForTalkboard(this.state.messages[i+1].created_at)))){
                                   section = <MessageSection text={DateUtil.parseDateForTalkboard(m.created_at)} />
                               }
                        return (<Fragment key={i}>
                            <MessageItem message={m} key={m.id} onClickImage={this.onClickImage}/>
                            {section}
                        </Fragment>)
                    })}
                </div>
                <div style={{height: '160px', width: '100%'}}/>
                <InputForm 
                    text={this.state.inputText} 
                    onTextChange={(e) => {this.setState({inputText: e.target.value})}}
                    onClickSubmit={this._postMessage}
                    onChangeImageInput={this.onChangeImageInput}
                    onClickClose={this.onClickCloseButton}
                    images={this.state.inputImages}
                />
            </div>
        );
    }
}

const MessageSection = ({text}:{text:string}) => (
    <div className='message-section'>{text}</div>
)

const InputForm = ({text, onTextChange, onClickSubmit, onChangeImageInput, images, onClickClose}:
    {text: string, onTextChange: (e:any)=>void, onClickSubmit:()=>void, 
        onChangeImageInput:(e: any)=>void, images: Image[], onClickClose:(index: number)=>void}) => (
    <div className='input-form'>
        <textarea
            className='input-textarea'
            placeholder="掲示板に書き込む"
            rows={10}
            onChange={onTextChange}
            value={text}
        />
        {images.length > 0 && <ImagePreview images={images} onClickClose={onClickClose}/>}
        <div className='input-footer'>
            <div className='submit-button' onClick={onClickSubmit}>送信</div>
            <label className='image-label'>
                <FontAwesome name='image' className="image-icon" />
                <input
                    className="image-input-form"
                    type="file"
                    accept="image/jpeg,image/png"
                    onChange={onChangeImageInput}
                    multiple
                />
            </label>
        </div>
    </div>)

const ImagePreview = ({images, onClickClose}: {images: Image[], onClickClose:(index: number)=>void}) => (<div className='image-preview'>
    {images.map((image, index) => <div className='image-item' key={index}>
        <CommonImage className='image' src={image.image}/>
        <div className='close-button' onClick={() => {onClickClose(index)}}>
            <FontAwesome name='times-circle' className="close-button-icon" />
        </div>
    </div>)}
</div>)


const MessageItem = ({message, onClickImage}: 
    {message: Message, onClickImage:(images:string[], selectedIndex:number)=>void}) => (
    <div className="message-wrapper">
        <div className="message">
            <div className="message-left">
                <CommonImage src={message.avatar_url} className="user-icon"/>
            </div>
            <div className="message-right">
                <div className="message-top">
                    <div className="user-name">{message.nickname}</div>
                    <div className="date">{DateUtil.parseDateWithAgo(message.created_at)}</div>
                </div>
                <div className="comment"><Linkfy>{message.content}</Linkfy></div>
                {message.image_urls && message.image_urls.length > 0 
                && <MessageImageList imageUrls={message.image_urls} onClickImage={onClickImage}/>}
            </div>
        </div>
    </div>)

const MessageImageList = ({imageUrls, onClickImage}:{imageUrls: string[], 
    onClickImage:(images:string[], selectedIndex:number)=>void}) => (
<div className="message-image-list">
    {imageUrls.map((url, i) => 
    <CommonImage key={i} src={url} className="message-image" 
    onClick={() => {onClickImage(imageUrls, i)}}/>
    )}
</div>)

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showImagePreview: (show: boolean, images: string[], selectedIndex: number) => {
            dispatch(actionCreator.imagePreview.imagePreview({
                show: show,
                images: images,
                selectedIndex: selectedIndex,
            }));
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TalkBoard);
