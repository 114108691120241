import React, {useState, Fragment, Component} from 'react';
import {range, _dateToString} from '../../helpers/utils';
import StorageHelper, {CURRENT_USER, DEFAULT_PATH, SOURCE_VER, USER_COMMUNITIES, DEFAULT_HREF} from '../../helpers/StorageHelper';

import CongregateCreate from '../../containers/congregate/CongregateCreate';
import StoreDetail from '../../containers/store/StoreDetail';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';

interface StoreRouterProps {
    history: any;
    setCurrentUser: (data: any) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    // currentUser: {
    //     communities?: {
    //         id: string;
    //         name: string;
    //         communities_users: {}[];
    //         community_image?: {
    //             image: {
    //                 content: {
    //                     url: string;
    //                 }
    //             }
    //         };
    //     }[] | null;
    // };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
        communities?: {
            id: string;
            name: string;
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
            communities_users: {
                id: number;
                user_id: string;
                nickname?: string;
                avatar_url?: string;
            }[];
        }[] | null;
    };
}

interface StoreRouterState {

}

class StoreRouter extends React.Component<StoreRouterProps, StoreRouterState> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    render() {

        /**
         * 認証判定
         * トークンが保存されている場合認証されているとみなす
         * @type {boolean}
         */

        let user = StorageHelper.getData(CURRENT_USER);
        let isAuthenticated = user ? user.isAuthenticated : false;
        let isRegistered = user ? user.isRegistered : false;

        return (isAuthenticated ? <CongregateCreate {...this.props}/> : <StoreDetail {...this.props}/>);
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        // currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        // setCurrentUser: (data: any) => {
        //     dispatch(actionCreator.currentUser.currentUser(data));
        // },
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        // showSpinner: (show: boolean) => {
        //     dispatch(actionCreator.spinner.spinner({
        //         show: show,
        //     }));
        // },
        // showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
        //     dispatch(actionCreator.dialog.showDialog({
        //         show: true,
        //         title: title,
        //         text: text,
        //         onClickYes: onClickYes,
        //         onClickNo: onClickNo,
        //     }));
        // },
        // hideDialog: () => {
        //     dispatch(actionCreator.dialog.hideDialog());
        // },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StoreRouter);