import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import StorageHelper, {CURRENT_USER, CURRENT_COMMUNITIE} from '../../helpers/StorageHelper';
import {Link} from 'react-router-dom';

import no_image from 'assets/no_image.png';
import message from 'assets/menu/message.png';
import add from 'assets/menu/add.png';
import all from 'assets/menu/all.png';
import cart from 'assets/menu/cart.png';
import present from 'assets/menu/present.png';
import notification from 'assets/menu/notification.png';
import arrow_right from 'assets/arrow_right.png';


import UsersCommunityApi from '../../helpers/api/UsersCommunityApi';

import './Menu.css';

const CREATE_COMMUNTY_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSd8tMIHBLiylE1rUBAulMcCHckP_nCoOTfbP5ldD8ucpHha3w/viewform?usp=sf_link';

interface MenuProps {
    // history: any;
    onSetSidebarOpen: (open: boolean) => void;
    currentUser: {
        name: string;
        token: string;
        user_id: string;
        avatar_url?: string | null;
        profile?: {
            address: string;
            birth: string;
            email: string;
            first_name: string;
            gender: string;
            last_name: string;
            user_id: number;
        } | null;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                user_id: string;
                nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface MenuStates {
    open: boolean;
}

class Menu extends React.Component<MenuProps, MenuStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount() {

    }

    render() {

        // console.log('Menu props', this.props);
        // console.log('Menu state', this.state);

        let user = StorageHelper.getData(CURRENT_USER);
        let communitie = StorageHelper.getData(CURRENT_COMMUNITIE);
        console.log('community: ', communitie);

        let image = this.props.currentUser.avatar_url || no_image;
        let user_name = this.props.currentUser.profile ? `${this.props.currentUser.profile.last_name} ${this.props.currentUser.profile.first_name}` : '';

        var head = (
            <div className="cursor">
                <div className="community-image">
                    <img src={all}/>
                </div>
                <div className="community-box">
                    <p className="community-name">すべてのコミュニティ</p>
                </div>
                <div className="arrow-box">
                    <img src={arrow_right} className="arrow"/>
                </div>
            </div>
        );

        var list = [];
        if (this.props.currentUser.communities) {
            for (let data of this.props.currentUser.communities) {
                let name = data ? data!.name! : '';

                let community_image = no_image;
                if (data.community_image) {
                    community_image = data.community_image!.image!.content!.url || no_image;
                }

                if (communitie && data.id == communitie.id) {
                    head = (
                        <div className="col-12 community">
                            <div className="community-image">
                                <img src={community_image}/>
                            </div>
                            <div className="community-box">
                                <p className="community-name">{name}</p>
                                <p className="community-count">参加メンバー {data.communities_users.length}名</p>
                            </div>
                            <div className="arrow-box">
                                <img src={arrow_right} className="arrow"/>
                            </div>
                        </div>
                    );
                }

                let link = `/community/${data.name}`;

                list.push(
                    <div key={name} className="col-12 community cursor" onClick={() => {
                        window.location.href = link;
                    }}>
                        <div className="community-image">
                            <img src={community_image}/>
                        </div>
                        <div className="community-box">
                            <p className="community-name">{name}</p>
                            <p className="community-count">参加メンバー {data.communities_users.length}名</p>
                        </div>
                    </div>
                );
            }
        }

        return (
            <nav className="Menu row">
                <div className="col-12 user">
                    <Link to="/mypage" className="cursor">
                        <img src={image} className="user-image"/>
                        <div className="user-name">
                            {user_name}
                        </div>
                    </Link>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 community">
                            <div className={'row ' + (this.state.open ? 'open' : '')} onClick={() => {
                                this.setState({
                                    open: !this.state.open,
                                });
                            }}>
                                {head}
                            </div>
                        </div>
                    </div>
                </div>

                { this.state.open &&
                <div className="col-12 select-area">
                    <div className="row">
                        <div className="col-12 community">
                            <div className="row select-list">
                                <div className="col-12 community cursor" onClick={() => {
                                    window.location.href = '/';
                                }}>
                                    <div className="community-image">
                                        <img src={all}/>
                                    </div>
                                    <div className="community-box">
                                        <p className="community-name">すべてのコミュニティ</p>
                                    </div>
                                </div>

                                { list.length > 0 && list }

                                {/*
                                <div className="col-12 community add">
                                    <Link to="/community/create/edit" className="cursor">
                                        <div className="community-image">
                                            <img src={add}/>
                                        </div>
                                        <div className="community-box">
                                            <p className="community-name">コミュニティを追加</p>
                                        </div>
                                    </Link>
                                </div>
                                 */}
                            </div>
                        </div>
                    </div>
                </div>
                }

                <div className="col-12">

                    <p className="common-title">メニュー</p>

                    <div className="row">
                        <div className="col-12 common">
                            {/*<Link to="/talk" className="cursor">*/}
                            {/*<div className="common-image">*/}
                            {/*<img src={message}/>*/}
                            {/*</div>*/}
                            {/*<div className="common-box">*/}
                            {/*<p className="title">メッセージ</p>*/}
                            {/*</div>*/}
                            {/*</Link>*/}


                            <Link to={communitie ? `/community/${communitie.name}` : '/' } className="cursor">
                                <div className="common-box">
                                    <p className="title">ホーム</p>
                                </div>
                            </Link>
                            {communitie && 
                            <Link to={`/community/${communitie.name}/talkboard`} className="cursor">
                                <div className="common-box">
                                    <p className="title">掲示板</p>
                                </div>
                            </Link>}
                            {communitie &&
                            <Link to={`/community/${communitie.name}/event_list`} className="cursor">
                                <div className="common-box">
                                    <p className="title">参加予定のイベント</p>
                                </div>
                            </Link>
                            }
                            {communitie &&
                            <Link to={`/community/${communitie.name}/detail`} className="cursor">
                                <div className="common-box">
                                    <p className="title">コミュニティ情報</p>
                                </div>
                            </Link>
                            }
                            <Link to={`/notification`} className="cursor">
                                <div className="common-box">
                                    <p className="title">通知</p>
                                </div>
                            </Link>
                            {/*<Link to="/mypage/product" className="cursor">*/}
                            {/*<div className="common-image">*/}
                            {/*<img src={present}/>*/}
                            {/*</div>*/}
                            {/*<div className="common-box">*/}
                            {/*<p className="title">出品した商品</p>*/}
                            {/*</div>*/}
                            {/*</Link>*/}
                            {/*<Link to="/mypage/purchase" className="cursor">*/}
                            {/*<div className="common-image">*/}
                            {/*<img src={cart}/>*/}
                            {/*</div>*/}
                            {/*<div className="common-box">*/}
                            {/*<p className="title">購入した商品</p>*/}
                            {/*</div>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
