import React from 'react';
import BaseApi from './BaseApi';

export default class UserManagementApi extends BaseApi {

    /**
     * /users/:id
     */
    static _fetchUsers(id, params, success, error) {
        super._fetch('/api/v1/users/' + id , 'GET', params, success, error);
    }

    /**
     * /users/:id/products
     */
    static _fetchUsersProducts(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/products', 'GET', params, success, error);
    }

    /**
     * /users/:id/purchase
     */
    static _fetchUsersPurchase(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/purchase', 'GET', params, success, error);
    }

    /**
     * /users/:id/edit
     */
    static _fetchUsersEdit(id, params, success, error) {
        super._fetch('/api/v1/users/' + id + '/edit', 'GET', params, success, error);
    }

    /**
     * /users/registration/logout
     */
    static _fetchUsersRegistrationLogout(params, success, error) {
        super._fetch('/api/v1/users/registration/logout', 'DELETE', params, success, error);
    }

    /**
     * /users/registration
     */
    static _fetchUsersRegistration(params, success, error) {
        super._fetch('/api/v1/users/registration', 'DELETE', params, success, error);
    }

    /**
     *  PUT /users/registration 
     */
    static _fetchUsersRegistrationUpdate(params, success, error) {
        super._fetch('/api/v1/users/registration', 'PUT', params, success, error);
    }

    /**
     *  POST /users/activation
     */
    static _fetchUsersActivation(params, success, error) {
        super._fetch('/api/v1/users/activation', 'POST', params, success, error);
    }

    /**
     *  POST /users/invitations
     */
    static _fetchUsersInvitations(params, success, error) {
        super._fetch('/api/v1/users/invitations', 'POST', params, success, error);
    }

}
