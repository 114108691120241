import React from 'react';
import StorageHelper, {CURRENT_USER} from '../StorageHelper';
import { BASE_URL } from '../../Construct.js';

import firebase, {auth} from "../../firebase";

// trueでセッション切れ時のアラートの多重表示を禁止する
window.alert_cancel = false;

export default class BaseApi {

    static _parse_to_curl(BASE_URL, url, options, token, query){
        // デバッグ用。curlですぐ呼び出せるように。

        var token = token ? `?access_token=${token}${query}` : ``;

        var c = `curl -X ${options.method} \\\n`
        c += `'${BASE_URL + url + token}' \\\n`
        c += `-H 'cache-control: no-cache' \\\n`
        c += `-H 'content-type: application/json'`
        if (options.body){
            c += ' \\\n'
            c += `-d '${options.body}'`
        }
        return c
    }

    static _fetch(url, method, params, success_callback, error_callback) {
        const self = BaseApi;

        let currentUser = StorageHelper.getData(CURRENT_USER);

        let token = currentUser ? currentUser.access_token : '';

        // StorageUtil._getAuthToken( (token) => {

            // version
            params.version = 1; // TODO 定数化

            // option
            let options = {
                    method: method,
                    headers: {
                        'AuthToken': token, // TODO APIの仕様に合わせる
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                };

            let is_query_methods = ['GET'].indexOf(method) >= 0;

            if (token && is_query_methods) {
                params.access_token = token;
            }

            var query = ''
            if (is_query_methods){
                let ignore_params = ['access_token', 'version']
                query = Object.keys(params).map(function(key){
                    return ignore_params.indexOf(key) >= 0 ? '' : `${key}=${params[key]}`
                }).filter(q => q != '').join('&')
                query = query == '' ? '' : '&' + query
            }

            // set body
            if (!is_query_methods) {
                options.body = JSON.stringify(params);
            }

            console.log('fetch:' + BASE_URL + url, params);
            console.log(options);
            console.log(self._parse_to_curl(BASE_URL, url, options, token, query))

            var t = token ? ('?access_token=' + token) : '';

            // fetch
            fetch(BASE_URL + url + t + query, options) // access_token暫定付与
                .then((response) => {

                    console.log('response', response);

                    // 強制アップデート
                    if (response.status == 426) {
                        console.log('Update your app');
                        return {
                            success: true,
                            update: true,
                        };
                    }
                    return response.json();
                })
                .then((responseJson) => {
                    console.log('response success');
                    console.log(responseJson);
                    if (responseJson.success == undefined || responseJson.success == true) { // エラー時のみsuccess = falseが入る
                        success_callback(responseJson);
                    } else if (responseJson.success == false &&responseJson.message == 'Invalid Token') {
                        // TODO エラー文言でログアウト判断している。エラーコードで対応したい
                        // auth.signOut();
                        StorageHelper.remove(CURRENT_USER);
                        window.location.href = '/signin';
                        if (window.alert_cancel) return;
                        window.alert_cancel = true;
                        error_callback('ログインセッションが切れました。');
                    } else if (responseJson.success == false && responseJson.message == 'Couldn\'t find User') {
                        // TODO エラー文言で会員未登録を判断している。エラーコードで対応したい
                        console.log('responseJson message', responseJson.message);
                        error_callback(null);
                    } else if (responseJson.message != undefined) {
                        error_callback(responseJson.message);
                    } else {
                        error_callback();
                    }
                })
                .catch((error) => {
                    console.log('response error');
                    console.log(error);
                    if (error.message) {
                        error_callback(error.message);
                    } else {
                        error_callback();
                    }
                });
        // });
    }
}
