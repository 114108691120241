import React from 'react';
import BaseApi from './BaseApi';

export default class NotificationsApi extends BaseApi {

    /**
     * GET /api/v1/notifications
     */
    static _fetchNotifications(params, success, error) {
        super._fetch('/api/v1/notifications', 'GET', params, success, error);
    }

    /**
     * POST /api/v1/notifications/:notification_id/read
     */
    static _fetchNotificationsRead(notification_id, params, success, error) {
        super._fetch('/api/v1/notifications/' + notification_id + '/read', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/notifications/:notification_id/unread
     */
    static _fetchNotificationsUnread(notification_id, params, success, error) {
        super._fetch('/api/v1/notifications/' + notification_id + '/unread', 'POST', params, success, error);
    }
}
