import React from 'react';

import './CommonUserCell.css';

interface CommonUserCellProps {
    active?: boolean; // 選択済みか
    image?: any;
    image_detal?: any;
    onClick?: (e: any) => void;
    title?: string;
    text?: string;
    sub_title?: string;
}

interface CommonUserCellStates {

}

class CommonUserCell extends React.Component<CommonUserCellProps, CommonUserCellStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        var cursor = '';

        if (this.props.onClick) {
            cursor = ' cursor';
        }

        var c = 'box row' + cursor;
        if (this.props.active) {
            c = 'box row active' + cursor;
        }

        return (
            <div className="col-12 cell CommonUserCell" onClick={this.props.onClick}>
                <div className={c}>
                    {this.props.image &&
                    <img src={this.props.image} className="user-image"/>
                    }
                    {this.props.image_detal &&
                    <img src={this.props.image_detal} className="detail-image"/>
                    }
                    <div className="user-detail">
                        {this.props.title &&
                        <p className="title">{this.props.title}</p>
                        }
                        {this.props.text &&
                        <p className="text">{this.props.text}</p>
                        }
                        {this.props.sub_title &&
                        <p className="desc">{this.props.sub_title}</p>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonUserCell;
