import React from 'react';
import './CommonComments.css';
import no_image from 'assets/no_image.png';
import { Comment } from '../../../helpers/schemas/FireStore';

interface CommonCommentsProps {
    comments: Comment[];
    tag?: string;
}

interface CommonCommentsStates {
    selectedComment: number;
}

class CommonComments extends React.Component<CommonCommentsProps, CommonCommentsStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            selectedComment: 0,
        };
    }

    changeComment = (num: number) => {
        this.setState({selectedComment: num});
    }

    render() {
        let user_image_list = [];
        let selectedComment = this.props.comments[this.state.selectedComment];

        user_image_list = this.props.comments.map( (comment, index) => {
            let img = no_image;

            if (comment.user_image_url) {
                img = comment.user_image_url;
            }

            if (index == this.state.selectedComment) {
                return (<img src={img} className="selected" alt="" onClick={ () => {this.changeComment(index)} }/>);
            } else {
                return (<img src={img} alt="" onClick={ () => {this.changeComment(index)} }/>);
            }
        });

        return (
            <div className="CommonComments col-12">
                {this.props.tag &&
                <div>
                    {this.props.tag}（{this.props.comments.length}名）
                </div>
                }
                <div className="user-image-list">
                    {user_image_list}
                </div>
                <div className="user-comment-list">
                    <div className="user-comment-cell">
                        <div className="user-name">
                            {selectedComment.user_name}
                        </div>
                        <div className="user-comment">
                            {selectedComment.comment}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonComments;