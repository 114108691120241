import React from 'react';
import {Transition, CSSTransition} from 'react-transition-group';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../reducers';

import './Spinner.css';
import FontAwesome from "react-fontawesome";

interface SpinnerProps {
    spinner: {
        show: boolean;
    }
}

interface SpinnerStates {

}

class Spinner extends React.Component<SpinnerProps, SpinnerStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <CSSTransition in={this.props.spinner.show} timeout={100} classNames="fade" unmountOnExit>
                <div className="Spinner">
                    <div className="box">
                        <FontAwesome
                            name='spinner'
                            size='2x'
                            spin
                        />
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        spinner: state.spinner.spinner,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {}
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Spinner);