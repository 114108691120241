import React from 'react';

import logo from 'assets/logo.png';

import './Guide.css';

interface GuideProps {
    onClick?: (e: any) => void;
}

interface GuideStates {

}

class Guide extends React.Component<GuideProps, GuideStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="Guide col-12">
                <div className="guide-container cursor" onClick={this.props.onClick}>
                    <h1>使い方ガイド</h1>
                    <div className="col-12">
                        <img src={ logo }/>
                    </div>
                    <p>コミュニティで、お得に楽しくあつまろう</p>
                </div>
            </div>
        );
    }
}

export default Guide;
