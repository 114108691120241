import {spinner, SpinnerAction} from './Spinner';

type Actions
    = SpinnerAction;

export type State = {
    spinner: {
        show: boolean;
    };
};

const init = (): State => {
    return {
        spinner: {
            show: false,
        }
    };
};

export const reducer = (state: State = init(), action: Actions) => {
    switch (action.type) {
        case 'SPINNER':
            return {
                spinner: {
                    show: action.payload.show,
                },
            };
        default:
            return state;
    }
};

export const actionCreator = {
    spinner,
};