import moment from 'moment';
import 'moment/min/locales'
moment.locale('ja');

export default class DateUtil {
    static parseDateWithAgo(date:Date, from_ago=15){
        // 直近の日付は「●日前」
        // 昔の日付は「2019年10月1日」のように整形する
        // from_agoで直近を指定。
        const _date = moment(date);
        const before_now = moment().subtract(from_ago, 'd')
        if (_date.isAfter(before_now)) {
            return _date.fromNow()
        } else {
            return _date.format('LL');
        }
    }

    static parseDateForTalkboard(date: Date){
        const _date = moment(date);
        const _today = moment();
        const diffDays = _today.diff(_date, 'days')
        switch (diffDays) {
            case 0:
                return 'Today';
            case 1:
                return 'Yesterday';
            default:
                return _date.format('M月D日');
        }
    }
}