import firebase from "../firebase";
import { firestore } from "../firebase";
import {
    Store,
    Event,
    FavorStoreUser,
    CommunityInfo,
    FavorStore,
    Campaign,
} from "./schemas/FireStore";

class FireStoreHelper {

    // translate to the new line codes
    translateToNewLine = (str: string) => {
        if (str) {
            return str.replace(/\\n/g, '\n');
        } else {
            return null;
        }
    };

    // NOTE: Data Transform

    docToData = (doc: any) => {
        if (doc.exists) {
            return { id: doc.id, ...doc.data() };
        } else {
            return null;
        }
      };

    refToDataList = async (
        ref: firebase.firestore.CollectionReference | firebase.firestore.Query
      ): Promise<any[]> => {
        return (await ref.get()).docs.map((doc) => {
          return this.docToData(doc);
        });
      };

    // NOTE: Get all docs
    
    getDocsDataAll = async <T extends {}>(
        collectionPath: string
        ): Promise<T[]> => {
            return this.refToDataList(firestore.collection(collectionPath));
        };
        
    getStoresAll = async (): Promise<Store[]> => {
        // 全件取得: コミュニティのご贔屓店舗
        return this.getDocsDataAll<Store>("stores");
    };

    getEventsAll = async (): Promise<Event[]> => {
        // 全件取得: 過去イベント
        return this.getDocsDataAll<Event>("events");
    };

    getCampaignsAll = async (): Promise<Campaign[]> => {
        // 全件取得: キャンペーン
        return this.getDocsDataAll<Campaign>("campaigns");
    };

    getFavorStoresAll = async (): Promise<FavorStore[]> => {
        // 全件取得: ユーザのご贔屓店舗
        // NOTE: ユーザは getFavorStoreUserInfo より取得
        return this.getDocsDataAll<FavorStore>("favor_stores");
    };

    getCampaignAll = async (): Promise<Campaign[]> => {
        return this.getDocsDataAll<Campaign>("campaigns");
    };

    // Note: Get doc
    getStore = async (id: string): Promise<Store> => {
        // 特定の店舗取得
        const doc = await firestore.collection('stores').doc(id).get();

        // 改行コードを置換
        let data = this.docToData(doc);
        data.description = this.translateToNewLine(data.description);

        return data;
    };

    getEvent = async (id: string): Promise<Event> => {
        // 特定のイベント取得
        const doc = await firestore.collection('events').doc(id).get();
        console.log('getEvent', doc);

        // 改行コードを置換
        let data = this.docToData(doc);
        data.pre_event_announce_content = this.translateToNewLine(data.pre_event_announce_content);
        data.store_reply_comment = this.translateToNewLine(data.store_reply_comment);

        return data;
    };

    getCampaign = async (id: string): Promise<Campaign> => {
        // 特定のキャンペーン取得
        const doc = await firestore.collection('campaigns').doc(id).get();
        console.log('getCampaign', doc);

        // 改行コードを置換
        let data = this.docToData(doc);
        data.description = this.translateToNewLine(data.description);

        return data;
    };

    getEventsByStoreName = async (store_name: any): Promise<Event[]> => {
        return this.refToDataList(
            firestore.collection('events').where('store_name', '==', store_name).orderBy('date', 'desc')
        );
    };

    // NOTE: Get Info

    getCommunityInfo = async (): Promise<CommunityInfo> => {
        // コミュニティ情報取得
        const doc = firestore.collection('info').doc('community');
        return this.docToData(doc);
    }

    getFavorStoreUserInfo = async (): Promise<FavorStoreUser> => {
        const doc = await firestore.collection('info').doc('favor_store_user').get();
        return this.docToData(doc);
    }

}

const fireStoreController = new FireStoreHelper();
export default fireStoreController;