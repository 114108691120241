import React, {useState, Fragment} from 'react';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import Swiper from 'react-id-swiper';
import Moment from 'moment';
import FontAwesome from "react-fontawesome";
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";
import {JOINED_STATUS, APPROVAL_STATUS} from '../../Construct';

import CommunityTop from '..//nigata-kenzin/community/CommunityTop';

import './Congregate.css';

// redux
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import Modal from '../../components/Modal';

// import NewsApi from '../../helpers/api/NewsApi';
import StoresApi from '../../helpers/api/StoresApi';
import CongregationsApi from '../../helpers/api/CongregationsApi';
import StorageHelper, {CURRENT_COMMUNITIE} from '../../helpers/StorageHelper';

import Header from '../../components/Header';
import CongregateSwiper from '../../components/CongregateSwiper';
import CommonSegmentButton from '../../components/CommonSegmentButton';
import {string} from "prop-types";
import no_image from 'assets/no_image.png';
import no_community from 'assets/no_community.png';
import success from 'assets/congregate/success.png';
import logo from 'assets/logo-m.png';
import icon_user from 'assets/icon_user.png';
import arrow_right from 'assets/arrow_right.png';
import newgame from 'assets/newgame_logo.png';
import no_home_data from 'assets/no_home_data.png';
import TalkBoard from '../talk/TalkBoard';
import { Event } from '../../helpers/schemas/Api';
import { range } from '../../helpers/utils';
import CommonImage from '../../components/CommonImage';

const PER = 20;

interface CongregateProps {
    community_id?: number;
    history: any;
    setCurrentUser: (name: string, token: string) => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                user_id: string;
                nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface CongregateState {
    swiper: any | null,
    render: boolean;

    load_operator_event: boolean;
    load_user_event: boolean;

    hide_tip: boolean;
    community_id?: number;
    community_name?: string;
    nickname?: string;

    operator_events: Event[];
    user_events: Event[];
    
    communities_users: {
        avatar_url: string;
    }[];
}

class Congregate extends React.Component<CongregateProps, CongregateState> {

    private tip = 0;
    private tip1: any;
    private tip2: any;

    constructor(props: any) {
        super(props);

        var community_id = undefined;
        var community_name = undefined;
        var communities_users: any[] = [];
        var nickname = undefined;
        if (this.props.currentUser.communities) {
            for (let c of this.props.currentUser.communities) {
                if (c.name == props.match.params.name) {

                    // 選択中のコミュニティをセットする
                    StorageHelper.setData(CURRENT_COMMUNITIE, c);

                    for (let cu of c.communities_users) {
                        if (cu.user_id && cu.user_id === this.props.currentUser.user_id) {
                            nickname = cu.nickname;
                        }
                    }

                    community_id = parseInt(c.id);
                    community_name = c.name;
                    communities_users = c.communities_users;
                    break;
                }
            }
        }

        this.state = {
            swiper: null,
            community_id: community_id,
            community_name: community_name,
            communities_users: communities_users,
            nickname: nickname,
            render: true,
            load_operator_event: false,
            load_user_event: false,
            operator_events: [],
            user_events: [],
            hide_tip: true,
        };
    }

    componentDidMount() {

        this.props.onSetSidebarOpen(false);

        if (!StorageHelper.getValue('CongregateStateTip')) {
            setTimeout(() => {
                this.setState({
                    hide_tip: true,
                }, () => {
                    // this._showTooltip();
                });
            }, 2000);
        }

        if (this.state.community_id && this.state.community_id > 0) {
            setTimeout(() => {
                if (!this.state.nickname) {
                    this.props.showDialog(
                        "確認",
                        `コミュニティプロフィールを登録してください。`,
                        (e: any) => {
                            this.props.hideDialog();
                            this.props.history.push(`/community/${this.state.community_name}/profile`);
                        },
                        null,
                    );
                }
            }, 700);
        }

        // const dummy_events: Event[] = range(1, 10).map(i => {
        //     return {
        //         id: i,
        //         title: '競馬場行ってみませんか？',
        //         reserved_time: "2021-03-27 12:00",
        //         started_at: "2021-03-30 12:00",
        //         number_of_participants: 5,
        //         number_of_people: 6,
        //         organizer: {
        //             nickname: '田中太郎',
        //             avatar_url: "https://mag.sendenkaigi.com/hansoku/201909/images/086_01.jpg",
        //             introduction_tag_list: ['早稲田大学', '法学部'],
        //             email: 'hoge@yahoo.co.jp',
        //             phone_number: '0012321312',
        //         },
        //         operation_flag: false,
        //         image: "https://mag.sendenkaigi.com/hansoku/201909/images/086_01.jpg",
        //         place: "東京競馬場",
        //     } 
        // });
        // this.setState({'user_events': dummy_events, 'operator_events': dummy_events})
        this._fetchEvents(1, "operator");
        this._fetchEvents(1, "user");
    }

    load = 0;
    _showSpinner = (show: boolean) => {
        if (show) {
            this.load++;
        } else {
            this.load--;
        }
        this.props.showSpinner(this.load > 0);
    }

    _showTooltip = () => {
        ReactTooltip.hide();
        switch (this.tip) {
            case 0:
                ReactTooltip.show(this.tip1);
                break;
            case 1:
                ReactTooltip.show(this.tip2);
                break;
            default:
                this.setState({hide_tip: true});
                StorageHelper.setValue('CongregateStateTip', '1');
                break;
        }
        this.tip++;
    }

    page = 1;

    _fetchEvents = (page: number, event_type: "operator" | "user") => {

        var p1: any = {
            page: page,
            per: PER,
            operation_flag: event_type === "operator",
        };

        if (this.state.community_id) {
            p1.community_id = this.state.community_id;
        }

        this._showSpinner(true);

        CongregationsApi._fetchCongregations(
            p1,
            (responseJson: any) => {
                console.log('_fetchEvents', responseJson);
                this._showSpinner(false);
                switch (event_type) {
                    case "operator":
                        this.setState({operator_events: [...this.state.operator_events, ...responseJson.congregations]})
                        break;
                    case "user":
                        this.setState({user_events: [...this.state.user_events, ...responseJson.congregations]})
                        break;
                    default:
                        break;
                }
            },
            (error: any) => {
                this._showSpinner(false);
                this.setState({
                    operator_events: [],
                    user_events: [],
                });
                alert(error);
            },
        );
    }

    render() {
        console.log('Congregate props', this.props);
        console.log('Congregate state', this.state);

        // 運営公式のイベント
        var swiper_congregations_operator = [];
        for (let c of this.state.operator_events) {
            console.log('operator_events:', c);
            swiper_congregations_operator.push(
                <div key={c.id} className="admin-event-cell cursor" onClick={e => {
                    this.props.history.push('/congregate/detail/' + c.id);
                    }}>
                    <div className="cover">
                        <CommonImage src={c.image && c.image.content.url} alt={c.title}/>
                        <div className="cover-footer">
                            <div className="footer-desc">{Moment(c.reserved_time).format('MM月DD日 HH:mm')}</div>
                        </div>
                    </div>
                    <div className="subtext1">{c.place}</div>
                    <div className="title">{c.title}</div>
                    <div className="admin-info">
                        <div className="left-area">
                            <CommonImage src={c.organizer.avatar_url} alt={c.organizer.nickname}/>
                        </div>
                        <div className="right-area">
                            <div className="name">{c.organizer.nickname}</div>
                            <div className="subtext">{c.organizer.introduction_tag_list && c.organizer.introduction_tag_list.join(' ')}</div>
                        </div>
                    </div>
                    <div className="subtext1">参加者 {c.number_of_participants}名（{c.number_of_people}名まで募集）</div>
                </div>
            );
        }

        // ユーザーのイベント
        let swiper_congregations_user = [];
        for (let c of this.state.user_events) {
            console.log('user_events:', c);
            swiper_congregations_user.push(
                <div className="user-event-cell cursor" key={c.id} onClick={() => {
                    this.props.history.push('/congregate/detail/' + c.id);
                }}>
                    <div className="cover">
                        <CommonImage src={c.image && c.image.content.url} alt={c.title}/>
                        <div className="cover-footer">
                            <div className="footer-desc">{c.number_of_participants}人参加予定（{c.number_of_people}人まで）</div>
                        </div>
                    </div>
                    <div className="subtext1">{Moment(c.started_at).format('M月D日 H:m')}</div>
                    <div className="title">{c.title}</div>
                    <div className="subtext2">{c.place}</div>
                    <div className="user-info">
                        <div className="left-area">
                            <CommonImage src={c.organizer.avatar_url} alt={c.organizer.nickname}/>
                        </div>
                        <div className="right-area">
                            <div className="name">{c.organizer.nickname}</div>
                            <div className="subtext">{c.organizer.introduction_tag_list && c.organizer.introduction_tag_list.join(' ')}</div>
                        </div>
                    </div>
                </div>
            );
        }
        

        // コミュニティのユーザーをセット(モーダル表示)
        let user_list = [];
        for (let cu of this.state.communities_users) {
            // console.log('communities_users', cu);

            var img = no_image;
            if (cu.avatar_url) {
                img = cu.avatar_url;
            }

            user_list.push(
                <img src={img} alt=""/>
            );
        }

        // 新潟県人会用ページ
        if (this.state.community_id && this.state.community_id === 39) {
            return (<div className="Congregate row"><CommunityTop {...this.props}/></div>);
        }

        const is_newgame = this.state.community_id === 37;

        return (
            <div className="Congregate row">
                { this.state.render &&
                <Fragment>
                    {/* 参加メンバー */}
                    {this.state.community_id &&
                    <div className="col-12 user-list-cell cursor" onClick={() => {
                        this.props.history.push(`/community/${this.state.community_name}/member`);
                    }}>
                        <div className="title">
                            <p className="icon">
                                <img src={icon_user} className="icon" alt="icon"/>
                            </p>
                            <p className="sub-title">
                                参加メンバー<br/>
                                <small>メンバー数：{user_list.length}人</small>
                            </p>
                        </div>
                        <div className="user-list">
                            {user_list}
                        </div>
                        <div className="arrow">
                            <img src={arrow_right} className="arrow" alt="arrow-right"/>
                        </div>
                    </div>
                    }
                    {/* 使い方ガイド */}
                    {/*
                    <div className="guide cursor" onClick={(e) => {
                        window.open("https://note.com/titi_support/n/nea8e943ea5f1", "_blank");
                    }}>
                        <div className="guide-title">使い方ガイド</div>
                        <div className="guide-image-wrapper">
                            <img src={is_newgame ? newgame : logo} alt="guide"/>
                        </div>
                        <div className="guide-text">{this.state.community_name || "コミュニティ"}参加者で<br/>お得に楽しくあつまろう</div>
                    </div>
                     */}
                    {/* 運営公式イベント */}
                    <div className="col-12 list">
                        <div className="title">
                            <img src={is_newgame ? newgame : logo} className="main" alt=""/>
                            運営公式イベントに参加しませんか？
                        </div>
                    </div>
                    {swiper_congregations_operator.length > 0 &&
                    <div className="col-12 swiper">
                        <CongregateSwiper
                            key="swiper_congregations_else"
                            content={swiper_congregations_operator}
                            content_width={210}
                        />
                        {/*
                        <div className="large-button" onClick={() => {}}>
                            すべての公式イベントを表示する
                        </div>
                         */}
                    </div>
                    }
                    {this.state.load_operator_event &&
                    <FontAwesome
                        name='spinner'
                        size='2x'
                        spin
                    />
                    }
                    {!this.state.load_operator_event && swiper_congregations_operator.length === 0 &&
                        <p className="col-12 no-data">参加できるイベントがありません</p>
                    }
                    {/* みんなが企画したイベント */}
                    <div className="col-12 list">
                        <div className="title">
                            <img src={logo} className="main" alt=""/>
                            みんなが企画したイベント
                        </div>
                    </div>
                    <div className="col-12 user-event-list">
                        {swiper_congregations_user}
                    </div>
                    {!this.state.load_user_event && swiper_congregations_user.length === 0 &&
                        <p className="col-12 no-data">参加できるイベントがありません</p>
                    }
                    {/* イベント作成ボタン */}
                    {/* <div className="create-event-button">
                        イベント<br/>作成
                    </div> */}
                </Fragment>
                }
                { !this.state.hide_tip &&
                <Fragment>
                    <ReactTooltip effect="solid" place="bottom" multiline={true}/>
                    <div className="tip-bg" onClick={e => this._showTooltip()}></div>
                </Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Congregate);
