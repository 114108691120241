import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import firebase, {auth, database, functions} from "../../firebase";
import Moment from 'moment';

import StorageHelper, {CURRENT_USER} from '../../helpers/StorageHelper';
import UserManagementApi from '../../helpers/api/UserManagementApi';
import TalkApi from '../../helpers/api/TalkApi';

import Header from '../../components/Header';
import no_image from 'assets/no_image.png';

import './Talk.css';

interface TalkProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface TalkStates {
    render: boolean;
    room_key: string;
    messages: {
        createdAt: string;
        text: string;
        user: {
            name: string | null;
            avatar: string | null;
        }
    }[];
    users: {
        avatar_url: string;
        profile: {
            first_name: string | null;
            last_name: string | null;
        }
    }[];
}

class Talk extends React.Component<TalkProps, TalkStates> {

    private roomMsgRef: any;
    private currentUser: any;
    private form: any;

    constructor(props: any) {

        super(props);

        // console.log('talk id:', props.match.params.id);
        this.state = {
            render: false,
            room_key: props.match.params.id,
            messages: [],
            users: [],
        };

        this.currentUser = StorageHelper.getData(CURRENT_USER);
        this.roomMsgRef = database.ref(`room_messages/${props.match.params.id}`);
    }

    componentDidMount() {
        this.props.showSpinner(true);
        this.roomMsgRef.orderByChild('updated_at').on('value', this._handleUpdate);

        // setTimeout(() => {
        //     this.setState({
        //         message: this.props.message,
        //     });
        //     this.forceUpdate();
        // }, 1000);
    }

    handleFormSubmit(e: any) {
        e.preventDefault();
        this._onSend();
    }

    _onSend() {

        let comment = this.form.message.value;
        if (!comment) {
            return;
        }

        const message = {
            room_key: this.state.room_key,
            comment: comment,
            user_id: this.currentUser.user_id,
            created_at: Moment(new Date()).toISOString(),
            updated_at: Moment(new Date()).toISOString(),
        };

        // console.log('_onSend', message);

        this.props.showSpinner(true);

        const createMessages = functions.httpsCallable('createMessages');
        createMessages(message).then(({data}) => {
            // console.log('httpsCallable OK', data);

            TalkApi._fetchTalkMessagesCreate(
                201,
                {
                    content: comment,
                    communities_user_id: this.currentUser.user_id,
                },
                (responseJson: any) => {
                    this.form.message.value = '';
                },
                (error: any) => {
                    alert(error);
                },
            );


            // this.setState({message: ''});
        })
            .catch((httpsError: any) => {
                // console.log('httpsCallable NG ', httpsError);
                alert(httpsError.message);
            });
    }

    _handleUpdate = (snapshot: any) => {

        var messages = [];
        for (let key in snapshot.val()) {

            var value = snapshot.val()[key];

            let message = {
                _id: key,
                text: value.comment,
                createdAt: value.created_at,
                user: {
                    _id: value.user_uid,
                    user_id: value.user_id,
                    name: null,
                    avatar: null,
                },
            };
            messages.push(message);
        }

        this.setState({
            render: true,
            messages: messages,
        });

        this._fetchUser(messages);
    }

    _fetchUser = (messages: any) => {

        var user_ids = [];
        for (let message of messages) {
            user_ids[message.user.user_id] = true;
        }

        this.props.showSpinner(false);

        for (let key in user_ids) {
            UserManagementApi._fetchUsers(
                key,
                {},
                (responseJson: any) => {

                    var user_list = this.state.users;
                    user_list[responseJson.id] = responseJson;

                    this.setState({
                        users: user_list,
                    });

                    this._updateMessages(messages);
                },
                (error: any) => {
                    alert(error);
                },
            );
        }
    }

    _updateMessages = (messages: any) => {
        for (let message of messages) {
            if (this.state.users[message.user.user_id]) {
                const user = this.state.users[message.user.user_id];
                message.user.name = `${user.profile.last_name} ${user.profile.first_name} `;
                message.user.avatar = user.avatar_url;
            }
        }

        setTimeout(() => {
            this.setState(previousState => ({
                messages: messages,
            }));
        }, 10);
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        var list: {}[] = [];

        for (let message of this.state.messages) {


            let createdAt = message.createdAt ? Moment(message.createdAt).format('YYYY-MM-DD h:mm') : '--:--';

            list.push(
                <div className="col-12 cell">
                    <div className="row">
                        <div className="col-2">
                            <img src={message.user.avatar || no_image} className="room-image"/>
                        </div>
                        <div className="col-10 room-box">
                            <p className="room-user-name">{createdAt} {message.user.name}</p>
                            <p className="room-text">{message.text}</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="Talk row">
                <Header
                    title={'トーク'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}>
                </Header>
                { this.state.render &&
                <div className="col-12">
                    {list}
                </div>
                }
                <div className="footer row">
                    <div className="col-12 form">
                        <form
                            onSubmit={this.handleFormSubmit.bind(this)}
                            ref={el => this.form = el && el.elements}
                        >
                            <textarea name={'message'} rows={2} required/>
                            <button type="submit" className="btn btn-secondary">送信</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Talk);
