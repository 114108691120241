import {Action} from 'redux';

export type CurrentUserPayload = {    // todoを追加する時に必要なのはtodoの内容くらい
    name: string;
    token: string;
    user_id: string;
    avatar_url?: string;
    profile?: {
        address: string;
        birth: string;
        email: string;
        first_name: string;
        gender: string;
        last_name: string;
        user_id: number;
    } | null;
    communities?: {
        id: string;
        name: string;
        communities_users: {
            id: number;
            user_id: string;
            nickname?: string;
        }[];
        community_image?: {
            image: {
                content: {
                    url: string;
                }
            }
        };
    }[] | null;
};

export interface CurrentUserAction extends Action {
    type: 'CURRENT_USER';
    payload: CurrentUserPayload;
}

export const currentUser = (payload: CurrentUserPayload): CurrentUserAction => {
    return {
        payload,
        type: 'CURRENT_USER',
    };
};