import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import queryString from 'query-string';
import {actionCreator, RootState} from '../../reducers';
import InvitationManagementApi from '../../helpers/api/InvitationManagementApi';

import './CommunityInvite.css';
import Header from '../../components/Header';

interface CommunityInviteProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
}

interface CommunityInviteStates {
    community_name: string,
    code?: string | string[] | null,
    id?: string | string[] | null,
    type?: string | string[] | null,
}

class CommunityInvite extends React.Component<CommunityInviteProps, CommunityInviteStates> {

    constructor(props: any) {
        super(props);

        const values = queryString.parse(props.location.search);

        this.state = {
            community_name: props.match.params.name,
            code: values.c,
            id: values.i,
            type: values.type,
        };
    }

    componentDidMount() {
        this.fetchInvitation();
    }

    /**
     * コミュニティ招待実施
     */
    fetchInvitation = () => {

        if (!this.state.id || !this.state.code) {
            this.props.showDialog(
                "確認",
                `URLが不正です。`,
                (e: any) => {
                    this.props.hideDialog();
                    this.props.history.push(`/home`);
                },
                null,
            );
            return;
        }
        // 招待コード
        this.props.showSpinner(true);
        InvitationManagementApi._fetchInvitation(
            {
                invitation: {
                    inviteable_id: this.state.id,
                    inviteable_type: 'InvitationCode',
                },
                invitation_code: {
                    content: this.state.code,
                }
            },
            (responseJson: any) => {
                // console.log('_fetchInvitation', responseJson);
                this.props.history.push(`/community/${this.state.community_name}/detail`);
            },
            (error: any) => {
                alert(error);
                this.props.showSpinner(true);

                this.props.history.push(`/home`);
                return;
            },
        );
    }

    render() {
        return (
            <div className="CommunityInvite row">
                <Header
                    title={'コミュニティ招待'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityInvite);
