import firebase, {auth} from "../firebase";
import StorageHelper, {CURRENT_USER} from './StorageHelper';
import TalkApi from './api/TalkApi';

export default class TalkHelper {

    static _createRooms( title = 'NO TITLE', community_id, ids, fb_uids , type , image_url , comp = null , production_id = null ) {

        let currentUser = StorageHelper.getData(CURRENT_USER);

        const current_uid = currentUser.firebase_uid;

        TalkApi._fetchTalksCreate(
            {
                title: title,
                communities_user_ids: ids,
                community_id: community_id,
            },
            (responseJson) => {

                // console.log('_fetchTalks', responseJson);

                var user_uids = {};
                user_uids[current_uid] = true;
                for (let uid of fb_uids) {
                    user_uids[uid] = true;
                }

                const current_id = currentUser.user_id;

                var user_ids = {};
                user_ids[current_id] = true;
                for (let id of ids) {
                    user_ids[id] = true;
                }

                const param = {
                    room_id: responseJson.id,
                    community_id: community_id || 0,
                    name: title || '',
                    comment: '',
                    type: type,
                    user_ids: user_ids,
                    user_uids: user_uids,
                    created_at: new Date(),
                    updated_at: new Date(),
                    image_url: image_url,
                    production_id: production_id,
                };

                // console.log('httpsCallable param', param);
                // console.log('httpsCallable param', image_url);

                const createRooms = firebase.functions().httpsCallable('createRooms');
                createRooms(
                    param
                )
                .then(({ data }) => {
                        // console.log('httpsCallable OK', data);
                    comp(data.room);
                })
                .catch(httpsError => {
                        // console.log('httpsCallable NG ', httpsError);
                });

            },
            (error) => {
                alert(error);
            },
        );
    }
}
