import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { actionCreator, RootState } from '../../reducers';

import './Create.css';
import Header from '../../components/Header';
import { Product } from '../../helpers/schemas/Api';
import ProductionApi from '../../helpers/api/ProductionApi';
import CommonImage from '../../components/CommonImage';
import FormImageList from '../../components/FormImageList';
import ReviewApi from '../../helpers/api/ReviewApi';
import CommonUserInfo from '../../components/CommonUserInfo';
import CommonStars from '../../components/CommonStars';

const StarRatings = require("react-star-ratings").default;

interface CreateProps {
    history: any;
    setCurrentUser: (name: string, token: string) => void;
    currentUser: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
}

interface CreateStates {
    product?: Product,
    productId: number,
    review_text: string,
    review_rate: number,
    review_images: any[],
}

class Create extends React.Component<CreateProps, CreateStates> {

    constructor(props: any) {
        super(props);
        this.state = {
            productId: props.match.params.id,
            review_text: '',
            review_rate: 0,
            review_images: [],
        };
    }

    componentDidMount() {
        this._fetchProduct();
    }

    _onSubmitReview = () => {
        this.props.showSpinner(true);
        let error_msgs = this._errorMessages()
        if (error_msgs.length == 0) {
            let params: { rate: number, comment: string, image?: string } = {
                rate: this.state.review_rate,
                comment: this.state.review_text,
            }

            const { review_images } = this.state
            console.log('review_images', review_images);
            if (review_images.length > 0) {
                params['image'] = review_images[0]
            }

            ReviewApi._fetchProductReviewCreate(
                this.state.productId,
                params,
                (res: any) => {
                    this.props.showSpinner(false);
                    alert('レビューを投稿しました');
                    this.props.history.goBack();
                },
                (error: any) => {
                    this.props.showSpinner(false);
                    console.log(error);
                    alert(error);
                })
        } else {
            this.props.showSpinner(false);
            error_msgs = error_msgs.map(msg => '・' + msg);
            alert(error_msgs.join("\n"));
        }
    }

    _errorMessages() {
        const { review_text, review_rate, } = this.state;
        var msgs = []
        if (!review_text) {
            msgs.push("レビューを入力してください")
        }
        if (review_rate == 0) {
            msgs.push("星を入力してください")
        }
        return msgs;
    }

    _fetchProduct = () => {
        this.props.showSpinner(true);
        ProductionApi._fetchProductsShow(
            this.state.productId,
            {},
            (product: Product) => {
                this.setState({ product });
                this.props.showSpinner(false);
            },
            (error: any) => {
                alert(error);
                console.log(error);
                this.props.showSpinner(false);
            },
        )
    }

    render() {
        const { product, review_rate, review_text } = this.state;
        if (!product) { return (<div />) }

        return (
            <div className="Create">
                <Header
                    title={'レビューを作成'}
                    rightButtonTitle={'投稿'}
                    rightButtonAction={this._onSubmitReview}>
                </Header>

                <CommonUserInfo upperText={`${product.communities_user.nickname}`}
                    imageUrl={product.user.avatar_url} descripion={`- ${product.title}`} 
                    className='user-info-cell'/>

                <Border />

                <div className='star-cell'>
                    <div className='star-info'>出品者に点数は伝わりません - 平均点数しか表示されません</div>
                    <CommonStars 
                        numberOfStars={5}
                        rating={review_rate}
                        changeRating={(rating: number) => this.setState({ review_rate: rating })}
                        starDimension={'40px'}
                        starSpacing={'1vw'}
                        className={'form-star-ratings'}
                    />
                </div>

                <Border />

                <FormSection>写真（任意）</FormSection>
                <FormImageList numberOfImages={1} imageList={this.state.review_images}
                    onChange={(imageList) => { this.setState({ review_images: imageList }) }}
                    imageOptionText={'任意'}
                />
                <div className={'form-margin'} />
                {/* <FormSection>レビュー</FormSection> */}
                <textarea className='form-input-text large' name={'reiew_text'} placeholder={'どうでしたか？'} onChange={(e) => this.setState({ review_text: e.currentTarget.value })} value={review_text}></textarea>

            </div>
        );
    }
}

const FormSection = ({ children }: { children?: ReactNode }) => (<div className="form-section">{children}</div>)

const Border = () => (<div className='border' />)

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Create);
