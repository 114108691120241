import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import FontAwesome from "react-fontawesome";
import Moment from 'moment';
import {JOINED_STATUS, APPROVAL_STATUS} from '../../Construct';
import StorageHelper, {CURRENT_COMMUNITIE} from '../../helpers/StorageHelper';

import Header from '../../components/Header';
import CommonCell from '../../components/CommonCell';

import CongregationsApi from '../../helpers/api/CongregationsApi';

import './CommunityEventList.css';

const PER = 20;

interface CommunityEventListProps {
    history: any;
    onSetSidebarOpen: (open: boolean) => void;
    showSpinner: (show: boolean) => void;
    showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => void;
    hideDialog: () => void;
    currentUser: {
        user_id: string;
        communities?: {
            id: string;
            name: string;
            communities_users: {
                id: number;
                user_id: string;
                // nickname?: string;
            }[];
            community_image?: {
                image: {
                    content: {
                        url: string;
                    }
                }
            };
        }[] | null;
    };
}

interface CommunityEventListStates {
    // event_list: Event[],
    community_id?: number;
    community_name?: string;
    event_list: {
        id: number;
        title: string;
        reserved_time: string;
        status: number;
        invitation_code: string;
        store: {
            place: string;
            reduction_ratio: string;
        };
        seat: {
            decision_number_of_people: number;
            number_of_people: number;
        };
        course: {
            name: string;
            price: number;
            benefits: string;
            images?: {
                content: {
                    url: string;
                }
            }[];
        };
        congregation_roles: {
            id: string;
            communities_user: string;
            role: number;
            nickname: string;
            avatar_url: string;
        }[];
        image?: string;
        number_of_participants?: number;
        place?: string;
        address?: string;
    }[];
}

class CommunityEventList extends React.Component<CommunityEventListProps, CommunityEventListStates> {

    load = 0;

    constructor(props: any) {
        super(props);


        var community_id = undefined;
        var community_name = undefined;
        if (this.props.currentUser.communities) {
            for (let c of this.props.currentUser.communities) {
                if (c.name == props.match.params.name) {

                    // 選択中のコミュニティをセットする
                    StorageHelper.setData(CURRENT_COMMUNITIE, c);
                    community_id = parseInt(c.id);
                    community_name = c.name;
                    break;
                }
            }
        }

        this.state = {
            community_id: community_id,
            community_name: community_name,
            event_list: [],
        };
        this._fetchCongregationsJoin(1);
    }

    async componentDidMount() {
        this.props.onSetSidebarOpen(false);
    }

    _showSpinner = (show: boolean) => {
        if (show) {
            this.load++;
        } else {
            this.load--;
        }
        this.props.showSpinner(this.load > 0);
    }

    _fetchCongregationsJoin = (page: number) => {

        // 参加済み
        var p2: any = {
            page: page,
            per: PER,
            joined_status: JOINED_STATUS.JOIN,
            approval_status: APPROVAL_STATUS.APPROVAL, // 承認済
        };

        if (this.state.community_id) {
            p2.community_id = this.state.community_id;
        }

        p2.start_date = Moment(new Date()).format('YYYY-MM-DD'); // 本日以降-１年間
        p2.end_date = Moment(new Date()).add(1, 'year').format('YYYY-MM-DD');

        this._showSpinner(true);

        CongregationsApi._fetchCongregations(
            p2,
            (responseJson: any) => {
                // console.log('_fetchCongregations', responseJson);
                this._showSpinner(false);

                this.setState({
                    event_list: this.state.event_list.concat(responseJson.congregations),
                });
            },
            (error: any) => {
                this._showSpinner(false);
                this.setState({
                    event_list: [],
                });
                alert(error);
            },
        );
    }

    render() {

        // 過去イベントを参考にする
        var swiper_event_list = [];
        for (let event of this.state.event_list) {

            var image_url;
            if (event.image) {
                image_url = event.image;
            }

            swiper_event_list.push(
                <CommonCell
                    date={event.reserved_time}
                    image={image_url}
                    title={event.title}
                    location={event.place}
                    text={event.number_of_participants && event.number_of_participants > 0 ? ('参加者：' + event.number_of_participants) : ''}
                    onClick={() => {
                        this.props.history.push('/congregate/detail/' + event.id);
                    }}
                />
            );
        }

        return (
            <div className="CommunityEventList row">
                <Header
                    title={'イベント一覧'}
                    leftButtonAction={() => this.props.onSetSidebarOpen(true)}
                />
                {this.load == 0 && swiper_event_list}

                {swiper_event_list.length == 0 &&
                <p className="col-12 no-data">参加予定のイベントがありません</p>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        currentUser: state.currentUser.currentUser,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        onSetSidebarOpen: (open: boolean) => {
            dispatch(actionCreator.sideMenu.sideMenu({
                sidebarOpen: open,
            }));
        },
        showSpinner: (show: boolean) => {
            dispatch(actionCreator.spinner.spinner({
                show: show,
            }));
        },
        showDialog: (title: string, text: string, onClickYes: any, onClickNo: any) => {
            dispatch(actionCreator.dialog.showDialog({
                show: true,
                title: title,
                text: text,
                onClickYes: onClickYes,
                onClickNo: onClickNo,
            }));
        },
        hideDialog: () => {
            dispatch(actionCreator.dialog.hideDialog());
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityEventList);
