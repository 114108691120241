import React from 'react';
import {connect} from 'react-redux';
import {Action, Dispatch} from 'redux';
import {actionCreator, RootState} from '../../reducers';
import {Link} from 'react-router-dom';

import 'App.css';
import 'bootstrap/dist/css/bootstrap.css';

import firebase, {auth} from "../../firebase";

interface UIProps {
    history: any;
    currentUser: any;
    onSubmit: (text: string) => void;
    todos: {
        id: number;
        text: string;
        completed: boolean;
    }[];
}

interface UIState {
}

class UI extends React.Component<UIProps, UIState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        // if (!this.props.currentUser.token) {
        //     this.props.history.push('/login');
        // }
        auth.signOut();
    }

    // clickHandler = () => {
    //     this.props.onSubmit('toggleClickHandler');
    // }

    onReload = (e: any) => {
        window.location.reload(true);
    }

    render() {

        // console.log('props', this.props);
        // console.log('state', this.state);

        return (
            <div className="app">
                <h1>UI SAMPLE</h1>

                <h1>LINK</h1>
                <p><Link to='/signin'>LOGIN</Link></p>
                <p><Link to='/home'>HOME</Link></p>
                <p><a href="#" onClick={this.onReload}>キャッシュクリア</a></p>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        // currentUser: state.currentUser.currentUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
    return {
        // onSubmit: (text: string) => {
        //     dispatch(actionCreator.todos.addTodo({text}));
        // }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UI);
