import React from 'react';

import './CommonIconTitle.css';

interface CommonIconTitleProps {
    src: any;
    text?: string | any;
    map?: string;
    onClick?: any;
    tel?: string;
}

interface CommonIconTitleStates {

}

class CommonIconTitle extends React.Component<CommonIconTitleProps, CommonIconTitleStates> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {

        var c = '';
        if (this.props.onClick) {
            c += ' underline cursor';
        }
        if (this.props.tel) {
            c += ' tel';
        }

        return (
            <p className={`CommonIconTitle meta${c}`} onClick={this.props.onClick}>
                {this.props.src &&
                <img src={this.props.src} alt="" className="icon"/>
                }

                {this.props.tel &&
                <a href={'tel:' + this.props.tel}>
                    {this.props.tel}
                </a>
                }

                {this.props.text && this.props.text}

                {this.props.map &&
                <a href={`https://www.google.com/maps/search/?api=1&query=${this.props.map}`} target="_blank" className="btn btn-xs btn-secondary btn-map">マップ</a>
                }
            </p>
        );
    }
}

export default CommonIconTitle;
