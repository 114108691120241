import React from 'react';
import BaseApi from './BaseApi';

export default class StocksApi extends BaseApi {

    /**
     * GET /api/v1/stores/:store_id/stocks
     */
    static _fetchStocks(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/stocks', 'GET', params, success, error);
    }

    /**
     * GET /api/v1/stores/:store_id/stocks/:id
     */
    static _fetchStocksShow(store_id, id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/stocks/' + id, 'GET', params, success, error);
    }

    /**
     * POST /api/v1/stores/:store_id/stocks
     */
    static _fetchStocksCreate(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/stocks', 'POST', params, success, error);
    }

    /**
     * POST /api/v1/stores/:store_id/stocks
     */
    static _fetchStocksCreateBatch(store_id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/stocks/create_batch', 'POST', params, success, error);
    }

    /**
     * DELETE /api/v1/stores/:store_id/stocks/:id
     */
    static _fetchStocksDelete(store_id, id, params, success, error) {
        super._fetch('/api/v1/stores/' + store_id + '/stocks/' + id, 'DELETE', params, success, error);
    }
}
